import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/services/course.service';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs/operators';
import { CourseAssessmentShareService } from 'src/app/services/course-assessment-share.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginComponent } from 'src/app/authenticate/login/login.component';
// import { NgxChartsModule } from '@swimlane/ngx-charts';
// import { single } from './../data';

@Component({
  selector: 'app-assessment-graph',
  templateUrl: './assessment-graph.component.html',
  styleUrls: ['./assessment-graph.component.scss']
})
export class AssessmentGraphComponent implements OnInit {
 
  courseName:string;
  course__Id:string;
  session_id:string;
  assessmentId:string;
  graphAssessmentData;
  assessmentName:string;
  attemtedStudentData;
  missedStudentData;
  attemtedStudentList = [];
  missedStudentList = [];
  graphAssessmentQUS = [];
  
  view: any[] = [500, 300];

  // options
  gradient: boolean = false;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition:string = 'right';
  colorScheme = {
    domain: ['#EC5F5F', '#1CCC26', '#C7B42C', '#AAAAAA','#AAE3F5','#7AA3E5','#E44D25']
  };

 
  single;
  loader: boolean = false;
  respData = [];
  course_category_id;
  course_category;
  topicId;
  sessionkey;  
  isTA:string
  last_Update_at:any = null;
  graphData = [];
  activeIdNav:any = 1;
  ngbNavItem:any = 1
  constructor(private courseService: CourseService,
    private _notificationService: NotificationService,
    private _courseAssessmentShareService: CourseAssessmentShareService,
    private activatedRoute:ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    this.courseName = this.activatedRoute.snapshot.queryParamMap.get('course');
    this.course__Id = this.activatedRoute.snapshot.queryParamMap.get('course_id');
    this.activeIdNav = Number(this.activatedRoute.snapshot.queryParamMap.get('tab_no'));
    this.assessmentId = this.activatedRoute.snapshot.queryParamMap.get('assessment_id');
    this.session_id = this.activatedRoute.snapshot.queryParamMap.get('session_id');
    this.course_category_id = this.activatedRoute.snapshot.queryParamMap.get('course_category_id');
    this.course_category = this.activatedRoute.snapshot.queryParamMap.get('course_category');
    this.topicId = this.activatedRoute.snapshot.queryParamMap.get('topicId');
    this.sessionkey = this.activatedRoute.snapshot.queryParamMap.get('sessionkey');
    this.isTA = this.activatedRoute.snapshot.queryParamMap.get('isTA');
    this.ngbNavItem = this.activatedRoute.snapshot.queryParamMap.get('ngbNavItem');

    this.getGraphAssessment();
    // this.courseAssessmentData = this._courseAssessmentShareService.getCourseAssessment();
    // this.sessionId = this._courseAssessmentShareService.getSessionId();
    // this.courseId = this._courseAssessmentShareService.getCourseId();
    // this.topicId = this._courseAssessmentShareService.getTopicId();
  
    // this.getStudentAssessmentData();

  }


  getGraphAssessment(){
    this.courseService.graphAssessment(this.assessmentId, this.course__Id).subscribe((response)=>{
      this.graphAssessmentData = response;
      this.last_Update_at = this.graphAssessmentData.updated_at;
      this.assessmentName = this.graphAssessmentData.assessment_name;
      this.attemtedStudentData = this.graphAssessmentData.attempted_percent;
      this.missedStudentData = this.graphAssessmentData.missed_percent;
      this.attemtedStudentList = this.graphAssessmentData.attempted;
      this.missedStudentList = this.graphAssessmentData.missed;
      this.graphAssessmentQUS = this.graphAssessmentData.questions;
      this.graphData = [
        {
          "name": "Missed",
          "value": this.missedStudentData
        },
        {
          "name": "Attempted",
          "value": this.attemtedStudentData
        }
      ]
    })
  }

  go_to_url() {
    let url = '/courses/single-course'
    let body;
    if (this.session_id) {
      body = {
        course_id: this.course__Id,
        session_id: this.session_id,
        course_category: this.course_category,
        course_category_id: this.course_category_id,
        topicId:this.topicId,
        sessionkey: this.sessionkey,
        isTA:this.isTA,
        course:this.courseName,
        tab_no:this.activeIdNav,
        ngbNavItem: this.ngbNavItem
      }
      url = '/courses/video-with-notes'
    } else {
      body = {
        course_id: this.course__Id,
        course:this.courseName,
        tab_no:this.activeIdNav
      }
    }
    this.router.navigate([url], { queryParams: body })
  }

  // getAlphabet(val) {
  //   return (val + 9).toString(36).toUpperCase();
  // }

  // getStudentAssessmentData() {
  //   if (this.courseAssessmentData) {

  //     let assessment_id, schedule_id, topic_id;
  //     assessment_id = this.courseAssessmentData._id;
  //     schedule_id = this.courseAssessmentData.schedule_id ? this.courseAssessmentData.schedule_id : '';
  //     topic_id = this.topicId ? this.topicId : '';
  //     this.loader = true;
  //     this.courseService.submissionAssessmentQusDetail(this.courseId, this.sessionId, schedule_id, topic_id, assessment_id).pipe(
  //       finalize(() => {
  //         this.loader = false;
  //       })).subscribe(
  //         (response) => {
  //           this.respData = response.questions;

  //         },
  //         (error) => {
  //           this.loader = false;
  //           this._notificationService.showNotification("error", error.message);
  //         })
  //   }
  // }

  // redirect() {
  //   let path = '';
  //   if(this.courseAssessmentData) path = '/courses/single-course';
  //   else  path = '/courses';
  //   let course_name = '';
  //   course_name = this._courseAssessmentShareService.getCourseName();
  //   this.router.navigate([path], { queryParams: { course_id: this.courseId, course: course_name} });    
  // }

  // onLabelClick(event: any, data: any) {
  //   let result = data.find((obj: any) => {
  //       return obj.extra.displayName === event;
  //   })
  //   this.onSelect(result);
  // }
  // onSelect(data): void {
  //   // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  // }

  // onActivate(data): void {
  //   // console.log('Activate', JSON.parse(JSON.stringify(data)));
  // }

  // onDeactivate(data): void {
  //   // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  // }

  // getColor(idx){
  //   return this.colorScheme.domain[idx];
  // }

  //FOR BUILD FIX
  legendTitle: any = '';
}


