import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

import { NotificationService } from 'src/app/services/notification.service';
import { CourseService } from 'src/app/services/course.service';
import { finalize } from 'rxjs/operators';
import { FormGroup, FormArray, FormControl, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseAssessmentShareService } from 'src/app/services/course-assessment-share.service';
import { Route } from '@angular/compiler/src/core';
import { queue } from 'rxjs/internal/scheduler/queue';
import { timer, Subscription } from 'rxjs';
import { HandleUploadService } from 'src/app/shared/modules/shared/services/handle-upload.service';



@Component({
  selector: 'app-submit-course-assessment',
  templateUrl: './submit-course-assessment.component.html',
  styleUrls: ['./submit-course-assessment.component.scss']
})
export class SubmitCourseAssessmentComponent implements OnInit {
  courseAssessmentData: any;
  courseId: any;
  topicId: any;
  sessionId: any;
  course_category: any;
  course_category_id: any;
  sessionkey: any;
  _id: any
  role = '';
  @Output() valueChange = new EventEmitter();
  @Output() hideCrossChange = new EventEmitter();
  @Output() title = new EventEmitter();
  assessmentData: any;
  submitAssessmentForm: FormGroup;
  multiAns: FormArray;
  multiQus: any;

  loader: boolean = false;
  alertMsg: string;
  notAnsweredQusPopup: boolean = false;
  answerCounter: number = 0;
  private modalRef;
  imageURL;
  public assessmentTimer: string = '';
  timedAssessmentPopup: boolean = false;
  alertTimedAssessmentMsg: String;
  timerInterval: any;


  countDown: Subscription;
  counter = 0;
  tick = 1000;
  hours: any
  second: any
  minutes: any
  timest: string;
  remainCounter=0;
  session_id:string;
  course_id:string;
  timedAnswer:any = [];
  courseName:string = null;
  activeIdNav:any = 1;


  // audio/video popup variable
  openVideoAudioPopup:boolean = false
  //check upload status
  moveButtonDisabledAction:boolean = false

  ngbNavItem:any = 1;

  constructor(private courseService: CourseService,
    private _notificationService: NotificationService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private _courseAssessmentShareService: CourseAssessmentShareService,
    private route: Router,
    private ActivateRoute: ActivatedRoute,
    private handleUploadService: HandleUploadService) { }

  ngOnInit() {
    this.course_id = this.ActivateRoute.snapshot.queryParamMap.get('course_id');
    this.courseName = this.ActivateRoute.snapshot.queryParamMap.get('course');
    this.courseName = this.ActivateRoute.snapshot.queryParamMap.get('course');
    this.topicId = this.ActivateRoute.snapshot.queryParamMap.get('topic_id');
    this.session_id = this.ActivateRoute.snapshot.queryParamMap.get('session_id');
    this.activeIdNav = Number(this.ActivateRoute.snapshot.queryParamMap.get('tab_no'));
    this.ngbNavItem = this.ActivateRoute.snapshot.queryParamMap.get('ngbNavItem');
    this.role = localStorage.getItem('role');
    this.get_submit_assessment()
    this.validateForm(); 
    this.getSubmissionAssessmentDetail();   
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    if(event.keyCode === 123 || event.keyCode === 42 || (event.keyCode === 42 && event.key === 'p')){ 
      event.preventDefault(); 
    } 
    if (event.ctrlKey && 'cvxspwuaz'.indexOf(event.key) !== -1) {
      event.preventDefault()
    }
  }

  displayTimer() {
    //console.log(this.courseAssessmentData.start_time);
    if(this.courseAssessmentData.start_time){
      this.timer(this.courseAssessmentData.start_time);
    }
    //FOR TIMER STOP FOR CALENDERED |START |17-FEB-2023
    if ((this.courseAssessmentData.is_timed) && this.courseAssessmentData.total_time != 0) {
      this.timer(this.courseAssessmentData.total_time);
    }
    //console.log(this.courseAssessmentData.total_time * 60);
    //FOR TIMER STOP FOR CALENDERED |START |17-FEB-2023
    this.counter = (this.courseAssessmentData.total_time * 60) + 1;
    this.countDown = timer(0,this.tick).subscribe((count) => {
      if (this.counter == 0 && count) {
        // console.log('timer completed', count, this.counter);
        if (this.countDown) {
          this.countDown.unsubscribe();
        }
        return;
      }
      if(this.counter > 0){
        this.counter--;
      }
    });
  }

  validateForm(): void {
    this.submitAssessmentForm = this.formBuilder.group({
      questions: this.formBuilder.array([]),
    })
    this.handleUploadService.addForm = this.submitAssessmentForm;
  }
  createQus(): FormGroup {
    return this.formBuilder.group({
      answer: new FormArray([]),
      mark: [false]
    })
  }

  createAns(): FormGroup {
    return this.formBuilder.group({
      value: [''],
      speech_text: [''],
      ans_recorded_audio_key:[''],
      ans_recorded_video_key:['']
    });
  }

  addQus() {
    this.multiQus = this.submitAssessmentForm.get('questions') as FormArray;
    this.multiQus.push(this.createQus());
  }
  addAnswer(i) {

    this.multiAns = <FormArray>this.submitAssessmentForm.get('questions')['controls'][i].get('answer');
    this.multiAns.push(this.createAns());

  }

  selectedCheck(event, i, ii, val) {

    if (event.target.checked) {
      this.submitAssessmentForm.controls.questions['controls'][i].controls.answer.controls[ii].controls.value.setValue(val);
    } else {
      this.submitAssessmentForm.controls.questions['controls'][i].controls.answer.controls[ii].controls.value.setValue("");
    }

  }
  assNme: any;
  remaining_time:any
  assessment_id;
  assessmentIsTrue
  negative_marking_status:boolean = false;
  getSubmissionAssessmentDetail() {   
    let assessmentType = '';
    let assessmentId;
    if (this.courseAssessmentData.schedule_id == "") {
      assessmentType = 'assessment_id';
      assessmentId = this.courseAssessmentData._id;
    } else {
      assessmentType = 'schedule_assessment_id';
      assessmentId = this.courseAssessmentData.schedule_id;
    } 
    
    
    this.assessment_id = assessmentId;
    //FOR SESSION LEVEL ASSESSMENT
    //FOR TIMER STOP FOR CALENDERED |START |17-FEB-2023
    /*if(!this.courseAssessmentData.is_timed) {
      if(this.courseAssessmentData.active && this.courseAssessmentData.total_time != 0) {
        this.courseAssessmentData.is_timed = true;
      }
      else {
        this.courseAssessmentData.is_timed = false;
      }
    }*/
    //FOR TIMER STOP FOR CALENDERED |END |17-FEB-2023


    if(this.topicId === undefined || this.topicId === null){
      this.topicId = '';
    }
    if(this.session_id === undefined || this.session_id === null){
      this.session_id = '';
    }
    if(this.courseId === undefined || this.session_id === null){
      this.courseId = '';
    }
    this.assessmentIsTrue = this.courseAssessmentData.is_timed;
    this.loader = true;
    
    this.courseService.getSubmissionAssessment(assessmentType, assessmentId,this.assessmentIsTrue, this.courseId,this.topicId, this.session_id).pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe(
        (response) => {
          this.assessmentData = response.assessment;
          this.assNme = this.assessmentData.assessment_name;
          this.negative_marking_status = this.assessmentData.negative_marking_status;
          //console.log(this.assessmentData)
          if(this.assessmentData.remaining_time) {
            this.remaining_time = this.assessmentData.remaining_time
            this.assNme = this.assessmentData.assessment_name;
             // your input string
            let hms = this.remaining_time;  
            // split it at the colons
            let a = hms.split(':'); 
            // minutes are worth 60 seconds. Hours are worth 60 minutes.
            let seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
            this.remainCounter = seconds

            this.timer(seconds/60);

            this.countDown = timer(0,this.tick).subscribe((count) => {
              if (this.remainCounter == 0 && count) {
                // console.log('timer completed', count, this.remainCounter);
                if (this.countDown) {
                  this.countDown.unsubscribe();
                }
                return;
              }
              if(this.remainCounter > 0){
                this.remainCounter--;
              }
            });
          }


          if (this.assessmentData) {
            if(!this.remaining_time) {
              //FOR DISPLAY TIMER
              this.displayTimer();
            }

            for (let i = 0; i < this.assessmentData.questions.length; i++) {
              let quess = this.assessmentData.questions[i];
              this.addQus();

              if (this.assessmentData.questions[i].question_type == 'multianswermcq') {
                for (let j = 0; j < this.assessmentData.questions[i].option.length; j++) {
                  this.addAnswer(i);
                }
              } else { 
                this.addAnswer(i);
              }
              
              
              if (quess.question_type == 'text'){                
                for (let j = 0; j < quess.answer.length; j++) {
                  let optValue = quess.answer[j].value;
                  this.submitAssessmentForm.controls.questions['controls'][i].controls.answer.controls[j].controls.value.setValue(optValue);                  
                }
              }

              if (quess.question_type == 'fib'){
                for (let j = 0; j < quess.answer.length; j++) {
                  let optValue = quess.answer[j].value;
                  this.submitAssessmentForm.controls.questions['controls'][i].controls.answer.controls[j].controls.value.setValue(optValue);                  
                }
              }

              if (quess.question_type == 'bool'){
                let isOptTrue;
                for (let j = 0; j < quess.option.length; j++) {
                  isOptTrue = quess.option.filter((option:any)=>{
                    return option.checked == true;
                  })                  
                }

                if(isOptTrue.length > 0){
                  let asbb = this.submitAssessmentForm.controls.questions['controls'][i].controls.answer.controls[0].controls.value.setValue(isOptTrue[0].option_value);                  
                  this.submitAssessmentForm.controls.questions['controls'][i].controls.answer.controls[0].controls.value.setValue(isOptTrue[0].option_value);                  
                } 
              }

              if (quess.question_type == 'singleanswermcq'){
                let isOptTrue;
                for (let j = 0; j < quess.option.length; j++) {
                  isOptTrue = quess.option.filter((option:any)=>{
                    return option.checked == true;
                  })                  
                }

                if(isOptTrue.length > 0){
                  let asbb = this.submitAssessmentForm.controls.questions['controls'][i].controls.answer.controls[0].controls.value.setValue(isOptTrue[0].option_value);                  
                  this.submitAssessmentForm.controls.questions['controls'][i].controls.answer.controls[0].controls.value.setValue(isOptTrue[0].option_value);                  
                } 
              }

              if (quess.question_type == 'multianswermcq'){
                let isOptTrue;
                for (let j = 0; j < quess.option.length; j++) {
                  isOptTrue = quess.option.filter((option:any)=>{
                    return option.checked == true;
                  })                  
                }
                // console.log('multi',isOptTrue);
                

                if(isOptTrue.length > 0){
                  for (let k = 0; k < isOptTrue.length; k++) {
                    // let asbb = this.submitAssessmentForm.controls.questions['controls'][i].controls.answer.controls[k].controls.value.setValue(isOptTrue[0].option_value);                  
                    this.submitAssessmentForm.controls.questions['controls'][i].controls.answer.controls[k].controls.value.setValue(isOptTrue[k].option_value);                  
                  }
                } 
              }




            }
            this.title.emit(this.assessmentData.assessment_name);
            
          } else {
            this._notificationService.showNotification('info', response.message)
            this.valueChange.emit('closeModal');
            return
          }
        },
        (error) => {
          this.valueChange.emit('closeModal');
          this._notificationService.showNotification("error", error.message);
          this.go_to_urlForSubmit()
        })
  }

  // resume couter 
  cancelInterVal = false;
  getResumeCounter() {
    this.countDown.unsubscribe();
    if (this.timerInterval) { clearInterval(this.timerInterval); }
    this.cancelInterVal = true;

    if(this.courseAssessmentData.is_timed && this.courseAssessmentData.schedule_id == ''){
      if(!this.remaining_time){
       this.hours = String(Math.floor(this.counter / 3600)).padStart(2, '0');
       this.minutes = String(Math.floor(this.counter / 60) - (this.hours * 60)).padStart(2, '0');
        this.second = String(Math.floor(this.counter % 60)).padStart(2, '0');
        this.timest = [this.hours + ":" + this.minutes + ":" + this.second].toString()
   
      }else{
       this.hours = String(Math.floor(this.remainCounter / 3600)).padStart(2, '0');
       this.minutes = String(Math.floor(this.remainCounter / 60) - (this.hours * 60)).padStart(2, '0');
        this.second = String(Math.floor(this.remainCounter % 60)).padStart(2, '0');
        this.timest = [this.hours + ":" + this.minutes + ":" + this.second].toString()
      }

    } else{
      this.timest = "00:00:00";
    }
  //  this.submitAssessmentForm.value.questions.forEach((question:any)=>{
  //    this.timedAnswer.push(question);
  //  })
   for (let i = 0; i < this.submitAssessmentForm.value.questions.length; i++) {

    this.submitAssessmentForm.value.questions[i].answer = this.submitAssessmentForm.value.questions[i].answer.filter(val => (val.value.length > 0) || (val.ans_recorded_video_key.length > 0) || (val.ans_recorded_audio_key.length > 0))
    
    this.assessmentData.questions[i].answer = this.submitAssessmentForm.value.questions[i].answer;
    
  }

    let body = {
      course_id: this.courseId,
      assessment_id: this.courseAssessmentData._id,
      remaining_time: this.timest,
      session_id  : this.session_id,
      topic_id:this.topicId,
      answered_questions: this.assessmentData.questions
    }
    console.log('this.timest',this.timest);
    
    this.loader = true;
    this.courseService.resumeCountDown(body).pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe(
        (response) => {
          this._notificationService.showNotification("success", response.message);
          // console.log(response);
          this.getSubmissionAssessmentDetail();
          this.get_submit_assessment();   
        },
        (error) => {
          
          this._notificationService.showNotification("error", error.message);
         
        })
  }

  closeCustomModel() {
    this.notAnsweredQusPopup = false;
  }


  formSubmit() {
    this.answerCounter = 0;
    for (let i = 0; i < this.submitAssessmentForm.value.questions.length; i++) {

      this.submitAssessmentForm.value.questions[i].answer = this.submitAssessmentForm.value.questions[i].answer.filter(val => (val.value.length > 0) || (val.ans_recorded_video_key.length > 0) || (val.ans_recorded_audio_key.length > 0))
      
      this.assessmentData.questions[i].answer = this.submitAssessmentForm.value.questions[i].answer;
      
    }


    this.submitAssessmentForm.value.questions.forEach(element => {

      if (element.answer.length == 0) {
        this.answerCounter++;
      }

    });

    if (this.submitAssessmentForm.value.questions.length == this.answerCounter) {
      this.notAnsweredQusPopup = true;
      this.alertMsg = 'No question has not answered yet.';
      this.hideCrossChange.emit('hidedismissbutton');
    } else if ((this.answerCounter < this.submitAssessmentForm.value.questions.length) &&
      (this.answerCounter > 0)) {

      this.notAnsweredQusPopup = true;
      this.alertMsg = 'Some question has not answered yet,Are you sure to submit the test.';
      this.hideCrossChange.emit('hidedismissbutton');
    }
    else {
      this.notAnsweredQusPopup = false;
      this.callSubmitAssessment();
    }


  }


  callSubmitAssessment() {
    let submitObj: any = {};
    let d = new Date();
    let submitted_time: any;
    this.loader = true;
    let formattedMonth, formattedDate, formattedHour, formattedMin: any;
    if ((d.getMonth() + 1) < 10) formattedMonth = '0' + (d.getMonth() + 1);
    else formattedMonth = (d.getMonth() + 1);

    if (d.getDate() < 10) formattedDate = '0' + d.getDate();
    else formattedDate = d.getDate();

    if (d.getHours() < 10) formattedHour = '0' + d.getHours();
    else formattedHour = d.getHours();

    if (d.getMinutes() < 10) formattedMin = '0' + d.getMinutes();
    else formattedMin = d.getMinutes();

    submitted_time = d.getFullYear() + '-' + formattedMonth + '-' + formattedDate + ' ' + formattedHour + ':' + formattedMin;

    submitObj.course_id = this.courseId,
      submitObj.topic_id = this.topicId ? this.topicId : "",
      submitObj.course_session_id = this.sessionId ? this.sessionId : "",
      submitObj.assessment_id = this.courseAssessmentData._id,
      submitObj.schedule_assessment_id = this.courseAssessmentData.schedule_id,
      submitObj.status = "done",
      submitObj.questions = this.assessmentData.questions,
      submitObj.submitted_time = submitted_time;

      
    this.courseService.submitAssessment(submitObj).pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe(
        (response) => {
          this._notificationService.showNotification("success", response.message);
          while (this.multiQus.length !== 0) {
            this.multiQus.removeAt(0)
          }
          this.submitAssessmentForm.reset();
          if (this.timerInterval) { clearInterval(this.timerInterval); }
          this.assessmentTimer = '';
          this.valueChange.emit('closeModal');
          // console.log('Model Url')
          this.go_to_urlForSubmit();
        },
        (error) => {
          this.loader = false;
          this._notificationService.showNotification("error", error.message);
        })
  }

  submitNo() {
    this.notAnsweredQusPopup = false;
    this.answerCounter = 0;
    this.hideCrossChange.emit('showdismissbutton');
  }

  submitYes() {
    this.notAnsweredQusPopup = false;
    this.answerCounter = 0;
    this.callSubmitAssessment();
    this.hideCrossChange.emit('showdismissbutton');
  }

  submitOk() {
    this.notAnsweredQusPopup = false;
    this.answerCounter = 0;
    this.callSubmitAssessment();
    this.hideCrossChange.emit('showdismissbutton');
  }
  urls = [];
  viewImagepopUP(question_html, viewImage) {
    this.urls = [];
    let m,
      rex = /src\s*=\s*"(.+?)"/g;
    while (m = rex.exec(question_html)) {
      let re = /\amp;/gi;
      let result = m[1].replace(re, "");
      this.urls.push(result);
    }
    if (this.urls.length > 0) {
      this.modalRef = this.modalService.open(viewImage);
    }

  }

  timer(time) {

    let totalTime: any = 60 * time;

    let timer: any = totalTime, minutes, seconds;
    this.timerInterval = setInterval(() => {
      minutes = timer / 60, 10;
      minutes = parseInt(minutes);

      seconds = timer % 60, 10;
      seconds = parseInt(seconds);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      this.assessmentTimer = minutes + ":" + seconds;
      timer--;
      if (timer < 0) {
        if (this.timerInterval) { clearInterval(this.timerInterval); }
        this.assessmentTimer = '';
        this.submitTimedAssessment();
      }
    }, 1000);
  }

  submitTimedAssessment() {
    this.answerCounter = 0;
    for (let i = 0; i < this.submitAssessmentForm.value.questions.length; i++) {

      this.submitAssessmentForm.value.questions[i].answer = this.submitAssessmentForm.value.questions[i].answer.filter(val => (val.value.length > 0) || (val.ans_recorded_video_key.length > 0) || (val.ans_recorded_audio_key.length > 0))

      this.assessmentData.questions[i].answer = this.submitAssessmentForm.value.questions[i].answer;
    }


    this.submitAssessmentForm.value.questions.forEach(element => {

      if (element.answer.length == 0) {
        this.answerCounter++;
      }

    });

    if (this.submitAssessmentForm.value.questions.length == this.answerCounter) {
      this.timedAssessmentPopup = true;
      this.alertTimedAssessmentMsg = 'Time up, You have not answered any question.';
      this.hideCrossChange.emit('hidedismissbutton');
    } else if ((this.answerCounter < this.submitAssessmentForm.value.questions.length) &&
      (this.answerCounter > 0)) {

      this.timedAssessmentPopup = true;
      this.alertTimedAssessmentMsg = "You have ran out of time. Your assessment automatically submitted.";
      this.hideCrossChange.emit('hidedismissbutton');
    }
    else {
      this.timedAssessmentPopup = false;
      this.callSubmitAssessment();
    }
  }

  onOkTimedAssessment() {
    this.timedAssessmentPopup = false;
    this.answerCounter = 0;
    this.callSubmitAssessment();
    this.hideCrossChange.emit('showdismissbutton');
  }

  get_submit_assessment() {
    this.courseAssessmentData = this._courseAssessmentShareService.getCourseAssessment();
    if(this.courseAssessmentData){
      this.sessionId = this._courseAssessmentShareService.getSessionId();
      this.courseId = this._courseAssessmentShareService.getCourseId();
      this.topicId = this._courseAssessmentShareService.getTopicId();
      this.sessionkey = this._courseAssessmentShareService.getSessionKey();
      this.course_category_id = this._courseAssessmentShareService.gercourse_category_id();
      this.course_category = this._courseAssessmentShareService.getCourse_Category();
      if (this.courseId) {
        localStorage.setItem('course_id', this.courseId);
      }
    }
    if (!this.courseAssessmentData) {
      let course_id = localStorage.getItem('course_id');
      
      this.route.navigate(['/courses/single-course'], { queryParams: { course_id: course_id } });
      localStorage.removeItem('course_id')
    }
  }
   

  go_to_url() {
    // console.log(this.courseAssessmentData.total_time)
    // console.log('form',this.submitAssessmentForm.value);
    // console.log('form assessment',this.assessmentData.questions);
    let body
    let url = '/courses/single-course'
    if(this.courseAssessmentData.is_timed && this.courseAssessmentData.total_time != 0){
      
      this.getResumeCounter();
      // console.log('1')
    }
    //FOR TIMER STOP FOR CALENDERED |START |17-FEB-2023
    /*if(this.courseAssessmentData.total_time && this.courseAssessmentData.active){
      this.getResumeCounter();
      console.log('2')
    }*/
    //FOR TIMER STOP FOR CALENDERED |END |17-FEB-2023
    if (this.sessionId) {
      body = {
        course_id: this.courseId,
        course:this.courseName,
        session_id: this.sessionId,
        course_category: this.course_category,
        course_category_id: this.course_category_id,
        topicId:this.topicId,
        sessionkey: this.sessionkey,
        isTA: false,
        tab_no:this.activeIdNav,
        ngbNavItem: this.ngbNavItem
      }
      url = '/courses/video-with-notes'
    } else {
      body = {
        course_id: this.courseId,
        course:this.courseName,
        tab_no:this.activeIdNav
      }
    }
    this.route.navigate([url], { queryParams: body })
  }


  go_to_urlForSubmit() {
    let body
    let url = '/courses/single-course'
    if (this.sessionId) {
      body = {
        course_id: this.courseId,
        course:this.courseName,
        session_id: this.sessionId,
        course_category: this.course_category,
        course_category_id: this.course_category_id,
        topicId:this.topicId,
        sessionkey: this.sessionkey,
        isTA: false,
        tab_no:this.activeIdNav,
        ngbNavItem: this.ngbNavItem
      }
      url = '/courses/video-with-notes'
    } else {
      body = {
        course_id: this.courseId,
        course:this.courseName,
        tab_no:this.activeIdNav
      }
    }
    this.route.navigate([url], { queryParams: body })
  }



  newArrayItem: any = [];
  isBlcokTrue: boolean = false;
  itemm = 0;
  // isMarked = false;
  answerVal;
  // marked(val: any) {
  //   this.isMarked = val.target.checked;
  // }
  nextItem() {
    console.log('Form Value',this.submitAssessmentForm.value)
    this.itemm++;
    // this.isMarked = false;
    this.newArrayItem = [];
    if (this.itemm == this.assessmentData.questions.length) {
      this.answerVal;
      this.isBlcokTrue = true;
      for (let i = 0; i < this.submitAssessmentForm.value.questions.length; i++) {
        this.submitAssessmentForm.value.questions[i].answer = this.submitAssessmentForm.value.questions[i].answer.filter(val => (val.value.length > 0) || (val.ans_recorded_video_key.length > 0) || (val.ans_recorded_audio_key.length > 0))
        this.answerVal = this.submitAssessmentForm.value.questions[i].answer;

        console.log(this.answerVal)
        this.newArrayItem.push({
          answerValue: this.answerVal,
          markReview: this.submitAssessmentForm.value.questions[i].mark
        })
      }
    }    
  }
  prevItem() {
    if (this.itemm > 0 || this.itemm != 0) {
      this.itemm--;
    }

  }
  goSummary: boolean = false;
  goQustn(v: any, iQst) {
    this.itemm = v.target.id;
    this.isBlcokTrue = false;
    // this.newArrayItem = [];

    if (this.itemm == iQst) {
      this.goSummary = true;
    }
  }
  goToSummary(e: any) {
    this.itemm = this.submitAssessmentForm.value.questions.length;    
    this.goSummary = false;
    this.isBlcokTrue = true;
    this.newArrayItem = [];
    // console.log(this.submitAssessmentForm.value);

    for (let i = 0; i < this.submitAssessmentForm.value.questions.length; i++) {
      this.submitAssessmentForm.value.questions[i].answer = this.submitAssessmentForm.value.questions[i].answer.filter(val => (val.value.length > 0) || (val.ans_recorded_video_key.length > 0) || (val.ans_recorded_audio_key.length > 0))
      this.answerVal = this.submitAssessmentForm.value.questions[i].answer;
      this.newArrayItem.push({
        answerValue: this.answerVal,
        markReview: this.submitAssessmentForm.value.questions[i].mark
      })
    }
  }

  openAudioVideoPopup() {
    this.openVideoAudioPopup = true;
  }
 closeAudioVideoPopup() {
    this.openVideoAudioPopup = false;
  }

  onMoveButtonDisabled(state: boolean) {
    this.moveButtonDisabledAction = state;
  }

  //FOR BUILD FIX
  eventCheck(event) {
    //
  }

}
