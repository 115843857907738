<div class="new_UIstyle">
    <!-- banner section -->
        <div class="banner banner-gradient banner-course-assement">
            <div class="container">
                <div class="banner-content">
                    <div class="banner-content-content">
                        <h2 class="white-text avenirBlack">{{assessmentNameB }}</h2>
                        <div class="tags-badges" *ngIf="courseList.length > 0">
                            <span class="white-text">Course<span *ngIf="courseList.length > 1">s</span>: </span> 
                            <span class="badge badge-light badge-primary" 
                            *ngFor="let course of courseList; let i = index;" >{{course.course}}</span>
                        </div>
                    </div>
                    <div class="banner-content-action btn-groups">
                        <button class="btn btn-primary btn-w-120" *ngIf="role != 'student'" (click)="go_to_list()">Back to Assessment</button>
                    </div>
                </div>
            </div>
        </div>
    
        <!-- *ngIf="isEmpty(responseData)>0" -->
        <section class="section" >
            <div class="container" [ngClass]="{submitting : loader}">
                <div class="text-center pt-2 pb-2">
                    <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                
                <div class="row align-items-center">
                        <div class="col-md-12 m-auto">
                        <div>
                            <!-- <div class="stepsButtons d-flex align-items-center justify-content-between" 
                            *ngIf="responseData?.questions.length === (itemm + 1)">
                                <button type="button" class="btn btn-primary btn-w-120 nextButton"  (click)="goToSummary()">Go to Result</button>
                            </div> -->
                            <div class="course-assessment-qsn-blk" *ngFor="let aData of responseData?.questions; let i = index;"  [ngClass]="i == itemm ? 'd-block':'d-none'">
                              <!-- {{aData | json}} -->
                           <div class="row">
                                <div class="course-assessment-qsn col-12 mb-3" >
                                <span class="badge badge-green-lt mb-2" *ngIf="role != 'student'">Marks: {{aData?.marks}}</span>
                                <div class="tinyText ass_ques_btn_wrap">
                                <h5 class="avenirBlack"><span class="number-icon">{{i+1}}</span></h5>
                                <div class="tinyTextContent" [ngClass]="{'mb-1':responseData?.questions[itemm]?.question_recording_url && responseData.questions[itemm].question_recording_url.url 
                                || responseData?.questions[itemm]?.question_video_url && responseData.questions[itemm].question_video_url.url}" 
                                [innerHTML]="aData?.question_html" 
                                title="view image"></div>
                                <button type="button" class="audio_video_iconButtton" 
                                *ngIf="responseData?.questions[itemm]?.question_recording_url && responseData.questions[itemm].question_recording_url.url 
                                || responseData?.questions[itemm]?.question_video_url && responseData.questions[itemm].question_video_url.url"
                                (click)="openAudioVideoPopup()" 
                                >Click this link to play the audio/video to answer the question.</button>
                                
                                <div class="questionsAnsw">
                                    <div *ngIf="aData.type == 'singleanswermcq'">
                                        <div class="row gy-2">
                                            <div class="col-md-6 custom-control custom-radio mb-2"
                                                *ngFor="let option of aData?.options.values;let ii = index">
                                                <input type="radio" [value]="option"
                                                    class="form-check-input custom-control-input me-2"  readonly>
                                                <label class="form-check-label custom-control-label" >
                                                    <div class="d-inline-block vTop"
                                                        [innerHTML]="option"></div>
                                                </label>
                                            </div>
                                        </div>       
                                    </div>
                
                                    <div *ngIf="aData.type == 'multianswermcq'">
                                        <div class="row gy-2" >
                                                <div class="col-md-6 custom-control custom-checkbox mb-2"
                                                    *ngFor="let option of aData?.options.values;let ii = index" >
                                                    <input type="checkbox"  [value]="option"
                                                        class="form-check-input custom-control-input"  readonly>
                                                    <label class="form-check-label custom-control-label">
                                                        <div class="d-inline-block vTop"
                                                            [innerHTML]="option"></div>
                                                    </label>
                                                </div>
                                            </div>
                                    </div>
                                    <div *ngIf="aData.type == 'bool'">
                                        <div class="row gy-2" >
                                            <div class="col-md-6 custom-control custom-radio mb-2"
                                                *ngFor="let option of aData?.options.values;let ii = index" >
                                                <input type="radio" [value]="option" 
                                                    class="form-check-input custom-control-input"  readonly>
                                                <label class="form-check-label custom-control-label" >
                                                    <div class="d-inline-block vTop"
                                                        [innerHTML]="option"></div>
    
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                
                                    <!-- <div *ngIf="aData.type == 'text'">
                                        <div class="mb-2" *ngFor="let ansText of aData?.answer;">
                                            <div class="form-control form-control-textarea" 
                                                [innerHTML]="ansText"></div>       
                                        </div>
                                    </div>
                                    <div *ngIf="aData.type == 'fib'">
                                        <div class="mb-2" *ngFor="let fibText of aData?.answer;">
                                            <div class="form-control form-control-textarea"
                                            [innerHTML]="fibText"></div>   
                                        </div>    
                                    </div> -->
                
                                </div>
    
                                <div  class="questContent" *ngIf="(aData.answer && aData.answer.length > 0) || (aData.correct_answer && aData.correct_answer.length > 0)">	
                                    <label class="avenirBlack dark-green-text">Correct Answer:</label> 
                                    <div class="mb-2" >
                                        <div class="row" *ngIf="aData.type == 'text' || aData.type == 'fib' || aData.type == 'speech'"> 
                                            <div class="col-12" *ngFor="let modAns of aData.answer">
                                                
                                                <div class="form-control form-control-textarea"
                                                *ngIf="aData.type == 'text' || aData.type == 'fib'"
                                                 [innerHTML]="modAns"></div>  

                                                <div class="d-block mb-2" 
                                                *ngIf="aData.type == 'speech'"
                                                 [innerHTML] = "modAns"></div>	
                                                <span class="d-inline-block d-none" *ngIf="modAns.img?.length>0"><img [src]="modAns.img"	
                                                        width="100px" height="100px" /></span>	
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="aData.type != 'text'"> 
                                            <div class="col-auto" *ngFor="let modAns of aData.correct_answer">
                                                <span class="d-block mb-2" [innerHTML] = "modAns"></span>	
                                                <span class="d-inline-block d-none" *ngIf="modAns.img?.length>0"><img [src]="modAns.img"	
                                                        width="100px" height="100px" /></span>	
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                
                            </div>
                               </div>
                            </div> 
                        </div>    
                    </div>

                    </div>
                    <div class="col-12 stepsButtons d-flex align-items-center justify-content-between">
                        <button type="button" class="btn btn-primary btn-w-120 prevButton" [hidden]="(itemm + 1) == 1 && role != 'student'"  (click)="prevItem()">Previous</button>
                        <button type="button" class="btn btn-primary btn-w-120 nextButton" [hidden]="(itemm + 1) == responseData?.questions?.length" (click)="nextItem()">Next</button>
                        <!-- <button type="button" class="btn btn-primary btn-w-120 nextButton"  (click)="goToSummary()" *ngIf="(itemm + 1) == responseData?.questions?.length">Go to result</button> -->
                    </div>
                    <!-- <div class="stepsButtons d-flex align-items-center justify-content-between" *ngIf="goSummary">
                        <button type="button" class="btn btn-primary btn-w-120 nextButton"  (click)="goToSummary()">Go to Result</button>
                    </div> -->
                </div>
            </div>
        </section> 
    </div>
    
<!-- audio/video question modal -->
<app-audio-video-model *ngIf="openVideoAudioPopup" [openVideoAudioPopup]="openVideoAudioPopup" 
(openVideoAudioPopupClick)="closeAudioVideoPopup()"
[videoCondion]="responseData?.questions[itemm]?.question_video_url && responseData.questions[itemm].question_video_url.url" 
[audioCondion]="responseData?.questions[itemm]?.question_recording_url && responseData.questions[itemm].question_recording_url.url"
[videoUrl]="responseData?.questions[itemm].question_video_url.url"
[audioUrl]="responseData.questions[itemm].question_recording_url.url"></app-audio-video-model>




<div class="banner d-none">
    <div class="container text-center">
        <h1 class="">Assessment of {{assessmentName|capitalization}}</h1>
    </div>
</div>
<div class="pageBody viewAssessment  d-none">
    <div class="container">

        <div class="position-relative">
            <div class="text-center asmntFilter mb-3">
                <ng-multiselect-dropdown [placeholder]="'Please select'" [data]="dropdownList"
                    [(ngModel)]="selectedItems" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                    (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect($event)"
                    (onDeSelectAll)="onDeSelectAll($event)">
                </ng-multiselect-dropdown>
            </div>

            <div class="showTableContainer" [ngClass]="{submitting : loader}" *ngIf="isEmpty(responseData)>0">
                <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="table-responsive" id="htmlData" #htmlData>
                    <table class="table alt nestedTbl" [ngStyle]="{'width':tableWidth}">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th *ngFor="let head of responseData.question_header">{{head |capitalization}}</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let item of responseData.questions;let i = index;">

                            <tr>
                                <td style="width:60px;">{{i+1}}.</td>
                                <td style="width:450px;" *ngIf="item?.question_html" [innerHTML]="item?.question_html">
                                </td>
                                <td style="width:150px;" *ngIf="item?.type">{{item?.type}}</td>
                                <td style="width:200px;" *ngIf="item?.option">
                                    <div class="row">
                                        <div *ngIf = "item?.option.type == 'singleanswermcq' || item?.option.type == 'bool'" class="col-6" >
                                            <div class="custom-control custom-radio" *ngFor="let optVal of item?.option.values;let ii = index">
                                                <input type="radio" class="custom-control-input" name = "optVal" id="colAnsC{{ii}}">
                                                <label class="custom-control-label"
                                                for="colAnsC{{ii}}"><div [innerHTML] = "optVal"></div></label>
                                            </div>
                                        </div>
                                        <div *ngIf = "item?.option.type == 'multianswermcq'" class="col-6" >
                                            <div class="custom-control custom-checkbox" *ngFor="let optVal of item?.option.values;let ii = index">
                                                <input type="checkbox" class="custom-control-input" id="colAnsC{{ii}}">
                                                <label class="custom-control-label"
                                                for="colAnsC{{ii}}"><div [innerHTML] = "optVal"></div></label>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td style="width:250px;" *ngIf="item?.answer">
                                    <div class="noW" *ngFor="let ans of item?.answer;" [innerHTML]="ans"></div>
                                </td>
                                <td style="width:100px;" *ngIf="item?.marks?item?.marks.toString():''">{{item?.marks}}
                                </td>
                                <td style="width:150px;" *ngIf="item?.complexity">{{item?.complexity}}</td>
                                <td style="width:100px;" *ngIf="item?.tags">
                                    <span *ngFor="let tag of item?.tags" class="badge badge-primary m-1">{{tag}}</span>
                                </td>
                            </tr>
                            <ng-container *ngIf="item?.sub_qus">
                                <tr *ngFor="let subqus of item?.sub_qus;let j = index;">
                                    <!-- <td class="noPad" [attr.colspan] = "getcolspanVal(item)">
                                        <table class="table">
                                            <tr>
                                                
                                            </tr>
                                        </table>
                                    </td> -->
                                    <td style="width:60px;">{{i+1}}.{{j+1}}</td>
                                    <td style="width:450px;" *ngIf="subqus?.question">{{subqus?.question}}</td>
                                    <td style="width:150px;" *ngIf="subqus?.type">{{subqus?.type}}</td>
                                    <td style="width:200px;" *ngIf="subqus?.option">
                                        <div class="row">
                                            <div class="col-6" *ngFor="let subqusAns of subqus?.option;let k = index">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input"
                                                        id="colAnsC{{k}}">
                                                    <label class="custom-control-label"
                                                        for="colAnsC{{k}}">{{subqusAns}}</label>
                                                </div>
                                            </div>

                                        </div>
                                    </td>
                                    <td style="width:250px;" *ngIf="subqus?.answer">
                                        <span *ngFor="let subqusmodelAns of subqus?.answer;">{{subqusmodelAns}}</span>
                                    </td>
                                    <!-- Add Blank if needs -->
                                    <td *ngIf="item?.marks?item?.marks.toString():''" style="width:100px;">&nbsp;</td>
                                    <td *ngIf="item?.complexity" style="width:150px;">&nbsp;</td>
                                    <td *ngIf="item?.tags" style="width:100px;">&nbsp;</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>

                <div class="pt-4 d-flex justify-content-between">
                    <div>
                        <button class="btn btn-secondary-outline" routerLink="/retrieve-assessment">
                            <i class="fas fa-arrow-left mr-2"></i>Back to Assessment
                        </button>
                    </div>
                    <div>
                        <button class="btn btn-danger ml-3" (click)="generatePdf()"><i
                                class="fas fa-file-download mr-2"></i>Generate PDF</button></div>
                </div>
            </div>
        </div>
    </div>
</div>