import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { CourseService } from '../../services/course.service';
import { finalize } from 'rxjs/operators';
import { QuestionService } from 'src/app/services/question.service';
import { FileUpload } from 'primeng';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subject,of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UploadFilesService } from '../../services/upload-files.service'
import { QuestionBankService } from 'src/app/services/question-bank.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-ask-question',
  templateUrl: './ask-question.component.html',
  styleUrls: ['./ask-question.component.scss']
})
export class AskQuestionComponent implements OnInit {
  askQuestionForm: FormGroup;
  // @Input() category_id;
  @Input() course_category;
  @Input() yourQuestion;
  @Input() course_session_id;
  @Input() answer_id;
  @Input() courseId;
  @Input() currentTime: number;
  @Output() closeModelValue = new EventEmitter();
  @ViewChild("fileU1", { static: false })
  fileUploadClear1: ElementRef;

  loader = false;

  role: any = '';
  Week;
  category_id: any;
  sessionkey = ''
  topic_id =''
  course_category_id = ''
  weekIndex;
  selectedPic: any = [];
  selectedVideo: any = [];
  selectedDoc: any = [];
  fileItems: any = [];
  progressBar: number;
  routerUrl: any;
  chooseCate: boolean = false;
  chooseCategory: string = 'Choose a category from list'
  selectedFiles: FileList;
  progressInfos = [];
  message = '';
  fileInfos: Observable<any>;
  uploadedMedia: Array<any> = [];
  uploadArray = [];
  courseName:string = null;
  activeIdNav:any = 1;
  ngbNavItem:any = 1;
  

  constructor(private fb: FormBuilder,
    private ns: NotificationService,
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private activatroute: ActivatedRoute,
    private questionService: QuestionService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private _questionBankService: QuestionBankService,
    private UFS: UploadFilesService) {
  }

  ngOnInit() {
    this.courseId = this.activatedRoute.snapshot.queryParamMap.get('courseId');
    this.courseName = this.activatedRoute.snapshot.queryParamMap.get('course');
    this.course_session_id = this.activatedRoute.snapshot.queryParamMap.get('course_session_id');
    this.sessionkey = this.activatedRoute.snapshot.queryParamMap.get('sessionkey');
    this.course_category_id = this.activatedRoute.snapshot.queryParamMap.get('course_category_id');
    this.course_category = this.activatedRoute.snapshot.queryParamMap.get('course_category');
    this.topic_id = this.activatedRoute.snapshot.queryParamMap.get('topicId');
    this.activeIdNav = this.activatedRoute.snapshot.queryParamMap.get('tab_no');
    this.ngbNavItem = this.activatedRoute.snapshot.queryParamMap.get('ngbNavItem');


    // this.askQuestionForm = this.fb.group({
    //   subject: ['', Validators.required],  
    //   coins: ['', Validators.required],
    //   yourQuestion: ['', Validators.required],
    //   picture: ['', Validators.required],
    //   video: ['']
    // })

    let sp = this.router.url.split('?')
    this.routerUrl = sp[0];
    this.role = localStorage.getItem('role')

    this.askQuestionForm = new FormGroup({
      'subject': new FormControl('', Validators.required),
      'coins': new FormControl('', Validators.required),
      'tags': new FormControl('', Validators.required),
      'yourQuestion': new FormControl('', Validators.required),
      'picture': new FormControl(''),
      'video': new FormControl('')
    })
    this.getAllCourse();
 //   this.allListTags();
    this.get_edit_question();
    this.askEditQuestionDetail();

  }

  tagsData: any = [];
  //FOR SEARCH TAG COURSEWORK |START
  onInputTag = (searchText: string): Observable<any[]> => {
    searchText = searchText.trim();
    if(searchText.length) {
      return this._questionBankService.fetchSearchTags(searchText);
    }
    return of([]);
  }
  //FOR SEARCH TAG COURSEWORK |END
  // allListTags() {
  //   this.courseService.getTags().subscribe(tagsResult => {
  //     this.tagsData = tagsResult.message;
  //   })
  // }

  selectFilelenghth;
  moduleName;
  browseFileLen = 0;
  selectedArray;
  fileSizeLimit: number;
  fileSize: number = 0;
  mediaFileSize: number = 0;
  onFileBrowse(event: Event) {
    const target = event.target as HTMLInputElement;
    let browseFile = target.files;
    this.browseFileLen = browseFile.length;
    this.uploadArray = [];
    this.processFiles(target.files);
  }
  processFiles(files) {
    let maxLimitItem = 10;
    this.fileSizeLimit = 20000000;
    this.selectedArray = this.uploadedMedia.length;
    let moreAllowItem = maxLimitItem - this.selectedArray;
    let fileLength = files.length;

    if (fileLength < moreAllowItem || fileLength == moreAllowItem) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (this.validateFile(file.name)) {
          this.ns.showNotification("info", 'Selected file format is not supported.');
          this.browseFileLen--;
        } else {
          let hasItem = this.uploadedMedia.find(s => s.FileName === file.name);
          if (!hasItem) {
            this.selectFilelenghth = this.selectedArray + this.browseFileLen;
            var reader = new FileReader();
            reader.readAsDataURL(file); // read file as data url
            reader.onload = (event: any) => {
              // called once readAsDataURL is completed

              let fileType = file.type;
              if (fileType.includes('image')) {
                this.moduleName = 'question-image';
              } else if (fileType.includes('video')) {
                this.moduleName = 'question-video';
              } else if (fileType.includes('audio')) {
                this.moduleName = 'question-file';
              } else if (fileType.includes('text') || fileType.includes('') || fileType.includes('application')) {
                this.moduleName = 'question-file';
              }

              this.uploadedMedia.push({
                FileName: file.name,
                FSB: file.size,
                FileSize:
                  this.UFS.getFileSize(file.size) +
                  ' ' +
                  this.UFS.getFileSizeUnit(file.size),
                FileType: file.type,
                FileUrl: event.target.result,
                FileProgessSize: 0,
                FileProgress: 0,
                Id: '',
                path: '',
                abc:false
              });

              this.startProgress(file, this.uploadedMedia.length - 1, this.moduleName);
            }

          } else {
            this.ns.showNotification("info", 'File already added.');
            this.browseFileLen--;
            //this.restUploadFile();
          }
        }
      }
      if (this.uploadedMedia.length > 0) {
        for (let m = 0; m < this.uploadedMedia.length; m++) {
          this.mediaFileSize += parseInt(this.uploadedMedia[m].FSB);
        }
      } else if (this.uploadedMedia.length < 1 || this.uploadedMedia.length == undefined) {
        this.mediaFileSize = 0;
      }
      let newSize = this.mediaFileSize + this.fileSize;
    } else {
      this.ns.showNotification("info", 'Only 10 files allowed.');
      this.browseFileLen = 0;
    }
  }
  savevisible: boolean = true;
  async startProgress(file, index, module_name) {
    let filteredFile = this.uploadedMedia
      .filter((u, index) => index === index)
      .pop();

    if (filteredFile != null) {
      let fileSize = this.UFS.getFileSize(file.size);
      let fileSizeInWords = this.UFS.getFileSizeUnit(file.size);
      if (this.UFS.isApiSetup) {
        let formData = new FormData();
        formData.append('resource', file);

        this.UFS
          .uploadMedia(formData, module_name)
          .subscribe(
            (res: any) => {
              if (res.resource_info == undefined) {
                this.savevisible = false;
                
              } else {
                this.savevisible = true;
                this.uploadArray.push(res.resource_info)
              }

              this.uploadedMedia[index].abc = this.savevisible;

              if (res.status === 'progress') {
                let completedPercentage = parseFloat(res.message);

                filteredFile.FileProgessSize = `${(
                  (fileSize * completedPercentage) /
                  100
                ).toFixed(2)} ${fileSizeInWords}`;
                filteredFile.FileProgress = completedPercentage;

                let pro = this.uploadedMedia.find(s => s.FileProgress != 100)

              } else if (res.status === 'completed') {
                filteredFile.Id = res.resource_id;
                filteredFile.path = res.path;

                filteredFile.FileProgessSize = fileSize + ' ' + fileSizeInWords;
                filteredFile.FileProgress = 100;
              }

              if (res.resource_info) {
                filteredFile.Id = res.resource_info[0].resource_id;
                filteredFile.path = res.resource_info[0].path;
                this.restUploadFile();
              }
            },
            (error: any) => {
              console.log(error);
            }
          );
      } else {
        for (
          var f = 0;
          f < fileSize + fileSize * 0.0001;
          f += fileSize * 0.01
        ) {
          filteredFile.FileProgessSize = f.toFixed(2) + ' ' + fileSizeInWords;
          var percentUploaded = Math.round((f / fileSize) * 100);
          filteredFile.FileProgress = percentUploaded;
          await this.fakeWaiter(Math.floor(Math.random() * 35) + 1);
        }
      }
    }

  }

  fakeWaiter(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'exe') {
      return true;
    }
    else {
      return false;
    }
  }

  questionModel = null;
  openModelDeleteRes(deleteRes) {
    this.questionModel = this.modalService.open(deleteRes, { size: "sm", centered: true });
  }

  closeResPopup() {
    this.questionModel.close();
    // this.questionId = null;
  }

  deleteResouce(item, idx: number) {
    let sp = item.path;
    let fileTitleSplit = sp.split('/');
    let fileTitle = fileTitleSplit[1];
      this.uploadedMedia = this.uploadedMedia.filter((u, index) => index !== idx);

      this.selectFilelenghth = this.uploadedMedia.length;
      this.questionService.deleteResource(sp).subscribe(res => {
        this.ns.showNotification("success", res.message);
        this.questionModel.close();
        this.restUploadFile();
        if(this.browseFileLen > 0){
          this.browseFileLen--;
        }

        if(this.uploadArray.length > 0){
          this.uploadArray.length--;
        }

      })
      if (this.selectFilelenghth == 0 || this.selectFilelenghth < 1) {
        this.restUploadFile();
      }
      if (this.submitAskQustion) {
        this.selectedDoc = [];
        this.selectedPic = [];
        this.selectedVideo = [];
      }
  }


  restUploadFile() {
    this.fileUploadClear1.nativeElement.value = "";
  }

  questionName: string;
  tags: any = [];
  answersList: any = [];
  resourceVideoItems: any = [];
  resourceImageItems: any = [];
  resourceDocItems: any = [];
  answerslength: any;
  pageInfo: any;
  questionId: any;
  selectedDockey: any = [];
  selectedPickey: any = [];
  selectedVideokey: any = [];
  progressInfosEdit: any = [];
  askEditQuestionDetail() {
    if (this.routerUrl == '/edit-ask-question') {
      this.loader = true;
      this.questionId = this.route.snapshot.queryParamMap.get('questionId');
      this.pageInfo = this.route.snapshot.queryParamMap.get('info');
      this.questionService.getUserQuestionAnswer(this.questionId).subscribe(res => {
        let questionData = res.response;
        this.selectedDockey = questionData.question_doc_key;
        this.selectedPickey = questionData.question_image_key;
        this.selectedVideokey = questionData.question_video_key;
        this.loader = false;
        // this.askQuestionForm.controls['subject'].disable();
        // this.askQuestionForm.controls['coins'].disable();
        this.askQuestionForm.controls['subject'].setValue(questionData.course_category_id);
        this.askQuestionForm.controls['coins'].setValue(questionData.reward);
        this.askQuestionForm.controls['tags'].setValue(questionData.tags);
        this.askQuestionForm.controls['yourQuestion'].setValue(questionData.question);
        this.category_id = questionData.course_category_id;

        if (questionData.question_doc_path.length > 0) {
          this.resourceCondition(questionData.question_doc_key, this.uploadedMedia)
        }
        if (questionData.question_image_path.length > 0) {
          this.resourceCondition(questionData.question_image_key, this.uploadedMedia)
        }
        if (questionData.question_video_path.length > 0) {
          this.resourceCondition(questionData.question_video_key, this.uploadedMedia)
        }
        this.selectFilelenghth = this.uploadedMedia.length;
      })
    }

  }


  // common method of resources array
  resourceCondition(getArrayItem, setArrayItem) {
    for (let i = 0; i < getArrayItem.length; i++) {
      let filePath = getArrayItem[i];
      let fileNameSplitA = getArrayItem[i].split('/');
      let fileNameSplitB = fileNameSplitA[1].split('__');
      let extension = fileNameSplitB.pop().split('.');
      let fileNameSplitTitle = fileNameSplitB[0] + '.' + extension[1];
      setArrayItem.push({
        FileName: fileNameSplitTitle,
        FileProgessSize: 100,
        FileProgress: 100,
        path: filePath
      });
    }
  }


  // validation for edit question button
  question_type = 'Ask a question here';
  get_edit_question() {
    if (this.router.url == '/ask-question' && this.role == 'student') {
      this.question_type = 'Ask a question here';
      return true;
    } else if (this.routerUrl == '/edit-ask-question') {
      this.question_type = 'Edit a question here';
      return true;
    }
    return false;
  }




  submitAskQustion: boolean = false;
  questionFormSubmit() {
    let folderPath;
    let uploadedMediaFiles;
    let uploadedMediaFilespath;
    for (let s = 0; s < this.uploadedMedia.length; s++) {
      uploadedMediaFiles = this.uploadedMedia[s];
      uploadedMediaFilespath = uploadedMediaFiles.path.split('/');
      folderPath = uploadedMediaFilespath[0];

      if (folderPath == 'question_bank_files') {
        this.selectedDoc.push(uploadedMediaFiles.path);
      }
      if (folderPath == 'question-images') {
        this.selectedPic.push(uploadedMediaFiles.path);
      }
      if (folderPath == 'question-videos') {
        this.selectedVideo.push(uploadedMediaFiles.path);
      }
      //  console.log('module name',moduleName) 
    }

    this.submitAskQustion = true;
    if (this.askQuestionForm.invalid) {
      this.ns.showNotification("info", "Fill Correct data");

      this.selectedDoc = [];
      this.selectedPic = [];
      this.selectedVideo = [];

      return;
    }

    if (Number(this.askQuestionForm.value.coins) > Number(localStorage.getItem('coins'))) {
      this.ns.showNotification("info", "You do not have so much coins please check your balance first")
      return false;
    }
    this.loader = true;

    if (!this.selectedPic) {
      this.selectedPic = null;
    }
    if (!this.selectedVideo) {
      this.selectedVideo = null;
    }
    if (!this.selectedDoc) {
      this.selectedDoc = null;
    }
    if (!this.answer_id || this.answer_id == undefined) {
      this.answer_id = '';
    }

    if (!this.currentTime) {
      this.currentTime = 0;
    }
    if (this.courseId) {
      this.courseId;

    }
    if (this.course_session_id) {
      this.course_session_id;
    }

    if (!this.courseId) {
      this.courseId = '';
    }
    if (!this.course_session_id) {
      this.course_session_id = '';
    }
    if (!this.category_id) {
      this.category_id = this.askQuestionForm.value.subject;
    }

    if (this.routerUrl == '/ask-question' && this.role == 'student') {
      this.courseService.video_question(
        this.askQuestionForm.value,
        this.course_session_id,
        this.courseId,
        this.selectedPic,
        this.selectedVideo,
        this.selectedDoc,
        this.category_id,
        this.currentTime,
        this.answer_id)
        .pipe(finalize(() => this.loader = false)).subscribe(res => {
          this.ns.showNotification("success", "Question added successfully");
          this.submitAskQustion = false;
          this.askQuestionForm.reset();
          this.chooseCate = true;
          this.restUploadFile();
          // this.selectFilelenghth = [];
          localStorage.setItem('coins', res.avail_coin);
          this.closeModelValue.emit('closeModel');
          // this.noFileSelected = true;
          this.progressInfos = [];
          this.progressInfosEdit = [];
          this.uploadedMedia = [];
          setTimeout(() => {
            if (!this.course_session_id) {
              this.router.navigate(['/My-Questions'])
            }

            if (this.role == 'student' && this.courseId != '' && this.course_session_id != '') {
              console.log(this.courseId)
              this.router.navigate(['/courses/video-with-notes'], { queryParams: { course_id: this.courseId,course:this.courseName, session_id: this.course_session_id, course_category: this.course_category, course_category_id: this.course_category_id, sessionkey: this.sessionkey, topicId:this.topic_id,ngbNavItem: this.ngbNavItem,tab_no:this.activeIdNav } })
            }
          }, 500);
        },
          (err) => {
            this.ns.showNotification("error", err.error.message);
            // this.ns.showNotification("error", "Question is not added");
          })
    } else if (this.routerUrl == '/edit-ask-question') {
      // /edit-ask-question
      this.questionId = this.route.snapshot.queryParamMap.get('questionId');
      this.pageInfo = this.route.snapshot.queryParamMap.get('info');
      this.courseService.editAskQuestion(this.askQuestionForm.value, this.questionId, this.selectedPic, this.selectedVideo, this.selectedDoc, this.category_id).subscribe(res => {
       
        this.ns.showNotification("success", "Question Updated successfully");
        // this.noFileSelected = true;
        this.progressInfos = [];
        this.progressInfosEdit = [];
        this.uploadedMedia = [];
        this.loader = false;
        setTimeout(() => {
          if (this.role == 'student' && !this.course_session_id) {
            this.router.navigate(['/My-Questions'])
          } if (this.role != 'student' && !this.course_session_id) {
            this.router.navigate(['/Student-Questions'])
          }


          if (this.courseId != '' && this.course_session_id != '') {
            this.router.navigate(['/courses/video-with-notes'], { queryParams: { course_id: this.courseId, course:this.courseName, session_id: this.course_session_id, course_category: this.course_category, course_category_id: this.course_category_id, sessionkey: this.sessionkey, topicId:this.topic_id,ngbNavItem: this.ngbNavItem,tab_no:this.activeIdNav   } })
          }

        }, 500);
      },
        (err) => {
          this.ns.showNotification("error", err.error.detail);

          // this.ns.showNotification("error", "Question is not Updated");
        })

    }
  }
  discardaskForm() {
    if (this.askQuestionForm) {
      this.askQuestionForm.reset();
    }
    this.restUploadFile();
    this.progressInfos = [];
    this.progressInfosEdit = [];
    this.uploadedMedia = [];
  }

  quesCat = [];
  getAllCourse() {
    this.loader = true;
    this.courseService.categoryAPI().pipe(finalize(() => {
      this.loader = false;
    })).subscribe(data => {
      this.quesCat = data;
      //  this.getMyQuestions();
    })
  }

  myquestions
  categoryId;
  onChangeSubject(value) {
    if (value == 'select') {
      this.myquestions = [];
      return;
    }
    this.categoryId = value;
    const body = {
      course_category_id: value,
      role: localStorage.getItem('role'),
    }
    this.questionService.getStudentQuestionsList(body).pipe(finalize(() => {
      this.loader = false;
    })).subscribe(res => {
      this.myquestions = res.questions;
    })
  }



}
