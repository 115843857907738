import { Component, OnInit } from '@angular/core';
import { QuestionBankService } from 'src/app/services/question-bank.service';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AssessmentShareService } from 'src/app/services/assessment-share.service';
import {TooltipPosition} from '@angular/material/tooltip';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-retreive-assessment',
  templateUrl: './retreive-assessment.component.html',
  styleUrls: ['./retreive-assessment.component.scss']
})
export class RetreiveAssessmentComponent implements OnInit {
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  currentPage = 1;
  selectedAssessmentType = 'published';
  assessments = [];
  loader: boolean = false;
  public pages: any = [];
  role;
  // printList = 'More Item';
  assessmentTypes = [
    { name: 'Published', value: 'published' },
    { name: 'Unpublished', value: 'unpublished' },
    { name: 'Both', value: 'both' }]
  numPages: any;
  assessmentsType:string = null;
  constructor(private _questionBankService: QuestionBankService,
    private _notificationService: NotificationService,
    private router: Router,
    private modalService: NgbModal,
    private _assessmentShareService: AssessmentShareService) { }

  ngOnInit() {
    this.fetchAssessments();
    this.role = localStorage.getItem('role');

    if (this.role == 'student' && this.router.url == '/retrieve-assessment') {
      this.router.navigate(['/']);
    }
  }

  get getRole() {
    return this.role;
  }
  courseList:any = [];
  courseListN:any = [];
  fetchAssessments() {
    this.role = localStorage.getItem('role');
    if (this.role != 'student') {
      this.loader = true;
      this.assessments = [];
      this._questionBankService.retrieve_assessment(this.currentPage, this.selectedAssessmentType).pipe(
        finalize(() => {
          this.loader = false;
        })).subscribe(
          (response) => {

            this.assessmentsType = response.type;
            this.assessments = response.saved_assessment;
            let co = this.assessments.forEach((item, i)=>{
              this.courseList = item.courses.map(x => x.course);
              this.courseListN.push(this.courseList)
            });
            
            this.numPages = Math.ceil(response.total_assessments / 10);
            let startPage: number, endPage: number;
            if (screen.width <= 767) {
              if (this.numPages <= 5) {
                // less than 10 total pages so show all
                startPage = 1;
                endPage = this.numPages;
              } else {
                if (this.currentPage <= 3) {
                  startPage = 1;
                  endPage = 5;
                } else if (this.currentPage + 2 >= this.numPages) {
                  startPage = this.numPages - 4;
                  endPage = this.numPages;
                } else {
                  startPage = this.currentPage - 2;
                  endPage = this.currentPage + 2;
                }
              }
            } else {
              if (this.numPages <= 10) {
                // less than 10 total pages so show all
                startPage = 1;
                endPage = this.numPages;
              } else {
                if (this.currentPage <= 6) {
                  startPage = 1;
                  endPage = 10;
                } else if (this.currentPage + 4 >= this.numPages) {
                  startPage = this.numPages - 9;
                  endPage = this.numPages;
                } else {
                  startPage = this.currentPage - 5;
                  endPage = this.currentPage + 4;
                }
              }
            }
            // console.log('startPage '+startPage +' and endPage '+endPage);
            this.pages = [];
            this.pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
            // console.log(this.pages);
          },
          (error) => {
            this.loader = false;
            this._notificationService.showNotification("error", "Unable to retrieve assessments please try again later");
          })
    }
  }

  onSelectAssessment(val) {
    this.selectedAssessmentType = val
    this.currentPage = 1;
    this.fetchAssessments();
  }


  fetchAssMent(valP) {
    this.role = localStorage.getItem('role');
    if (this.role != 'student') {
      this.loader = true;
      this.assessments = []
      this._questionBankService.searchAssessment(valP, this.currentPage).pipe(finalize(() => {
        this.loader = false;
      })).subscribe((response: any) => {
        this.assessments = response.assessments;
        this.numPages = response.total_pages;
        let startPage: number, endPage: number;
        startPage = 1;
        endPage = this.numPages
        this.pages = [];
        this.pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
      })
    }
  }


  inputValue: string
  isAssAvailable: boolean = false;
  setTimeOutFun:any;
  searchAss(ev: any) {    
    // search value store in variable;
    let valP = ev.target.value;
    // get role for premissions
    this.role = localStorage.getItem('role');
    // check premission
    if (this.role != 'student') {
      // clear timeout 
      clearTimeout(this.setTimeOutFun);
      // start settimeout 
      this.setTimeOutFun =  setTimeout(() => {        
        if(ev && valP == ''){          
          this.isAssAvailable = false;
          this.fetchAssessments();
        } else if (valP && valP.trim().toLowerCase() != '') { 
          // checking if value not equal to emplty
            this.currentPage = 1;
            this.inputValue = valP.trim().toLowerCase();
            this.fetchAssMent(valP)
            this.isAssAvailable = true;
      } 
    }, 1000);
    }
  }


  previous() {
    if (this.currentPage <= 1) {
      this.currentPage = 1;
    } else {
      this.currentPage = this.currentPage - 1;
    }

    if (this.isAssAvailable) {
      this.fetchAssMent(this.inputValue)
    } else {
      this.fetchAssessments();
    }
  }

  first() {
    this.currentPage = 1;
    if (this.isAssAvailable) {
      this.fetchAssMent(this.inputValue)
    } else {
      this.fetchAssessments();
    }
  }

  last() {
    this.currentPage = this.numPages;

    if (this.isAssAvailable) {
      this.fetchAssMent(this.inputValue)
    } else {
      this.fetchAssessments();
    }
  }

  next() {
    if (this.currentPage < this.numPages) {
      this.currentPage = this.currentPage + 1;
    }

    if (this.isAssAvailable) {
      this.fetchAssMent(this.inputValue)
    } else {
      this.fetchAssessments();
    }
  }

  onPage(val) {

    this.currentPage = val;

    if(val){
      this.courseListN = [];
    }
    if (this.isAssAvailable) {
      this.fetchAssMent(this.inputValue)
    } else {
      this.fetchAssessments();
    }

  }

  editassessment(data) {
    // console.log(data);
    this._assessmentShareService.setAssessment(data);
    this.router.navigate(['/edit-assessment'], { queryParams: { assessment_id: data._id } });
  }

  gotoViewAssessment(data) {
    this._assessmentShareService.setAssessment(data.assessment_name);
    localStorage.setItem('assessment_name', data.assessment_name);
    this.router.navigate(['/view-assessment', data._id]);
  }

  questionModel = null;
  openModelDeleteAss(deleteAss) {
    this.questionModel = this.modalService.open(deleteAss, { size: "sm", centered: true });
  }

  closeAssPopup() {
    this.questionModel.close();
  }

  deleteAssMent(assessment_id) {
      this._questionBankService.deleteAssmentID(assessment_id).pipe(finalize(() => this.loader = false)).subscribe(res => {
        this._notificationService.showNotification("success", "Assessment Deleted Successfully");
        this.questionModel.close();
        if (this.isAssAvailable) {
          this.fetchAssMent(this.inputValue)
        } else {
          this.fetchAssessments();
        }
        // setTimeout(() => {
        //   this.searchQus(this.inputValue)
        // }, 3000);
      },
        (err) => {
          this._notificationService.showNotification("error", err.message);
        })
  }

}


