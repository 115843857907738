import { Component, OnInit, OnDestroy, Renderer2, Input, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { CourseService } from 'src/app/services/course.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CourseAssessmentShareService } from 'src/app/services/course-assessment-share.service';
import { ShowCourseView } from 'src/app/model/course.model';
import { DomSanitizer } from '@angular/platform-browser';
import { QuestionService } from 'src/app/services/question.service';
import { environment } from 'src/environments/environment';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { ShareService } from 'src/app/services/share.service';
import { Location } from '@angular/common';



declare var tinymce: any;



@Component({
  selector: 'app-video-notes',
  templateUrl: './video-notes.component.html',
  styleUrls: ['./video-notes.component.scss']
})
export class VideoNotesComponent implements OnInit {
  loader = false;
  url: string;
  resources = [];
  insertBlankFlag: boolean = false;
  toolbarWithoutBlankButton = 'undo redo | formatselect | bold italic backcolor | \
  alignleft aligncenter alignright alignjustify | \
  bullist numlist outdent indent | removeformat | \
  link image | preview media help';
  fileArr: any = [];
  Topic_assessments = [];
  sessionAssessment = [];
  FeedbacksList = [];

  courseId = null;
  answersCounts: any
  courseName = null;
  content = [];
  isSubscribed = false;
  course_category_id: string;
  course_category: string;
  role = '';
  currentRate = 0;
  description: string;
  public player;
  videoStartTime;
  active_id = 1;

  @Input() note_summary_updated = new EventEmitter();


  currentUsername = '';
  myQuestions = [];
  openCoursework;
  announcementpopUp;
  rating: number;
  courseAssessmentData: any;
  viewsubmitmodal: any;
  dismissFlag: boolean;
  viewFeedbackmodal: any;
  teacher_view_submission = [];
  course_work_view: any;
  go_live_name = 'Go Live';
  go_live: any;
  assessment_live = 'Inactive';
  topicId: any;
  ques_id = '';
  session_id = '';
  live_session_id = ''
  pageInfo = '';

  session_detail_data: any
  playlist_info:any
  session_status: string;
  private modalRef;
  currentTime: string;
  videoElement: any;
  file_id = '';
  ngbNavItem:any;
  question_user_id: any;
  private ytEvent;
  public noteTake: boolean = false;
  public noteButtonName: any = 'Notes';
  user_name = '';
  sessionkey = '';
  userId = '';
  showCourseData: ShowCourseView;
  sesKey: string;
  apiEndpoint: string;
  upload_index: any;
  progressBar: number;
  first_name: any;
  questionId = '';
  avatarURL;
  pageComment: number = 1;
  totalcomments: number = 0;
  pageP: number = 1;
  totalAnsLen: number;
  totalRecordeP: any;
  sessionStatus;
  playlist_infoSession:any = [];
  isVideoChange = false;
  activeIdNav:any = 1;
  isTA:string = 'false';
  @ViewChild('videoPlayer',{static:false}) videoPlayer: ElementRef;
  @ViewChild('scrollContainer',{static:false}) scrollContainer: ElementRef;
  enableReportIssue: boolean = false;

  constructor(
    private renderer: Renderer2,
    private courseService: CourseService,
    private questionService: QuestionService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private _courseAssessmentShareService: CourseAssessmentShareService,
    private route: Router,
    private DOMSANI: DomSanitizer,
    private activatroute : ActivatedRoute,
    private notificationService: NotificationService,
    private sharedShervice: ShareService,
    private location: Location
    ) {
    this.apiEndpoint = environment.APIEndpoint;
    this.renderer.addClass(document.body, 'videoNotes');
  }

  ngOnInit() {
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.user_name = localStorage.getItem('currentUser');
    this.role = localStorage.getItem('role');
    this.courseName = localStorage.getItem('courseName');
    localStorage.setItem('pageName', 'view course topics');
    this.courseId = this.activatedRoute.snapshot.queryParamMap.get('course_id');
    this.sessionkey = this.activatedRoute.snapshot.queryParamMap.get('sessionkey');
    this.first_name = localStorage.getItem('first_name')
    this.session_id = this.activatedRoute.snapshot.queryParamMap.get('session_id');
    this.course_category_id = this.activatedRoute.snapshot.queryParamMap.get('course_category_id');
    this.course_category = this.activatedRoute.snapshot.queryParamMap.get('course_category');
    this.pageInfo = this.activatedRoute.snapshot.queryParamMap.get('info');
    this.questionId = this.activatedRoute.snapshot.queryParamMap.get('questionId');
    this.topic_id = this.activatedRoute.snapshot.queryParamMap.get('topicId');
    this.userId = localStorage.getItem('user_id')
    this.ngbNavItem = 1;//this.activatedRoute.snapshot.queryParamMap.get('ngbNavItem');
   // this.sessionStatus = this.activatedRoute.snapshot.queryParamMap.get('sessionStatus');
    this.avatarURL = localStorage.getItem('avatarURL');
    this.isTA = this.activatedRoute.snapshot.queryParamMap.get('isTA');
    this.activeIdNav = this.activatedRoute.snapshot.queryParamMap.get('tab_no');

    // this.session_detail()
    this.activatroute.queryParams.subscribe(res => {
      // this.playPlayList(res.course_id, res.session_id,res.course_category,res.course_category_id,res.topic_id,res.sessionkey); 
      this.courseId = res.course_id
      this.session_id = res.session_id
      this.topic_id= res.topicId;
      this.sessionkey = res.sessionkey;
      //this.sessionStatus = res.sessionStatus
      this.session_detail(); 
      // this.getCommentsInfo(this.session_id, this.courseId);
      // this.viewQuestions();
      this.isVideoChange = true;
      setTimeout(() => {
        this.videoElement = document.getElementById('videoByS3');
        if(this.videoElement){
          this.videoPlayer.nativeElement.onended = (event) => {
            this.trackingStatus();
            const curretnItemIndex = this.playlist_infoSession.findIndex((item)=>{
              return item._id == this.session_id;
            })
            if(this.playlist_infoSession[curretnItemIndex+1]){
              this.playPlayList(this.playlist_infoSession[curretnItemIndex+1]._id, this.playlist_infoSession[curretnItemIndex+1])
            }
        };
      }
      }, 2000);

      this.ngbNavItem = Number(localStorage.getItem('ngbNavItem'));
     });

     if(!localStorage.getItem('ngbNavItem')) {
      localStorage.setItem('ngbNavItem', '1');
     }

     this.active_id = Number(this.ngbNavItem);
    if(this.ngbNavItem == 4){
      this.active_id = 4;
      this.viewQuestions();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.scrollToactiveDiv();


    }, 500);
    setTimeout(() => {
    this.videoElement = document.getElementById('videoByS3');
    if(this.videoElement){
      this.videoPlayer.nativeElement.onended = (event) => {
        this.trackingStatus();
        const curretnItemIndex = this.playlist_infoSession.findIndex((item)=>{
          return item._id == this.session_id;
        })
        if(this.playlist_infoSession[curretnItemIndex+1]){
          this.playPlayList(this.playlist_infoSession[curretnItemIndex+1]._id, this.playlist_infoSession[curretnItemIndex+1])
        }
    };
    }
  }, 2000);

  
}

onNavChange(event){
  this.ngbNavItem = event.nextId
  this.active_id = this.ngbNavItem;
  localStorage.setItem('ngbNavItem', String(this.ngbNavItem));
  // this.updateQueryParam('ngbNavItem', this.ngbNavItem);
}

updateQueryParam(param: string, value: string) {
  // Get current query parameters
  const queryParams = { ...this.activatedRoute.snapshot.queryParams };

  // Update the specific parameter
  queryParams[param] = value;

  // Navigate with updated query parameters
  this.route.navigate([], {
    relativeTo: this.activatedRoute,
    queryParams: queryParams,
    queryParamsHandling: 'merge' // This merges the existing query params with the new ones
  });
}

scrollToactiveDiv(){
  if(this.scrollContainer) {
    const conatiner = this.scrollContainer.nativeElement;
    const activeDiv =  conatiner.querySelector('.activeSessionItem');

    if(activeDiv){
      activeDiv.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest" });
    }
  }
}

trackingStatus(){
  this.courseService.statusTarcking(this.courseId,this.topic_id, this.session_id).subscribe((response)=>{
  })
}

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'videoNotes');
  }

  transcription_details: any = [];
  sessions:any;
  s3_key = '';
  vtt_file;
  session_detail() {
    this.loader = true;
    let myVid: any;
    this.courseService.session_detail(this.courseId, this.session_id,this.topic_id).subscribe(session => {
      this.sessions = session;
      this.session_status = session.content_detail.status;
      this.sessionStatus = session.content_detail.status
      this.session_detail_data = session.content_detail
      this.url = session.content_detail.url;
      this.s3_key = session.s3_key;
      this.sessionkey= session.content_detail.key
      this.live_session_id = session.content_detail.course_live_session_id
      this.vtt_file = this.sanitizeURL(session.vtt)
      this.file_id = session.content_detail.file_id;
      this.resources = session.content_detail.resources;
      this.sessionAssessment = session.content_detail.assessments;
      this.playlist_info = session.topic_info;
      this.playlist_infoSession = session.topic_info.sessions;
      this.videoStartTime = session.view_video_time;
      this.session_status == 'active' ? this.go_live_name = 'Live' : this.go_live_name = 'Go Live';
      this.Topic_assessments = session.content_detail.assessments;
      this.topicId = session.topic_id;
      this.loader = false;
      
     
    }, (err) => {
      this.notificationService.showNotification('error', err.message);
    })
  }

  playPlayList(session_id,playlist_info){
   
    this.route.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { session_id: session_id,sessionkey:playlist_info.key},
        queryParamsHandling: 'merge'
      })
      
      
  }

  

  // For TRANSCRIPTION

  getTranscription() {
    this.loader = true;
    this.courseService.getTranscription(this.courseId, this.session_id,this.topic_id).subscribe(sessionTranscripts => {
      if(Object.keys(sessionTranscripts.response).length > 0) {
        this.transcription_details = sessionTranscripts.response;
      }
      this.loader = false;
      this.reportIssue = true
    }, (err) => {
      this.notificationService.showNotification('error', err.message);
    })
  }


  viewQuestions() {
    this.courseService.viewQuestions(this.session_id, this.courseId).subscribe(questions => {
      this.myQuestions = questions.response;
      this.totalRecordeP = this.myQuestions.length  / 10;
    })
  }

  askQuestionModel(model) {
    this.modalRef = this.modalService.open(model, { size: 'md' });
    this.pauseVideo()
  }


  pauseVideo() {
    let time = 0;
    if (this.player) {
      this.player.target.pauseVideo();
      time = this.player.target.getCurrentTime();
      time = time - 10;
      this.currentTime = time.toString().split('.')[0];
    }
    else {
      this.videoElement = document.getElementById('videoByS3');
      if (this.videoElement) {
        this.videoElement.pause();
      }
    }
  }


  saveVideoState(player, popup) {
    let videoTime;
    let splitVideoTime;
    this.file_id ? this.videoElement = undefined : this.player = undefined;
    if (player) {
      videoTime = player.target.getCurrentTime();
      videoTime = videoTime.toString();
      splitVideoTime = videoTime.split('.');
      videoTime = splitVideoTime[0];
    } else if (this.videoElement) {
      this.videoElement = document.getElementById('videoByS3');
      videoTime = this.videoElement.currentTime.toFixed(0);
    }
    if (videoTime) {
      this.courseService.saveVideoTime(this.courseId, this.session_id, videoTime).pipe(finalize(() => this.loader)).subscribe(saveTime => {
      })
    }
  }

  closeAskQuestionModel(value) {
    if (value == 'closeModel') {
      this.closeAskQuestion();
    }
  }

  closeAskQuestion() {
    this.modalRef.close();
    if (this.player) {
      this.player.target.playVideo();
    } else {
      this.videoElement.play();
    }
    this.viewQuestions();
  }

  user_id = '';
  topic_id;
  course_session_id;
  view_teacher_assessment = [];
  assessmentFeedbackPopup(session, topicAssessment, assessment_view, topic_id, course_session_id, type?: string) {
    this.courseAssessmentData = topicAssessment;
    this.topic_id = topic_id;
    this.course_session_id = course_session_id;
    this.pauseVideo();
    if (assessment_view) {
      this.user_id = assessment_view._id;
      this.view_teacher_assessment = assessment_view
    }
    this.route.navigate(['/courses/feedback-assessement'] , { queryParams: { assessment_id:topicAssessment._id, course_id:this.courseId,topicId: topic_id, session_id: this.course_session_id,user_id:this.userId } })
  }

  savePlayer(player) {
    this.player = player;
    this.player.target.seekTo(this.videoStartTime)
    this.player.target.playVideo();
    this.speed = this.player.target.getPlaybackRate();
  }

  submitCourseFeedBack(form: NgForm, course_session_id) {
    if (!(form.value.feedBack)) {
      this.notificationService.showNotification('info', 'Please enter comment');
      return;
    }
    //  this.loader = true;
    let comment = form.value.feedBack.trim()
    if(!comment){
      this.notificationService.showNotification('info', 'Please enter comment');
      return;
    }
    this.courseService.session_video_comments(this.courseId, comment, course_session_id).pipe(finalize(() => { this.loader = false })).subscribe(feedback => {
      this.notificationService.showNotification("success", "Comment added successfully");
      this.getCommentsInfo(this.session_id, this.courseId)
      form.reset();
    })
  }

  feedBack_list = [];
  getCommentsInfo(session_id, courseId) {
    this.loader = true;
    this.courseService.getCommentsInfo(session_id, courseId).pipe(finalize(() => { this.loader = false; })).subscribe(weekInfo => {
      this.feedBack_list = weekInfo.content_detail.comments;
      this.videoStartTime = weekInfo.content_detail.view_video_time;
    })

  }


  openAssessmentForSession(session, assessment, time, index?: number) {
    
    //need a key in view_feedback api for checking assessment feedback status of user true/false;
    // if (assessment.view_feedback) {
      //   this.notificationService.showNotification('info', 'you have already seen the assessment feedback now you are not eligible any more for give the assessment.')
      //   return;
      // }
      let _id = assessment._id;

      //FOR TIMER STOP FOR CALENDERED |START |17-FEB-2023
    /*let topic_assessment_is = {
      'active': true,
      "schedule_id": "",
      "total_time": time,
      "_id": _id,
    }*/
    let topic_assessment_is = assessment;
    topic_assessment_is['active'] = true;
    topic_assessment_is['schedule_id'] = "";
    topic_assessment_is['total_time'] = time;
    topic_assessment_is['_id'] = _id;
    //FOR TIMER STOP FOR CALENDERED |END |17-FEB-2023

    this.session_detail();
    if (this.sessionAssessment[index].active || this.sessionAssessment[index].active == undefined || this.session_status == 'recorded') {
      this.courseAssessmentData = topic_assessment_is;
      assessment.active_assessment = 'Active';
      // this.viewsubmitmodal = this.modalService.open(session, { size: 'lg' });
      this.go_to_assessment(this.courseAssessmentData, '/courses/submit-assessement')
      this.pauseVideo()
    } else {
      this.notificationService.showNotification('info', 'This assessment is not live right now.')
    }
  }

  openNote() {
    this.noteTake = !this.noteTake;
    if (this.noteTake)
      this.noteButtonName = "Notes";
    else
      this.noteButtonName = "Notes";
  }

  closeNB() {
    this.noteTake = false;
  }

  assessmentTitle(value) {
    this.courseAssessmentData.title = value;
  }

  sendToQuestion(id) {
    this.route.navigate(['/question-details'], { queryParams: { questionId: id, info: "MYQUES" } });
  }

  update_note(event) {
    event == 'update_note' ? this.session_detail() : '';
  }

  viewAssessmentGraphically(courseAssessment, url, type?: string) {
    this.session_id = this.activatedRoute.snapshot.queryParamMap.get('session_id');
    this.course_category_id = this.activatedRoute.snapshot.queryParamMap.get('course_category_id');
    this.course_category = this.activatedRoute.snapshot.queryParamMap.get('course_category');

    this._courseAssessmentShareService.setCourseAssessment(courseAssessment);
    this._courseAssessmentShareService.setSessionId(this.session_id);
    this._courseAssessmentShareService.setCourseId(this.courseId);
    this._courseAssessmentShareService.setTopicId(this.topicId);
    this._courseAssessmentShareService.setCourseName(this.courseName);
    this._courseAssessmentShareService.setCourseSessionId(this.course_session_id);
    this._courseAssessmentShareService.setpublishRight(this.sessions.publish_right);
    this._courseAssessmentShareService.setUserId(this.user_id);
    this._courseAssessmentShareService.setSessionKey(this.sessionkey);
    this._courseAssessmentShareService.setCourse_Category(this.course_category);
    this._courseAssessmentShareService.setCourse_category_id(this.course_category_id);

    if (type != 'teacher_view') {
      this.route.navigate([url],{ queryParams: {course_id: this.courseId, course:this.courseName, topicId: this.topicId, session_id: this.session_id,tab_no:this.activeIdNav,ngbNavItem: this.ngbNavItem } })
    }
  }

  getGraphAssessment(assessmentdata){
    this.route.navigate(['/courses/assessment-graph'], { queryParams: { course_id: this.courseId, course:this.courseName, assessment_id:assessmentdata._id, session_id:this.session_id, course_category_id:this.course_category_id,topicId:this.topicId,sessionkey:this.sessionkey, isTA:this.isTA, tab_no:this.activeIdNav,ngbNavItem: this.ngbNavItem } })
  }

  onStateChange(event) {
    this.ytEvent = event.target;
    if(event.data === 0){
      this.trackingStatus();
      const curretnItemIndex = this.playlist_infoSession.findIndex((item)=>{
        return item._id == this.session_id;
      })
      if(this.playlist_infoSession[curretnItemIndex+1]){
        this.playPlayList(this.playlist_infoSession[curretnItemIndex+1]._id, this.playlist_infoSession[curretnItemIndex+1])
      }
  }
  }

  go_back() {
    this.route.navigate(['/courses/single-course'], { queryParams: { course_id: this.courseId, course:this.courseName, tab_no:this.activeIdNav } })
  }

  go_live_assessement(toggle) {

    if (this.session_status == ('active') || this.session_status == ('inactive')) {
      if (toggle.target.checked) {
        if (confirm('Are you sure you want to use this session again! All previous data get erased')) {
          this.make_live(toggle);
          this.session_status == 'active' ? 'inactive' : 'active';
        } else {
          toggle.target.checked = !toggle.target.checked;
          this.go_live_name = 'Go Live';
        }
      } else if (!toggle.target.checked) {
        this.make_live(toggle);
      }
    } else if (this.session_status == '') {
      if (confirm('Are you sure ?')) {
        this.make_live(toggle);
      } else {
        toggle.target.checked = !toggle.target.checked;
        this.go_live_name = 'Go Live';
        return;
      }
    }
  }

  markatt:boolean;
  golive(toggle) {

    if (this.session_status == ('active') || this.session_status == ('inactive')) {
      if (toggle.target.checked) {
        if (confirm('Are you sure you want to use this session again! All previous data get erased')) {
          this.make_live(toggle);
          this.markatt = toggle.target.checked;
          this.session_status == 'active' ? 'inactive' : 'active';
        } else {
          toggle.target.checked = !toggle.target.checked;
          this.markatt = toggle.target.checked;
          this.go_live_name = 'Go Live';
        }
      } else if (!toggle.target.checked) {
        
        this.make_live(toggle);
      }
    } else if (this.session_status == '') {
      if (confirm('Are you sure ?')) {
        this.make_live(toggle);
        this.markatt = toggle.target.checked;
      } else {
        toggle.target.checked = !toggle.target.checked;
        this.markatt = toggle.target.checked;
        this.go_live_name = 'Go Live';
        return;
      }
    }
  }

  make_live(toggle) {
    this.courseService.go_live_with_assessment(this.session_id, this.courseId, this.topicId, toggle.target.checked).subscribe(go_live => {
      this.go_live = go_live.url;
      go_live.url ? this.go_live_name = 'Live' : this.go_live_name = 'Go Live';
      go_live.url ? this.session_status = 'active' : this.session_status = 'inactive';
      go_live.url ? window.open(this.go_live, '_blank') : '';
      this.notificationService.showNotification('success', go_live.message);
      this.markatt = toggle.target.checked;
      this.session_detail();
   
    },
      (error) => {
        this.notificationService.showNotification('error', error.message);
        toggle.target.checked = false;
        this.markatt = false
      })
  }



  make_assessment_live(assessment, event) {
    let live = ''
    event.target.checked ? live = 'Active' : live = 'Inactive';
    if (confirm('Are you sure for making assessment ' + live + ' ?')) {
      this.courseService.make_live_assessment(this.session_id, this.courseId, this.topicId, assessment._id, event.target.checked).subscribe(live_assessment => {
        assessment.active_assessment = event.target.checked ? 'Active' : 'Inactive';
        this.notificationService.showNotification('success', 'assessment ' + assessment.active_assessment);
        this.session_detail();
      },
        (error) => {
          this.notificationService.showNotification('error', error.message);
          event.target.checked = false;
          this.assessment_live = 'Inactive';

        })
    } else {
      event.target.checked = !event.target.checked;
      this.assessment_live = 'Inactive';
      return;
    }
  }

  resetForm(form: NgForm) {
    form.reset();
  }

  closeModalQuestion(val) {
    if (val == 'closeModal' || val == 'noassessment') {
      this.viewsubmitmodal.close();
    }
    if (val == 'closeFeedbackModal') {
      this.viewFeedbackmodal.close();
    }

  }
  speed = 1;
  videoSpeed(event, file_id) {
    if (file_id) {
      document.querySelector('video').playbackRate = event.target.value;
      document.querySelector('video').play();
      this.speed = event.target.value;
    } else if (!file_id) {
      this.player.target.setPlaybackRate(Number(event.target.value));
      this.speed = this.player.target.getPlaybackRate();
    }
  }

  view_assessment_submission: any[];
  assessment_view;
  viewSubmission(submission, assessment, topic_id?: string) {
    let assessmentId = assessment._id;
    let scheduleId = assessment.schedule_id;
    let submitted_time = assessment.submitted_time
    this.assessment_view = assessment;
    this.topicId = topic_id ? topic_id : "";
    this.markAttendance(assessment)
    // this.courseService.view_assessment_teacher(assessmentId, this.courseId, scheduleId, submitted_time, assessmentId).subscribe(view_submission => {
    //   this.view_assessment_submission = view_submission.response
    //   this.modalService.open(submission, { size: 'lg' });
    // })
  }

  go_to_assessment(courseAssessmentData, url, type?: string) {
    this.viewAssessmentGraphically(courseAssessmentData, url, type)
  }
  markAttendance(assessment) {
    if (assessment) {
      this.route.navigate(['courses/assessment-marking'], { queryParams: {  
        course: this.courseName,
        course_id: this.courseId,
        assessment_id: assessment._id,
        assessment_name: assessment.title,
        schedule_id: assessment.schedule_id,
        start_time: assessment.start_time,
        topicId: this.topicId,
        session_id: this.session_id,
        isTA:this.isTA,
        tab_no:this.activeIdNav,
        ngbNavItem: this.ngbNavItem
      } })
      return;
    }
    this.notificationService.showNotification('info', 'We are trying to ready student attendance for you give us 1 min.')
    return;
  }
  showHideDismiss(val) {
    if (val == 'showdismissbutton') { this.dismissFlag = false; }
    else if (val == 'hidedismissbutton') { this.dismissFlag = true; }
    else { this.dismissFlag = true; }
  }

  go_live_session(session, key) {
    
    return session && session.publish_right && key == 'live_session' && !this.notificationService.get_status_zoom(this.session_status);
  }

  get_s3_link() {
    if (this.file_id == '' && this.get_session() == 'session' && this.session_status != '') {
      return true;
    }
    else if (this.file_id != '') {
      return true;
    }
    return false;
  }

  get_youtube_video() {
    if (this.file_id == '' && this.get_session() == 'session' && this.session_status == '') {
      return true;
    }
    return false;
  }

  get_session() {
    if (this.sessionkey == 'session' || 'live_session') {
      return 'session';
    } else {
      return 'added'
    }
  }

  rate_change(event) {
    this.speed = event.data;
  }

  urlVerify(text) {
    var urlRegex = /(((https?:\/\/)|(http?:\/\/)|(www\.))[^\s]+)/g
    return text.replace(urlRegex, (url) => {
      return '<a href="' + url + ' "target="_blank">' + url + '</a>';
    })
  }

  issueReport = false;
  indOfEdit;
  issueReportEdit(indOftrans) {
    this.indOfEdit = indOftrans
    this.issueReport = !this.issueReport;
  }

  report_issue_trans(item, status) {
    let suggession = ''
    if (status == 'self') {
      if (this.self) {
        suggession = this.self;
      } else {
        this.notificationService.showNotification('info', 'Please add some transcription first.');
        return;
      }
    } else if (status != 'self') {
      suggession = item.pendings.suggestion
    }
    this.courseService.report_issue(item.start_time, item.end_time, status, suggession, this.s3_key).pipe(finalize(() => {
      setTimeout(() => {
        this.renderer.removeClass(document.body, 'hasLoader')
      }, 2000);
    })).subscribe(val => {
      this.notificationService.showNotification('success', val.message);
      this.getTranscription();
      this.session_detail();
      this.self = ''
      this.renderer.addClass(document.body, 'hasLoader');
    })
  }
  self = '';
  save_self(event) {
    this.self = event.target.value;
  }

  play(item) {
    if (this.player) {
      this.player.target.setCurrentTime(item.start_time);
    } else {
      this.videoElement = document.getElementById('videoByS3');
      this.videoElement.currentTime = this.getTotalSeconds(item.start_time);
      this.videoElement.play();
    }
  }

  /**
  * To convert hour:minute:second to seconds
  * @param {string} timeVal - to get the time in hour:minute:second format.
  * @return {number} - to return time in seconds
  */
  getTotalSeconds(timeVal: string): number {
    const timeData = timeVal.split(':');
    const totalSeconds = (parseInt(timeData[0]) * 60 * 60) + (parseInt(timeData[1]) * 60) + (parseInt(timeData[2]));
    return totalSeconds;
  }

  sanitizeURL(url) {
    return this.DOMSANI.bypassSecurityTrustUrl(url)

  }

  sessionQuestion(id,) {
    this.pauseVideo()
    this.route.navigate(['/question-details'], { queryParams: { questionId: id, courseId: this.courseId, course:this.courseName,session_id: this.session_id, course_category_id: this.course_category_id, videoStartTime: this.videoElement.currentTime, info: "RECOM", tab_no:this.activeIdNav } })

  }


 


  downloadResource(resource) {
    window.open(resource, '_blank');
  }

  Questions: any
  answers: any
  question_video_List:any
  question_image_list:any
  question_doc_List:any
  question_doc_key:any
  question_doc_path:any
  question_image_key:any
  question_video_key:any
  edit_note:boolean =true
  QuestDetails:boolean = false
  getQuestionAnswer(questionId) {
    this.edit_note = false;
    this.QuestDetails = true
   this.question_video_List=[]
   this.question_image_list=[]
   this.question_doc_List=[]
    this.ques_id = questionId
    this.questionService.getUserQuestionAnswer(questionId).subscribe(questions => {
      this.Questions = questions.response;
      this.answers = this.Questions.answers;
      this.answersCounts = this.answers.length;
      this.totalAnsLen = this.answers.length;
      this.question_user_id = this.Questions.user_id
      this.question_status = this.Questions.question_status;
      this.question_doc_key = this.Questions.question_doc_key;
      this.question_image_key = this.Questions.question_image_key;
      this.question_video_key = this.Questions.question_video_path
      this.question_doc_path =  this.Questions.question_doc_path;
        let ansResourceVideos = this.Questions.question_video_path;
        let resourceImages = this.Questions.question_image_path;
        let resourceDocs = this.Questions.question_doc_path;

      if (ansResourceVideos) {
        this.resourceCondition(ansResourceVideos, this.question_video_List);
      }
      if (resourceImages) {
        this.resourceCondition(resourceImages, this.question_image_list);
      }
      if (resourceDocs) {
        this.resourceCondition(resourceDocs, this.question_doc_List);
      }
      
    })
    
  }

  answersDetails: any
  approval_status: any;
  question_status:any
  like_count:number;
  dislike_count:number;
  ansDetailUserID
  answer_image: any
  answer_video: any
  answer_doc: any
  answer_video_List:any
  answer_image_list:any
  answer_doc_List:any
  answer_id;
  question__id;
  answerHtml;
  resourceDocsAns= [];
  resourceImagesAns = [];
  ansResourceVideosAns = [];
  ansResourceVideosKeyAns = [];
  resourceImagesKeyAns = [];
  resourceDocsKeyAns = [];
  AnsDetails:boolean=false
  getAnswerDetails(Id,commentFilter = '') {
    this.answer_id = Id
    this.QuestDetails =false
    this.AnsDetails = true
    this.answer_video_List=[]
    this.answer_image_list=[]
    this.answer_doc_List=[]
    this.questionService.myAnswersession(Id,commentFilter).subscribe(answers => {
      this.answersDetails = answers.response;
      this.FeedbacksList = this.answersDetails.Feedback;
      this.totalcomments = this.answersDetails.Feedback.length;
      this.answer_image = this.answersDetails.answer_image
      this.answer_video = this.answersDetails.answer_video
      this.answer_doc = this.answersDetails.answer_doc;
      this.question__id = this.answersDetails.question_id
      this.isLiked = this.answersDetails.Liked;
        this.isDisLiked = this.answersDetails.Disliked;
        this.like_count = this.answersDetails.like_count;
        this.dislike_count = this.answersDetails.dislike_count;
        this.ansDetailUserID = this.answersDetails.user_id;
        this.approval_status = this.answersDetails.approval_status;
        this.question_status =this.answersCounts.question_status
        this.answerHtml = this.answersDetails.answer_html;


      //new code added 

      // Resources key
      this.ansResourceVideosKeyAns = this.answersDetails.answer_video_key;
      this.resourceImagesKeyAns = this.answersDetails.answer_image_key;
      this.resourceDocsKeyAns = this.answersDetails.answer_doc_key;
      // Resources paths
      this.ansResourceVideosAns = this.answersDetails.answer_video;
      this.resourceImagesAns = this.answersDetails.answer_image;
      this.resourceDocsAns = this.answersDetails.answer_doc;


      let ansResourceVideos = this.answersDetails.answer_video;
      let resourceImages = this.answersDetails.answer_image;
      let resourceDocs = this.answersDetails.answer_doc;

      // Resources paths condition basis
      if (ansResourceVideos) {
        this.resourceCondition(ansResourceVideos, this.answer_video_List);
      }
      if (resourceImages) {
        this.resourceCondition(resourceImages, this.answer_image_list);
      }
      if (resourceDocs) {
        this.resourceCondition(resourceDocs, this.answer_doc_List);
      }

    })
  }
  popupModel=null
  deleteQuestionModel(deleteQues){
    this.popupModel = this.modalService.open(deleteQues, { size: "sm", centered: true });
  }

  closeQuesPopup(){
    this.popupModel.close();
  }

  deleteQuestion(questionId) {

      this.questionService.deleteQuestion(questionId)
        .pipe(finalize(() => this.saving_notes = false)).subscribe(data => {
          this.notificationService.showNotification('success', data.message);
          this.popupModel.close();
          this.viewQuestions();
          this.session_detail();
        },
          (err) => {
            this.notificationService.showNotification('error', err.message);
          })
    

  }

  saving_notes
  comments_like_liked(comment_id, value, course_session_id,is_true) {
    this.courseService.session_video_comments_likeDislike(comment_id, value, course_session_id,is_true)
      .subscribe(Liked => {       
          this.notificationService.showNotification('success', Liked.Status);    
        this.getCommentsInfo(this.session_id, this.courseId);       
      },
        (err) => {
          this.notificationService.showNotification('error', err.message);
        })
  }


  comments_like_disliked(comment_id, value, course_session_id,is_true) {
    this.courseService.session_video_comments_likeDislike(comment_id, value, course_session_id,is_true)
      .subscribe(Liked => {             
          this.notificationService.showNotification('success', Liked.Status);
        
        this.getCommentsInfo(this.session_id, this.courseId);
      },
        (err) => {
          this.notificationService.showNotification('error', err.message);
        })
  }

  selectFileSize: number = 0;
  totalFilesSize: number = 0;
  selectFilelenghth: any;
  uploadedFiles: any = [];
  selectedPic: any = [];
  selectedVideo: any = [];
  selectedDoc: any = [];
  // fileName: any;
  itemSum: number = 0;
  moduleName: string;
  uploadItem: boolean = false;
  noFileSelected: boolean = true;
  onResourceSelected(event) {
    this.selectedPic = [];
    this.selectedVideo = [];
    this.selectedDoc = [];
    let files = event.target.files;
    this.noFileSelected = true;
    if (event.target.files.length > 0 && event.target.files.length < 11) {
      this.noFileSelected = false;
      this.selectFilelenghth = files.length + ' ' + 'File Selected';
      for (let i = 0; i < files.length; i++) {
        let fileType = files[i].type;
        let selectFile = files[i];
        // this.itemSum += parseInt(selectFile.size);
        // this.totalFilesSize = (this.itemSum / 1000000);
        // let sumNum = parseInt(this.totalFilesSize.toFixed());
        // this.selectFiles = files[i];
        // this.selectFilelenghth = files[i].length;

        // Find Module Name
        if (fileType.includes('image')) {
          this.moduleName = 'answer-image';
        } else if (fileType.includes('video')) {
          this.moduleName = 'answer-videos';
        } else if (fileType.includes('audio')) {
          this.moduleName = 'answer-file';
        } else if (fileType.includes('text') || fileType.includes('') || fileType.includes('application')) {
          this.moduleName = 'answer-file';
        }

        this.notificationService.upload_resource(this.moduleName, selectFile).pipe(
          finalize(() => {
          })).subscribe(
            (response: HttpEvent<any>) => {
              this.uploadItem = true;
              switch (response.type) {
                case HttpEventType.Sent:
                  break;
                case HttpEventType.ResponseHeader:
                  break;
                case HttpEventType.UploadProgress:
                  this.progressBar = Math.round(response.loaded / response.total * 100);
                  break;
                case HttpEventType.Response:
                  // this.addResourceFrom.controls.resource_id.setValue(response.body.resource_info[0].resource_id);

                  // let uploadedFile;

                  if (response.body.resource_info[0].module_name == 'answer-image') {
                    this.selectedPic.push(response.body.resource_info[0].path);
                    // uploadedFile = response.body.resource_info[0]
                  } else if (response.body.resource_info[0].module_name == 'answer-videos') {
                    this.selectedVideo.push(response.body.resource_info[0].path);
                    // uploadedFile = response.body.resource_info[0]
                  } else if (response.body.resource_info[0].module_name == 'answer-file') {
                    this.selectedDoc.push(response.body.resource_info[0].path);
                    // uploadedFile = response.body.resource_info[0]
                  }


                  // this.uploadedFiles.push(uploadedFile);
                  // let filePath = this.uploadedFiles[i].path
                  // this.fileName = filePath.split("/");
                  // this.fileName = this.fileName[1];
                  this.notificationService.showNotification('success', 'file uploaded successfully');
                  setTimeout(() => {
                    // this.progressBar = 0;
                  }, 1500);
              }
            },
            (err) => {
              this.progressBar = 0;
              this.notificationService.showNotification('info', 'Please add again not able to save your file');
            })
      }
    } else if (!event.target.files.length || event.target.files.length < 0) {
      this.noFileSelected = true;
      this.selectFilelenghth = [];
      return;
    } else {
      this.notificationService.showNotification('info', 'Only 10 files allowed');
    }
  }


  giveYourAnswer(questionId) {
    this.route.navigate(['/give-your-answer'], { queryParams: { questionId: questionId, courseId:this.courseId, course:this.courseName,course_session_id: this.session_id,course_category:this.course_category,course_category_id:this.course_category_id,sessionkey:this.sessionkey,topicId:this.topic_id,info: this.pageInfo,tab_no:this.activeIdNav,ngbNavItem: this.ngbNavItem } });
  }


  EditQuestion(question_id: any) {
    this.route.navigate(['/edit-ask-question'], { queryParams: { questionId: question_id,courseId:this.courseId, course:this.courseName, course_session_id: this.session_id,course_category:this.course_category,course_category_id:this.course_category_id,topicId:this.topic_id,sessionkey:this.sessionkey, info: this.pageInfo,tab_no:this.activeIdNav,ngbNavItem: this.ngbNavItem  } });
    // if (this.role == 'student' && this.route.url == '/My-Questions' || this.role == 'super_admin' && this.route.url == '/Student-Questions') {
    //   this.route.navigate(['/edit-ask-question'], { queryParams: { questionId: question_id, info: this.pageInfo } });
    // }
  }

  editAnswer(answer_id: any) {
    this.route.navigate(['/edit-your-answer'], { queryParams: { questionId: this.questionId, answerId: answer_id ,courseId:this.courseId, course:this.courseName,  course_session_id: this.session_id,course_category:this.course_category,course_category_id:this.course_category_id,topicId:this.topic_id,sessionkey:this.sessionkey, info: this.pageInfo, tab_no:this.activeIdNav,ngbNavItem: this.ngbNavItem } });
  }



  deleteAnswerModel(deleteAns){
    this.popupModel = this.modalService.open(deleteAns, { size: "sm", centered: true });
  }

  // closeQuesPopup(){
  //   this.popupModel.close();
  // }

  answerId = ''
  deleteToAnswer(answer_id) {
    this.answerId = answer_id;
      this.questionService.deleteAnswer(answer_id, this.ques_id).pipe(finalize(() => this.loader = false)).subscribe(res => {
        this.notificationService.showNotification("success", "Deleted Answer Successfully");
        this.popupModel.close();
        this.getQuestionAnswer(this.ques_id);
        // below is old methord need to remove
        // this.getQuestionsResult();
      },
        (err) => {
          this.notificationService.showNotification("error", err.Status);
        })
    
  }


  sendAnswerFeedback(form: NgForm, answerId) {
    if (form.value.feedBack != '' && form.value.feedBack.trim() != '' && form.value.feedBack != null) {
      this.loader = true;
      this.questionService.answerFeedbackApi(answerId, form.value.feedBack).subscribe(answerFeedback => {
        this.notificationService.showNotification("success", answerFeedback.message);
        this.loader = false;
        this.newFilter = false;
        this.topFilter = false;
        this.FeedbacksList.unshift({ username: localStorage.getItem('currentUser'), Feedback: form.value.feedBack, Timestamp: new Date() })
        form.reset();
        this.getAnswerDetails(answerId,'');
        // this.getAnswerMetaData();
      },
        (error) => {
          this.notificationService.showNotification("info", error.message);
        })
    } else {
      this.notificationService.showNotification("info", 'Please add your comment.');
    }
  }



  is_answer_assessment(assessment) {
    if (assessment.one_time_assessment) {
      console.log(assessment.user_submission);
      return assessment.user_submission
    } else {
      console.log(assessment.view_feedback);
      return assessment.view_feedback
    }
  }

  openAssessmentPopup(session, topAssessment, topic_id?: string) {
    if (topAssessment.view_feedback) {
      this.notificationService.showNotification('info', 'you have already seen the assessment feedback now you are not eligible any more for give the assessment.')
      return;
    }
    if (this.sessions.publish_right) {
      this.notificationService.showNotification('info', 'you are not eligible for this assessment.')
      return;
    }
    this.topicId = topic_id;
    this.courseAssessmentData = topAssessment;
    this.pauseVideo();
    this.open_assessment(this.topic_id, this.courseAssessmentData, '/courses/submit-assessement')

  }

  open_assessment(topicId, courseAssessmentData, url, type?: string) {
    this.viewAssessmentGraphically(courseAssessmentData, url, type);
  }

  askaQuestion(session_id, course_id) {
    this.route.navigate(['/ask-question'], { queryParams: {courseId:course_id, course:this.courseName, course_session_id: session_id,course_category:this.course_category,course_category_id:this.course_category_id,topicId:this.topic_id,sessionStatus:this.sessionStatus,sessionkey:this.sessionkey, tab_no:this.activeIdNav,ngbNavItem: this.ngbNavItem  } });
   }
  

   resourceCondition(getArrayItem, setArrayItem) {
    for (let i = 0; i < getArrayItem.length; i++) {
      let filePath = getArrayItem[i];
      let fileNameSplitA = getArrayItem[i].split('/');
      let fileNameSplitB = fileNameSplitA[4].split('?');
      let getFileName = fileNameSplitB[0];

      setArrayItem.push({
        'fileName': getFileName,
        'fileUrl': filePath
      });
    }
  }
  
  isLiked
  isDisLiked
  setLikeDislike(value,) {
    if(this.userId != this.ansDetailUserID){
    this.questionService.setLikeDislike(value, this.answer_id).pipe(finalize(() => this.loader)).subscribe(liked => {
      this.isLiked = liked.Liked;
      this.isDisLiked = liked.Disliked;
      this.getAnswerDetails(this.answer_id,'');
    })
  }
}



  newFilter = false;
  topFilter = false;
  sortComment(type:string){
    if(type == 'top'){
      this.topFilter = !this.topFilter;
      this.newFilter = false;
      this.getAnswerDetails(this.answer_id,'top');
    }  if(type == 'Newest'){ 
      this.topFilter = false;
      this.newFilter = !this.newFilter;
      this.getAnswerDetails(this.answer_id,'Newest');
    } 
    if(this.topFilter == false && this.newFilter == false){
      this.getAnswerDetails(this.answer_id,'');
    }

  } 


  ansCommentLikeDis(commentId, status, boolStatus){
    let body = {
       'answer_id':this.answer_id,
       'comment_id':commentId,
       'status':status,
       'is_true':boolStatus
    }
    // if(boolStatus == true){
    //   // this.newFilter = false;
    //   this.topFilter = false;
    // }
    this.questionService.commentLikeDislike(body).subscribe(res => {
      this.notificationService.showNotification("success", res.Status);
      if(this.newFilter){
        this.getAnswerDetails(this.answer_id, 'Newest');
      } else if(this.topFilter){
        this.getAnswerDetails(this.answer_id, 'top');
      } else{
        this.getAnswerDetails(this.answer_id, '');
      }
    })
  }

  approveAnswer() {
    if (this.approval_status.toLowerCase() == "approved") {
      this.notificationService.showNotification("info", "Alreday Approved ");
      return false;
    }
    if (confirm("Are you sure you want approve this answer?")) {
      this.questionService.approveAnswer(this.answer_id, this.question__id).pipe(finalize(() => this.loader = false)).subscribe(res => {
        this.notificationService.showNotification("success", "Approved Successfully");
        this.approval_status = "approved";
      },
        (err) => {
          this.notificationService.showNotification("error", err.Status);
        })
    }
  }

  closeQuestion() {
    if (this.answers.length == 0) {
      this.notificationService.showNotification("info", "Can't close the question because it has no answer yet.");
      return false;
    }
    if (confirm("Are you sure that you wants to close the question?")) {
      this.loader = true;
      this.questionService.closeQuestion(this.ques_id).pipe(finalize(() => { this.loader = false })).subscribe(closeQuestion => {
        this.question_status = closeQuestion.question_status;
        this.loader = false;
        this.notificationService.showNotification("success", closeQuestion.Message);
      })
    }
  }

  goback(type){
    if(type == 'QuestDetails'){
      this.QuestDetails =true;
      this.AnsDetails =false;
      this.getQuestionAnswer(this.ques_id);
      this.viewQuestions();
    }else{
      this.edit_note = true
      this.QuestDetails =false
      this.viewQuestions();
    }
    
  }  

  reportIssue:boolean = true
  cancelTrans(){
   this.reportIssue = false
   this.getTranscription();
   this.session_detail();
  } 

  automarkAttendance() {
      this.route.navigate(['courses/attendance'], { queryParams: { courseId: this.courseId, course:this.courseName, topicId:this.topicId,  liveSessionId:this.live_session_id, session_id:this.session_id,tab_no:this.activeIdNav,ngbNavItem: this.ngbNavItem   } })
      return;
    
    // this.notificationService.showNotification('info', 'We are trying to ready student attendance for you give us 1 min.')
    // return;
  }

  viewUserSubmission(assesments_info, topicId, sessionId){
    this.route.navigate(['/courses/user-submissions'] , { queryParams: { course_id:this.courseId, course:this.courseName, session_id:sessionId,course_category:this.course_category,course_category_id:this.course_category_id,assessment_id: assesments_info._id, assessment_name: assesments_info.title,topicId:topicId, sessionkey: this.sessionkey,isTA:this.isTA, tab_no:this.activeIdNav,ngbNavItem: this.ngbNavItem} })
  }
  backToCourseDetails(){
  this.route.navigate(['/courses/single-course'], { queryParams: { course_id: this.courseId, course:this.courseName, tab_no:this.activeIdNav} });  
  }

  //FOR BUILD FIX
  NoteAndSummryEditDetail() {
    //
  }

  getQuestionsAnswer(id) {
    //
  }

  getAnswerDetailRoute(id) {
    //
  }
}

