<!-- UPLOAD FILES -->
<div class="dragDropWrap" appDragDropFiles (fileDropped)="onFileDropped($event)"
    [ngClass]="{ 'is-invalid': isRequiredField && formSubmitStatus && ((!isChildField && isRequiredField && formData?.controls[fieldControlName]?.controls?.length == 0) || (isChildField && formData?.controls[parentFieldControlName]?.controls[subQusIndex]?.controls[fieldControlName]?.controls?.length == 0))}" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" [ngClass]="{'fileover': fileOver}">
    <div class="dragDrop dragDropOr  d-none d-md-flex">
        <figure class="dragDropIcon"><i class="fas fa-upload"></i></figure>
        <div class="dragDropContent">
            <p>
                <strong class="blue-text d-block">Drag & Drop your file here
                </strong>Only mp3, wav and mp4 formats are allowed.
            </p>
        </div>
        <span class="divideOr"></span>
    </div>
    <div class="dragDropUpload btn-groups">
        <label class="custom-upload-btn btn btn-blue btn-shadow btn-icon-text">
            <input type="file" class="file-upload-input" accept="audio/mp3,audio/wav,video/mp4" (change)="onSelectFiles($event)" (click)="resetFileField($event)" />
            <span class=""><i class="fas fa-download"></i>{{'Browse File'}}</span>
        </label>
    </div>
</div>

<!-- RESOURCE ERRORS -->
<div class="invalid-feedback" *ngIf="!isChildField && isRequiredField && formSubmitStatus && formData?.controls[fieldControlName]?.controls?.length == 0">
    <div>{{fieldLabelName}} is required</div>
</div>

<div class="invalid-feedback" *ngIf="isChildField && isRequiredField && formSubmitStatus && formData?.controls[parentFieldControlName]?.controls[subQusIndex]?.controls[fieldControlName]?.controls?.length == 0">
    <div>{{fieldLabelName}} is required</div>
</div>

<!-- FILES PROGRESS STATUS -->
<div class="uploadedItemWrap">
    <div class="mt-4 uploadedItem" *ngFor="let multiFile of multiFilesData; let idx = index"
        [ngClass]="{noRemoveBtn: idx == 0}">
        <div class="uploadedItemTop" *ngIf="multiFile.progressBar > 0">
            <div class="uploadedItemDetail">
                <p class="mb-1">
                    <strong class="blue-text">{{multiFile.name}}</strong>
                </p>
                <p class="uploadedItemStatus">
                    <small>{{multiFile.progressBar}}% Complete</small>
                </p>
            </div>
            <div class="uploadedItemAction">
            </div>
        </div>
        <div class="progress uploadedProgress" *ngIf="multiFile.progressBar > 0">
            <div class="progress-bar uploadedProgressBlue" role="progressbar" [style.width.%]="multiFile.progressBar"
                aria-valuenow="multiFile.progressBar" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </div>

    <div class="mt-4 uploadedItem" *ngFor="let uploadedFile of uploadedFilesData; let uploadedFileIndex=index">
        <div class="uploadedItemTop" *ngIf="!uploadedFile.resource_deleted">
            <div class="uploadedItemDetail">
                <p class="mb-1">
                    <a href="{{uploadedFile.resource_url}}" target="_blank"><strong
                            class="blue-text">{{uploadedFile.resource_title}}</strong></a>
                </p>
            </div>
            <div class="uploadedItemAction">
                <a (click)="deleteUploadedResource(uploadedFileIndex)" class="uploadedActionLink">
                    <i class="far fa-times-circle"></i>
                </a>
            </div>
        </div>
    </div>
</div>

<ng-template #deleteModal let-c="close" let-d="dismiss">
    <div class="new_UIstyle">
        <div class="modal-header justify-content-between align-items-center">
            <h6 class="modal-title avenirBlack primary-text">Delete Confirmation</h6>
            <button type="button" class="close ml-0" aria-label="Close" (click)="closeDeleteModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>{{deleteMessage}}</p>
        </div>
        <div class="modal-footer">
            <div class="text-left">
                <button type="button" class="btn btn-primary" (click)="closeDeleteModal()">Cancel</button>
            </div>
            <div class="text-right">
                <button type="button" class="btn btn-primary" (click)="onConfirmDelete()">Yes</button>
            </div>
        </div>
    </div>
</ng-template>
