<div class="new_UIstyle">
    <div class="pageBodyWrap">
        <!-- Banner Section Start for Student -->
        <section class="banner banner-home" style="background-image: url('assets/images/banner/banner01.jpg');"
            *ngIf="!islogin || islogin && role == 'student'">
            <div class="container">
                <div class="banner-content text-center">
                    <h1 class="white-text">Learn without limits</h1>
                    <p class="white-text lead">Explore new skills, deepen existing passions, and get lost in learning.
                        <br />
                        What you find just might surprise and inspire you.
                    </p>
                </div>
            </div>
        </section>
        <!-- Banner Section End -->

        <!-- Discover Course Section Start -->
        <section class="section discover-course-wrap" *ngIf="!islogin">
            <div class="container">
                <div class="title discover-course-title text-center">
                    <h3>Discover our Courses</h3>
                </div>
                <div class="discover-items-wrap">
                    <!-- <div class="discover-items-nav">
                          <ul>
                              <li><a href="#" class="active">All categories</a></li>
                              <li><a href="#">Design</a></li>
                              <li><a href="#">Development</a></li>
                              <li><a href="#">Marketing</a></li>
                              <li><a href="#">IT & Software</a></li>
                              <li><a href="#">Business</a></li>
                              <li><a href="#">Photography</a></li>
                              <li><a href="#">Music</a></li>
                          </ul>
                      </div> -->
                    <div class="discover-items-slider course-item-slider" [ngClass]="{submitting : loader}">
                        <div class="text-center" *ngIf="loader">
                            <div class="spinner-border text-warning formLoader" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <owl-carousel-o [options]="discoverCourse">
                            <ng-template carouselSlide *ngFor="let disItem of disCoverItems;">
                                <div class="discover-course-item">
                                    <div class="course-item-block shineEffect">
                                        <figure class="course-item-fig">
                                            <img [src]="disItem.banner_img" alt="image" />
                                        </figure>
                                        <div class="course-item-content">
                                            <p class="course-title"><a
                                                    (click)="navigate_to('courses/single-course', disItem.course_id, disItem.subject)">
                                                    <Strong [title]="disItem.subject">{{disItem.subject|
                                                        slice:0:30}}:</Strong>
                                                    <Strong
                                                        [innerHTML]="disItem.learning_objectives | slice:0:34"></Strong></a>
                                            </p>
                                            <p class="creatorBlock">
                                            <figure class="creatorImg">
                                                <img [src]="disItem.creator_img" alt="Img Not Found"
                                                    *ngIf="disItem.creator_img" />
                                                <span class="creatorImgText"
                                                    *ngIf="disItem.creator_img == ''">{{disItem.created_by_name[0] |
                                                    uppercase}}</span>
                                            </figure>
                                            <small class="creatorDes">{{disItem.created_by_name | titlecase}}</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </section>


        <!-- Discover Course Bottom Section Start -->
        <section class="section discover-course-bottom-wrap" *ngIf="!islogin">
            <!-- discover-course-bottom-item -->
            <div class="discover-course-bottom-item shineEffect">
                <figure class="discover-course-bottom-figure"><img src="../../assets/images/home/learning01.jpg"
                        alt="learning01.jpg" /></figure>
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 discover-course-bottom-col">
                            <h2 class="primary-text">World-class learning for you</h2>
                            <div class="content">
                                <p>Earn credentials from leading companies
                                    and universities. Start learning from instructors
                                    in subjects like <strong>business, computer science,
                                        data science, language learning & more.</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- discover-course-bottom-item -->
            <div class="discover-course-bottom-item shineEffect" *ngIf="!islogin">
                <figure class="discover-course-bottom-figure"><img src="../../assets/images/home/learning02.jpg"
                        alt="learning02.jpg" /></figure>
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 discover-course-bottom-col">
                            <h2 class="primary-text">Peer to Peer Learning Platform</h2>
                            <div class="content">
                                <p>To provide a flexible and safe learning platform
                                    through open dialogues and enquiry.</p>

                                <p>Nurturing and Empowering young minds through
                                    technology and innovation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <!-- Discover Course Bottom Section End -->



        <!-- Categories For You Section Start -->
        <section class="section pickup-course-wrap" *ngIf="islogin && role == 'student'&& viewed_sessions.length > 0">
            <div class="container">
                <div class="title pickup-course-title text-center">
                    <h3>Let's Pickup From Where We Left, {{first_name}}</h3>
                </div>

                <div class="row gy-2">
                    <div class="col-12" [ngClass]="{submitting : loaderRecentSessions}">
                        <div class="text-center" *ngIf="loaderRecentSessions">
                            <div class="spinner-border text-warning formLoader" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                    <div class="pickup-course-list-wrap col-lg-6 col-xl-4 mb-2"
                        *ngFor="let viewed_sessions of viewed_sessions">
                        <div class="pickup-course-elem-block">
                            <a class="pickup-course-elem-link" (click)="letsPickUp(viewed_sessions)">
                                <figure class="pickup-course-elem-icon">
                                    <i class="far fa-play-circle"></i>
                                    <img [src]="viewed_sessions.banner_img" alt="category-icon01.png" />
                                </figure>
                                <div class="pickup-course-elem-desc">
                                    <p class="pickup-course-sub-text" [title]="viewed_sessions.course_name">
                                        <small><strong>{{viewed_sessions.course_name}}</strong></small>
                                    </p>
                                    <h6>{{viewed_sessions.course_name}}</h6>
                                    <p class="duration-time">
                                        <small><strong class="session-name"
                                                [title]="viewed_sessions.session_name | titlecase">{{viewed_sessions.session_name
                                                | titlecase}}</strong> &nbsp;
                                            {{viewed_sessions.session_time | date:"HH:mm"}}</small>
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- <div class="alert alert-info text-center mb-2" *ngIf="viewed_sessions.length == 0">
                    <p>No Result Found.</p>
                </div> -->
            </div>
        </section>
        <!-- Categories For You Section End -->

        <!-- Recommended Course Section Start -->
        <section class="section recommended-course-wrap" *ngIf="islogin && role == 'student'">
            <div class="container">
                <div class="title recommended-course-title text-center">
                    <h3>Recommended Courses For You</h3>
                </div>
                <div class="discover-items-wrap">
                    <!-- <div class="discover-items-nav">
                    <ul>
                        <li><a href="#" class="active">All categories</a></li>
                        <li><a href="#">Design</a></li>
                        <li><a href="#">Development</a></li>
                        <li><a href="#">Marketing</a></li>
                        <li><a href="#">IT & Software</a></li>
                        <li><a href="#">Business</a></li>
                        <li><a href="#">Photography</a></li>
                        <li><a href="#">Music</a></li>
                    </ul>
                </div> -->
                    <div class="discover-items-slider course-item-slider"
                        [ngClass]="{submitting : loaderRecommendedCourses}">
                        <div class="text-center" *ngIf="loaderRecommendedCourses">
                            <div class="spinner-border text-warning formLoader" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <owl-carousel-o [options]="discoverCourse">
                            <ng-template carouselSlide *ngFor="let RecommendedCourses of recommended_courses">
                                <div class="discover-course-item">
                                    <div class="course-item-block shineEffect">
                                        <figure class="course-item-fig">
                                            <img [src]="RecommendedCourses.banner_img" alt="course01.png" />
                                        </figure>
                                        <div class="course-item-content">
                                            <p class="course-title"><a
                                                    (click)="navigate_to('/courses/single-course', RecommendedCourses.course_id,RecommendedCourses.subject)">
                                                    <Strong
                                                        [title]="RecommendedCourses.subject">{{RecommendedCourses.subject}}</Strong>
                                                    <Strong
                                                        [innerHTML]="RecommendedCourses.learning_objectives"></Strong></a>
                                            </p>
                                            <p class="creatorBlock">
                                            <figure class="creatorImg">
                                                <img [src]="RecommendedCourses.creator_img" alt="Img Not Found"
                                                    *ngIf="RecommendedCourses.creator_img" />
                                                <span class="creatorImgText"
                                                    *ngIf="RecommendedCourses.creator_img == ''">{{RecommendedCourses.created_by_name[0]
                                                    | uppercase}}</span>
                                            </figure>
                                            <small class="creatorDes">By {{RecommendedCourses.created_by_name |
                                                titlecase}}</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </section>
        <!-- Recommended Course Section End -->

        <!-- Categories For You Section Start -->
        <section class="section categories-you-wrap" *ngIf="islogin && role == 'student'">
            <div class="container">
                <div class="title categories-you-title text-center">
                    <h3>Categories For You</h3>
                </div>
                <div class="row ">
                    <div class="col-12" [ngClass]="{submitting : loaderTopCategories}">
                        <div class="text-center" *ngIf="loaderTopCategories">
                            <div class="spinner-border text-warning formLoader" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                    <div class="category-list-wrap col-md-4 mt-4" *ngFor="let category of top_categories">
                        <div class="category-elem-block">
                            <a class="category-elem-link" (click)="onTopChangeCategory(category.id)">
                                <!-- <figure class="category-elem-icon">
                                <img [src]="categories.banner_img" alt="category-icon01.png" />
                            </figure> -->
                                <div class="category-elem-desc">
                                    <span>{{category.name}}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- <div class="col-12 col-lg-4 pb-4 pb-lg-0">
        <ul class="catCourseNav" *ngFor="let category of allCategory">
            <li [ngClass]="{'active':category_id == category._id}">
                <a (click)="onChangeCategory(category._id)">{{category.Course_Category}}</a>
               
            </li>
           
        </ul>
    </div> -->
        <!-- Categories For You Section End -->

        <section class="section subscribed-course-wrap" *ngIf="islogin && role == 'student'">
            <div class="container">
                <div class="title subscribed-course-title text-center">
                    <h3>Your Top Courses / Featured Courses</h3>
                </div>
                <div class="discover-items-wrap" [ngClass]="{submitting : loaderTopCourses}">
                    <div class="text-center" *ngIf="loaderTopCourses">
                        <div class="spinner-border text-warning formLoader" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div class="discover-items-slider course-item-slider">
                        <owl-carousel-o [options]="discoverCourse">
                            <ng-template carouselSlide *ngFor="let lets_picks of top_courses">
                                <div class="discover-course-item">
                                    <div class="course-item-block shineEffect">
                                        <figure class="course-item-fig">
                                            <img [src]="lets_picks.banner_img" alt="course01.png" />
                                        </figure>
                                        <div class="course-item-content">
                                            <p class="course-title">
                                                <a
                                                    (click)="navigate_to('/courses/single-course', lets_picks.course_id, lets_picks.subject)">
                                                    <Strong [title]="lets_picks.subject">{{lets_picks.subject}}</Strong>
                                                    <Strong [innerHTML]="lets_picks.learning_objectives "></Strong>
                                                </a>
                                            </p>
                                            <p class="creatorBlock">
                                            <figure class="creatorImg">
                                                <img [src]="lets_picks.creator_img" alt="Img Not Found"
                                                    *ngIf="lets_picks.creator_img" />
                                                <span class="creatorImgText"
                                                    *ngIf="lets_picks.creator_img == ''">{{lets_picks.created_by_name[0]
                                                    | uppercase}}</span>
                                            </figure>
                                            <small class="creatorDes">By {{lets_picks.created_by_name |
                                                titlecase}}</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </section>

        <!-- Subscribed Course Section Start -->
        <section class="section subscribed-course-wrap" *ngIf="islogin && role == 'student'">
            <div class="container">
                <div class="title subscribed-course-title text-center">
                    <h3>Your Subscribed Courses</h3>
                </div>
                <div class="discover-items-wrap">
                    <div class="discover-items-slider course-item-slider"
                        [ngClass]="{submitting : loaderSubscribedCourses}">
                        <div class="text-center" *ngIf="loaderSubscribedCourses">
                            <div class="spinner-border text-warning formLoader" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <owl-carousel-o [options]="subscribeCourse">
                            <ng-template carouselSlide *ngFor="let lets_picks of susbcribe_course">
                                <div class="discover-course-item">
                                    <div class="course-item-block shineEffect item_card">
                                        <figure class="course-item-fig">
                                            <img [src]="lets_picks.banner_img" alt="course01.png" />
                                        </figure>
                                        <div class="course-item-content">
                                            <p class="course-title"><a
                                                    (click)="navigate_to('/courses/single-course', lets_picks.course_id,lets_picks.subject)">
                                                    <Strong [title]="lets_picks.subject">{{lets_picks.subject}}</Strong>
                                                    <Strong [innerHTML]="lets_picks.learning_objectives"></Strong></a>
                                            </p>
                                            <p class="creatorBlock">
                                            <figure class="creatorImg">
                                                <img [src]="lets_picks.creator_img" alt="Img Not Found"
                                                    *ngIf="lets_picks.creator_img" />
                                                <span class="creatorImgText"
                                                    *ngIf="lets_picks.creator_img == ''">{{lets_picks.created_by_name[0]
                                                    | uppercase}}</span>
                                            </figure>
                                            <small class="creatorDes">By {{lets_picks.created_by_name |
                                                titlecase}}</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                        </owl-carousel-o>
                        <div class="alert alert-info text-center" *ngIf="susbcribe_course.length == 0">
                            <p>No subscribed courses? Checkout our recommended courses and start your learning journey
                                today!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Subscribed Course Section End -->



        <!-- teacher admin -->

        <!-- Banner Section Start for Teacher -->
        <section class="banner banner-home banner-home-teacher"
            style="background-image: url('assets/images/banner/banner-teacher.png');"
            *ngIf="islogin && role == 'teacher' || islogin && role == 'super_admin'">
            <div class="container">
                <div class="banner-content text-center">
                    <h1 class="white-text">Come teach with us</h1>
                    <!-- <p  class="white-text lead">Explore new skills, deepen existing passions, and get lost in learning. <br />
                    What you find just might surprise and inspire you.</p> -->
                    <ul class="banner-h-list d-flex align-items-center mb-3 mb-lg-5">
                        <li>Plan Your Course</li>
                        <li>Record Your Course</li>
                        <li>Publish Your Course</li>
                    </ul>
                    <div *ngIf="findIndexOf(permissions, 'add_course') > -1">
                        <button class="btn btn-primary" routerLink="/courses/add">+ Create New Course</button>
                    </div>
                </div>
            </div>
        </section>
        <!-- Banner Section End -->

        <!-- pickup teacher Section Start -->
        <div class="relative" [style.minHeight]="loader == true ? '40vh': ''">
            <!-- <div class="text-center pt-5 pb-5" *ngIf="loader">
                <div class="spinner-border text-warning" role="status" >
                    <span class="sr-only">Loading...</span>
                </div>  
            </div> -->
            <section class="section teacher-course-wrap"
                *ngIf="IsLengthDraft && islogin && role == 'teacher' || IsLengthDraft && islogin &&  role == 'super_admin' ">
                <div class="container">

                    <div class="title teacher-course-title">
                        <h3 class=" mb-lg-0"
                            *ngIf="cShowDraft && islogin && role == 'teacher' || islogin &&  role == 'super_admin'">
                            Let's
                            Pickup From Where We Left, {{first_name}}</h3>
                        <h3 class="avenirBlack  mb-lg-0" *ngIf="!cShowDraft && role == 'teacher'">Finish Your Courses,
                            {{first_name}}</h3>
                        <a class="textBtn primary-text avenirBlack" href="javascript:void(0);" (click)="viewAllDraft()"
                            *ngIf="routerUrl !== '/draft-courses'">View All</a>
                    </div>

                    <div class="relative" [ngClass]="{submitting : loader}">
                        <div class="text-center pt-5 pb-5" *ngIf="loader">
                            <div class="spinner-border text-warning" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                    <div class="teacher-course-list">
                        <div *ngFor="let unPC of publishCourseDraft | paginate: { itemsPerPage: 10, currentPage: pageP, totalItems:totalLengthU }"
                            class="mb-3">
                            <div class="teacher-course-items  teacher-course-items-hover mb-0"
                                *ngIf="unPC.status == 'unpublish' && unPC.edit_right == true">
                                <div class="teacher-course-items-left mw-100">
                                    <figure class="teacher-course-fig"><img [src]="unPC.banner_img" /></figure>
                                    <div class="teacher-course-items-detail">
                                        <h6 class="avenirBlack  mb-1">
                                            <!-- <span class="d-inline-block me-1">{{ unPC.course_category }}: </span>  -->
                                            <a href="javascript:void(0);"
                                                (click)="sendCourseDetails(unPC.course_id, unPC.subject)"><span
                                                    class="d-inline-block">{{ unPC.subject }}</span></a>
                                        </h6>
                                        <div class="rating-elem">
                                            <ngb-rating class="rating-link" [(rate)]="unPC.rating" max="5"></ngb-rating>
                                        </div>
                                        <div class="teacher-course-items-status"><span
                                                class="badge badge-light badge-primary">{{ unPC.course_category
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="teacher-course-items-right">
                            <h6 class="avenirBlack mb-2">Finish your Course</h6>
                            <div class="progress progress-light progress-primary">
                                <div class="progress-bar" role="progressbar" style="width:25%" aria-valuenow="0"
                                    aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div> -->
                                <div class="course-hover-overlay">
                                    <a (click)="sendEditDetails(unPC.course_id, unPC.subject)"
                                        class="hover-link primary-text h6"><strong>Edit / Manage Course</strong></a>
                                </div>
                            </div>
                        </div>
                        <div class="alert alert-info text-center mb-2" *ngIf="publishCourseDraft.length == 0">
                            <p>No Result Found.</p>
                        </div>
                        <pagination-controls class="text-center my-pagination"
                            *ngIf="routerUrl == '/draft-courses' && publishCourseDraft.length > 0" previousLabel="<"
                            nextLabel=">" maxSize="10" (pageChange)="currentPageNo($event)"></pagination-controls>

                    </div>
                </div>
            </section>
            <!-- pickup teacher Section Start -->

            <section class="section teacher-course-wrap"
                *ngIf="IsLengthPublish  && islogin && role == 'teacher' || IsLengthPublish && islogin && role == 'super_admin'">
                <div class="container">

                    <div class="title teacher-course-title">
                        <!-- <h3 class="avenirBlack mb-lg-0" *ngIf="cShowPublish && islogin && role == 'teacher'">Your Published Courses</h3> -->
                        <h3 class="mb-lg-0" *ngIf=" islogin && role == 'teacher'">My Published
                            Courses</h3>
                        <h3 class="mb-lg-0" *ngIf="islogin && role == 'super_admin'">Edu-collab Published
                            Courses</h3>
                        <a href="javascript:void(0);" class="textBtn primary-text avenirBlack"
                            (click)="viewAllPublish()" *ngIf="routerUrl !== '/published-courses'">View All</a>
                    </div>
                    <!-- Filter Block Start -->
                    <!-- <div class="filter-elem-block mb-4" *ngIf="!cShowPublish">
                    <div class="filter-elem-item filter-elem-left">
                        <a id="fliter-btn" class="filter-elem-item-link" (click)="filterClickEvent()">
                            <i class="fas fa-filter mr-2"></i>
                            <h6 class="avenirBook">Filters</h6>
                        </a>
                    </div>
                    <div class="filter-elem-item filter-elem-right">
                        <form>
                            <div class="sort-elenm">
                                <label><small>Sort by:</small></label>
                                <select class="form-control form-select">
                                    <option>Most Popular</option>
                                    <option>2</option>
                                    <option>3</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div> -->
                    <div class="relative" [ngClass]="{submitting : loader}">
                        <div class="text-center pt-5 pb-5" *ngIf="loader">
                            <div class="spinner-border text-warning" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                    <div class="teacher-course-list">
                        <div class="mb-3"
                            *ngFor="let pbCourse of publishCoursePublish | paginate: { itemsPerPage: 10, currentPage: pageP, totalItems:totalRecordeP }">
                            <div class="teacher-course-items teacher-course-items-vCenter mb-0"
                                *ngIf="pbCourse.status == 'publish' && pbCourse.edit_right == true">
                                <div class="teacher-course-items-left">
                                    <figure class="teacher-course-fig"><img [src]="pbCourse.banner_img"
                                            alt="course-item-1.png" /></figure>
                                    <div class="teacher-course-items-detail">
                                        <h6 class="avenirBlack mb-1">
                                            <!-- <span class="d-inline-block me-1">{{ pbCourse.course_category }}:</span> -->
                                            <a href="javascript:void(0);"
                                                (click)="sendCourseDetails(pbCourse.course_id, pbCourse.subject)"><span
                                                    class="d-inline-block">{{ pbCourse.subject}}</span></a>
                                        </h6>
                                        <div class="rating-elem">
                                            <ngb-rating class="rating-link" [(rate)]="pbCourse.rating"
                                                max="5"></ngb-rating>
                                        </div>
                                        <div class="teacher-course-items-status"><span
                                                class="badge badge-light badge-green-dark">{{
                                                pbCourse.course_category}}</span></div>
                                    </div>
                                </div>
                                <div class="teacher-course-items-right">
                                    <div class="btn-groups  justify-content-end">
                                        <!-- <button class="btn btn-primary" ngbTooltip="View Course" container="body" type="button"
                                (click)="sendCourseDetails(course.course_id, course.subject)">
                                <i class="fas fa-eye"></i>
                            </button> -->

                                        <a class="edit_course1 mr-5" ngbTooltip="Edit"
                                            (click)="sendEditDetails(pbCourse.course_id, pbCourse.subject)"
                                            *ngIf="pbCourse.edit_right == true && (findIndexOf(permissions, 'edit_course_content') > -1 || findIndexOf(permissions, 'edit_all_courses') > -1)">
                                            <i class="fas fa-pencil-alt "></i>
                                        </a>



                                        <!-- <a class="deactivate_course mr-3" type="button" ngbTooltip="Delete"
                                        
                                        *ngIf="findIndexOf(permissions, 'deactivate_course') > -1">
                                        <i class="fas fa-trash-alt"></i>
                                    </a> -->

                                        <!-- <button class="btn btn-primary"
                                        *ngIf="pbCourse.show_to != 'all' &&  findIndexOf(permissions, 'make_course_universal') > -1"
                                        type="button" (click)="makeCourseAvailableToall(pbCourse.course_id)">
                                        Available To All
                                    </button> -->

                                        <a class="deactivate_course mr-5" type="button" ngbTooltip="Deactivate"
                                            (click)="deactiveCourseModel(deativeCourse)"
                                            *ngIf="findIndexOf(permissions, 'deactivate_course') > -1">
                                            <i class="fas fa-trash-alt"></i>
                                        </a>

                                        <!-- new ui deative course -->
                                        <ng-template #deativeCourse let-c="close" let-d="dismiss">
                                            <div class="new_UIstyle">
                                                <div class="modal-header justify-content-between align-items-center">
                                                    <h5 class="avenirBook modal-title text-danger mb-0">Deactive Course
                                                    </h5>
                                                    <button type="button" class="close ml-0" aria-label="Close"
                                                        (click)="closeCoursePopup()">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <p>Are you sure you want to deactive Course <strong
                                                            class="text-danger">?</strong></p>
                                                </div>

                                                <div class="modal-footer">
                                                    <div class="text-left">
                                                        <button type="submit" class="btn btn-primary btn-sm"
                                                            (click)="closeCoursePopup()">NO</button>
                                                    </div>
                                                    <div class="text-right">
                                                        <button type="submit" class="btn btn-primary btn-sm"
                                                            (click)="deactivedCourse(pbCourse.course_id)">YES</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>


                                        <!-- <a class="Available_to_all mr-3" ngbTooltip="Available To All"
                                        *ngIf="pbCourse.show_to != 'all' &&  findIndexOf(permissions, 'make_course_universal') > -1"
                                        type="button" (click)="makeCourseAvailableToall(pbCourse.course_id)">
                                        <i class="fas fa-universal-access"></i>
                                    </a> -->

                                        <!-- *ngIf="pbCourse.edit_right == true && (findIndexOf(permissions, 'edit_course_content') > -1 || findIndexOf(permissions, 'edit_all_courses') > -1)" -->
                                        <button class="btn btn-primary"
                                            (click)="viewStudentMonitoring(pbCourse.course_id, pbCourse.subject)"
                                            *ngIf="pbCourse.edit_right == true && (findIndexOf(permissions, 'edit_course_content') > -1 || findIndexOf(permissions, 'edit_all_courses') > -1)">Monitoring
                                            Report</button>
                                    </div>

                                    <div class="btn-groups  justify-content-end mt-3" *ngIf="role == 'super_admin' ">

                                        <form action="">
                                            <div class="form-check form-switch form-switch-right">
                                                <label class="form-check-label mr-2" for="flexSwitchCheckChecked">
                                                    <strong>Make Course Universal</strong>
                                                </label>
                                                <input [checked]="pbCourse.is_public" class="form-check-input"
                                                    (change)="makeCourseUniversalModel(makeCourseUniversal,$event)"
                                                    id="flexSwitchCheckChecked" type="checkbox">
                                            </div>
                                        </form>

                                          <!-- new ui deative course -->
                                          <ng-template #makeCourseUniversal let-c="close" let-d="dismiss">
                                            <div class="new_UIstyle">
                                                <div class="modal-header justify-content-between align-items-center">
                                                    <h5 class="avenirBook modal-title text-danger mb-0">Make Course Universal
                                                    </h5>
                                                    <button type="button" class="close ml-0" aria-label="Close"
                                                        (click)="closeCourseMakeUniversalPopup()">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body" *ngIf="!pbCourse.is_public">
                                                    <p>Are you sure you want to make this course universal <strong
                                                            class="text-danger">?</strong></p>
                                                </div>

                                                <div class="modal-body" *ngIf="pbCourse.is_public">
                                                    <p>Are you sure you don't want to make this course universal <strong
                                                            class="text-danger">?</strong></p>
                                                </div>

                                                <div class="modal-footer">
                                                    <div class="text-left">
                                                        <button type="submit" class="btn btn-primary btn-sm"
                                                            (click)="closeCourseMakeUniversalPopup()">NO</button>
                                                    </div>
                                                    <div class="text-right">
                                                        <button type="submit" class="btn btn-primary btn-sm"
                                                            (click)="makeCourseAvailableToall(pbCourse.course_id)">YES</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>


                                </div>
                                <!-- <div class="course-hover-overlay">
                            <a href="" class="hover-link primary-text h6"><strong>Edit / Manage Courses</strong></a>
                        </div> -->
                            </div>
                        </div>
                        <div class="alert alert-info text-center mb-2" *ngIf="publishCoursePublish.length == 0">
                            <p>No Result Found.</p>
                        </div>
                        <pagination-controls class="text-center my-pagination"
                            *ngIf="routerUrl == '/published-courses' && publishCoursePublish.length > 0"
                            previousLabel="<" nextLabel=">" maxSize="10"
                            (pageChange)="currentPageNo($event)"></pagination-controls>
                    </div>

                </div>
            </section>
        </div>
    </div>
</div>











<!--  



<button class="btn btn-danger" (click)="openReviewModal(content)">Review<i class="fas fa-play ml-3"></i></button>

<ng-template #content let-modal>
    <div class="modal-header">      
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="embed-responsive embed-responsive-16by9 rounded">
            <iframe class="embed-responsive-item" [src]="demoUrl | youtubesafeUrl" allowfullscreen></iframe>
        </div> 
    </div>
</ng-template> -->