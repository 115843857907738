import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuestionBankService {
  apiEndpoint: string;

  constructor(private httpClient: HttpClient) {
    this.apiEndpoint = environment.APIEndpoint;
  }

  fetchTags(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/get_tags').pipe(
      catchError(this.handleError)
    );
  }

  addQues(value): Observable<any> {
    return this.httpClient.post<any>(this.apiEndpoint + '/add-question', value);
  }

  editQues(value): Observable<any> {
    return this.httpClient.post<any>(this.apiEndpoint + '/edit_question', value);
  }

  fetchQus(val, page): Observable<any> {
    let params = new HttpParams();
    params = params.append('question_tag', val.toString());
    params = params.append('page', page.toString());
    return this.httpClient.get<any>(this.apiEndpoint + '/search_add_questions', { params });
  }

  // getItems(offset: number, limit: number = 20): Observable<any[]> {
  //   const params = new HttpParams()
  //     .set('offset', offset.toString())
  //     .set('limit', limit.toString());

  //   return this.http.get<any[]>(this.apiUrl, { params });
  // }

  fetchQusFilter(val,ques_type,catVal, page = ''): Observable<any> {
    let params = new HttpParams();
    params = params.append('question_tag', val);
    params = params.append('question_type', ques_type);
    params = params.append('category', catVal);

    if(page) {
      params = params.append('page', page);
    }

    return this.httpClient.get<any>(this.apiEndpoint + '/search_add_questions', { params });
  }

  uploadTextQus(fileData, ques_type): Observable<any> {
    let params = new HttpParams();
    const formData = new FormData();
    formData.append('file_path', fileData)
    formData.append('ques_type', ques_type)
    return this.httpClient.post<any>(this.apiEndpoint + '/upload_questions', formData,{
      params,
      reportProgress: true,
      observe: 'events'
    });
  }

  fetchImportedQus(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/get_import_questions').pipe(
      catchError(this.handleError)
    );
  }

  create_assessment(value): Observable<any> {
    return this.httpClient.post<any>(this.apiEndpoint + '/create_assessment', value);
  }

  edit_assessment(value): Observable<any> {
    return this.httpClient.post<any>(this.apiEndpoint + '/edit_assessment', value);
  }

  retrieve_assessment(currentpage, type): Observable<any> {
    let params = new HttpParams();

    params = params.append('current_page', currentpage);
    params = params.append('type', type);

    return this.httpClient.get<any>(this.apiEndpoint + '/get_saved_assessment', { params });
  }

  view_assessment(data): Observable<any> {
    return this.httpClient.post<any>(this.apiEndpoint + '/assessment-view', data);
  }

  generateAssessmentPdf(data): Observable<any> {
    return this.httpClient.post<any>(this.apiEndpoint + '/assessment/generatePdf', data, { observe: 'response', responseType: 'blob' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }

  handleError(error) {

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

  fetchQusType(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/get_questype').pipe(
      catchError(this.handleError)
    );;
  }


  categoryAPI(): Observable<any> {
    return this.httpClient.get<any>(this.apiEndpoint + '/course_category').pipe(
      catchError(this.handleError)
    );
  }
  save_ques(value): Observable<any> {
    let body = {
      data: value
    }
    return this.httpClient.post<any>(this.apiEndpoint + '/add_upload_questions', body).pipe(
      catchError(this.handleError)
    );;
  }
  deleteQuesInQb(question_id) {
    let params = new HttpParams();
    params = params.append('question_id', question_id)
    return this.httpClient.delete<any>(this.apiEndpoint + "/delete_add_questions", { params }).pipe(catchError(this.handleError))
  }
  searchAssessment(searchText, page): Observable<any> {
    let params = new HttpParams();

    params = params.append('search_text', searchText);
    params = params.append('page', page);

    return this.httpClient.get<any>(this.apiEndpoint + '/assessment/search', { params });
  }
  deleteAssmentID(assessment_id) {
    let params = new HttpParams();
    params = params.append('assessment_id', assessment_id)
    return this.httpClient.delete<any>(this.apiEndpoint + "/assessment", { params }).pipe(catchError(this.handleError))
  }

  fetchSearchTags(searchText): Observable<any> {
    let params = new HttpParams();
    params = params.append('search_text', searchText);

    return this.httpClient.get<any>(this.apiEndpoint + '/tags/search', { params }).pipe(
      catchError(this.handleError)
    );
  }
}
