import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NgbModalConfig, NgbModal, NgbPanelChangeEvent, NgbPanelToggle, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, map, share } from 'rxjs/operators';
import { ShowCourseView } from 'src/app/model/course.model';
import { FormGroup, FormBuilder, Validators, NgForm, FormArray } from '@angular/forms';
import { ShareService } from 'src/app/services/share.service';

import { interval } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { QuestionService } from 'src/app/services/question.service';
import { CourseAssessmentShareService } from 'src/app/services/course-assessment-share.service';
import { MonitoringShareService } from 'src/app/services/monitoring-share.service';
import { CourseService } from 'src/app/services/course.service';
import * as fileSaver from 'file-saver';
import { environment } from 'src/environments/environment';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';
import { DatePipe, formatDate } from '@angular/common';
import { allCategory, CourseInfo } from 'src/app/model/course.model';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { UploadFilesService } from 'src/app/services/upload-files.service';
import { query } from '@angular/animations';
import { AuthService } from 'src/app/services/auth.service';
import { SocketService } from 'src/app/services/web/socket.service';
import { error } from '@angular/compiler/src/util';
declare var tinymce: any;

@Component({
  selector: 'app-single-ec-course',
  templateUrl: './single-ec-course.component.html',
  styleUrls: ['./single-ec-course.component.scss'],
  providers: [DatePipe]
})
export class SingleEcCourseComponent implements OnInit {

  fileArr: any = [];
  insertBlankFlag: boolean = false;
  toolbarWithoutBlankButton = 'undo redo | formatselect | bold italic backcolor | \
  alignleft aligncenter alignright alignjustify | \
  bullist numlist outdent indent | removeformat | \
  link image | preview media help';

  // currentDate = new Date();
  datePipe: DatePipe;
  show: boolean = false;
  btnStat: any = 'maxi';
  isShownPVideos: boolean = false;
  loader = false;
  outer_loader = false;
  panel_loader = false
  courseId = null;
  courseName = null;
  course_Id = null;
  showCourseData: ShowCourseView;
  showCourseDatatopic: any;
  content = [];
  isSubscribed = false;
  course_category_id: string;
  course_category: string;
  role = '';
  currentRate = 0;
  description: string;
  url: string;
  ratingSection = false;
  private ytEvent;
  public player;
  videoStartTime;

  currentUsername = '';
  myQuestions = [];
  announcementForm: FormGroup;
  openCoursework;
  announcementpopUp;
  rating: number;
  individual_rating: number;
  courseAssessmentData: any;
  viewsubmitmodal: any;
  dismissFlag: boolean;
  viewFeedbackmodal: any;
  teacher_view_submission: any[];
  submitted_time: any[];
  course_work_view: any;
  ratingText: string

  fileFlag: boolean = false;

  // catgeory_work_data: any[];
  // catgeory_work_data1: any[];
  // catgeory_work_data2: any[];

  selectedFiles: FileList;
  progressInfos = [];
  message = '';
  // fileInfos: Observable<any>;
  uploadedMedia: Array<any> = [];
  uploadArray = [];
  startSesUsrId = ''
  meetingStartedpress:boolean = false;
  Zoom_L_sessionId:string = '';

  @Input() allCategory: allCategory[] = [];
  @Input() categoryVisDetails: CourseInfo[] = [];
  @Input() total_pages: number;
  @Input() current_page: number;
  @Input() number_of_page;
  @Output() send_index_number_deactivate = new EventEmitter();
  @ViewChild("fileU1", { static: false }) input: ElementRef;
  @Output() send_index_number = new EventEmitter();



  go_live_name = 'Go Live';
  go_live: any;
  assessment_live = 'Inactive';
  topicId = '';

  file_id = '';
  session_id = '';
  title = '';
  resources = [];
  Topic_assessments = [];
  openModelVideo;
  sessionAssessment = [];
  topic_id_value: any;
  dateStr: any;
  sessionkey;
  coursework;
  announcementIndex;
  feedBack_list = [];
  private modalRef;
  currentTime: string;
  user_id = '';
  topic_id;
  course_session_id;
  value;
  editAnnouncementValue;
  submission_button = 'Publish'
  show_rating = false;
  session_detail_data: any
  session_status: string;
  videoElement: any;
  view_assessment_submission: any[];
  assessment_view;
  apiEndpoint;
  currentDate1;
  curretdateTime = new Date();
  intervalId;
  subscription: Subscription;
  ratingContent: null


  overSession = false;
  joinSession = false;
  joinSessionDis = false;
  liveSessions: any = [];
  tenMin = 600000;
  liveSessionDur;
  istDateTimeDur;
  liveSessionArr;
  liveSessionNewData;
  liveSessionNewArr = [];
  istDateTime;
  topicSession: any
  topi: any;
  liveSessionTst: Date;
  topicGetData: [] = [];
  topic_Session: [] = [];
  topic_resources: [] = [];
  topic_assessments: [] = [];
  live_sessions: [] = [];
  topic_course_works: [] = []
  topic_info: [] = []
  routerUrl: any
  IsLengthDraft;
  IsLengthPublish;
  totalLengthU: any;
  totalRecordeP: any;
  pageP: number = 1;
  cShowDraft;
  cShowPublish;
  publishCoursePublish;
  publishCourseDraft;
  publishStatus;
  publishCourse = [];
  timerInterval: any

  futureDate: Date = new Date('01.01.2022');
  currentDate: Date = new Date();
  filterStars: any;
  filterSort: any;
  onFilter: any;
  userId: string;
  activeIdNav:any = 1;
  subscription_status;
  announcementList;
  instructorsList;
  courseResource;
  courseWorkList;
  assessmentList;
  allUploadedFiles: any[] = [];
  live_session_stattus:string = '';

  uploaded_index;
  multiFilesData: any[] = [];
  uploadedFilesData: any[] = [];


  live_session_id = '';
  isButtonActive: boolean = false;
  isActive: boolean = false;


  public now: Date = new Date();
  todaysDataTime: any = '';
  nowDate;
  nowtime: any

  topicIdParam:string;
  isTA: boolean;
  isPB: boolean = false;
  sigleCourseTabs:number = 1;
  apiCalled:boolean

  liveSessionTopic_Id:string = '';
  liveSessionCourse_Id:string = '';

  constructor(config: NgbModalConfig,
    private modalService: NgbModal,
    private courseService: CourseService,
    private shareService: ShareService,
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private notificationService: NotificationService,
    private questionService: QuestionService,
    private _courseAssessmentShareService: CourseAssessmentShareService,
    private fb: FormBuilder,
    private modalReview: NgbModal,
    private UFS: UploadFilesService,
    public datepipe: DatePipe,
    rateConfig: NgbRatingConfig,
    private _monitoringShareService: MonitoringShareService,
    private socket_service: SocketService,) {
    config.backdrop = 'static';
    this.role = localStorage.getItem('role');
    this.userId = localStorage.getItem('user_id')
    rateConfig.readonly = true;
    
  }

  ngOnInit() {
    this.courseId = this.activatedRoute.snapshot.queryParamMap.get('course_id');
    this.topicIdParam = this.activatedRoute.snapshot.queryParamMap.get('topic_id');
    this.activeIdNav = Number(this.activatedRoute.snapshot.queryParamMap.get('tab_no'));
    // this.courseName = this.activatedRoute.snapshot.queryParamMap.get('course');
    this.Zoom_L_sessionId = localStorage.getItem('Zoom_L_sessionId');
    this.live_session_stattus = localStorage.getItem('live_session_status_z');
    this.liveSessionCourse_Id = this.courseId ;
    localStorage.setItem('course_id',  this.courseId);     
    this.session_status == 'active' ? this.go_live_name = 'Live' : this.go_live_name = 'Go Live';
    this.apiEndpoint = environment.APIEndpoint;
    this.zoomEventResponse();
    this.getEditDetails();
    if(localStorage.getItem('courseName')){
      this.courseName = localStorage.getItem('courseName');
    }
    this.getTopicList(); 
    //this.getpublishCourses();
    this.getEditReview(this.courseId, this.rating_sort)
    this.onChangefilter
    tinymce.remove();


    let sp = this.route.url.split('?')
    this.routerUrl = sp[0];

    // this.todaysDataTime = formatDate(this.now, 'yyyy-MM-dd hh:mm:ss ', 'en-US',);
    // this.nowDate = formatDate(this.todaysDataTime, 'yyyy-MM-dd', 'en-US');
    // this.nowtime = formatDate(this.todaysDataTime, 'hh:mm:ss', 'en-US');


    // Using Basic Interval
    // Using RxJS Timer
    this.subscription = timer(0, 1000)
      .pipe(
        map(() => new Date()),
        share()
      )
      .subscribe(time => {
        this.curretdateTime = time;
      });

    // console.log(this.curretdateTime);

    this.announcementForm = this.fb.group({
      course_id: ['', Validators.required],
      announcement_html: ['', Validators.required],
      title: ['', Validators.required],
      resources: ['', Validators.required]
    })
    this.currentUsername = localStorage.getItem("currentUser");
    this.send_index_number_deactivate.emit('1+deactivate');

    if (this.routerUrl == '/courses/single-course') {
      this.ratingText = 'Give your review'
    } else if (this.routerUrl == '/courses/review-rating') {
      this.ratingText = 'View course details'
    }
   
  }

  ngAfterContentInit(){
    if(this.activeIdNav == 1){      
      // this.get_Assessments();
    } else if(this.activeIdNav == 2){      
      this.get_annoucement();
    } else if(this.activeIdNav == 3){      
      this.get_CourseResource();
    } else if(this.activeIdNav == 4){      
      this.get_instrutors();
    } else if(this.activeIdNav == 5){      
      this.getLiveSession();
    } else if(this.activeIdNav == 6){      
      this.get_Assessments();
    } else if(this.activeIdNav == 7){      
      this.get_CourseWorks();
    }
  }


  // ngAfterViewInit() {
    
  // }



  viewPendingRecordings() {
    this.isShownPVideos = !this.isShownPVideos;
    if (this.role != 'student' && this.showCourseData && this.showCourseData.course_info.publish_right) {
      this.get_zoom_recording();
    }
  }

  print(value) {
  }


  getEditDetails() {
    this.loader = true;
    this.courseService.viewCourse(this.courseId).subscribe(res => {
      this.showCourseData = res;
      localStorage.setItem('courseName', this.showCourseData.course_info.subject)
      this.isTA = res.course_info.is_teaching_assisstant;
      this.isPB = res.course_info.publish_right;
      this.subscription_status = this.showCourseData.course_info.subscription_status
      this.isSubscribed = res.course_info.subscription_status;
      this.course_category = res.course_info.course_category_name;
      this.course_category_id = res.course_info.course_category_id;
      this.rating = res.course_info.rating;
      this.individual_rating = res.course_info.individual_rating;
      this.is_true = this.showCourseData.course_info.is_public
      this.loader = false;
      if (this.role != 'student') {
        this.isSubscribed = true;
      }
    },
      (err) => {
        this.notificationService.showNotification("error", err.message);
        setTimeout(() => {
          this.route.navigate(['/']);
        }, 3000);
      });
  }



  active:any = [];
  getTopicList() {
    this.loader = true;
    this.courseService.getTopicList(this.courseId).pipe(finalize(() => this.loader = false)).subscribe(res => {
      console.log(res);
      
      this.topic_info = res.topic_info;
      this.topic_info.filter((topicsItem:any,index)=>{
        if(topicsItem._id == this.topicIdParam){
          this.active = 'active-' + index;
          this.isActiveTab(this.courseId,this.topicIdParam);
        }        
      })
      this.loader = false
    });
  }


  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.sigleCourseTabs = changeEvent.nextId

    this.updateQueryParamActiveUrl('tab_no', this.sigleCourseTabs.toString())

    if(this.sigleCourseTabs !== 1){
      this.topicsloaded = false;
      clearInterval(this.timerInterval);
    }  
    if(this.sigleCourseTabs == 5){
      this.topicsloaded = false;     
      let courseId = localStorage.getItem('course_id')
      this.getSocketResponse(courseId, '');
    }
    
	}

  // update current url with query params
  updateQueryParamActiveUrl(key: string, value: string) {
    // Get current query parameters
    this.activatedRoute.queryParams.subscribe(params => {
      // Create a new object with updated parameters
      const updatedParams = { ...params, [key]: value };

      // Navigate with the updated query parameters
      this.route.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: updatedParams,
        queryParamsHandling: 'merge' // Retain existing parameters
      });
    });
  }

topicsloaded:boolean = false
  getTopicLoads(event: NgbPanelChangeEvent, courseId, topicId) {
    //  this.sigleCourseTabs = 1;
    this.liveSessionCourse_Id = this.courseId;
    this.liveSessionTopic_Id = topicId;
    if (event.nextState == true) {
      this.panel_loader = true;
      this.closePanel = event
      this.active = []
      this.topic_Session = [];
      this.live_sessions = [];
      this.topic_resources = [];
      this.topic_assessments = [];
      this.topic_course_works = [];
      this.active = (this.active == this.closePanel.panelId) ? [] : [this.closePanel.panelId];
      this.courseService.get_topicSession(courseId, topicId).pipe(finalize(() =>
        this.panel_loader = false)).subscribe(topicData => {
          this.topic_Session = topicData.sessions;
          this.live_sessions = topicData.live_sessions;
          this.topic_resources = topicData.resources
          this.topic_assessments = topicData.assessments
          this.topic_course_works = topicData.course_work
          this.topicGetData = topicData;
          this.topi = this.topicGetData;
          this.topicsloaded = true;
          this.apiCalled =false;
          this.panel_loader = false;
          this.topicId = topicId;
          


          this.live_sessions.forEach((session:any)=>{
            session.is_active = false;
          });
          this.live_sessions.filter((live_session:any)=>{            
            if(live_session.status == 'meeting_started'){
              let host_user_id = live_session.host_user_id;
              let user_id = localStorage.getItem('user_id');
                if(host_user_id === user_id){
                  live_session.started_session = true;
                } else{
                  live_session.started_session = false;
                }
             }
          })
          this.getSocketResponse(courseId, topicId);   

        });
    }
  }

  isActiveTab(courseId, topicId){
    this.panel_loader = true;
      // this.active = []
      this.topic_Session = [];
      this.topic_resources = [];
      this.topic_assessments = [];
      this.topic_course_works = [];
      // this.active = (this.active == this.closePanel.panelId) ? [] : [this.closePanel.panelId];
      this.courseService.get_topicSession(courseId, topicId).subscribe(topicData => {

        this.topic_Session = topicData.sessions;
          this.live_sessions = topicData.live_sessions;
          this.topic_resources = topicData.resources
          this.topic_assessments = topicData.assessments
          this.topic_course_works = topicData.course_work
          this.topicGetData = topicData;
          this.topi = this.topicGetData;
          this.topicsloaded = true;
          this.apiCalled =false;
          this.panel_loader = false;
          this.topicId = topicId;
          


          this.live_sessions.forEach((session:any)=>{
            session.is_active = false;
          });
          this.live_sessions.filter((live_session:any)=>{            
            if(live_session.status == 'meeting_started'){
              let host_user_id = live_session.host_user_id;
              let user_id = localStorage.getItem('user_id');
                if(host_user_id === user_id){
                  live_session.started_session = true;
                } else{
                  live_session.started_session = false;
                }
             }
          })
          this.getSocketResponse(courseId, topicId); 
        
          // this.topic_Session = topicData.sessions
          // this.topic_resources = topicData.resources
          // this.topic_assessments = topicData.assessments
          // this.topic_course_works = topicData.course_work
          // this.topicGetData = topicData;
          // this.topi = this.topicGetData
          // this.panel_loader = false
        });
  }


  openZoom(live_session) {
    this.liveSessionCourse_Id;
    this.liveSessionTopic_Id;

    if (live_session.start_url == '') {
      this.apiCalled = false
      this.courseService.meetingStarted(live_session, this.courseId).pipe(finalize(() => { this.loader = false; })).subscribe(res => {
        this.meetingStartedpress = true;
        window.open(live_session.start_url, '_blank')
      })
    } else {
      this.apiCalled = false
      this.courseService.meetingStarted(live_session, this.courseId).pipe(finalize(() => { this.loader = false; })).subscribe(res => {
        this.meetingStartedpress = true;
        window.open(live_session.start_url, '_blank')
      },(error) => {
        this.notificationService.showNotification("error", error.message);
      })

    }

  }


  zoomEventResponse(){
    this.socket_service.receiveLiveSessionForadmin().subscribe((data:any)=>{  
        let socketResponseData = data;

        if(socketResponseData){
          if(socketResponseData.course_id == this.liveSessionCourse_Id){
            
            let socketLiveSessionStatus = socketResponseData.live_session_status;
            let socketLiveSessionDuration = socketResponseData.total_recording_duration;
            let socketLiveSessionUrl = socketResponseData.url;
            let socketLiveSessioncourse_session_id = socketResponseData.course_session_id;
            let socketLiveSession_Id = socketResponseData._id;
            localStorage.setItem('socketLiveSession_Id', socketLiveSession_Id);

            if(this.live_sessions.length > 0){
              this.live_sessions.filter((liveSessionObj:any)=>{
                if(liveSessionObj._id === socketLiveSession_Id){
                  liveSessionObj.status = socketLiveSessionStatus;

                   if(liveSessionObj.status == 'meeting_initiated'){
                     if(this.liveSessionCourse_Id !== '' && this.liveSessionTopic_Id !== ''){
                       this.course_topic_api(this.liveSessionCourse_Id, this.liveSessionTopic_Id);
                     }
                   } else if(liveSessionObj.status == 'meeting_started'){
                      let host_user_id = liveSessionObj.host_user_id;
                      let user_id = localStorage.getItem('user_id');  
                          if(host_user_id === user_id){
                            liveSessionObj.started_session = true;
                          } else{
                            liveSessionObj.started_session = false;
                          }
                    } else if(liveSessionObj.status == 'recording_completed'){
                      liveSessionObj.total_recording_duration = socketLiveSessionDuration;
                      liveSessionObj.url = socketLiveSessionUrl;
                      liveSessionObj.course_session_id = socketLiveSessioncourse_session_id;
                    }

                }
              })

            }


            if(this.liveSessions.length > 0){
              this.liveSessions.filter((liveSessionObj:any)=>{
                if(liveSessionObj._id === socketLiveSession_Id){
                  liveSessionObj.status = socketLiveSessionStatus;

                   if(liveSessionObj.status == 'meeting_initiated'){
                     if(this.liveSessionCourse_Id !== '' && this.liveSessionTopic_Id !== ''){
                       this.course_topic_api(this.liveSessionCourse_Id, this.liveSessionTopic_Id);
                     }
                   } else if(liveSessionObj.status == 'meeting_started'){
                      let host_user_id = liveSessionObj.host_user_id;
                      let user_id = localStorage.getItem('user_id');  
                          if(host_user_id === user_id){
                            liveSessionObj.started_session = true;
                          } else{
                            liveSessionObj.started_session = false;
                          }
                    } else if(liveSessionObj.status == 'recording_completed'){
                      liveSessionObj.total_recording_duration = socketLiveSessionDuration;
                      liveSessionObj.url = socketLiveSessionUrl;
                      liveSessionObj.course_session_id = socketLiveSessioncourse_session_id;
                    }

                }
              })

            }


            }
          }
      })
}




  todayDate:Date;
  now_date_socket:any;
  now_time_socket:any;
  nd_time_socket:any;
  started_session:boolean = false;
  liveSessionStartDate:Date;
  liveSessionEndDate:Date;
  getSocketResponse(courseId,topicId){
    clearInterval(this.timerInterval);
    if(this.sigleCourseTabs == 1 && this.topicsloaded){
      this.timerInterval = setInterval(() => { 
        
      this.todayDate = new Date();
     
      this.live_sessions.filter((liveSession:any)=>{
        if(liveSession.status == '' || liveSession.status == 'meeting_initiated'){

              this.liveSessionStartDate = new Date(liveSession.start_date_time);
              this.liveSessionEndDate = new Date(liveSession.end_date_time);
                
            if (this.liveSessionStartDate.getTime() <= this.todayDate.getTime()) {         
              
              if(this.liveSessionStartDate.getTime() <=  this.todayDate.getTime() && this.liveSessionEndDate.getTime() >=  this.todayDate.getTime()){
                liveSession.is_active = true;
              } else{
                liveSession.is_active = false;
              }

              if(this.liveSessionEndDate.getTime() < this.todayDate.getTime()){
                liveSession.status = 'missed'
              }
            } 
            
        }
      })  
    }, 1000);
    } else if(this.sigleCourseTabs == 5 && !this.topicsloaded){         
      this.timerInterval = setInterval(() => { 
        this.todayDate = new Date();
        
        this.liveSessions.filter((liveSession:any)=>{
          if(liveSession.status == '' || liveSession.status == 'meeting_initiated'){

            this.liveSessionStartDate = new Date(liveSession.start_date_time);
            this.liveSessionEndDate = new Date(liveSession.end_date_time);
              
          if (this.liveSessionStartDate.getTime() <= this.todayDate.getTime()) {          
            if(this.liveSessionStartDate.getTime() <=  this.todayDate.getTime() && this.liveSessionEndDate.getTime() >=  this.todayDate.getTime()){
              liveSession.is_active = true;
            } else {
              liveSession.is_active = false;
            }

            if(this.liveSessionEndDate.getTime() < this.todayDate.getTime()){
              liveSession.status = 'missed'
            }
          } 
      }
        })  
      }, 1000);
    } else if(this.sigleCourseTabs !== 1 && this.sigleCourseTabs !== 5){
      clearInterval(this.timerInterval);     
    } 
  }

  course_topic_api(courseId:string = '', topicId:string = ''){
    this.courseService.get_topicSession(courseId, topicId).subscribe(topicData => {
          this.live_sessions = [];
          this.live_sessions = topicData.live_sessions;

          this.live_sessions.filter((live_session:any)=>{            
            if(live_session.status == 'meeting_started'){
              let host_user_id = live_session.host_user_id;
              let user_id = localStorage.getItem('user_id');
                if(host_user_id === user_id){
                  live_session.started_session = true;
                } else{
                  live_session.started_session = false;
                }
             }
          })
        });
  }



  filterByReference(arr1, arr2) {
    let res = [];
    res = arr1.filter(el => {
       return !arr2.find(element => {
          return element._id === el._id;
       });
    });
    return res;
 }
 
  getLiveSession() {
    this.loader = true;
    this.courseService.getLiveSession(this.courseId).pipe(finalize(() => this.loader = false)).subscribe(res => {
      this.liveSessions = res.live_sessions;
      this.liveSessions.sort((a: any, b: any) => {
        return <any>new Date(b.ist_date) - <any>new Date(a.ist_date);
      });
      this.loader = false
    });
  }

  activateButton() {
    this.isButtonActive = true;
  }



  showreviewData: any;
  getEditReview(course_id, rating_sort) {
    this.loader = true;
    this.courseService.getReview(course_id, rating_sort)
      .pipe(finalize(() => this.loader = false)).subscribe(res => {
        this.showreviewData = res;
        this.onFilter = res.reviews
      });
  }



  onChangefilter(val: string) {
    let course_id = this.courseId
    this.courseService.getReview(course_id, val)
      .pipe(finalize(() => this.loader = false)).subscribe(res => {
        this.onFilter = res.reviews;
      });

  }



  editSelectedCourseWork(id) {
    this.route.navigate(['/courses/course-work'], { queryParams: { courseWork: id, course_id: this.courseId,tab_no:this.activeIdNav } });
  }

  editSelectedCourseWorkpeer(id, topic_id = '', is_group = null) {
    let pagePath = '/courses/course-work';

    //FOR CHECK TEACHING ASSISTANT
    if (this.isTA == true) {
      pagePath = '/courses/course-work-submissions';
      if (is_group == true) {
        pagePath = '/courses/course-work-detail';
      }
    }

    this.route.navigate([pagePath], { queryParams: { courseWork: id, course_id: this.courseId,course:this.courseName, topic_id: topic_id,tab_no:this.activeIdNav} });
  }

  editSelectedCourseWorkpeer_teacher(id, topic_id = '', is_group = null) {
    const role = this.role;
    const allowedRoles = ['teacher', 'super_admin'];
    if (allowedRoles.indexOf(role) == -1) {
      if (role == 'student') {
        let pagePath = '/courses/course-work';

        //FOR CHECK TEACHING ASSISTANT
        if (this.isTA == true) {
          pagePath = '/courses/course-work-submissions';
          if (is_group == true) {
            pagePath = '/courses/course-work-detail';
          }
        }

        this.route.navigate([pagePath], { queryParams: { courseWork: id, course_id: this.courseId,course:this.courseName, topic_id: topic_id,tab_no:this.activeIdNav } });
      }
    }
    else {
      let pagePath = '/courses/course-work-submissions';
      if (is_group == true) {
        pagePath = '/courses/course-work-detail';
      }
      this.route.navigate([pagePath], { queryParams: { courseWork: id, course_id: this.courseId,course:this.courseName, topic_id: topic_id,tab_no:this.activeIdNav } });
    }
  }



  topicdata: any
  async getTopicReview(courseId, rating_sort) {
    try {
      const promiseArray = this.showCourseData.course_info.topics.map(async (item) => {
        const topic1 = await this.courseService.getReview(courseId, rating_sort).toPromise()
        return {
          ...topic1
        }
      })
      const promiseArrayRes = await Promise.all(promiseArray)
      this.showCourseData.course_info.topics = promiseArrayRes
      this.topicdata = this.showCourseData.course_info.topics;
    } catch (error) {
      console.error(error);
    }

  }

  setRating(value, courseId) {
    if (!(value.feedBackReview)) {
      this.notificationService.showNotification('info', 'Please write feedback');
      return;
    }

    if (!(value.rating)) {
      this.notificationService.showNotification('info', 'Please give your rating');
      return;
    }
    if (this.showCourseData.course_info.publish_right) {
      this.notificationService.showNotification("info", "You can not give ratting for own course");
      return;
    }

    if (confirm("Are you sure you want give rating?")) {
      this.questionService.setRating(value, value.feedBackReview, '', courseId).pipe(finalize(() => this.loader)).subscribe(rating => {
        this.individual_rating = rating.individual_rating;
        this.notificationService.showNotification("success", rating.Status);
        this.onRating();
        this.getEditReview(courseId, this.rating_sort)
        this.getEditDetails();

      })

    }

  }

  editCourseView(page_name) {
    this.route.navigate([page_name], { queryParams: { courseId: this.courseId, course: this.courseName,tab_no:this.activeIdNav,pageRef:'single_ec'  } }); 
  }
  viewQuestions() {
    this.courseService.viewQuestions(this.session_id, this.courseId).subscribe(questions => {
      this.myQuestions = questions.questions
    })
  }

  sendToQuestion(id) {
    this.openModelVideo.close();
    this.route.navigate(['/question-details'], { queryParams: { questionId: id, info: "MYQUES" } });
  }

  subscribeCourse(isSubscribe) {
    this.loader = true;
    this.courseService.subscribeCourse(isSubscribe, this.courseId).subscribe(isSubscribe => {
      this.isSubscribed = isSubscribe.Courses.Subscription_Status;
      this.notificationService.showNotification("success", isSubscribe.Courses.Status);
      localStorage.setItem('coins', isSubscribe.Courses.coin);
      this.getEditDetails();
      this.activeIdNav = 1;
      this.loader = false;
    },
      (err) => {
        this.notificationService.showNotification("error", err.message);
        this.loader = false;
      })
  }


  getCommentsInfo(session_id, courseId) {
    this.loader = true;
    this.courseService.getCommentsInfo(session_id, courseId).pipe(finalize(() => { this.loader = false; })).subscribe(weekInfo => {
      this.feedBack_list = weekInfo.content_detail.comments;
      this.videoStartTime = weekInfo.content_detail.view_video_time;
    })

  }

  submitCourseFeedBack(form: NgForm, course_session_id) {
    if (!(form.value.feedBack)) {
      this.notificationService.showNotification('info', 'please enter some comments');
      return;
    }
    this.loader = true;
    this.courseService.session_video_comments(this.courseId, form.value, course_session_id).pipe(finalize(() => { this.loader = false })).subscribe(feedback => {
      this.notificationService.showNotification("success", "Comment added successfully");
      this.getCommentsInfo(this.session_id, this.courseId)
      form.reset();
    })
  }

  resetForm(form: NgForm) {
    form.reset();
  }

  askQuestionModel(model) {
    this.modalRef = this.modalService.open(model, { size: 'md' });
    this.pauseVideo()
  }

  pauseVideo() {
    let time = 0;
    if (this.player) {
      this.player.target.pauseVideo();
      time = this.player.target.getCurrentTime();
      time = time - 10;
      this.currentTime = time.toString().split('.')[0];
    }
    else {
      this.videoElement = document.getElementById('videoByS3');
      if (this.videoElement) {
        this.videoElement.pause();
      }
    }
  }

  closeAskQuestion() {
    this.modalRef.close();
    if (this.player) {
      this.player.target.playVideo();
    } else {
      this.videoElement.play();
    }
  }

  minMax() {
    this.show = !this.show;
    if (this.show)
      this.btnStat = "mini";
    else
      this.btnStat = "maxi";
  }

  liveSessionDate = ''
  openSessVd(session, file_id, url, description, session_id, title, resources, topic_assessment, topic_id?: string, sessionValue?: any) {
    //STOP VIDEO NOTES REDIRECT FOR VIDEO PROCESSING |START
    if ((sessionValue.live_status == '' || sessionValue.status == '' || sessionValue.live_status == 'meeting_initiated' || sessionValue.status == 'meeting_initiated') && sessionValue.key == 'live_session') {
      this.notificationService.showNotification('info', 'Live session yet to be started, please check back later.')
      return;
    } else if ((sessionValue.live_status == 'meeting_ended' || sessionValue.status == 'meeting_ended' ) && (sessionValue.live_status != 'recording_completed' || sessionValue.status != 'recording_completed') && sessionValue.key == 'live_session') {
      this.notificationService.showNotification('info', 'Live session recording is being processed, please check back later');
      return;
    } else if ((sessionValue.live_status == 'missed' || sessionValue.status == 'missed' ) && sessionValue.key == 'live_session') {
      this.notificationService.showNotification('info', 'Live Session has been expired.');
      return;
    } else if ((sessionValue.live_status == 'meeting_started'  || sessionValue.status == 'meeting_started') && sessionValue.key == 'live_session') {
      this.notificationService.showNotification('info', 'Live session is in progress , please check back later.');
      return;
    } else if((sessionValue.live_status == 'recording_completed' || sessionValue.status == 'recording_completed') && !sessionValue.course_session_id){
      
    //SOME TIME VIDEO DURATION IS COMING THAT IS WHY  INTEGRATE THIS IS FOR REFRESH  
      this.courseService.get_topicSession(this.courseId, this.topicId).pipe(finalize(() =>
        this.panel_loader = false)).subscribe(topicData => {
          this.topic_Session = topicData.sessions;
          this.live_sessions = topicData.live_sessions;
          // console.log("Admin")
        })

      }
  

    //STOP VIDEO NOTES REDIRECT FOR VIDEO PROCESSING |END
    this.file_id = '';
    this.session_id = session_id;
    this.title = title
    this.topic_id_value = topic_id
    this.description = description
    this.file_id = file_id;
    this.sessionkey = sessionValue.key;
    this.course_session_id = sessionValue._id;

    if (sessionValue.key == 'live_session') {
      this.session_detail(sessionValue)
    } else {
      this.session_detail(session)
    }


  }





  openAssessmentPopup(session, courseAssessment, topic_id?: string) {
    if (courseAssessment.view_feedback) {
      this.notificationService.showNotification('info', 'you have already seen the assessment feedback now you are not eligible any more for give the assessment.')
      return;
    }
    if (this.showCourseData.course_info.publish_right) {
      this.notificationService.showNotification('info', 'you are not eligible for this assessment.')
      return;
    }
    this.topicId = topic_id;
    this.courseAssessmentData = courseAssessment;
    this.pauseVideo();
    this.open_assessment(this.topicId, this.courseAssessmentData, '/courses/submit-assessement')
  }
  openAssessmentForSession(session, assessment, time, index?: number) {
    let _id = assessment._id
    let topic_assessment_is = {
      'active': true,
      "schedule_id": "",
      "total_time": time,
      "_id": _id
    }
    this.session_detail('');
    if (this.sessionAssessment[index].active || this.sessionAssessment[index].active == undefined || this.session_status == 'recorded') {
      this.courseAssessmentData = topic_assessment_is;
      assessment.active_assessment = 'Active';
      this.viewsubmitmodal = this.modalService.open(session, { size: 'lg' });
    } else {
      this.notificationService.showNotification('info', 'This assessment is not live right now.')
    }


  }

  // openAnnounce(announcement) {

  //   if (tinymce.get().length > 0) {
  //     tinymce.execCommand('mceRemoveEditor', false, 'announcementText');
  //   }
  //   this.CallMceEditor('announcementText');
  //   this.announcementForm.reset();
  //   this.editAnnouncementValue = [];
  //   this.announcementpopUp = this.modalService.open(announcement, { size: 'md' });
  // }

  openIt(url, ref = '') {
    if (ref == 'join_session') {
      if (url) {
        window.open(url, '_blank');
      }
    }
    else {
      window.open(url, '_blank');
    }
  }

  openCourseView(openCoursework, coursework) {
    this.coursework = coursework;
    this.openCoursework = this.modalService.open(openCoursework, { size: 'lg' });
  }
  addAnnouncements() {
    this.validate_announcement(this.announcementIndex)
  }

  closeAnnouncement() {
    this.announcementpopUp.close();
  }

  onStateChange(event) {
    this.ytEvent = event.target;
  }

  savePlayer(player) {
    this.player = player;
    this.player.target.seekTo(this.videoStartTime)
    this.player.target.playVideo();
  }


  closeModalQuestion(val) {
    if (val == 'closeModal' || val == 'noassessment') {
      this.viewsubmitmodal.close();
    }
    if (val == 'closeFeedbackModal') {
      this.viewFeedbackmodal.close();
    }

  }

  showHideDismiss(val) {

    if (val == 'showdismissbutton') { this.dismissFlag = false; }
    else if (val == 'hidedismissbutton') { this.dismissFlag = true; }
    else { this.dismissFlag = true; }
  }

  view_teacher_assessment = [];
  isActiveTr: boolean = false;
  assessmentFeedbackPopup(session, courseAssessment, view_assessment, topic_id, course_session_id, type?: string) {
    this.view_teacher_assessment = [];
    this.courseAssessmentData = courseAssessment;
    if (topic_id) {
      this.topicId = topic_id;
    } else {
      this.topicId = '';
    }
    this.course_session_id = '';
    this.isActiveTr = true;
    this.pauseVideo();
    if (view_assessment) {
      this.user_id = view_assessment._id;
      this.view_teacher_assessment = view_assessment;
    }
    this.route.navigate(['/courses/feedback-assessement'], { queryParams: { course_id: this.courseId, assessment_id: courseAssessment._id, topicId: this.topicId } })
    if (type == 'teacher_view') {
      this.viewFeedbackmodal = this.modalService.open(session, { size: 'lg' });
    }
  }

  viewUserSubmission(assesments_info, topicId) {
    this.route.navigate(['/courses/user-submissions'], {
      queryParams: {
        course_id: this.courseId,
        course: this.courseName,
        assessment_id: assesments_info._id,
        assessment_name: assesments_info.title,
        topicId: topicId,
        isTA: this.isTA,
        tab_no:this.activeIdNav
      }
    })
  }

  viewCourseWorkIndex(index, openCoursework) {
    let value = index.split(' ');
    if (value[1] == 'topicLevel') {
      this.coursework = this.showCourseData.course_info.topics[value[2]].course_work[value[0]];
    } else if (value[1] == 'courseLevel') {
      this.coursework = this.showCourseData.course_info.course_work[value[0]];
    }
    this.openCoursework = this.modalService.open(openCoursework, { size: 'lg' });
  }

  teamName;
  submission_publish = 'unpublished'
  submission_view_teacher(index, course_work) {
    this.loader = true;
    this.teacher_view_submission = [];
    let value = index.split(' ');
    if (value[1] == 'topicLevel') {
      this.course_work_view = this.showCourseData.course_info.topics[value[2]].course_work[value[0]];
    } else if (value[1] == 'courseLevel') {
      this.course_work_view = this.showCourseData.course_info.course_work[value[0]];
    }
    !(this.course_work_view.is_group) ? this.teamName = 'User Name' : this.teamName = 'Team Name';
    let team_name = '';
    this.courseService.viewSubmission(team_name, this.course_work_view._id, this.course_work_view.schedule_id, this.courseId, this.course_work_view.peer_review, this.course_work_view.self_review, 'grade_view').pipe(finalize(() => { this.loader = false; })).subscribe(viewSubmission => {
      this.teacher_view_submission = viewSubmission.response;
      viewSubmission.submission_publish ? this.submission_publish = 'published' : this.submission_publish = 'unpublished';
      this.submission_publish == 'published' ? this.submission_button = 'Edit' : this.submission_button = 'Publish';
      this.modalService.open(course_work, { size: 'lg' })
    })

    if (!this.teacher_view_submission) {
      this.submission_publish = 'unpublished'
      this.notificationService.showNotification('info', 'no submission is present');
    }
  }

  assessmentTitle(value) {
    this.courseAssessmentData.title = value;
  }

  loaderEvent(value) {
    if (value == 'true') {
      this.loader = true;
    } else {
      this.loader = false;
    }
  }


  viewSubmission(submission, assessment, topic_id?: string,) {
    let assessmentId = assessment._id;
    let scheduleId = assessment.schedule_id;
    let submitted_time = assessment.start_time;
    let assessment_result_id = assessmentId
    this.assessment_view = assessment;
    this.topicId = topic_id ? topic_id : "";
    this.markAssessment(assessment);
  }

  markAssessment(assessment) {
    this.route.navigate(['courses/assessment-marking'], {
      queryParams: {
        course: this.courseName,
        course_id: this.courseId,
        assessment_id: assessment._id,
        assessment_name: assessment.title,
        schedule_id: assessment.schedule_id,
        start_time: assessment.start_time,
        topicId: this.topicId,
        session_id: this.session_id,
        isTA: this.isTA,
        tab_no:this.activeIdNav
      }
    })
  }



  saveVideoState(player, popup) {
    let videoTime;
    let splitVideoTime;
    this.file_id ? this.videoElement = undefined : this.player = undefined;
    if (player) {
      videoTime = player.target.getCurrentTime();
      videoTime = videoTime.toString();
      splitVideoTime = videoTime.split('.');
      videoTime = splitVideoTime[0]
    } else if (this.videoElement) {
      this.videoElement = document.getElementById('videoByS3');
      videoTime = this.videoElement.currentTime.toFixed(0);
    }
    if (videoTime) {
      this.courseService.saveVideoTime(this.courseId, this.session_id, videoTime).pipe(finalize(() => this.loader)).subscribe(saveTime => {
      })
    }
    this.openModelVideo.close();
  }

  session_detail(sessionView) {
    //TO SET DEFAULT VIDEO NOTE TAB SELECTION
    localStorage.setItem('ngbNavItem', '1');

    //THIS IS FOR OLD LIVE SESSION
    if (sessionView.key == 'live_session' && sessionView._id  && (sessionView.status == "recorded" || sessionView.status == "zoom_recorded")) {
      console.log(sessionView._id)
      this.route.navigate(['courses/video-with-notes'], {
        queryParams: {
          course_id: this.courseId,
          course:this.courseName,
          session_id: sessionView._id,
          course_category: this.course_category,
          course_category_id: this.course_category_id,
          topicId: this.topic_id_value,
          sessionkey: this.sessionkey,
          isTA: this.isTA,
          tab_no:this.activeIdNav,
          // ngbNavItem:1
        }
      })
    }

    if (sessionView.key == 'live_session' && sessionView.course_session_id  && (sessionView.status != "recorded" && sessionView.status != "zoom_recorded")) {
      this.route.navigate(['courses/video-with-notes'], {
        queryParams: {
          course_id: this.courseId,
          course:this.courseName,
          session_id: sessionView.course_session_id,
          course_category: this.course_category,
          course_category_id: this.course_category_id,
          topicId: this.topic_id_value,
          sessionkey: this.sessionkey,
          isTA: this.isTA,
          tab_no:this.activeIdNav,
          // ngbNavItem:1
        }
      })
    } else {

      this.route.navigate(['courses/video-with-notes'], {
        queryParams: {
          course_id: this.courseId,
          course:this.courseName,
          session_id: this.session_id,
          course_category: this.course_category,
          course_category_id: this.course_category_id,
          topicId: this.topic_id_value,
          sessionkey: this.sessionkey,
          isTA: this.isTA,
          tab_no:this.activeIdNav,
          // ngbNavItem:1
        }
      })
    }
  }
  closeAskQuestionModel(value) {
    if (value == 'closeModel') {
      this.closeAskQuestion();
    }
  }

  add_feedback_grade(event, submission_index, submission_name, course_work_view, type, submission_view_index, teacher_view) {
    if (this.submission_button == 'Edit' && (type == 'grade' || type == 'feedback')) {
      this.notificationService.showNotification('info', 'Please use edit for edit the grade/feedback');
      return;
    }
    if (this.teacher_view_submission[submission_index].submissions[submission_name].files.length == 0) {
      this.notificationService.showNotification('info', 'Users has not submitted any file for this submission');
      return;
    }
    let team_name = '';
    if (!event) {
      // this.notificationService.showNotification('info', 'please add the some value');
      return;
    }
    if (event > 100 && type == 'grade') {
      this.notificationService.showNotification('info', 'You can not give more than 100 marks, Please assign it again.');
      return;
    }
    // if(Number(event.target.value) > teacher_view.max_marks){
    //   this.notificationService.showNotification('info', 'Please add less than or equal too mark than max mark');
    //   return;
    // }
    if (this.submission_publish == 'published' && this.role == 'student') {
      this.notificationService.showNotification('info', 'Your Submission is published. No permission to edit it now.');
      return;
    }
    if (type == 'grade') {
      let grade_value = event.replace(/[^0-9]/g, '');

      if (!grade_value) {
        this.notificationService.showNotification('info', 'please enter grade in number only');
        return;
      } else if (grade_value == '0') {
        return;
      }
      this.teacher_view_submission[submission_index].submissions[submission_name].grade = { content: grade_value }
    } else if (type == 'feedback') {
      this.teacher_view_submission[submission_index].submissions[submission_name].remark = { content: event }
    } else if (type == 'peer_review') {
      if (!this.getReadOnly_review(teacher_view, type)) {
        this.notificationService.showNotification('info', 'No permission for peer review');
        return;
      }
      this.teacher_view_submission[submission_index].submissions[submission_name].peer_review = { content: event }
    } else if (type == 'self_review') {
      if (!this.getReadOnly_review(teacher_view, type)) {
        this.notificationService.showNotification('info', 'No permission for Self review');
        return;
      }
      this.teacher_view_submission[submission_index].submissions[submission_name].self_review = { content: event }
    }

    let grade = '0';
    let feedback = '';
    let peer_review = '';
    let self_review = '';

    if (course_work_view.group_type) {
      team_name = teacher_view.name;
    }



    if (type == 'grade') {
      grade = this.teacher_view_submission[submission_index].submissions[submission_name].grade.content ? this.teacher_view_submission[submission_index].submissions[submission_name].grade.content : '';
    } else if (type == 'feedback') {
      grade = '';
      feedback = this.teacher_view_submission[submission_index].submissions[submission_name].remark.content ? this.teacher_view_submission[submission_index].submissions[submission_name].remark.content : '';
    } else if (type == 'peer_review') {
      peer_review = this.teacher_view_submission[submission_index].submissions[submission_name].peer_review.content ? this.teacher_view_submission[submission_index].submissions[submission_name].peer_review.content : '';
    } else if (type == 'self_review') {
      self_review = this.teacher_view_submission[submission_index].submissions[submission_name].self_review.content ? this.teacher_view_submission[submission_index].submissions[submission_name].self_review.content : '';
    }

    let body = {
      user_id: this.teacher_view_submission[submission_index].user_id,
      team_name: team_name,
      course_work_id: course_work_view._id,
      schedule_id: course_work_view.schedule_id,
      course_id: this.courseId,
      grade: grade,
      feedback: feedback,
      submission_name: submission_name,
      submission_index: '' + submission_view_index,
      peer_review: peer_review,
      self_review: self_review
    }

    this.loader = true;
    let topic_id = '';
    this.courseService.add_remark_grade_course_work(body).pipe(finalize(() => {
      this.loader = false;
    })).subscribe(view_remark => {
      this.notificationService.showNotification('success', "added successfully")
    },
      (err) => {
        this.notificationService.showNotification('error', err.message);
      })
  }

  get_updated_date(live_session) {
    return this.notificationService.get_date_with_update(live_session.start_date, live_session.frequency, live_session.end_date, live_session.start_time, live_session.duration)
  }
  go_live_assessement(toggle) {
    if (this.session_status == ('active') || this.session_status == ('inactive')) {
      if (toggle.target.checked) {
        if (confirm('Are you sure you want to use this session again! All previous data get erased')) {
          this.make_live(toggle);
          this.session_status == 'active' ? 'inactive' : 'active';
        } else {
          toggle.target.checked = !toggle.target.checked;
          this.go_live_name = 'Go Live';
        }
      } else if (!toggle.target.checked) {
        this.make_live(toggle);
      }
    } else if (this.session_status == '') {
      if (confirm('Are you sure ?')) {
        this.make_live(toggle);
      } else {
        toggle.target.checked = !toggle.target.checked;
        this.go_live_name = 'Go Live';
        return;
      }
    }
  }

  make_live(toggle) {
    this.courseService.go_live_with_assessment(this.session_id, this.courseId, this.topic_id_value, toggle.target.checked).subscribe(go_live => {
      this.go_live = go_live.url;
      go_live.url ? this.go_live_name = 'Live' : this.go_live_name = 'Go Live';
      go_live.url ? this.session_status = 'active' : this.session_status = 'inactive';
      go_live.url ? window.open(this.go_live, '_blank') : '';
      this.notificationService.showNotification('success', go_live.message);
      this.getEditDetails();
    },
      (error) => {
        this.notificationService.showNotification('error', error.message);
        toggle.target.checked = false;
      })
  }


  make_assessment_live(assessment, event) {
    let live = ''
    event.target.checked ? live = 'Active' : live = 'Inactive';
    if (confirm('Are you sure for making assessment ' + live + ' ?')) {
      this.courseService.make_live_assessment(this.session_id, this.courseId, this.topic_id_value, assessment._id, event.target.checked).subscribe(live_assessment => {
        assessment.active_assessment = event.target.checked ? 'Active' : 'Inactive';
        this.notificationService.showNotification('success', 'assessment ' + assessment.active_assessment);
        this.session_detail('')
      },
        (error) => {
          this.notificationService.showNotification('error', error.message);
          event.target.checked = false;
          this.assessment_live = 'Inactive';

        })
    } else {
      event.target.checked = !event.target.checked;
      this.assessment_live = 'Inactive';
      return;
    }
  }
  viewAssessmentGraphically(courseAssessment, url, type?: string, topic_id?: string) {
    this._courseAssessmentShareService.setCourseAssessment(courseAssessment);
    this._courseAssessmentShareService.setSessionId(this.session_id);
    this._courseAssessmentShareService.setCourseId(this.courseId);
    this._courseAssessmentShareService.setTopicId(topic_id);
    this._courseAssessmentShareService.setCourseName(this.courseName);
    this._courseAssessmentShareService.setUserId(this.user_id);
    this._courseAssessmentShareService.setpublishRight(this.showCourseData.course_info.publish_right);
    if (type != 'teacher_view') {
      this.route.navigate([url], { queryParams: { topicId: topic_id,course:this.courseName, tab_no:this.activeIdNav } })
    }
  }

  getGraphAssessment(assessmentdata) {
    this.route.navigate(['/courses/assessment-graph'], { queryParams: { course_id: this.courseId, assessment_id: assessmentdata._id, course:this.courseName, tab_no:this.activeIdNav } })
  }

  show_graph(assessment, url, topic_id) {
    if (topic_id) {
      this.topicId = topic_id;
    }
    this.viewAssessmentGraphically(assessment, url);
  }

  show_info(info) {
    this.show_rating = !this.show_rating;
  }
  add_team_info(event) {
    if (event == 'team_add') {
      this.getEditDetails()
    }
  }

  publish_submission() {
    if (this.submission_button == 'Edit') {
      this.submission_button = 'Save'
      return;
    }
    if (confirm('Are you sure for making submission publish')) {
      this.courseService.publish_submission(this.courseId, this.course_work_view.schedule_id, this.course_work_view._id).subscribe(submission => {
        this.notificationService.showNotification('success', submission.message);
        this.submission_publish = 'published';
        this.submission_button = 'Edit';
      },
        (error) => {
          this.notificationService.showNotification('error', error.message);
        })
    }
  }

  viewStudentMonitoring() {
    let role: string = 'student';
    this.loader = true;
    this.courseService.getSubscribeStudent(this.courseId, role).pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe(data => {
        if (!data.hasOwnProperty('users_info')) {
          console.log(data.message);
          
          this.notificationService.showNotification("info", data.message);
          return;
        }
        this._monitoringShareService.setCourseName(this.courseName);
        // localStorage.setItem('courseName', this.courseName);
        
        this.route.navigate(['/courses/student-monitoring'], { queryParams: { course_id: this.courseId } });
      })
  }

  myTeaching_assis(teaching) {
    if (teaching.findIndex(username => username._id == localStorage.getItem('user_id')) != -1) {
      this.isSubscribed = true;
    }
    return teaching.findIndex(username => username._id == localStorage.getItem('user_id'));
  }

  getReadOnly(showCourseData) {
    if (this.myTeaching_assis(showCourseData.course_info.teach_assis) != -1) {
      return false;
    } else if (!showCourseData.course_info.publish_right) {
      return true;
    } else if (this.submission_button == 'Edit') {
      return true
    }
  }
  getAssistant(assistant) {
    return Object.keys(assistant).length === 0 && assistant.constructor === Object
  }
  open_assessment(topicId, courseAssessmentData, url, type?: string) {
    this.viewAssessmentGraphically(courseAssessmentData, url, type, topicId);
  }



  announcemenModelEdit(announcementdata, announce, index) {
    this.announcementForm.reset();
    this.loader = true;
    this.courseService.getAnnouncementTopic(this.courseId, index).pipe(finalize(() => this.loader = false)).subscribe((res) => {
      this.loader = false
      this.announcementTopicData = res.announcement_info;
      this.uploadedMedia = [];
      this.announcementForm.controls.title.setValue(this.announcementTopicData.title)
      this.announcementForm.controls.announcement_html.setValue(this.announcementTopicData.announcement_html);
      // this.editAnnouncementValue = announce;
      this.announcementIndex = index;
      this.selectFilelenghth = this.announcementTopicData.resources.length;
      if (this.announcementTopicData.resource_keys.length > 0) {
        this.resourceCondition(this.announcementTopicData.resource_keys, this.uploadedMedia)
      }
      this.active = []
      // this.active = (this.active == this.closePanel.panelId) ? [] : [this.closePanel.panelId];
      // this.active = []
      if (this.modalRef) {
        this.modalRef.close();
      }
      this.modalRef = this.modalReview.open(announcementdata, { windowClass: 'announcementdata', centered: true, size: 'lg' });

      if (tinymce.get().length > 0) {
        tinymce.execCommand('mceRemoveEditor', false, 'announcementText');

      }
      this.CallMceEditor('announcementText');
    });

  }

  announcemenModel(announcementdata) {
    this.active = [];
    //this.closePanel.nextState = false;
    this.announcementForm.reset();
    this.announcementIndex = null
    this.uploadedMedia = []
    if (this.modalRef) {
      this.modalRef.close();
    }
    this.modalRef = this.modalReview.open(announcementdata, { windowClass: 'announcementdata', centered: true, size: 'lg' });
    if (tinymce.get().length > 0) {
      tinymce.execCommand('mceRemoveEditor', false, 'announcementText');

    }
    this.CallMceEditor('announcementText');

  }

  popupModel = null
  deleteAnnounModel(deleteAnno) {
    this.popupModel = this.modalService.open(deleteAnno, { size: "sm", centered: true });
  }

  closeAnnPopup() {
    this.popupModel.close();
  }

  deleteAnnoucement(index) {
    this.courseService.delete_announcement(this.courseId, index).subscribe(value => {
      this.notificationService.showNotification('success', value.message);
      this.active = []
      //this.getEditDetails();
      this.get_annoucement();
      //this.getAnnouncement(this.editAnnouncementValue.announcement_id)
      this.announcementForm.reset();
      this.popupModel.close();

    },
      (err) => {
        this.notificationService.showNotification('error', err.message);
      });
  }


  uploadResourse = []
  validate_announcement(index) {

    let content = tinymce.get('announcementText').getContent();
    this.announcementForm.get('course_id').setValue(this.courseId);
    this.announcementForm.get('announcement_html').setValue(content);
    let resourceList = []


    if (this.uploadedMedia.length > 0) {
      this.uploadedMedia.forEach((e, index) => {
        if (!e.resource_deleted) {
          resourceList.push(e.path);
        }
      });
      this.announcementForm.value.resources = resourceList;

    }

    if (this.uploadedMedia.length == 0) {
      this.announcementForm.value.resources = [];

    }
    if (this.announcementForm.value.title < 1) {
      this.notificationService.showNotification('info', 'Please fill the correct Title');
      return;
    }

    if (this.announcementForm.value.announcement_html < 1) {
      this.notificationService.showNotification('info', 'Please fill the correct Description');
      return;
    }

    // if (this.announcementForm.invalid) {
    //   this.notificationService.showNotification('info', 'Please fill the correct announcement');
    //   return;
    // }

    if (index != ('' || null && undefined)) {
      this.courseService.edit_announcement(this.announcementForm.value, index)
        .pipe(finalize(() => this.announcementIndex = null)).subscribe(value => {
          this.notificationService.showNotification('success', value.message);
          this.announcementForm.reset();
          this.get_annoucement();
          this.restUploadFile();
          // this.announcementForm = null;

          this.modalRef.close()

        },
          (err) => {
            this.notificationService.showNotification('error', err.message);
          });
    } else {
      this.courseService.save_announcement(this.announcementForm.value).subscribe(value => {
        this.notificationService.showNotification('success', value.message);
        this.announcementForm.reset();
        this.get_annoucement();
        this.restUploadFile();
        this.modalRef.close()


      },
        (err) => {
          this.notificationService.showNotification('error', err.message);
        });
    }
  }

  // common method of resources array
  resourceCondition(getArrayItem, setArrayItem) {


    for (let i = 0; i < getArrayItem.length; i++) {
      let filePath = getArrayItem[i];
      let fileNameSplitA = getArrayItem[i].split('/');
      let fileNameSplitB = fileNameSplitA[1].split('__');
      let extension = fileNameSplitB.pop().split('.');
      let fileNameSplitTitle = fileNameSplitB[0] + '.' + extension[1];
      setArrayItem.push({
        FileName: fileNameSplitTitle,
        FileProgessSize: 100,
        FileProgress: 100,
        path: filePath
      });
    }

  }

  discardAnnouncment() {
    this.announcementForm.reset();
    this.announcementIndex = [];
    if (this.announcementIndex == ('' || null && undefined)) {
      this.uploadedMedia = []

    }
    this.restUploadFile();
    this.modalRef.close();
  }

  CallMceEditor(id) {
    if (tinymce.get()) {
      tinymce.remove(id);
    }
    // tinymce.editors = [];
    this.callMceEditor(id)
  }
  callMceEditor(edId) {
    tinymce.remove(edId);
    let that = this;
    setTimeout(() => {
      tinymce.baseURL = 'assets';
      tinymce.init({
        selector: 'textarea#' + edId,
        height: 300,
        // base_url: '/tinymce',
        suffix: '.min',
        themes: "assets/themes/silver",
        // icons: "assets/icons/",
        // content_css: '//www.tiny.cloud/css/codepen.min.css',

        // plugins: [
        //   'advlist autolink lists link image imagetools charmap print preview anchor',
        //   'searchreplace visualblocks code fullscreen',
        //   'insertdatetime media table paste code help wordcount'
        // ],
        toolbar: that.toolbarWithoutBlankButton,
        image_title: true,
        automatic_uploads: true,
        image_description: false,
        file_picker_types: 'file image media',
        // external_plugins: { tiny_mce_wiris: "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js" }, 
        // toolbar1: "tiny_mce_wiris_formulaEditor, tiny_mce_wiris_formulaEditorChemistry",
        file_picker_callback: function (cb, value, meta) {

          let input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');

          // Note: In modern browsers input[type="file"] is functional without 
          // even adding it to the DOM, but that might not be the case in some older
          // or quirky browsers like IE, so you might want to add it to the DOM
          // just in case, and visually hide it. And do not forget do remove it
          // once you do not need it anymore.

          input.onchange = function () {

            // var file = $event.path[0].files[0];
            let file = input.files[0];

            let reader: any = new FileReader();

            reader.onload = function () {

              // Note: Now we need to register the blob in TinyMCEs image blob
              // registry. In the next release this part hopefully won't be
              // necessary, as we are looking to handle it internally.
              let id = 'blobid' + (new Date()).getTime();
              let blobCache = tinymce.activeEditor.editorUpload.blobCache;
              let base64 = reader.result.split(',')[1];
              let blobInfo = blobCache.create(id, file, base64);

              blobCache.add(blobInfo);

              // call the callback and populate the Title field with the file name

              cb(reader.result, { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
        images_upload_handler: function (blobInfo, success, failure, progress) {
          let xhr, formData;

          xhr = new XMLHttpRequest();

          xhr.withCredentials = false;
          xhr.open('POST', that.apiEndpoint + '/upload-resource?module_name=course');
          xhr.setRequestHeader('session-token', localStorage.getItem('token')); // manually set header

          xhr.upload.onprogress = function (e) {
            progress(e.loaded / e.total * 100);
          };

          xhr.onerror = function () {
            failure("Image upload failed due to a XHR Transport error. Code: " + xhr.status);
          };

          xhr.onload = function () {
            let json;
            if (xhr.status != (201 || 200)) {
              failure('HTTP Error: ' + xhr.status);
              return;
            }
            json = JSON.parse(xhr.response);
            if (!json || typeof json.resource_info[0].url != 'string') {
              failure('Invalid JSON: ' + xhr.response.resource_info);
              return;
            }

            success(json.resource_info[0].url);
            let fileObj: any = {};
            fileObj._id = json.resource_info[0].url,
              fileObj.type = "file"
            that.fileArr.push(fileObj);
          };

          formData = new FormData();
          formData.append('resource', blobInfo.blob(), blobInfo.filename());


          xhr.send(formData);
        },
        setup: function (editor) {
          if (that.insertBlankFlag) {
            editor.ui.registry.addButton('customBlankButton', {
              text: 'Add Blank',
            });
          }
        }
      });
    }, 100)
  }
  get_status_zoom(status) {
    return this.notificationService.get_status_zoom(status);
  }
  live_sesssion_pass(password) {
    if (password.password) {
      return true;
    }
    return false;
  }
  meeting_id(live_session) {
    if (live_session.meeting_details) {
      return true;
    }
    return false;
  }
  allZoom_recording = [];
  get_zoom_recording() {
    if (this.isShownPVideos) {
      this.courseService.get_zoom_recording(this.courseId).subscribe(myVideos => {
        this.allZoom_recording = myVideos.response;

      },
        (error) => {
          this.notificationService.showNotification('error', 'No recording found');
        })
    }
    return;
  }

  get_time_zone(time) {
    return this.notificationService.get_time_zone(time);
  }

  recording_saved(event) {
    if (event == 'saved_recording') {
      this.getEditDetails();
    }
  }

  get_zip_file(teacher_view) {
    let team_name = ''
    if (teacher_view.user_id == '') {
      team_name = teacher_view.name;
    }
    this.loader = true;
    this.courseService.get_zip_submission(this.course_work_view._id, this.course_work_view.schedule_id, teacher_view.user_id, team_name).pipe(finalize(() => {
      this.loader = false;
    })).subscribe(get_zip => {
      let d = new Date();
      let fullDate: any = this.appendZero(d.getDate()) + '-' + this.appendZero((d.getMonth() + 1)) + '-' + d.getFullYear() + '_' + this.appendZero(d.getHours()) + this.appendZero(d.getMinutes()) + this.appendZero(d.getSeconds());
      let zip_file: any = new Blob([get_zip.body], { type: 'application/zip' });
      let fileName = teacher_view.name + '_' + fullDate + '.zip';
      fileSaver.saveAs(zip_file, fileName);
      this.notificationService.showNotification('success', 'Your Zip file is downloading')
    },
      (err) => {
        if (err.message == undefined) {
          this.notificationService.showNotification('error', 'No submission is present');
          return;
        }
      })
  }

  appendZero(val) {
    if (val < 10) {
      return '0' + val;
    }
    return val;
  }

  get_submission() {
    let Number_of_user = this.teacher_view_submission.length;
    let users;

    let total_submission;
    if (this.teacher_view_submission[0]) {
      total_submission = Object.keys(this.teacher_view_submission[0].submissions).length
    }
    if (Number_of_user) {
      users = Number_of_user + ' User/Team';
      if (total_submission) {
        users = users + ' and ' + total_submission + ' submission is present for this course work';
      }
      return users;
    }

  }

  get_submission_length(submission, index) {
    let submissions = submission.submissions;
    let value = [];
    let show_file = false;
    Object.keys(submissions).forEach(key => {
      value.push({ k: key, v: submissions[key] });
    });

    if (value.length) {
      value.forEach(v => {
        if (v.v.files.length > 0) {
          show_file = true;
        }
      })
    }
    return show_file;
  }

  chat_with_group() {
    this.route.navigate(['/guftagu'], { queryParams: { group_name: this.showCourseData.course_info.group_chat.name, group_room_id: this.showCourseData.course_info.group_chat.room_id } })
  }

  is_announcement(active, publish_right) {
    if (active && publish_right) {
      return true;
    }
    return false;
  }

  is_group_chat(group_chat) {
    if (this.role != 'super_admin' && this.isSubscribed && group_chat) {
      let index = this.room_member(group_chat.room_members);
      if (index > -1) {
        if (group_chat.room_members[index].user_status != 'deactivate') {
          return true;
        }
      }
    }
    return false;
  }

  room_member(member) {
    let index = member.findIndex(user => user.username == this.currentUsername);
    return index;
  }

  isSubscribe: boolean = false;
  courseScribe() {
    this.isSubscribe = !this.isSubscribe;
  }

  is_not_student() {
    if (this.myTeaching_assis(this.showCourseData.course_info.teach_assis) > -1) {
      return true;
    } else if (this.showCourseData.course_info.publish_right) {
      return true;
    }
    return false;
  }

  getReadOnly_review(review, type) {
    if (this.role == 'super_admin') {
      return true;
    } else if (this.submission_publish == 'published' && this.role == 'student' && type != 'self_review_view') {
      return false;
    } else if (type == 'peer_review' && this.role == 'student') {
      return review.peer_review_status;
    } else if (type == 'self_review' && this.role == 'student') {
      return review.self_review_status;
    } else if ((type == 'self_review_view' && this.role == 'student')) {
      return review.self_review_status;
    } else if (type == 'self_review_view' && this.role != 'student') {
      return true;
    } else if (this.role == 'teacher') {
      return false;
    } else if (this.myTeaching_assis(this.showCourseData.course_info.teach_assis) > -1) {
      return false;
    }
    return false;
  }

  is_review(type) {
    if (type == 'self_review') {
      return this.course_work_view.self_review;
    } else if (type == 'peer_review') {
      return this.course_work_view.peer_review;
    }
  }

  is_answer_assessment(assessment) {
    if (assessment.one_time_assessment) {
      return assessment.user_submission
    } else {
      return assessment.view_feedback
    }
  }
  editRate
  editReview
  rating_sort
  submitRatingModal(ratingContent, value: any = null) {

    this.modalReview.open(ratingContent, { windowClass: 'submitRatingClass', centered: true });
    this.getEditReview(this.courseId, this.rating_sort)
    this.editRate = value.Rating
    this.editReview = value.review



  }

  onRating() {
    const box = document.querySelector('.modal.fade.show');
    const box1 = document.querySelector('.modal-backdrop.fade.show');
    if (box) {
      box.classList.remove('show')
      box1.classList.remove('show')
      box.classList.remove('d-block')
      box1.classList.add('d-none')
      box.classList.add('d-none')
    }
  }


  ratingMethod() {
    this.loader = true
    if (this.routerUrl == '/courses/single-course') {
      this.route.navigate(['/courses/review-rating'], { queryParams: { course_id: this.courseId, course:this.courseName,tab_no:this.activeIdNav } });
      this.ratingSection = true;
      this.ratingText = 'View course details'
    } else if (this.routerUrl == '/courses/review-rating') {
      this.route.navigate(['/courses/single-course'], { queryParams: { course_id: this.courseId,course:this.courseName,tab_no:this.activeIdNav } });
      this.ratingSection = false;
      this.ratingText = 'View course details'
    }
    this.loader = false
  }

  sentToPublicProfile(value) {
    this.route.navigate(['public-profile'], { queryParams: { instructor_id: value } })

  }


  delCourse(courseId) {
    this.loader = true;
    if (confirm("Are you sure you want to deactivate this course?")) {
      this.courseService.deactivateCourse(courseId).pipe(finalize(() => {
        this.loader = false;
      })).subscribe(req => {
        this.notificationService.showNotification("success", req.Status);
        this.send_index_number.emit(this.current_page + '+teach');
      },
        (err) => {
          this.notificationService.showNotification("error", err.status);
        })
    }
  }


  is_tarsn_pending(transcription) {
    if (transcription.items.length > 0 && (this.role.toLowerCase() == 'super_admin' || this.role.toLowerCase() == 'teacher')) {
      let index = transcription.items.findIndex(ele => typeof ele['pendings'] == "object")
      return index != -1 ? true : false;
    }
    return false;
  }



  getpublishCourses() {
    this.loader = true;
    if (this.role == 'teacher' || this.role == 'super_admin') {
      this.courseService.addCourseInfo().pipe(finalize(() => {
        this.loader = false;
      })).subscribe(res => {
        this.cShowDraft = true;
        this.cShowPublish = true;
        this.publishCourse = res.courses_info;
        let pub;
        let unPub;
        for (let i of this.publishCourse) {
          unPub = this.publishCourse.filter(function (elem) {
            return elem.status === 'unpublish' && elem.edit_right == true;
          })
          pub = this.publishCourse.filter(function (elem) {
            return elem.status === 'publish' && elem.edit_right == true;
          })
        }
        this.totalLengthU = unPub.length;
        this.totalRecordeP = pub.length;


        if (unPub.length > 0) {
          this.IsLengthDraft = true;
        }
        if (pub.length > 0) {
          this.IsLengthPublish = true;
        }
        this.publishCourseDraft = unPub;
        this.publishCoursePublish = pub;
        this.publishCourseDraft = this.publishCourseDraft.slice(-2);
        this.publishCoursePublish = this.publishCoursePublish.slice(-10);
      });
    }
  }

  is_true
  makeCourseUniversalModel(makeCourseUniversal, event) {
    this.is_true = event.target.checked
    this.popupModel = this.modalService.open(makeCourseUniversal, { size: "sm", centered: true });
  }


  makeCourseAvailableToall(courseId) {
    this.loader = true;
    this.courseService.makeCourseUniversal(courseId, this.is_true).subscribe(req => {
      this.notificationService.showNotification("success", req.message);
      this.popupModel.close()
      this.getEditDetails();
      this.loader = false;
    },
      (err) => {
        this.notificationService.showNotification("error", err.message);
      })

  }

  closeCourseMakeUniversalPopup() {
    this.popupModel.close();
    this.getEditDetails();

  }




  get_number(number_of_page) {
    if (number_of_page != undefined) {
      return number_of_page
    }
    return 1;
  }

  findIndexOf(slug, pmenusSlug) {
    let indexOfValue;
    indexOfValue = this.shareService.getIndexArr(slug, pmenusSlug)
    return indexOfValue;
  }

  Send_index(number) {
    this.send_index_number_deactivate.emit(number + '+deactivate');
  }
  activate_course(course_id) {
    if (confirm('Are you sure you want to reactivate this course again ?')) {
      this.courseService.activate_course(course_id).subscribe(activate_course => {
        this.notificationService.showNotification('success', activate_course.message);
        this.send_index_number_deactivate.emit(this.current_page + '+deactivate');
      },
        (err) => {
          this.notificationService.showNotification('error', err.message);
        })
    }
  }

  deactiveCourseModel(deativeCourse) {
    this.popupModel = this.modalService.open(deativeCourse, { size: "sm", centered: true });
  }

  deactivedCourse(courseId) {
    this.loader = true;
    this.courseService.deactivateCourse(courseId).pipe(finalize(() => {
      this.loader = false;
    })).subscribe(req => {
      this.notificationService.showNotification("success", req.Status);
      this.popupModel.close()
      this.getpublishCourses()
    },
      (err) => {
        this.notificationService.showNotification("error", err.status);
      })
  }

  closePopup() {
    this.popupModel.close()
  }

  fileResList: any[];
  imgResList = []
  videoResList = [];
  resourceFileKey = []
  get_annoucement() {
    this.announcementList = [];
    this.loader = true;
    this.courseService.getAnnouncementList(this.courseId).pipe(finalize(() => this.loader = false)).subscribe((res) => {
      this.announcementList = res.announcement_info;
      this.loader = false;
      this.active = []
      // this.getEditDetails();
      // this.getAnnouncement(this.announcementList.announcement_id)

    })
  }




  announcementTopicData;
  getAnnouncement(announcement_id, announ_index: any = null) {
    if (this.closePanel.nextState) {
      this.loader = true;
      this.courseService.getAnnouncementTopic(this.courseId, announcement_id).pipe(finalize(() => this.loader = false)).subscribe((res) => {
        this.announcementTopicData = res.announcement_info;
        this.loader = false;
        let imgRes = []
        let fileRes = []
        let videoRes = []
        if (this.announcementTopicData.resources.length > 0) {
          this.announcementTopicData.resources.filter((e, index) => {
            if (e.type == 'image') {
              imgRes.push(e)
            }
            if (e.type == 'file') {
              fileRes.push(e)
            }
            if (e.type == 'video') {
              videoRes.push(e)
            }

          });
        }
        this.videoResList = videoRes
        this.imgResList = imgRes;
        this.fileResList = fileRes;
        // this.getEditDetails();


      })

    }

  }

  // active: any[] = [];
  closePanel:any;
  toggleAccordian(event) {
    this.closePanel = event
    this.active = (this.active == event.panelId) ? [] : [event.panelId];
  }

  get_instrutors() {
    this.instructorsList = {};
    this.loader = true;
    this.courseService.getInstructors(this.courseId).subscribe((res) => {
      this.instructorsList = res.course_info;
      this.loader = false;
    })
  }
  get_CourseResource() {
    this.courseResource = [];
    this.loader = true;
    this.courseService.getCourseResource(this.courseId).subscribe((res) => {
      this.courseResource = res.resources_info;
      this.loader = false;
    })
  }
  get_Assessments() {
    this.assessmentList = [];
    this.loader = true;
    this.courseService.getAssessments(this.courseId).subscribe((res) => {
      this.assessmentList = res.assesments_info;
      this.loader = false;
    })
  }
  get_CourseWorks() {
    this.courseWorkList = [];
    this.loader = true;
    this.courseService.getCoursework(this.courseId).subscribe((res) => {
      this.courseWorkList = res.course_work_info;
      this.loader = false;
    })
  }


  // new code  for re-design

  selectFilelenghth;
  moduleName;
  browseFileLen = 0;
  selectedArray;
  fileSizeLimit: number;
  fileSize: number = 0;
  mediaFileSize: number = 0;
  onFileBrowse(event: Event) {
    const target = event.target as HTMLInputElement;
    let browseFile = target.files;
    this.browseFileLen = browseFile.length;
    this.uploadArray = [];
    this.processFiles(target.files);
  }
  processFiles(files) {
    // this.fileSize = 0;
    let maxLimitItem = 10;
    this.fileSizeLimit = 20000000;
    this.selectedArray = this.uploadedMedia.length;
    let moreAllowItem = maxLimitItem - this.selectedArray;
    let fileLength = files.length;
    if (fileLength < moreAllowItem || fileLength == moreAllowItem) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (this.validateFile(file.name)) {
          this.notificationService.showNotification("info", 'Selected file format is not supported.');
          this.browseFileLen--;
        } else {
          let hasItem = this.uploadedMedia.find(s => s.FileName === file.name);
          if (!hasItem) {
            this.selectFilelenghth = this.selectedArray + this.browseFileLen;
            var reader = new FileReader();
            reader.readAsDataURL(file); // read file as data url
            reader.onload = (event: any) => {
              // called once readAsDataURL is completed

              let fileType = file.type;
              if (fileType.includes('image')) {
                this.moduleName = 'course';
              }
              else if (fileType.includes('video')) {
                this.moduleName = 'course';
              } else if (fileType.includes('audio')) {
                this.moduleName = 'course';
              } else if (fileType.includes('text') || fileType.includes('') || fileType.includes('application')) {
                this.moduleName = 'course';
              }

              this.uploadedMedia.push({
                FileName: file.name,
                FSB: file.size,
                FileSize:
                  this.UFS.getFileSize(file.size) +
                  ' ' +
                  this.UFS.getFileSizeUnit(file.size),
                FileType: file.type,
                FileUrl: event.target.result,
                FileProgessSize: 0,
                FileProgress: 0,
                Id: '',
                path: '',
                abc: false
              });

              this.startProgress(file, this.uploadedMedia.length - 1, this.moduleName);
            }

          } else {
            this.notificationService.showNotification("info", 'File already added.');
            this.browseFileLen--;
            // this.restUploadFile();
          }
        }
      }
      if (this.uploadedMedia.length > 0) {
        for (let m = 0; m < this.uploadedMedia.length; m++) {
          this.mediaFileSize += parseInt(this.uploadedMedia[m].FSB);
        }
      } else if (this.uploadedMedia.length < 1 || this.uploadedMedia.length == undefined) {
        this.mediaFileSize = 0;
      }
      let newSize = this.mediaFileSize + this.fileSize;
      // console.log('media Ken', this.uploadedMedia.length)
    } else {
      this.notificationService.showNotification("info", 'Only 10 files allowed.');
      this.browseFileLen = 0;
    }
  }
  savevisible: boolean = true;
  async startProgress(file, index, module_name) {
    let filteredFile = this.uploadedMedia
      .filter((u, index) => index === index)
      .pop();

    if (filteredFile != null) {
      let fileSize = this.UFS.getFileSize(file.size);
      let fileSizeInWords = this.UFS.getFileSizeUnit(file.size);
      if (this.UFS.isApiSetup) {
        let formData = new FormData();
        formData.append('resource', file);

        this.UFS
          .uploadMedia(formData, module_name)
          .subscribe(
            (res: any) => {
              if (res.resource_info == undefined) {
                this.savevisible = false;

              } else {
                this.savevisible = true;
                this.uploadArray.push(res.resource_info)
              }

              if (res.status === 'progress') {
                let completedPercentage = parseFloat(res.message);
                filteredFile.FileProgessSize = `${(
                  (fileSize * completedPercentage) /
                  100
                ).toFixed(2)} ${fileSizeInWords}`;
                filteredFile.FileProgress = completedPercentage;

                let pro = this.uploadedMedia.find(s => s.FileProgress != 100)

              } else if (res.status === 'completed') {
                filteredFile.Id = res.resource_id;
                filteredFile.path = res.path;

                filteredFile.FileProgessSize = fileSize + ' ' + fileSizeInWords;
                filteredFile.FileProgress = 100;
              }

              if (res.resource_info) {
                filteredFile.Id = res.resource_info[0].resource_id;
                filteredFile.path = res.resource_info[0].path;
                this.restUploadFile();
              }
            },
            (error: any) => {
              console.log(error);
            }
          );
      } else {
        for (
          var f = 0;
          f < fileSize + fileSize * 0.0001;
          f += fileSize * 0.01
        ) {
          filteredFile.FileProgessSize = f.toFixed(2) + ' ' + fileSizeInWords;
          var percentUploaded = Math.round((f / fileSize) * 100);
          filteredFile.FileProgress = percentUploaded;
          await this.fakeWaiter(Math.floor(Math.random() * 35) + 1);
        }
      }
    }

  }

  fakeWaiter(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'exe') {
      return true;
    }
    else {
      return false;
    }
  }

  deleteResouce(item, idx: number) {
    let sp = item.path;
    let fileTitleSplit = sp.split('/');
    let fileTitle = fileTitleSplit[1];
    if (confirm('Are you sure you want to delete.')) {
      this.uploadedMedia = this.uploadedMedia.filter((u, index) => index !== idx);
      this.selectFilelenghth = this.uploadedMedia.length;
      this.questionService.deleteResource(sp).subscribe(res => {
        this.notificationService.showNotification("success", res.message);
        this.restUploadFile();
        if (this.browseFileLen > 0) {
          this.browseFileLen--;
        }

        if (this.uploadArray.length > 0) {
          this.uploadArray.length--;
        }

      })
      if (this.selectFilelenghth == 0 || this.selectFilelenghth < 1) {
        this.restUploadFile();
      }
    }
  }


  restUploadFile() {
    let tsnString = (document.getElementById("fileU1") as HTMLTextAreaElement).value = '';
  }

  // redirect to url
  go_to_url(url) {
    this.route.navigate([url], { queryParams: { course_id: this.courseId, course: this.courseName,tab_no:this.activeIdNav } });
  }

  ngOnDestroy() {
    clearInterval(this.timerInterval);     
    // this.socket_service.close_socket();
    // localStorage.removeItem('socket_course_id')
  }


}

