import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { CourseService } from 'src/app/services/course.service';
import { finalize, filter } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { courseSession, courseTopicSession } from 'src/app/model/student-monitoring-report'
import { NotificationService } from 'src/app/services/notification.service';
import { Subscription } from 'rxjs';
import * as fileSaver from 'file-saver';
import { MonitoringShareService } from 'src/app/services/monitoring-share.service';


@Component({
  selector: 'app-student-monitoring',
  templateUrl: './student-monitoring.component.html',
  styleUrls: ['./student-monitoring.component.scss']
})
export class StudentMonitoringComponent implements OnInit {
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  marked = false;
  notesLoader = false;
  attendanceLoader = false;
  courseWorkLoader = false;
  assessmentLoader = false;
  displayMonths = 1;
  showWeekNumbers = true;
  outsideDays = 'visible';
  maxDate = new Date();
  sDate = new Date();
  sessionDateObj = new Date();
  selectedDD: any = null;

  public postGenerate: boolean = false;
  public preGenerate: boolean = true;

  public session: boolean = true;
  public personalize: boolean = false;
  public summary: boolean = false;
  activeids = "panel-session";
  public nextBtn1: boolean = false;
  public nextBtn2: boolean = false;
  public nextBtn3: boolean = false;

  public status: boolean = false;
  public status2: boolean = false;

  public pipe = new DatePipe('en-US'); // Use your own locale
  public bsRangeValue: Date[];
  fromDate: any;
  toDate: any;
  monitorData;
  selectedAttributes: any = [];
  selectedNotes: any = [];
  selectedAttendance: any = [];
  courseId: any;
  isAllNotesSelect: boolean = false;
  isAllAttendanceSelect: boolean = false;
  isAllTopicCWSelected: boolean = false;
  isAllCWSelected: any;
  courseworkMonitor: boolean = false;
  notesMonitor: boolean = false;
  attendanceMonitor: boolean = false;
  assessmentMonitor: boolean = false;
  sessions: courseSession[];
  searchText: any;
  searchedSessions: any = [];
  selectedSessions: any = [];
  checkedSessions: any = [];
  notesPersonalize: boolean = false;
  attendancePersonalize: boolean = false;
  cwTopicLevel: boolean = false;
  cwCourseLevel: boolean = false;

  assTopicLevel: boolean = false;
  assSessionLevel: boolean = false;
  assCourseLevel: boolean = false;
  isAllTLAsmntSelected: boolean = false;
  isAllCLAsmntSelected: boolean = false;
  isAllSLAsmntSelected: boolean = false;
  selectedCourseWork: any[];
  selectedTopicLevelCW: any[];
  selectedCLAsmnt: any[];
  selectedTLAsmnt: any[];
  selectedSLAsmnt: any[];
  statusAsmnt: boolean;
  statusCW: boolean;
  personalizeDisabled: boolean = false;
  summaryDisabled: boolean = false;
  subscriberNotes: boolean = false;
  sessionId: any;
  sessionTitle: any;
  sessionSubscription: Subscription;
  monitorSubscription: Subscription;
  subscriberId: any;
  exportSubscription: Subscription;
  courseName: string;
  loader: boolean;
  sessionTopicWiseFlag: any = '0';
  topicsWithSessions: courseTopicSession[];
  sessionOfSelectedTopic: any = [];
  selectedTopic: string;
  selectedIndex: any = 1;
  isSearchFinder:boolean = false;
  courseNameData:string = '';
  activeNav = '1';

  isSubscriberLarge:boolean = false;
  monitorDataMessage:string = null;

  constructor(private location: Location,
    private courseService: CourseService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ns: NotificationService,
    private _monitoringShareService: MonitoringShareService) { }

  ngOnInit() {
    //CHECK PERMISSION
    const role = localStorage.getItem('role');
    if (['teacher', 'super_admin'].indexOf(role) == -1) {
      this.router.navigate(['/']);
      return false;
    }

    this.sessionTopicWiseFlag = '0';
    this.activeNav = '1';
    this.courseId = this.activatedRoute.snapshot.queryParamMap.get('course_id');
    // this.courseName = this._monitoringShareService.getCourseName();    
    this.courseName =  localStorage.getItem('courseName');    
    this.getSessions();
  }

  isSubNotes(value) {
    this.subscriberNotes = value;
  }

  onSelectAllCheckBox(type) {
    if (type == 'coursework') {
      this.statusCW = !this.statusCW;

      if (this.statusCW) {
        this.cwTopicLevel = true;
        this.cwCourseLevel = true;
        if (!this.nextBtn3) { this.nextBtn2 = true; }
      } else {
        this.cwTopicLevel = false;
        this.cwCourseLevel = false;
        this.courseworkMonitor = false;
      }
    } else {
      this.statusAsmnt = !this.statusAsmnt;
      if (this.statusAsmnt) {
        this.assTopicLevel = true;
        this.assCourseLevel = true;
        this.assSessionLevel = true;
        if (!this.nextBtn3) { this.nextBtn2 = true; }
      } else {
        this.assTopicLevel = false;
        this.assCourseLevel = false;
        this.assSessionLevel = false;
        this.assessmentMonitor = false;
      }

    }

  }

  /*
  Session work start
  */
  getSessions() {
    this.loader = true;
    this.sessionSubscription = this.courseService.get_courseSessions(this.courseId).pipe(finalize(() => {
      this.loader = false;
    })).subscribe(data => {
      this.topicsWithSessions = data.response;
      // this.isSearchFinder = true;      
      if(!localStorage.getItem('courseName') || localStorage.getItem('courseName') == null){
        localStorage.setItem('courseName', data.subject)
      }
      const topicsessions = [];
      this.courseNameData = data.subject;
      this.topicsWithSessions.forEach(element => {
        element.sessions.forEach(ele => {
          topicsessions.push(ele);
        });
      });
      this.sessions = topicsessions;      
    },
      (err) => {
        this.ns.showNotification("error", err.message);
      });
  }

  handleClick(val) {
    this.nextBtn1 = false;
    this.nextBtn3 = false;
    
    if (val == '0') {
      this.activeNav = '1';
      this.sessionTopicWiseFlag = '0';
      this.selectedDD = null;
      this.sessionOfSelectedTopic = [];

      if(!this.editMode){
        this.searchText = '';
        this.uncheckedSessions();
        this.searchedSessions = [];
        this.selectedSessions = [];
        this.checkedSessions = [];
        this.getSessions();
      }
    }else if (val == '1'){
      this.sessionTopicWiseFlag = '1';
      this.activeNav = '2';
      // this.topicsWithSessions = [];
    }
    else if (val == '2') {
      // To display course tab content
      this.sessionTopicWiseFlag = '2';
      this.activeNav = '3';
    }
  }
  sessionSelect:boolean=false;
  onSelectTopic(topic) {
    this.sessionSelect = true;
    this.selectedDD = topic.topic_id;
    this.sessionOfSelectedTopic = [];
    const selectedTopic = this.topicsWithSessions.find((ele) => ele.topic_id == this.selectedDD);
    const pagination = Math.ceil(selectedTopic.sessions.length / 15);

    for (let i = 1; i <= pagination; i++) {
      this.sessionOfSelectedTopic.push({
        'offset': i,
        'title': this.getOffsetTitle(i)
      })
    }
    this.selectedTopic = selectedTopic.topic_title;
    if (this.selectedDD) {
      // this.nextBtn1 = true;
      this.next1();
    }
  }

  getOffsetTitle(i) {
    let minVal = 1;
    let maxVal = 15;
    if (i == 1) {
      minVal = 1;
    } else {
      minVal = (maxVal * (i - 1)) + 1;
    }
    const val = minVal + '-' + maxVal * i;
    return val
  }
  selectedOffset(index,val) {
    this.selectedIndex = index;
    if(val === 'genearteNext'){
      this.generateReport();
    }
  }
  searchFinder:any;
  isSearchTime:any;
  searchSessionLoader:boolean = false;
  searchSession() {
    // set val to the value of the searchbar
    this.searchFinder = this.searchText.toLowerCase().trim();
    const val = this.searchText.toLowerCase();
    // if the value is an empty string don't filter the items
    this.searchedSessions = [];
    clearTimeout(this.isSearchTime);
    if (val && val.trim() != '') {
      this.isSearchFinder = true;
      this.searchSessionLoader = true;
      this.isSearchTime = setTimeout(() => {
        this.searchedSessions = this.sessions.filter((item) => {
          return ((item.title.toLowerCase().trim().indexOf(val.trim()) >= 0));
        });                
        this.searchSessionLoader = false;
      }, 1000);
    } else {
      this.searchedSessions = [];
      this.isSearchFinder = false;
    }
  }

topicsCheckedSession:any=[];
  getCheckedSession(session) {  
    if (session.checked) {
      /* Pushing the object into array */
      this.checkedSessions.push(session);      

    } else {
      let el = this.checkedSessions.find(itm => itm._id === session._id);
      if (el)
        this.checkedSessions.splice(this.checkedSessions.indexOf(el), 1);
        // this.selectedSessions.splice(this.checkedSessions.indexOf(el), 1);
    }
  }

  choosedSessions() {       
    if (this.selectedSessions.length < 15 && this.checkedSessions.length <= 15) {
      for (let i = 0; i < this.checkedSessions.length; i++) {
        const found = this.selectedSessions.some(el => el._id === this.checkedSessions[i]._id);
        if (!found) {
          if (this.selectedSessions.length < 15) {
            this.selectedSessions.push(this.checkedSessions[i]);
          }
        }
      }
      if (this.selectedSessions.length > 0) {
        this.nextBtn1 = true;
      }
      return;
    }
    this.ns.showNotification('info', 'Maximum 15 sessions can choose')
    return;

  }

  get_from_list(item) {
    let index;
    index = this.selectedSessions.findIndex(session => session._id == item._id);
    return index
  }

  getSessionsIds() {
    const ids = [];
    this.selectedSessions.forEach(element => {
      ids.push(element._id);
    });
    return ids;
  }

  uncheckedSessions() {
    if (this.searchedSessions) {
      for (let i = 0; i < this.searchedSessions.length; i++) {
        this.searchedSessions[i].checked = false;
      }
    }
    
    // if (this.searchedSessions) {
    //   for (let i = 0; i < this.topicsWithSessions.length; i++) {
    //     this.topicsWithSessions[i].checked = false;
    //   }
    // }
  }

  removeChoosedSession(item, index) {
    
    if(confirm('Are you sure you want to delete session.') == true){
      let indexd;
      let indexCheck;
      indexCheck = this.checkedSessions.findIndex(session => session._id == item._id);
      indexd = this.searchedSessions.findIndex(session => session._id == item._id); 
      
      if(this.searchedSessions.length > 0){
        this.searchedSessions[indexd].checked = false;
      }
      this.topicsWithSessions.forEach((topic:any)=>{
       topic.sessions.filter((session,index)=>{
          if(session._id == item._id){
            session.checked = false;
            return index;
          }
        })
      })
        this.selectedSessions.splice(index, 1);
        this.checkedSessions.splice(indexCheck, 1);
        if (this.selectedSessions.length == 0) {
          this.uncheckedSessions();
          this.regenerateReport();
        }
    }
   
  }

  /*
  Session work end
  */


  /** CourseWork Code start */

  getCWLevel() {
    let level = [];
    if (this.cwCourseLevel && this.cwTopicLevel) {
      level = ["topic", "course"]
    } else if (this.cwCourseLevel && !this.cwTopicLevel) {
      level = ["course"]
    } else if (!this.cwCourseLevel && this.cwTopicLevel) {
      level = ["topic"]
    } else {
      level = []
    }
    return level;
  }


  getCWColSpan() {
    let val = 0;
    if (this.cwCourseLevel && this.cwTopicLevel) {
      val = ((this.selectedTopicLevelCW.length == 0 ? 1 : this.selectedTopicLevelCW.length) + (this.selectedCourseWork.length == 0 ? 1 : this.selectedCourseWork.length));
    } else if (this.cwCourseLevel && !this.cwTopicLevel) {
      val = this.selectedCourseWork.length == 0 ? 1 : this.selectedCourseWork.length
    } else if (!this.cwCourseLevel && this.cwTopicLevel) {
      val = this.selectedTopicLevelCW.length == 0 ? 1 : this.selectedTopicLevelCW.length
    } else {
      val = 0;
    }
    return val;
  }

  displayCWGrade(_id, cwcontent) {
    let index = cwcontent.findIndex(ele => ele._id == _id);
    let str = '';
    if (index > -1) {
      str += cwcontent[index].grade + ' (' + cwcontent[index].submission_data + ')';
      return str
    }

  }

  /**
   * @description Green -> submitted but not marked yet
                  white -> marked == submitted == total submission
                  red -> not submitted
   * @param id 
   * @param content 
   */
  displayCWActiveness(_id, content) {
    if (content.length > 0) {
      let index = content.findIndex(ele => ele._id == _id);
      if (index > -1) {
        let splittedArr: any = content[index].submission_data.split('/');//[marked/submitted/totalSubmission]
        if (splittedArr[0] > 0 && splittedArr[1] > 0 && splittedArr[2] > 0 && (splittedArr[0] === splittedArr[1]) && (splittedArr[1] === splittedArr[2]) && (splittedArr[0] === splittedArr[2])) {
          return 'white';
        } else if (splittedArr[1] == 0) {
          return 'red';
        } else {
          return 'green';
        }
      }
    } else {
      return 'red'
    }
  }

  display_color(_id,content) {
    if (content.length > 0) {
      let index = content.findIndex(ele => ele._id == _id);
      if (index > -1) {
        if(content[index].submitted_at != '' && content[index].late_by != '') {
          return 'text_color';
        } 
        return '';
      }
    }
  }

  late_days(_id,content) {
    if (content.length > 0) {
      let index = content.findIndex(ele => ele._id == _id);
      if (index > -1) {
        if(content[index].submitted_at != '' && content[index].late_by != '') {
         return 'submitted at: ' + this.ns.convert_into_ist(content[index].submitted_at) +' and ' + 'late by: ' +content[index].late_by
        } 
        return '';
      }
    }
  }

  
  /** CourseWork Code end */

  /** Attendance Code start */

  displayAttendanceActiveness(_id, content) {
    let index = content.findIndex(ele => ele.id == _id);
    if (content[index].attended) {
      return 'green';
    } else {
      return 'red';
    }
  }

  /** Attendance Code end */

  /** Notes Code start */

  displayNoteActiveness(_id, notecontent) {
    let index = notecontent.findIndex(ele => ele.id == _id);
    if (notecontent[index].is_note) {
      return 'green';
    } else {
      return 'red';
    }
  }

  /** Notes Code end */

  /** Assessment code start */

  displayASMNTGrade(_id, content) {

    let index = content.findIndex(ele => ele._id == _id);
    if (index > -1) {
      //if (content[index].is_marked) {
        return content[index].grade;
   //   }
    }

  }

  getAssessmentLevel() {
    let level = [];
    if (this.assTopicLevel && this.assSessionLevel && this.assCourseLevel) {
      level = ["topic", "course", "session"];
    } else if (this.assTopicLevel && this.assSessionLevel && !this.assCourseLevel) {
      level = ["topic", "session"];
    } else if (this.assTopicLevel && !this.assSessionLevel && this.assCourseLevel) {
      level = ["topic", "course"];
    } else if (!this.assTopicLevel && this.assSessionLevel && this.assCourseLevel) {
      level = ["session", "course"];
    } else if (this.assTopicLevel && !this.assSessionLevel && !this.assCourseLevel) {
      level = ["topic"];
    } else if (!this.assTopicLevel && !this.assSessionLevel && this.assCourseLevel) {
      level = ["course"];
    } else if (!this.assTopicLevel && this.assSessionLevel && !this.assCourseLevel) {
      level = ["session"];
    } else {
      level = []
    }
    return level;
  }

  getAssessmentColSpan() {
    let val = 0;
    if (this.assTopicLevel && this.assSessionLevel && this.assCourseLevel) {
      val = ((this.selectedTLAsmnt.length == 0 ? 1 : this.selectedTLAsmnt.length) +
        (this.selectedCLAsmnt.length == 0 ? 1 : this.selectedCLAsmnt.length) +
        (this.selectedSLAsmnt.length == 0 ? 1 : this.selectedSLAsmnt.length));
    } else if (this.assTopicLevel && this.assSessionLevel && !this.assCourseLevel) {
      val = ((this.selectedTLAsmnt.length == 0 ? 1 : this.selectedTLAsmnt.length) +
        (this.selectedSLAsmnt.length == 0 ? 1 : this.selectedSLAsmnt.length));
    } else if (this.assTopicLevel && !this.assSessionLevel && this.assCourseLevel) {
      val = ((this.selectedTLAsmnt.length == 0 ? 1 : this.selectedTLAsmnt.length) +
        (this.selectedCLAsmnt.length == 0 ? 1 : this.selectedCLAsmnt.length));
    } else if (!this.assTopicLevel && this.assSessionLevel && this.assCourseLevel) {
      val = ((this.selectedCLAsmnt.length == 0 ? 1 : this.selectedCLAsmnt.length) +
        (this.selectedSLAsmnt.length == 0 ? 1 : this.selectedSLAsmnt.length));
    } else if (this.assTopicLevel && !this.assSessionLevel && !this.assCourseLevel) {
      val = ((this.selectedTLAsmnt.length == 0 ? 1 : this.selectedTLAsmnt.length));
    } else if (!this.assTopicLevel && !this.assSessionLevel && this.assCourseLevel) {
      val = ((this.selectedCLAsmnt.length == 0 ? 1 : this.selectedCLAsmnt.length));
    } else if (!this.assTopicLevel && this.assSessionLevel && !this.assCourseLevel) {
      val = ((this.selectedSLAsmnt.length == 0 ? 1 : this.selectedSLAsmnt.length));
    } else {
      val = 0;
    }
    return val;
  }

  /**
   * @description Green -> submitted but not marked yet
                  white -> submitted and marked
                  red -> not submitted
   * @param id 
   * @param content 
   */
  displayAssessmentActiveness(_id, content) {
    if (content.length > 0) {
      let index = content.findIndex(ele => ele._id == _id);
      if (index > -1) {
        if (content[index].is_marked && content[index].is_submitted) {
          return 'white';
        } else if (!content[index].is_marked && content[index].is_submitted) {
          return 'green';
        } else {
          return 'red';
        }
      }
    } else {
      return 'red'
    }

  }


  /** Assessment code end */

  /** Common Code start */

  togglePersonalize() {

    if (this.notesPersonalize || this.attendancePersonalize || this.cwTopicLevel
      || this.cwCourseLevel || this.assTopicLevel || this.assCourseLevel || this.assSessionLevel) {  
    
      if (!this.nextBtn3) {
        this.nextBtn2 = true;
        this.nextBtn1 = false;
        this.nextBtn3 = false;
      }
    } else{
      this.nextBtn2 = false;
        this.nextBtn1 = false;
        this.nextBtn3 = false;
    }
    if (this.cwTopicLevel && this.cwCourseLevel) this.statusCW = true;
    else this.statusCW = false;

    if (this.assTopicLevel && this.assCourseLevel && this.assSessionLevel) this.statusAsmnt = true;
    else this.statusAsmnt = false;

  }

  toggleVisibility(type) {

    if (type == 'attendance') {
      if (this.attendanceMonitor) {
        this.isAllAttendanceSelect = true;
        this.onSelectAll('attendance', '');
      } else {
        this.selectedAttendance = [];
      }
      this.attendanceLoader = true;
      setTimeout(() => { this.attendanceLoader = false; }, 800);
    } else if (type == 'notes') {
      if (this.notesMonitor) {
        this.isAllNotesSelect = true;
        this.onSelectAll('note', '');
      } else {
        this.selectedNotes = [];
      }
      this.notesLoader = true;
      setTimeout(() => { this.notesLoader = false; }, 800);
    } else if (type == 'coursework') {
      if (this.courseworkMonitor) {
        if (this.cwTopicLevel) {
          this.isAllTopicCWSelected = true;
          this.onSelectAll('coursework', 'cwTopicLevel');
        }
        if (this.cwCourseLevel) {
          this.isAllCWSelected = true;
          this.onSelectAll('coursework', 'cwCourseLevel');
        }
      } else {
        this.selectedCourseWork = [];
        this.selectedTopicLevelCW = [];
      }
      this.courseWorkLoader = true;
      setTimeout(() => { this.courseWorkLoader = false; }, 800);
    } else if (type == 'assessment') {
      if (this.assessmentMonitor) {
        if (this.assTopicLevel) {
          this.isAllTLAsmntSelected = true;
          this.onSelectAll('assessment', 'assTopicLevel');
        }
        if (this.assCourseLevel) {
          this.isAllCLAsmntSelected = true;
          this.onSelectAll('assessment', 'assCourseLevel');
        }
        if (this.assSessionLevel) {
          this.isAllSLAsmntSelected = true;
          this.onSelectAll('assessment', 'assSessionLevel');
        }
      } else {
        this.selectedTLAsmnt = [];
        this.selectedCLAsmnt = [];
        this.selectedSLAsmnt = [];
      }
      this.assessmentLoader = true;
      setTimeout(() => { this.assessmentLoader = false; }, 800);
    } else {
      // do nothing
    }

  }

  onSelectAll(type, level) {
    if (type == 'attendance') {
      this.selectedAttendance = [];
      if (this.isAllAttendanceSelect) {
        for (let index = 0; index < this.monitorData.attendance.length; index++) {
          const element = this.monitorData.attendance[index];
          element.isSelected = true;
          this.selectedAttendance.push(element);
        }
      } else {
        for (let index = 0; index < this.monitorData.notes.length; index++) {
          const element = this.monitorData.notes[index];
          element.isSelected = false;
          this.selectedAttendance = [];
        }
      }

    } else if (type == 'note') {
      this.selectedNotes = [];
      if (this.isAllNotesSelect) {
        for (let index = 0; index < this.monitorData.notes.length; index++) {
          const element = this.monitorData.notes[index];
          element.isSelected = true;
          this.selectedNotes.push(element);
        }
      } else {
        for (let index = 0; index < this.monitorData.notes.length; index++) {
          const element = this.monitorData.notes[index];
          element.isSelected = false;
          this.selectedNotes = [];
        }
      }

    } else if (type == 'coursework') {
      if (level == 'cwTopicLevel') {
        this.selectedTopicLevelCW = [];
        if (this.isAllTopicCWSelected) {
          this.monitorData.course_work.topic_level.forEach((element, index) => {
            element.isSelected = true;
            element.serial = index + 1;
            this.selectedTopicLevelCW.push(element);
          });
        } else {
          this.monitorData.course_work.topic_level.forEach(element => {
            element.isSelected = false;
          });
          this.selectedTopicLevelCW = [];
        }
      } else {
        this.selectedCourseWork = [];
        if (this.isAllCWSelected) {
          this.monitorData.course_work.course_level.forEach((element, index) => {
            element.isSelected = true;
            element.serial = index + 1;
            this.selectedCourseWork.push(element);
          });
        } else {
          this.monitorData.course_work.course_level.forEach(element => {
            element.isSelected = false;
          });
          this.selectedCourseWork = [];
        }

      }
    } else if (type == 'assessment') {
      /** 
       * sessionLevel Assessment -> sid & tid will be there
       * courseLevel Assessment  -> sid & tid both will be blank
       * topicLevel Assessment   -> only tid will be there      
      */

      if (level == 'assTopicLevel') {
        this.selectedTLAsmnt = [];
        if (this.isAllTLAsmntSelected) {
          this.monitorData.assessment.topic_level.forEach((element, index) => {
            element.isSelected = true;
            element.serial = index + 1;
            this.selectedTLAsmnt.push(element);
          });
        } else {
          this.monitorData.assessment.topic_level.forEach(element => {
            element.isSelected = false;
          });
          this.selectedTLAsmnt = [];
        }

      } else if (level == 'assCourseLevel') {
        this.selectedCLAsmnt = [];
        if (this.isAllCLAsmntSelected) {
          this.monitorData.assessment.course_level.forEach((element, index) => {
            element.isSelected = true;
            element.serial = index + 1;
            this.selectedCLAsmnt.push(element);
          });
        } else {
          this.monitorData.assessment.course_level.forEach(element => {
            element.isSelected = false;
          });
          this.selectedCLAsmnt = [];
        }
      } else {
        this.selectedSLAsmnt = [];
        if (this.isAllSLAsmntSelected) {
          this.monitorData.assessment.session_level.forEach((element, index) => {
            element.isSelected = true;
            element.serial = index + 1;
            this.selectedSLAsmnt.push(element);
          });
        } else {
          this.monitorData.assessment.session_level.forEach(element => {
            element.isSelected = false;
          });
          this.selectedSLAsmnt = [];
        }
      }
    } else {
      //do nothing
    }


  }

  onSelect(val, type, level, i) {
    if (type == 'attendance') {
      if (val.isSelected) {
        this.selectedAttendance.push(val);
        if (this.monitorData.attendance.length == this.selectedAttendance.length) {
          this.isAllAttendanceSelect = true;
        }
      } else {
        let idx = this.selectedAttendance.findIndex(el => el.session_id == val.session_id)
        if (idx > -1) {
          this.selectedAttendance.splice(idx, 1);
        }
        this.isAllAttendanceSelect = false;
      }
    } else if (type == 'note') {
      if (val.isSelected) {
        this.selectedNotes.push(val);
        if (this.monitorData.notes.length == this.selectedNotes.length) {
          this.isAllNotesSelect = true;
        }
      } else {
        let idx = this.selectedNotes.findIndex(el => el.session_id == val.session_id)
        if (idx > -1) {
          this.selectedNotes.splice(idx, 1);
        }
        this.isAllNotesSelect = false;
      }
    } else if (type == 'coursework') {
      if (level == 'cwTopicLevel') {
        if (val.isSelected) {
          val.serial = i + 1;
          this.selectedTopicLevelCW.push(val);
          if (this.monitorData.course_work.topic_level.length == this.selectedTopicLevelCW.length) {
            this.isAllTopicCWSelected = true;
          }
        } else {
          let idx = this.selectedTopicLevelCW.findIndex(el => el._id == val._id);
          if (idx > -1) {
            this.selectedTopicLevelCW.splice(idx, 1);
          }
          this.isAllTopicCWSelected = false;
        }

      } else {
        if (val.isSelected) {
          val.serial = i + 1;
          this.selectedCourseWork.push(val);
          if (this.monitorData.course_work.course_level.length == this.selectedCourseWork.length) {
            this.isAllCWSelected = true;
          }
        } else {
          let idx = this.selectedCourseWork.findIndex(el => el._id == val._id);
          if (idx > -1) {
            this.selectedCourseWork.splice(idx, 1);
          }
          this.isAllCWSelected = false;
        }

      }
    } else if (type == 'assessment') {
      if (level == 'assTopicLevel') {
        if (val.isSelected) {
          val.serial = i + 1;
          this.selectedTLAsmnt.push(val);
          if (this.monitorData.assessment.topic_level.length == this.selectedTLAsmnt.length) {
            this.isAllTLAsmntSelected = true;
          }
        } else {
          let idx = this.selectedTLAsmnt.findIndex(el => el._id == val._id);
          if (idx > -1) {
            this.selectedTLAsmnt.splice(idx, 1);
          }
          this.isAllTLAsmntSelected = false;
        }

      } else if (level == 'assSessionLevel') {
        if (val.isSelected) {
          val.serial = i + 1;
          this.selectedSLAsmnt.push(val);
          if (this.monitorData.assessment.session_level.length == this.selectedSLAsmnt.length) {
            this.isAllSLAsmntSelected = true;
          }
        } else {
          let idx = this.selectedSLAsmnt.findIndex(el => el._id == val._id);
          if (idx > -1) {
            this.selectedSLAsmnt.splice(idx, 1);
          }
          this.isAllSLAsmntSelected = false;
        }
      } else {
        if (val.isSelected) {
          val.serial = i + 1;
          this.selectedCLAsmnt.push(val);
          if (this.monitorData.assessment.course_level.length == this.selectedCLAsmnt.length) {
            this.isAllCLAsmntSelected = true;
          }
        } else {
          let idx = this.selectedCLAsmnt.findIndex(el => el._id == val._id);
          if (idx > -1) {
            this.selectedCLAsmnt.splice(idx, 1);
          }
          this.isAllCLAsmntSelected = false;
        }

      }
    } else { }

  }

  generateReport() {
    this.loader = true;
    let monitoringObj: any = {};
    monitoringObj.course_id = this.courseId;
    if (this.selectedDD) {
      monitoringObj.topic = this.selectedDD;
      monitoringObj.offset = this.selectedIndex;
    } else {
      monitoringObj.session = this.getSessionsIds();
    }

    if (this.notesPersonalize) monitoringObj.notes = this.notesPersonalize;
    if (this.attendancePersonalize) monitoringObj.attendance = this.attendancePersonalize;
    if (this.cwTopicLevel || this.cwCourseLevel) {
      monitoringObj.coursework = this.getCWLevel();
    }
    if (this.assTopicLevel || this.assSessionLevel || this.assCourseLevel) {
      monitoringObj.assessment = this.getAssessmentLevel();
    }
    this.monitorSubscription = this.courseService.monitoringReport(monitoringObj).pipe(finalize(() => {
      this.loader = false;
    })).subscribe(data => {
      // this.monitorData = data.response;

    if(data.hasOwnProperty('response')){
        this.monitorData = data.response;
        this.isSubscriberLarge = false;

      }
      if(!data.hasOwnProperty('response')){
        this.isSubscriberLarge = true;
        this.monitorDataMessage = data.message;
      }      
      this.checkPersonalize();
      this.postGenerate = true;
      this.preGenerate = false;
    },
      (err) => {
        this.loader = false;
        this.ns.showNotification("error", err.message);
      });
  }


  checkPersonalize() {
    if (this.notesPersonalize) {
      this.notesMonitor = true;
      this.toggleVisibility('notes');
    }
    if (this.attendancePersonalize) {
      this.attendanceMonitor = true;
      this.toggleVisibility('attendance');
    }
    if (this.cwTopicLevel || this.cwCourseLevel) {
      this.courseworkMonitor = true;
      this.toggleVisibility('coursework');
    }
    if (this.assTopicLevel || this.assSessionLevel || this.assCourseLevel) {
      this.assessmentMonitor = true;
      this.toggleVisibility('assessment');
    }
  }
editMode:boolean = false;
  editReport() {
    this.editMode = true;
    if(this.selectedDD !== null){
      this.handleClick('1')
    } else if(this.selectedDD == null){
      this.handleClick('0')
    }
    this.preGenerate = true;
    this.postGenerate = false;
    this.session = true;
    this.personalize = false;
    this.summary = false;
    this.nextBtn1 = true;
    this.nextBtn2 = false;
    this.nextBtn3 = false;
    
    // this.activeids = "panel-session";
  }
  

  regenerateReport() {
    this.preGenerate = true;
    this.notesLoader = false;
    this.attendanceLoader = false;
    this.courseWorkLoader = false;
    this.assessmentLoader = false;
    this.postGenerate = false;
    this.session = true;
    this.personalize = false;
    this.summary = false;
    this.nextBtn1 = false;
    this.nextBtn2 = false;
    this.nextBtn3 = false;

    this.selectedAttributes = [];
    this.selectedNotes = [];
    this.selectedAttendance = [];
    this.isAllNotesSelect = false;
    this.isAllAttendanceSelect = false;
    this.isAllTopicCWSelected = false;
    this.isAllCWSelected = false;
    this.courseworkMonitor = false;
    this.notesMonitor = false;
    this.attendanceMonitor = false;
    this.assessmentMonitor = false;
    this.searchedSessions = [];
    this.selectedSessions = [];
    this.checkedSessions = [];
    this.notesPersonalize = false;
    this.attendancePersonalize = false;
    this.cwTopicLevel = false;
    this.cwCourseLevel = false;
    this.searchText = '';
    this.assTopicLevel = false;
    this.assSessionLevel = false;
    this.assCourseLevel = false;
    this.isAllTLAsmntSelected = false;
    this.isAllCLAsmntSelected = false;
    this.isAllSLAsmntSelected = false;
    this.selectedCourseWork = [];
    this.selectedTopicLevelCW = [];
    this.selectedCLAsmnt = [];
    this.selectedTLAsmnt = [];
    this.selectedSLAsmnt = [];
    this.activeids = "panel-session";
    this.statusCW = false;
    this.statusAsmnt = false;
    this.personalizeDisabled = false;
    this.summaryDisabled = false;
    this.sessionTopicWiseFlag = '0';
    this.selectedDD = null;
    this.sessionOfSelectedTopic = [];
  }

  /** Common Code end */
  goBack() {
    this.location.back();
  }

  getV(va?) {

    let difference_in_days = (this.bsRangeValue[1].getTime() - this.bsRangeValue[0].getTime()) / (1000 * 3600 * 24);

    if (difference_in_days > 7) {
      alert('Maximum 7 days allowed');
      return;
    } else {
      this.fromDate = this.pipe.transform(this.bsRangeValue[0], 'yyyy-MM-dd');
      this.toDate = this.pipe.transform(this.bsRangeValue[1], 'yyyy-MM-dd');
    }
  }

  next1() {
    this.session = false;
    this.personalize = true;
    this.nextBtn1 = false;
    this.nextBtn3 = false;
    if (this.notesPersonalize || this.attendancePersonalize || this.cwTopicLevel
      || this.cwCourseLevel || this.assTopicLevel || this.assCourseLevel || this.assSessionLevel) {  
    
      if (!this.nextBtn3) {
        this.nextBtn2 = true;
        this.nextBtn1 = false;
        this.nextBtn3 = false;
      }
    }else{
      this.nextBtn2 = false;
    }

  }
  next2() {
    this.session = false;
    this.personalize = false;
    this.summary = true;
    this.nextBtn1 = false;
    this.nextBtn2 = false;
    this.nextBtn3 = true;
  }

  export() {
    let selectedSessions = [], selectedAttendanceSessions = [], c_work: any = {};
    let course_assessment: any = {}, exportObj: any = {};

    if (this.attendanceMonitor) {
      selectedAttendanceSessions = this.selectedAttendance.map(data => data.session_id);
    }

    if (this.notesMonitor) {
      selectedSessions = this.selectedNotes.map(data => data.session_id);
    }

    if (this.courseworkMonitor) {
      if (this.selectedCourseWork) {
        c_work.course_level = this.selectedCourseWork.map(({ _id, schedule_id }) => ({ _id, schedule_id }));
      }
      if (this.selectedTopicLevelCW) {
        c_work.topic_level = this.selectedTopicLevelCW.map(({ _id, schedule_id }) => ({ _id, schedule_id }));
      }
    }

    if (this.assessmentMonitor) {
      if (this.selectedCLAsmnt) {
        course_assessment.course_level = this.selectedCLAsmnt.map(({ _id, schedule_id }) => ({ _id, schedule_id }));
      }
      if (this.selectedTLAsmnt) {
        course_assessment.topic_level = this.selectedTLAsmnt.map(({ _id, schedule_id }) => ({ _id, schedule_id }));
      }
      if (this.selectedSLAsmnt) {
        course_assessment.session_level = this.selectedSLAsmnt.map(({ _id, schedule_id }) => ({ _id, schedule_id }));
        course_assessment.session_info = this.selectedSLAsmnt.map(( session_id ) => (session_id.session_id));
      }
    }
    console.log(course_assessment);
    exportObj.course_id = this.courseId;
    exportObj.notes = selectedSessions;
    exportObj.attendance = selectedAttendanceSessions;
    exportObj.course_work = c_work;
    exportObj.assessment = course_assessment;
    this.loader = true;
    this.exportSubscription = this.courseService.exportReport(exportObj).pipe(finalize(() => {
      this.loader = false;
    })).subscribe(data => {

      
      let blob: any = new Blob([data.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      let d = new Date();
      let fullDate: any = this.appendZero(d.getDate()) + '-' + this.appendZero((d.getMonth() + 1)) + '-' + d.getFullYear() + '_' + this.appendZero(d.getHours()) + this.appendZero(d.getMinutes()) + this.appendZero(d.getSeconds());

      if (!this.courseName) {
        this.courseName = localStorage.getItem('courseName');
      }
      let fileName = this.courseName + '_' + fullDate + '.xlsx';
      fileSaver.saveAs(blob, fileName);

    },
      (err) => {
        console.log(err);
      });

  }

  appendZero(val) {
    if (val < 10) {
      return '0' + val;
    }
    return val;
  }
  redirect(sessionId, sessionTitle, subscriberId) {
    this.sessionId = sessionId;
    this.sessionTitle = sessionTitle;
    this.subscriberId = subscriberId;
    this.subscriberNotes = true;
  }

  ngOnDestroy() {
    if (this.sessionSubscription) this.sessionSubscription.unsubscribe();
    if (this.monitorSubscription) this.monitorSubscription.unsubscribe();
    if (this.exportSubscription) this.monitorSubscription.unsubscribe();
    localStorage.removeItem('courseName');
  }


  sendCourseDetails(id, course) {
    this.router.navigate(['/courses/single-course'], { queryParams: { course_id: id, course: course } });
  }

  //FOR BUILD FIX
  opened: boolean = false;

}
