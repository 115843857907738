import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { CourseService } from 'src/app/services/course.service';
import { QuestionBankService } from 'src/app/services/question-bank.service';
import { finalize } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionShareService } from 'src/app/services/question-share.service';
import { Subscriber, Subscription } from 'rxjs';
declare var tinymce: any;

import { HandleUploadService } from 'src/app/shared/modules/shared/services/handle-upload.service';
import { ResourceService } from 'src/app/services/resources/resource.service';

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.scss']
})
export class AddQuestionComponent implements OnInit {
  public addQuestionForm: FormGroup;

  submitAddQus: boolean = false
  loader: boolean = false;
  courses: any = [];
  qusTags: any = [];
  bigObj:any=[];
  blankTags: any = [];
  blankOptions: any = [];
  oldQusString = '';
  showSingleAnswerMcqFlag = false;
  showMultiAnswerMcqFlag = false;
  showBoolFlag = false;
  complexities = [];
  qusTypes = [];
  routerUrl:any
  multiOptions: FormArray;
  multiSubQus: FormArray;
  answerFlag: boolean = false;
  insertBlankFlag: boolean = false;
  qFlag: boolean = false;
  apiEndpoint
  fileArr: any = [];
  optionFlag: boolean = false;
  toolbarWithoutBlankButton = 'undo redo | formatselect | bold italic backcolor | \
  alignleft aligncenter alignright alignjustify | \
  bullist numlist outdent indent | removeformat | \
  link image | preview media help';
  toolbarWithBlankButton = 'undo redo | formatselect | bold italic backcolor | \
  customBlankButton | alignleft aligncenter alignright alignjustify | \
  bullist numlist outdent indent | removeformat | \
  link image | preview media help';
  ques_id: any = null;
  editQuesData: any;
  editQuesDataSubscription: Subscription;
  componentName: string;
  isShown: boolean = false;
  confirmMsg: any;
  qusTypesWithoutComprehension: any[];
  showComprehension: boolean = false;
  multiSubQusFlag: boolean = false;
  subQusmultiOptions: FormArray;
  multiSubQusOptions: FormArray;
  show: boolean = false;
  btnStat: any = 'maxi';
  subQIndx = 0;
  returnFlag: boolean = false;
  modelAnswerForTextQus: boolean = false;
  modelAnsErrFlag: boolean = false;
  units: any = [];
  editorId:number = 0;
  assessmentId: any = null;
  isQuesAudioVideo: boolean = false;
  quesTypeName: string = '';
  showAlertBox: boolean = false;
    //check upload status
    moveButtonDisabledAction:boolean = false

  constructor(
    private formBuilder: FormBuilder,
    private courseService: CourseService,
    private _questionBankService: QuestionBankService,
    private _notificationService: NotificationService,
    private route: ActivatedRoute,
    private _questionShareService: QuestionShareService,
    private router: Router,
    private handleUploadService: HandleUploadService,
    private resourceService: ResourceService) { }

  ngOnInit() {
    this.units = [{ "_id": "minutes", "name": "Minutes" }];
    this.getAllCourses();
    this.getAllTags();
    this.getAllQusTypes();
    this.getQusComplexity();
    this.validateForm();
    this.componentName = 'Add Question';
    this.apiEndpoint = environment.APIEndpoint;
    this.addQuestionForm.controls['durationUnit'].setValue(this.units[0]._id);
    //this.callMceEditor('textQus');
    let sp = this.router.url.split('?')
    this.routerUrl = sp[0];
  }


  minMax() {
    this.show = !this.show;
    if (this.show)
      this.btnStat = "mini";
    else
      this.btnStat = "maxi";
  }

  ngAfterViewInit() {
    this.callMceEditor('textQus');
    setTimeout(() => {
      this.getQuesDetails();
    }, 400)

  }

  getQuesDetails() {
    this.ques_id = this.route.snapshot.queryParamMap.get('ques_id');
    this.assessmentId = this.route.snapshot.queryParamMap.get('assessment_id');

    if (this.ques_id) {
      this.componentName = 'Edit Question';
      // this.editQuesData = this._questionShareService.getEditQues();
      this.courseService.viewQuestion(this.ques_id).pipe(finalize(() => this.loader=false)).subscribe(res => {
            // console.log(res.data)
            this.editQuesData = res.data;

            if (this.editQuesData) {
              this.addQuestionForm.controls['course'].setValue(this.editQuesData.course_id ? this.editQuesData.course_id : "");
              this.addQuestionForm.controls['type'].setValue(this.editQuesData.questype._id);
              this.addQuestionForm.controls['question'].setValue(this.editQuesData.question);
              tinymce.get("textQus").setContent(this.editQuesData.question);
              // this.addQuestionForm.controls['files'].setValue(this.editQuesData.files);
              // this.addQuestionForm.controls['meta_data'].setValue(this.editQuesData.meta_data);
              this.addQuestionForm.controls['tags'].setValue(this.editQuesData.tags);
              this.addQuestionForm.controls['duration'].setValue(this.editQuesData.duration);
              this.addQuestionForm.controls['durationUnit'].setValue(this.units[0]._id);
              this.addQuestionForm.controls['complexity'].setValue(this.editQuesData.complexity ? this.editQuesData.complexity._id : "");
              this.addQuestionForm.controls['marks'].setValue(this.editQuesData.marks);

              // FOR AUDIO VIDEO QUESTION
              this.addQuestionForm.controls['ans_by_recording'].setValue(this.editQuesData.ansbyrecording);
              this.addQuestionForm.controls['questionrecordedaudiokey'].setValue('');
              this.addQuestionForm.controls['questionrecordedvideokey'].setValue('');
              if(Object.keys(this.editQuesData.questionrecordedaudiokey).length > 0 || Object.keys(this.editQuesData.questionrecordedvideokey).length > 0) {
                let audioVideoQuesKeyName = '';
                if(Object.keys(this.editQuesData.questionrecordedaudiokey).length > 0) {
                  audioVideoQuesKeyName = 'questionrecordedaudiokey';
                }
                else if(Object.keys(this.editQuesData.questionrecordedvideokey).length > 0) {
                  audioVideoQuesKeyName = 'questionrecordedvideokey';
                }
                const quest_audio_video_resource = {};
                quest_audio_video_resource['title'] = this.editQuesData[audioVideoQuesKeyName].title;
                quest_audio_video_resource['resource_type'] = this.editQuesData[audioVideoQuesKeyName].file_type;
                quest_audio_video_resource['_id'] = '';
                quest_audio_video_resource['s3url'] = this.editQuesData[audioVideoQuesKeyName].url;
                quest_audio_video_resource['path'] = this.editQuesData[audioVideoQuesKeyName].path;
                this.editQuesData.quest_audio_video_resource = [];
                this.editQuesData.quest_audio_video_resource.push(quest_audio_video_resource);
                //FOR UPLOADED RESOURCES
                this.handleUploadService.sendSharedData({editData: this.editQuesData});
              }

              if (this.editQuesData.meta_data) {
                let sqt = this.selectQtype(this.editQuesData.questype._id, this.editQuesData.meta_data.length, 'editques')

                for (let i = 0; i < this.editQuesData.meta_data.length; i++) {
                  if (this.editQuesData.questype.type.toLowerCase() == 'Fill in the blanks'.toLowerCase()) {
                    this.addOptionsForFib(this.editQuesData.meta_data[i]);
                  }

                  this.editArrFib.push(this.editQuesData.meta_data[i]);
                  this.addQuestionForm.controls.meta_data['controls'][i].controls.option.setValue(this.editQuesData.meta_data[i].option);
                  this.addQuestionForm.controls.meta_data['controls'][i].controls.answer.setValue(this.editQuesData.meta_data[i].answer);
                  this.addQuestionForm.controls.meta_data['controls'][i].controls.answer_max_len.setValue(this.editQuesData.meta_data[i].answer_max_len);

                }
              }
              if (this.editQuesData.sub_qus) {
                let sqt = this.selectQtype(this.editQuesData.questype._id, 0, 'editques')
                let subqidx;
                for (let k = 0; k < this.editQuesData.sub_qus.length; k++) {
                  this.addCompQus();
                  this.addQuestionForm.controls.sub_qus['controls'][k].controls.question.setValue(this.editQuesData.sub_qus[k].question);
                  this.addQuestionForm.controls.sub_qus['controls'][k].controls['type'].setValue(this.editQuesData.sub_qus[k].type);
                  this.addQuestionForm.controls.sub_qus['controls'][k].controls.answer_counter.setValue(this.editQuesData.sub_qus[k].answer_counter);
                  this.selectSubQtype(this.editQuesData.sub_qus[k].type, this.editQuesData.sub_qus[k].meta_data.length, 'editsubques', k)
                  for (let p = 0; p < this.editQuesData.sub_qus[k].meta_data.length; p++) {
                    subqidx = this.qusTypesWithoutComprehension.findIndex(x => x._id == this.editQuesData.sub_qus[k].type);

                    if (this.qusTypesWithoutComprehension[subqidx].name.toLowerCase() == 'Fill in the blanks'.toLowerCase()) {
                      this.addSubQusOptionsForFIB(k, 'fib', this.editQuesData.sub_qus[k].meta_data[p])
                    }

                    this.addQuestionForm.controls.sub_qus['controls'][k].controls.meta_data['controls'][p].controls.option.setValue(this.editQuesData.sub_qus[k].meta_data[p].option);
                    this.addQuestionForm.controls.sub_qus['controls'][k].controls.meta_data['controls'][p].controls.answer.setValue(this.editQuesData.sub_qus[k].meta_data[p].answer);
                    this.addQuestionForm.controls.sub_qus['controls'][k].controls.meta_data['controls'][p].controls.answer_max_len.setValue(this.editQuesData.sub_qus[k].meta_data[p].answer_max_len);
                    this.addQuestionForm.controls.sub_qus['controls'][k].controls.meta_data['controls'][p].controls.flag_type.setValue(this.editQuesData.sub_qus[k].meta_data[p].flag_type);
                  }

                  // FOR AUDIO VIDEO SUB QUESTION
                  this.addQuestionForm.controls.sub_qus['controls'][k].controls['ans_by_recording'].setValue(this.editQuesData.sub_qus[k].ans_by_recording);
                  this.addQuestionForm.controls.sub_qus['controls'][k].controls['questionrecordedaudiokey'].setValue('');
                  this.addQuestionForm.controls.sub_qus['controls'][k].controls['questionrecordedvideokey'].setValue('');
                  if(Object.keys(this.editQuesData.sub_qus[k].questionrecordedaudiokey).length > 0 || Object.keys(this.editQuesData.sub_qus[k].questionrecordedvideokey).length > 0) {
                    let audioVideoQuesKeyName = '';
                    if(Object.keys(this.editQuesData.sub_qus[k].questionrecordedaudiokey).length > 0) {
                      audioVideoQuesKeyName = 'questionrecordedaudiokey';
                    }
                    else if(Object.keys(this.editQuesData.sub_qus[k].questionrecordedvideokey).length > 0) {
                      audioVideoQuesKeyName = 'questionrecordedvideokey';
                    }
                    const quest_audio_video_resource = {};
                    quest_audio_video_resource['title'] = this.editQuesData.sub_qus[k][audioVideoQuesKeyName].title;
                    quest_audio_video_resource['resource_type'] = this.editQuesData.sub_qus[k][audioVideoQuesKeyName].file_type;
                    quest_audio_video_resource['_id'] = '';
                    quest_audio_video_resource['s3url'] = this.editQuesData.sub_qus[k][audioVideoQuesKeyName].url;
                    quest_audio_video_resource['path'] = this.editQuesData.sub_qus[k][audioVideoQuesKeyName].path;
                    this.editQuesData.sub_qus[k].sub_quest_audio_video_res = [];
                    this.editQuesData.sub_qus[k].sub_quest_audio_video_res.push(quest_audio_video_resource);
                    //FOR UPLOADED RESOURCES
                    setTimeout(() => {
                      this.handleUploadService.sendSharedData({editData: this.editQuesData, isChildField: true, childFieldIndex: k});
                    }, 500);
                  }

                }

              }

              // this.addQuestionForm.value.files = this.editQuesData.files;

            }
          }
        );
    }
  }
  get addQus() { return this.addQuestionForm.controls; }

  addSubQus(i) {
    return this.addQuestionForm.controls.sub_qus['controls'][i]['controls'];
  }

  addSubQusOption(i, ii) {
    return this.addQuestionForm.controls.sub_qus['controls'][i]['controls'].meta_data['controls'][ii]['controls'];
  }

  validateForm(): void {
    this.addQuestionForm = this.formBuilder.group({
      course: ['', Validators.required],
      type: ['', Validators.required],
      question: ['', Validators.required],
      meta_data: this.formBuilder.array([]),
      sub_qus: this.formBuilder.array([]),
      tags: ['', Validators.required],
      duration: ['', Validators.required],
      durationUnit: [''],
      complexity: ['', Validators.required],
      marks: ['', Validators.required],
      quest_audio_video_resource: this.formBuilder.array([]),
      questionrecordedaudiokey: [''],
      questionrecordedvideokey: [''],
      ans_by_recording: [false],
    });

    //SHARE FORM WITH CHILD COMPONENT
    this.handleUploadService.addForm = this.addQuestionForm;
  }

  createOptions(qtype): FormGroup {

    this.submitAddQus = false;
    return this.formBuilder.group({
      option: [''],
      answer: [''],
      answer_max_len: 0,
      editorId: [this.editorId++]
    });
  }

  createOptionsForFib(data): FormGroup {
    this.submitAddQus = false;
    return this.formBuilder.group({
      option: [''],
      answer: data.answer,
      answer_max_len: data.answer_max_len
    });
  }

  addOptions(evt = ''): void {
    this.multiOptions = this.addQuestionForm.get('meta_data') as FormArray;
    this.multiOptions.push(this.createOptions(evt));
    let newValue = this.editorId;
    this.callMceEditor(this.multiOptions.at(this.multiOptions.length - 1).value.editorId);
  } 

  editArrFib = [];
  addOptionsForFib(data): void {
    this.multiOptions = this.addQuestionForm.get('meta_data') as FormArray;
    this.multiOptions.push(this.createOptionsForFib(data));

  }


  addSubQusOptions(idx, type): void {

    this.multiSubQusOptions = this.addQuestionForm.get('sub_qus')['controls'][idx].get('meta_data') as FormArray;

    this.multiSubQusOptions.push(this.createSubQusOptions(type));
    this.callMceEditor(type + idx + (this.multiSubQusOptions.length - 1));

  }

  addSubQusOptionsForFIB(idx, type, data): void {

    this.multiSubQusOptions = this.addQuestionForm.get('sub_qus')['controls'][idx].get('meta_data') as FormArray;

    this.multiSubQusOptions.push(this.createSubQusForFIB(type, data));

  }

  createSubQusOptions(type): FormGroup {
    return this.formBuilder.group({
      option: ['', Validators.required],
      answer: false,
      answer_max_len: 0,
      flag_type: type ? type : ''
    });
  }

  createSubQusForFIB(type, data): FormGroup {
    this.submitAddQus = false;
    return this.formBuilder.group({
      option: [''],
      answer: data.answer,
      answer_max_len: data.answer_max_len,
      flag_type: type ? type : ''
    });
  }



  removeOption(i: number) {

    if (this.multiOptions.length <= 4) {
      return
    } else {
      tinymce.editors[this.multiOptions.at(i).value.editorId].remove();
      this.multiOptions.removeAt(i);
    }

  }

  // removeSubQusOption(i: number) {

  //   if (this.multiSubQusOptions.length <= 4) {
  //     return
  //   } else {
  //     this.multiSubQusOptions.removeAt(i);
  //     tinymce.editors[i].remove();
  //   }

  // }
  removeSubQus(idx) {
    if (this.addQuestionForm.controls.sub_qus['controls'].length > 0) {
      let tinysubqusid: any = "textSubQus" + idx;
      let mdata: any = this.addQuestionForm.controls.sub_qus['controls'][idx].controls.meta_data.controls;
      if (mdata) {
        for (let i = 0; i < mdata.length; i++) {
          tinymce.remove('#' + mdata[i]['controls'].flag_type.value + idx.toString() + i.toString())
        }
        if (this.multiSubQusOptions) {
          while (this.multiSubQusOptions.length !== 0) {
            this.multiSubQusOptions.removeAt(0)
          }
        }

        // this.addQuestionForm.controls.sub_qus['controls'][idx].controls.meta_data.controls = []
      }
      tinymce.remove('#' + tinysubqusid)
      this.multiSubQus.removeAt(idx);
    }

  }

  initSubQus(): FormGroup {

    this.submitAddQus = false;
    return this.formBuilder.group({
      type: ['', Validators.required],
      question: ['', Validators.required],
      meta_data: this.formBuilder.array([]),
      answer_counter: null,
      sub_quest_audio_video_res: this.formBuilder.array([]),
      questionrecordedaudiokey: [''],
      questionrecordedvideokey: [''],
      ans_by_recording: [false],
    });
  }

  addCompQus() {
    this.insertBlankFlag = false;
    this.multiSubQus = <FormArray>this.addQuestionForm.get('sub_qus');
    this.multiSubQus.push(this.initSubQus());

    setTimeout(() => {
      this.callMceEditor('textSubQus' + (this.multiSubQus.length - 1));
    }, 200);
    this.multiSubQusFlag = true;
  }

  handleChange(idx) {

    for (let i = 0; i < this.addQuestionForm.controls.meta_data.value.length; i++) {
      this.addQuestionForm.controls.meta_data.value[i].answer = false;
    }
    this.addQuestionForm.controls.meta_data.value[idx].answer = true;
  }

  handleSubQusChange(indx, subindx) {

    let val: any = this.addQuestionForm.controls.sub_qus['controls'][indx]['controls'].meta_data.value;
    //this.addQuestionForm.controls.sub_qus['controls'][i].controls.meta_data['controls'][j].controls.option.setValue(tinymce.get(evt + i.toString() + j.toString()).getContent());
    for (let i = 0; i < val.length; i++) {
      // val[i].answer = false;
      this.addQuestionForm.controls.sub_qus['controls'][indx].controls.meta_data['controls'][i].controls.answer.setValue(false);
    }
    // val[subindx].answer = true;
    this.addQuestionForm.controls.sub_qus['controls'][indx].controls.meta_data['controls'][subindx].controls.answer.setValue(true);
    // console.log(this.addQuestionForm.controls.sub_qus['controls'][indx]['controls'].meta_data)
  }

  get optionControls() {
    return this.addQuestionForm.get('meta_data')['controls'];
  }

  get subQusControls() {
    return this.addQuestionForm.get('sub_qus')['controls'];
  }

  subQusMetaDataControls(opt) {
    // console.log(opt.controls.meta_data.controls);
    return opt.controls.meta_data.controls;
  }


  getAllCourses() {
    this.loader = true;
    this.courseService.categoryAPI().pipe(finalize(() => {
      this.loader = false;
    })).subscribe(data => {
      this.courses = data;
    });
  }

  getAllQusTypes() {
    this.courseService.fetchQusType().pipe(finalize(() => {

    })).subscribe(data => {
      this.qusTypes = data.ques_type;
    });
  }

  getQusComplexity() {
    this.courseService.qusComplexity().pipe(finalize(() => {

    })).subscribe(data => {
      this.complexities = data.complexity;
    });
  }

  getAllTags() {
    this._questionBankService.fetchTags().pipe(finalize(() => {
    })).subscribe(data => {
      this.qusTags = data.message;
    });
  }

  stripText(control) {
    control.setValue(control.value.replace(/[^0-9]/g, ''));
  }

  selectQtype(val, n, q_type) {

    let qidx;
    let evt;
    this.showComprehension = false;
    this.showSingleAnswerMcqFlag = false;
    this.showMultiAnswerMcqFlag = false;
    this.showBoolFlag = false;
    this.answerFlag = false;
    this.insertBlankFlag = false;
    this.multiSubQusFlag = false;
    this.qFlag = false;

    this.quesTypeName = '';
    if (this.qusTypes.length > 0 && val) {
      qidx = this.qusTypes.findIndex(x => x._id == val);
      evt = this.qusTypes[qidx].value;
      // console.log(evt);
      this.quesTypeName = evt;
    }

    if (this.multiOptions) {
      while (this.multiOptions.length !== 0) {
        this.multiOptions.removeAt(0)
      }
    }
    if (this.multiSubQus) {
      while (this.multiSubQus.length !== 0) {
        this.multiSubQus.removeAt(0)
      }
    }
    tinymce.remove();
    this.callMceEditor('textQus');
    this.modelAnswerForTextQus = false;
    if (evt == 'singleanswermcq' || evt == 'multianswermcq') {
      if (evt == 'singleanswermcq') {
        this.showSingleAnswerMcqFlag = true;
      } else {
        this.showMultiAnswerMcqFlag = true;
      }

      if (q_type == 'editques') {
        for (let i = 0; i < n; i++) {
          this.addOptions(evt);
        }
      } else {
        for (let i = 0; i < 4; i++) {
          // this.editorId++;
          this.addOptions(evt);
        }
      }


    } else if (evt == 'bool') {
      this.showBoolFlag = true;
      for (let i = 0; i < 2; i++) {
        this.addOptions(evt);
      }
    } else if (evt == 'comprehension') {
      this.qusTypesWithoutComprehension = this.qusTypes.filter(x => x._id != val);
      this.showComprehension = true;
      // console.log(this.qusTypesWithoutComprehension);
    } else if (evt == 'fib') {
      this.insertBlankFlag = true;
    }
    else if(evt == 'text' || evt == 'speech') {
      // this.callMceEditor('modelAnstextQus');
      this.addOptions(evt);
      this.insertBlankFlag = false;
      this.showComprehension = false;
      this.showSingleAnswerMcqFlag = false;
      this.showMultiAnswerMcqFlag = false;
      this.showBoolFlag = false;
      if(evt === 'text') {
        this.modelAnswerForTextQus = true;
      }
      else {
        this.modelAnswerForTextQus = false;
      }

      this.callMceEditor(0);
    }
    // return;

    // For audio video based question
    const audioVideoQuesTypes = ['text', 'speech', 'multianswermcq', 'singleanswermcq'];
    if(audioVideoQuesTypes.indexOf(evt) != -1) {
      this.isQuesAudioVideo = true;
    }
    else {
      this.isQuesAudioVideo = false;
    }

    if(evt == 'speech') {
      if(q_type !== 'editques') {
        this.addQuestionForm.get('ans_by_recording').setValue(true);
      }
    }
    else {
      if(q_type !== 'editques') {
        this.addQuestionForm.get('ans_by_recording').setValue(false);
      }
    }
  }

  selectSubQtype(val, n, subQ_type, subQ_idx) {
    this.subQIndx = subQ_idx;
    let subqusidx;
    let evt;
    this.insertBlankFlag = false;

    let flagType: any = '';
    if (this.qusTypesWithoutComprehension.length > 0) {
      subqusidx = this.qusTypesWithoutComprehension.findIndex(x => x._id == val);
      evt = this.qusTypesWithoutComprehension[subqusidx].value;

    }

    if (this.addQuestionForm.controls.sub_qus['controls'][subQ_idx].controls.meta_data.controls.length > 0) {
      let m_data: any = this.addQuestionForm.controls.sub_qus['controls'][subQ_idx].controls.meta_data.controls;


      for (let i = 0; i < m_data.length; i++) {
        tinymce.remove('#' + m_data[i]['controls'].flag_type.value + subQ_idx.toString() + i.toString())
      }
      while (this.multiSubQusOptions.length !== 0) {
        this.multiSubQusOptions.removeAt(0)
      }

    }

    setTimeout(() => {
      this.callMceEditor('textSubQus' + subQ_idx);
    }, 200);

    if (evt == 'singleanswermcq' || evt == 'multianswermcq') {
      if (evt == 'singleanswermcq') {
        flagType = 'singleanswermcq';
      } else {
        flagType = 'multianswermcq';
      }

      if (subQ_type == 'editsubques') {
        for (let i = 0; i < n; i++) {
          this.addSubQusOptions(subQ_idx, flagType);
        }
      } else {
        for (let i = 0; i < 4; i++) {
          this.addSubQusOptions(subQ_idx, flagType);
        }
      }


    } else if (evt == 'bool') {
      flagType = 'bool';
      for (let i = 0; i < 2; i++) {
        this.addSubQusOptions(subQ_idx, flagType);
      }
    } else if (evt == 'fib') {
      flagType = 'fib';
      this.insertBlankFlag = true;
      tinymce.remove('#textSubQus' + subQ_idx);
      this.callMceEditor('textSubQus' + subQ_idx);
    }
    else if (evt == 'text' || evt == 'speech') {

    } else {
      this.insertBlankFlag = false;
    }

    // For audio video based sub question
    const audioVideoQuesTypes = ['text', 'speech'];
    if(evt == 'speech') {
      if(subQ_type !== 'editsubques') {
        this.addQuestionForm.controls.sub_qus['controls'][subQ_idx].get('ans_by_recording').setValue(true);
      }
    }
    else {
      if(subQ_type !== 'editsubques') {
        this.addQuestionForm.controls.sub_qus['controls'][subQ_idx].get('ans_by_recording').setValue(false);
      }
    }

  }

  /**
   * TinyMCE Editor
   */

  /** End */


  callMceEditor(edId) {
    let that = this;
    let dialogConfig = {
      title: 'Fill in the blank ',
      body: {
        type: 'panel',
        items: [
          {
            type: 'input',
            name: 'charSpace',
            label: 'Enter max character for answer'
          },
          {
            type: 'input',
            name: 'fibAnswer',
            label: 'Enter answer'
          }
        ]
      },
      buttons: [
        {
          type: 'cancel',
          name: 'closeButton',
          text: 'Cancel'
        },
        {
          type: 'submit',
          name: 'submitButton',
          text: 'Okay',
          primary: true
        }
      ],
      initialData: {
        charSpace: '',
        fibAnswer: ''
      },
      onSubmit: function (api) {
        let data = api.getData();
        let blankSpaceLen = data.charSpace.trim();
        let blankSpaceAns = data.fibAnswer.trim();
        if (isNaN(blankSpaceLen)) {
          that._notificationService.showNotification('info', 'Please re-check the character length');
          return;
        }
        if (blankSpaceAns.length != Number(blankSpaceLen)) {
          that._notificationService.showNotification('info', 'Please re-check the character length, Length should be equal to the number of character');
          return;
        }

        let metaobj: any = {};
        metaobj.answer = blankSpaceAns;
        metaobj.answer_max_len = blankSpaceLen;
        that.bigObj.push(metaobj)
        if (blankSpaceLen && blankSpaceAns) {
          if (that.addQuestionForm.value.sub_qus.length == 0) {
            that.addOptionsForFib(metaobj);

            // var pet = data.isdog ? 'dog' : 'cat';

            tinymce.activeEditor.execCommand('mceInsertContent', false, '<strong>' + '___' + '___' + '___</strong> ');
            api.close();
          } else {
            let activeindx = tinymce.activeEditor.id.slice(10);
            //  console.log(activeindx);
            that.addSubQusOptionsForFIB(activeindx, 'fib', metaobj);
            tinymce.activeEditor.execCommand('mceInsertContent', false, '<strong>' + '___' + '___' + '___</strong> ');
            api.close();
          }

        } else {
          that._notificationService.showNotification("error", "Please Enter Some answer with length for fill in the blank");
          return;
        }


      }
    };
    setTimeout(() => {
      tinymce.baseURL = 'assets';
      tinymce.init({
        selector: 'textarea#' + edId,
        height: 300,
        // base_url: '/tinymce',
        suffix: '.min',
        themes: "assets/themes/silver",
        // icons: "assets/icons/",
        // content_css: '//www.tiny.cloud/css/codepen.min.css',

        plugins: [
          'advlist autolink lists link image imagetools charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: that.insertBlankFlag ? that.toolbarWithBlankButton : that.toolbarWithoutBlankButton,
        image_title: true,
        automatic_uploads: true,
        image_description: false,
        file_picker_types: 'file image media',
        // external_plugins: { tiny_mce_wiris: "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js" }, 
        // toolbar1: "tiny_mce_wiris_formulaEditor, tiny_mce_wiris_formulaEditorChemistry",
        file_picker_callback: function (cb, value, meta) {

          let input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/* audio/* video/*');

          // Note: In modern browsers input[type="file"] is functional without 
          // even adding it to the DOM, but that might not be the case in some older
          // or quirky browsers like IE, so you might want to add it to the DOM
          // just in case, and visually hide it. And do not forget do remove it
          // once you do not need it anymore.

          input.onchange = function () {

            // var file = $event.path[0].files[0];
            let file = input.files[0];

            let reader: any = new FileReader();

            reader.onload = function () {

              // Note: Now we need to register the blob in TinyMCEs image blob
              // registry. In the next release this part hopefully won't be
              // necessary, as we are looking to handle it internally.
              let id = 'blobid' + (new Date()).getTime();
              let blobCache = tinymce.activeEditor.editorUpload.blobCache;
              let base64 = reader.result.split(',')[1];
              let blobInfo = blobCache.create(id, file, base64);

              blobCache.add(blobInfo);

              // call the callback and populate the Title field with the file name

              cb(reader.result, { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
        images_upload_handler: function (blobInfo, success, failure, progress) {
          let xhr, formData;

          xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          xhr.open('POST', that.apiEndpoint + '/upload_files_add_question');
          xhr.setRequestHeader('session-token', localStorage.getItem('token')); // manually set header

          xhr.upload.onprogress = function (e) {
            progress(e.loaded / e.total * 100);
          };

          xhr.onerror = function () {
            failure("Image upload failed due to a XHR Transport error. Code: " + xhr.status);
          };

          xhr.onload = function () {
            let json;

            if (xhr.status != 200) {
              failure('HTTP Error: ' + xhr.status);
              return;
            }
            json = JSON.parse(xhr.responseText);

            if (!json || typeof json.location != 'string') {
              failure('Invalid JSON: ' + xhr.responseText);
              return;
            }

            success(json.location);
            let fileObj: any = {};
            fileObj._id = json.file_id,
              fileObj.type = json.file_type
            that.fileArr.push(fileObj);
          };

          formData = new FormData();
          formData.append('file_path', blobInfo.blob(), blobInfo.filename());

          xhr.send(formData);
        },
        setup: function (editor) {
          // if (edId == 'fibtype') {

          if (that.insertBlankFlag) {
            editor.ui.registry.addButton('customBlankButton', {
              text: 'Add Blank',
              onAction: function () {
                //editor.windowManager.open(dialogConfig);

                if(that.editArrFib){
                  var api=editor.windowManager.open(dialogConfig);
                  var data=api.getData();
        
                  // do it here 
                  if (typeof that.editArrFib[0].answer_max_len === "number"){
                    data.charSpace = that.editArrFib[0].answer_max_len.toString()
                  }else{
                   data.charSpace = that.editArrFib[0].answer_max_len
                  }
                  // console.log(typeof that.editArrFib[0].answer_max_len)
                  // data.charSpace = that.editArrFib[0].answer_max_len
                   data.fibAnswer = that.editArrFib[0].answer
                   api.setData(data);
                   
                }else{
                editor.windowManager.open(dialogConfig);

                }
                
              }
            });
          }
        }
      });

    }, 100)
  }

  formSubmit() {
    this.submitAddQus = true;
    this.qFlag = false;
    this.optionFlag = false;
    this.returnFlag = false;
    this.modelAnsErrFlag = false;
    let description = this._notificationService.titleCase(tinymce.get("textQus").getContent());
    description = this._notificationService.paraString(description);
    this.addQuestionForm.patchValue({
      question: description
    });

    //SEND FORM SUBMIT STATUS TO CHILD COMPONENT
    this.handleUploadService.sendSubmitFormStatus({status: true, formSubmitStatus: this.submitAddQus});

    // For model answor validation check
    if (this.addQuestionForm.value.sub_qus.length == 0) {
      let editorData = tinymce.get();
      if (this.modelAnswerForTextQus) {
        this.addQuestionForm.value.meta_data[0].answer = editorData[1].getContent();
        if (this.addQuestionForm.value.meta_data[0].answer == '') {
          if(this.quesTypeName == 'text') {
            this.modelAnsErrFlag = true;
          }
        }
      }

      //FOR SPEECH BASED QUESTION
      if(this.quesTypeName === 'speech') {
        this.addQuestionForm.value.meta_data[0].answer = editorData[1].getContent();
      }
    }

    /**
     * Validation for Question
     */

    if (this.addQuestionForm.value.question == "") {
      this.qFlag = true;
      return;
    }

    if (this.showComprehension) {
      if (this.addQuestionForm.value.sub_qus.length == 0) {
        this._notificationService.showNotification("error", "Please add some Sub Questions.");
        return;
      }
    }

    /**
     * Set value for Comprehension SubQus and Options
     */

    if (this.multiSubQus) {
      // console.log(tinymce.get())

      let n: any = this.addQuestionForm.controls.sub_qus['controls'];
      for (let i = 0; i < n.length; i++) {

        //FOR COMPREHENSION SUB QUESTION
        if(this.quesTypeName === 'comprehension') {
          const editorContent = tinymce.get("textSubQus" + i).getContent().trim();
          if(!editorContent) {
            this.addQuestionForm['controls'].sub_qus['controls'][i].controls.question.setValue('');
            this._notificationService.showNotification("error", "Please correct the data");
            return;
          }
        }

        if (tinymce.get("textSubQus" + i).getContent()) {
          this.addQuestionForm['controls'].sub_qus['controls'][i].controls.question.setValue(tinymce.get("textSubQus" + i).getContent())
          let val = '';
          let subqusidx = null;
          let evt = '';
          val = this.addQuestionForm.controls.sub_qus['controls'][i].value.type;
          subqusidx = this.qusTypesWithoutComprehension.findIndex(x => x._id == val);
          evt = this.qusTypesWithoutComprehension[subqusidx].value;

          for (let j = 0; j < n[i].controls.meta_data.controls.length; j++) {

            if (this.addQuestionForm.controls.sub_qus['controls'][i].value.meta_data.length > 0) {
              if (tinymce.get(evt + i.toString() + j.toString())) {
                // this.addQuestionForm.controls.sub_qus['controls'][i].value.meta_data[j].option = tinymce.get(evt + i + j.toString()).getContent();
                this.addQuestionForm.controls.sub_qus['controls'][i].controls.meta_data['controls'][j].controls.option.setValue(tinymce.get(evt + i.toString() + j.toString()).getContent());
              }

            }

          }
        }

      }
    }

    /**
     * Set value for MCQ/BINARY Options
     */
    if (this.addQuestionForm.value.sub_qus.length == 0) {
      let editorData = tinymce.get();
      if (this.modelAnswerForTextQus) {
        this.addQuestionForm.value.meta_data[0].answer = editorData[1].getContent();
        if (this.addQuestionForm.value.meta_data[0].answer == '') {
          if(this.quesTypeName == 'text') {
            this.modelAnsErrFlag = true;
          }
          return;
        }
      } else {
        for (let i = 1; i < editorData.length; i++) {
          this.addQuestionForm.value.meta_data[i - 1].option = editorData[i].getContent();
        }
      }

    }


    let count = 0;
    // console.log(this.addQuestionForm.value);
    if (this.addQuestionForm.invalid) {
      this._notificationService.showNotification("error", "Please correct the data");
      return;
    }

    // VALIDATE EXTRA FIELDS FOR TEXT AND SPEECH QUESTION
    if(this.quesTypeName === 'speech') {
      if(this.addQuestionForm.value.quest_audio_video_resource.length === 0) {
        this._notificationService.showNotification("error", "Please correct the data");
        return;
      }
    }

    // VALIDATE EXTRA FIELDS FOR COMPREHENSION TEXT AND SPEECH QUESTION
    if(this.quesTypeName === 'comprehension') {
      const subQuesLen = this.addQuestionForm.value.sub_qus.length;
      for(let i = 0; i < subQuesLen; i++) {
        if(this.addQuestionForm.value.sub_qus[i].type === '66a83bb69311e8e34f19f53a') {
          if(this.addQuestionForm.value.sub_qus[i].sub_quest_audio_video_res.length === 0) {
            this._notificationService.showNotification("error", "Please correct the data");
            return;
          }
        }
      }
    }

    /**
     *  code for minimum one select answer for binary
     */
    if (this.showBoolFlag || this.showSingleAnswerMcqFlag || this.showMultiAnswerMcqFlag) {
      if (this.addQuestionForm.value.meta_data.findIndex(x => x.answer == true) !== -1) {
        count++;
      }

      if (count > 0) this.answerFlag = false;
      else {
        this.answerFlag = true;
        return;
      }

      if (this.addQuestionForm.value.meta_data.findIndex(data => data.option == "") !== -1) {
        this.optionFlag = true;
        return;
      } else {
        this.optionFlag = false;
      }
      // console.log(optionCounter)
    }

    //Validation for options
    if(this.quesTypeName === 'multianswermcq') {
      const correctAns = this.addQuestionForm.value.meta_data.filter((item) => item.answer === true);
      if(correctAns && correctAns.length < 2) {
        this._notificationService.showNotification("error", "Two answers must be True.");
        return;
      }
    }

    /** Minimum select answer for binary/mcq types comprehension sub qus */

    // Validation for sub question binary/mcq options
    if (this.multiSubQus) {
      const subQuesTotal = this.addQuestionForm.controls.sub_qus['controls'].length;
      for (let i = 0; i < subQuesTotal; i++) {
        const subQuesCorrectAns = this.addQuestionForm.controls.sub_qus['controls'][i].value.meta_data.filter((item) => item.answer === true);
        console.log(this.addQuestionForm.controls.sub_qus['controls'][i]);
        if(this.addQuestionForm.controls.sub_qus['controls'][i].controls.meta_data['controls'][0].controls.flag_type.value == 'multianswermcq') {
          if(subQuesCorrectAns && subQuesCorrectAns.length < 2) {
            this.addQuestionForm.controls.sub_qus['controls'][i].controls.answer_counter.setValue(0);
            this._notificationService.showNotification("error", "Two answers must be True.");
            return;
          }
          else {
            this.addQuestionForm.controls.sub_qus['controls'][i].controls.answer_counter.setValue(1);
          }
        }
        else if(this.addQuestionForm.controls.sub_qus['controls'][i].controls.meta_data['controls'][0].controls.flag_type.value == 'singleanswermcq'
          || this.addQuestionForm.controls.sub_qus['controls'][i].controls.meta_data['controls'][0].controls.flag_type.value == 'bool') {
          if(subQuesCorrectAns && subQuesCorrectAns.length < 1) {
            this.addQuestionForm.controls.sub_qus['controls'][i].controls.answer_counter.setValue(0);
            this._notificationService.showNotification("error", "One answer must be True.");
            return;
          }
          else {
            this.addQuestionForm.controls.sub_qus['controls'][i].controls.answer_counter.setValue(1);
          }
        }
      }
    }




    /** Validation for fill in the blanks questions */

    if (this.insertBlankFlag) {
      if (this.addQuestionForm.value.sub_qus.length == 0) {
        if (this.addQuestionForm.value.meta_data.length == 0) {
          this._notificationService.showNotification("error", "Please insert some Fill in the blanks answer.");
          return;
        }
      } else {
        this.addQuestionForm.value.sub_qus.forEach(element => {
          if (element.meta_data.length == 0) {
            this._notificationService.showNotification("error", "Please insert some Fill in the blanks answer.");
            this.returnFlag = true;

          }
        });


      }
      if (this.returnFlag) {
        return;
      }
    }

    /**
     * end code for minimum one select answer
     */

    // console.log(this.addQuestionForm.value);

    // this.loader = true;

    if (this.editQuesData) {
      let msg: any = '';
      if (this.editQuesData.in_assessment) {
        msg = 'This question is already in use in an assessment so it cannot be overwritten. A new version with the changes will be saved. Are you sure you want to proceed?';
        this.toggleConfirmBox(msg);
      } else {
        msg = 'This question will be overwritten. Are you sure you want to proceed?';
        this.toggleConfirmBox(msg);
      }
    } else {
      let addQusObj: any = {}
      // console.log(this.addQuestionForm.value);
      if (this.addQuestionForm.value.sub_qus.length == 0) {
        const lookup = this.addQuestionForm.value.meta_data.reduce((a, e) => {
          a[e.option] = ++a[e.option] || 0;
          return a;
        }, {});

        const unique = this.addQuestionForm.value.meta_data.filter(e => lookup[e.option]);

        if (unique.length > 0) {
          this._notificationService.showNotification("error", "Options could not be same");
          return;
        }
        addQusObj.complexity = this.addQuestionForm.value.complexity
        addQusObj.course = this.addQuestionForm.value.course
        // addQusObj.duration = this.addQuestionForm.value.durationUnit == 'seconds' ? (this.addQuestionForm.value.duration / 60).toFixed(2) : this.addQuestionForm.value.duration

        addQusObj.duration =  this.addQuestionForm.value.duration

        addQusObj.question = this.addQuestionForm.value.question
        addQusObj.meta_data = this.addQuestionForm.value.meta_data
        addQusObj.tags = this.addQuestionForm.value.tags
        addQusObj.type = this.addQuestionForm.value.type
        addQusObj.marks = this.addQuestionForm.value.marks

      } else {
        let returnQusFlag = false;
        this.addQuestionForm.value.sub_qus.forEach(element => {
          const lookup = element.meta_data.reduce((a, e) => {
            a[e.option] = ++a[e.option] || 0;
            return a;
          }, {});

          const unique = element.meta_data.filter(e => lookup[e.option]);

          if (unique.length > 0) {
            returnQusFlag = true;
          }
        });
        if (returnQusFlag) {
          this._notificationService.showNotification("error", "Options could not be same");
          return;
        }

        addQusObj.complexity = this.addQuestionForm.value.complexity
        addQusObj.course = this.addQuestionForm.value.course
        // addQusObj.duration = this.addQuestionForm.value.durationUnit == 'seconds' ? (this.addQuestionForm.value.duration / 60).toFixed(2) : this.addQuestionForm.value.duration
       
        // AS PER CURRENT REQUIRMENT WE REMOVED SECONDS
        addQusObj.duration =  this.addQuestionForm.value.duration
        
        addQusObj.question = this.addQuestionForm.value.question

        if(this.quesTypeName !== 'comprehension') {
           addQusObj.sub_qus = this.addQuestionForm.value.sub_qus
        }

        addQusObj.tags = this.addQuestionForm.value.tags
        addQusObj.type = this.addQuestionForm.value.type
        addQusObj.marks = this.addQuestionForm.value.marks
      }

      //FOR SET QUESTION AUDIO VIDEO ID IN POST DATA
      addQusObj = this.addQuesAudioVideoData(addQusObj);
      addQusObj = this.addSubQuesAudioVideoData(addQusObj);

      this._questionBankService.addQues(addQusObj).pipe(
        finalize(() => {
          this.loader = false;
          this.submitAddQus = false;
        })).subscribe(
          (response) => {
            //REMOVE DELETED RESOURCES
            this.removeResources('add');

            this.reset();
            this.router.navigate(['/question-bank']);
            this._notificationService.showNotification("success", 'Question saved successfully');
          },
          (error) => {
            this._notificationService.showNotification("error", error.message);
          })
    }
  }

  toggleConfirmBox(msg) {
    this.isShown = true;
    this.confirmMsg = msg;
  }
  handleNo() {
    this.isShown = false;
    this.loader = false;
  }
  handleYes() {
    this.isShown = false;
    let editQusObj: any = {};
    // let obj: any = {};
    // obj = this.addQuestionForm.value;
    // obj._id = this.ques_id;
    // obj.in_import_table = this.editQuesData.in_import_table;
    if (this.addQuestionForm.value.sub_qus.length == 0) {

      editQusObj.complexity = this.addQuestionForm.value.complexity
      editQusObj.course = this.addQuestionForm.value.course
      // editQusObj.duration = this.addQuestionForm.value.durationUnit == 'seconds' ? (this.addQuestionForm.value.duration / 60).toFixed(2) : this.addQuestionForm.value.duration

       // AS PER CURRENT REQUIRMENT WE REMOVED SECONDS
      editQusObj.duration =  this.addQuestionForm.value.duration
      editQusObj.question = this.addQuestionForm.value.question

      // AS PER CURRENT REQUIRMENT WE REMOVED SECONDS

      //editQusObj.meta_data = this.addQuestionForm.value.meta_data
      
      if(this.bigObj.length == 1){
      this.bigObj[0].option = '';
      editQusObj.meta_data = this.bigObj
      
      }else{
        editQusObj.meta_data = this.addQuestionForm.value.meta_data
     
      }
      editQusObj.tags = this.addQuestionForm.value.tags
      editQusObj.type = this.addQuestionForm.value.type
      editQusObj._id = this.ques_id;
      editQusObj.marks = this.addQuestionForm.value.marks
      editQusObj.in_import_table = this.editQuesData.in_import_table;
    } else {
      editQusObj.complexity = this.addQuestionForm.value.complexity
      editQusObj.course = this.addQuestionForm.value.course
      // editQusObj.duration = this.addQuestionForm.value.durationUnit == 'seconds' ? (this.addQuestionForm.value.duration / 60).toFixed(2) : this.addQuestionForm.value.duration

       // AS PER CURRENT REQUIRMENT WE REMOVED SECONDS
      editQusObj.duration =  this.addQuestionForm.value.duration

      editQusObj.question = this.addQuestionForm.value.question
      editQusObj.sub_qus = this.addQuestionForm.value.sub_qus
      editQusObj.tags = this.addQuestionForm.value.tags
      editQusObj.type = this.addQuestionForm.value.type
      editQusObj._id = this.ques_id;
      editQusObj.marks = this.addQuestionForm.value.marks
      editQusObj.in_import_table = this.editQuesData.in_import_table;
    }

    //FOR SET QUESTION AUDIO VIDEO ID IN POST DATA
    editQusObj = this.addQuesAudioVideoData(editQusObj);
    editQusObj = this.addSubQuesAudioVideoData(editQusObj);

    //FOR ADD NEW QUESTION IF IN ASSESSMENT
    editQusObj.in_assessment = this.editQuesData.in_assessment;

    // console.log(editQusObj);
    this._questionBankService.editQues(editQusObj).pipe(
      finalize(() => {
        this.loader = false;
        this.submitAddQus = false;
      })).subscribe(
        (response) => {
          //REMOVE DELETED RESOURCES
          this.removeResources('edit');

          this.reset();

          //FOR EDIT ASSESSMENT PAGE REFERENCE
          this.redirectBackToPage('quesSaved');

          if(this.editQuesData.in_assessment == true) {
            this._notificationService.showNotification("success", 'Question saved successfully');
          }
          else {
            this._notificationService.showNotification("success", 'Question updated successfully');
          }
        },
        (error) => {
          this._notificationService.showNotification("error", "Unable to update question please try again later");
        })
  }

  addQuesAudioVideoData(addEditQusObj) {
    addEditQusObj.questionrecordedaudiokey = '';
    addEditQusObj.questionrecordedvideokey = '';
    addEditQusObj.ans_by_recording = '';
    if(this.quesTypeName === 'text' || this.quesTypeName === 'speech' || this.quesTypeName === 'multianswermcq' || this.quesTypeName === 'singleanswermcq') {
      addEditQusObj.ans_by_recording = this.addQuestionForm.value.ans_by_recording;

      if(this.addQuestionForm.value.quest_audio_video_resource.length > 0) {
        const fileData = this.addQuestionForm.value.quest_audio_video_resource;
        const fileId = fileData[0]._id;
        const fileUrl = fileData[0].url;
        const filePath = fileData[0].path;
        if(fileUrl && fileUrl.indexOf('/question-videos/') != -1) {
          addEditQusObj.questionrecordedvideokey = filePath;
        }
        else {
          addEditQusObj.questionrecordedaudiokey = filePath;
        }
      }
    }

    return addEditQusObj;
  }

  //FOR COMPREHENSION QUES SET SUBQUES AUDIO VIDEO FIELDS
  addSubQuesAudioVideoData(addEditQusObj) {
    if(this.quesTypeName === 'comprehension') {
      let subQuestions = [];

      const subQuesLength = this.addQuestionForm.value.sub_qus.length;
      for(let i = 0; i < subQuesLength; i++) {
        subQuestions[i] = {type: '', question: '', meta_data: [], answer_counter: null, questionrecordedaudiokey: '', questionrecordedvideokey: '', ans_by_recording: false};
        subQuestions[i].type = this.addQuestionForm.value.sub_qus[i].type;
        subQuestions[i].question = this.addQuestionForm.value.sub_qus[i].question;
        subQuestions[i].meta_data = this.addQuestionForm.value.sub_qus[i].meta_data;
        subQuestions[i].answer_counter = this.addQuestionForm.value.sub_qus[i].answer_counter;

        subQuestions[i].questionrecordedaudiokey = '';
        subQuestions[i].questionrecordedvideokey = '';
        subQuestions[i].ans_by_recording = this.addQuestionForm.value.sub_qus[i].ans_by_recording;

        if(this.addQuestionForm.value.sub_qus[i].type === '5ee4e014daae271b48a114fe' || this.addQuestionForm.value.sub_qus[i].type === '66a83bb69311e8e34f19f53a'
          || this.addQuestionForm.value.sub_qus[i].type === '5ee5c08cdaae271b48a11504'
          || this.addQuestionForm.value.sub_qus[i].type === '5ee5c08cdaae271b48a11505') {
          if(this.addQuestionForm.value.sub_qus[i].sub_quest_audio_video_res.length > 0) {
            const fileData = this.addQuestionForm.value.sub_qus[i].sub_quest_audio_video_res;
            const fileId = fileData[0]._id;
            const fileUrl = fileData[0].url;
            const filePath = fileData[0].path;
            if(fileUrl && fileUrl.indexOf('/question-videos/') != -1) {
              subQuestions[i].questionrecordedvideokey = filePath;
            }
            else {
              subQuestions[i].questionrecordedaudiokey = filePath;
            }
          }
        }
      }

      addEditQusObj.sub_qus = subQuestions;
    }

    return addEditQusObj;
  }

  reset(): void {
    this.addQuestionForm.reset();
    this.multiOptions = null;
    this.showSingleAnswerMcqFlag = false;
    this.showMultiAnswerMcqFlag = false;
    this.showBoolFlag = false;
    this.answerFlag = false;
    this.optionFlag = false;
    this.insertBlankFlag = false;
    this.showComprehension = false;
    this.fileArr = [];
    this.multiSubQus = null;
    this.multiSubQusOptions = null;
    this.addQuestionForm.controls.meta_data = this.formBuilder.array([]),
    this.addQuestionForm.value.meta_data = [];
    this.addQuestionForm.controls.sub_qus = this.formBuilder.array([]),
    this.addQuestionForm.value.sub_qus = [];

    for (let i = 0; i < tinymce.get().length; i++) {
      tinymce.get(tinymce.get()[i].id).setContent('');
    }
    tinymce.remove();

    this.clearData();
  }

  goBack(action = '') {
    this.removeResources('discard');
    this.reset();
  }

  //TO REMOVE RESOURCES
  removeResources(actionRef = '') {
    let removeFileList: any[] = [];
    if (actionRef == 'add' || actionRef == 'edit') {
      removeFileList = this.handleUploadService.allDeletedFiles;
    }
    else {
      removeFileList = this.handleUploadService.allUploadedFiles;
    }

    if (removeFileList.length > 0) {
      this.loader = true;
      this.resourceService.removeResource('', removeFileList).subscribe(res => {
        this.loader = false;
        if (actionRef == 'discard') {
          this.redirectBackToPage();
        }
        this.handleUploadService.allUploadedFiles = [];
        this.handleUploadService.allDeletedFiles = [];
      }, err => {
        this.loader = false;
      });
    }
    else {
      if (actionRef == 'discard') {
        this.redirectBackToPage();
      }
    }
  }

  redirectBackToPage(action = '') {
    //FOR EDIT ASSESSMENT PAGE REFERENCE
    if(this.assessmentId) {
      if(action == 'quesSaved') {
        this.router.navigate(['/edit-assessment'], { queryParams: { assessment_id: this.assessmentId, ques_id: this.ques_id} });
      }
      else {
        this.router.navigate(['/edit-assessment'], { queryParams: { assessment_id: this.assessmentId} });
      }
    }
    else {
      this.router.navigate(['/question-bank']);
    }
  }

  //FOR CLEAR SERVICE DATA |END
  clearData() {
    this.submitAddQus = false;
    this.handleUploadService.addForm = null;
    this.handleUploadService.allUploadedFiles = [];
    this.handleUploadService.allDeletedFiles = [];
  }

  //FOR HANDLE ALLOW ANSWER RECORED FORMAT TOOGLE BUTTON
  onAllowAnsRecorded(event, mainQuesType = '', idx = 0) {
    if(mainQuesType === '5f23bf7e616812672b4949f1') {
      if(this.addQuestionForm.controls.sub_qus['controls'][idx].get('type').value === '66a83bb69311e8e34f19f53a') {
        this.showAlertBox = !this.showAlertBox;
        this.addQuestionForm.controls.sub_qus['controls'][idx].get('ans_by_recording').setValue(true);
        this.addQuestionForm.controls.sub_qus['controls'][idx].get('ans_by_recording').markAsTouched();
      }
    }
    else {
      if(this.quesTypeName === 'speech') {
        this.showAlertBox = !this.showAlertBox;
        this.addQuestionForm.get('ans_by_recording').setValue(true);
        this.addQuestionForm.get('ans_by_recording').markAsTouched();
      }
    }
  }

  onMoveButtonDisabled(state: boolean) {
    this.moveButtonDisabledAction = state;
  }

  ngOnDestroy() {
    this.clearData();
  }
}
