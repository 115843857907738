import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-audio-video-model',
  templateUrl: './audio-video-model.component.html',
  styleUrls: ['./audio-video-model.component.scss']
})
export class AudioVideoModelComponent implements OnInit {

 @Input() audioCondion:any;
 @Input() videoCondion:any;
 @Input() videoUrl:any;
 @Input() audioUrl:any;
 @Input() openVideoAudioPopup:boolean;
 @Output() openVideoAudioPopupClick = new EventEmitter<void>();
 constructor() { }

  ngOnInit() {
  }

  closeAudioVideoPopup() {
    this.openVideoAudioPopupClick.emit(); // Emit the event

  }
}
