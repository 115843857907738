<div class="learning-course-items-wrap mR-tabs-topic-course-items">
    <section class="section pt-0 genrateMonitoringReprot" [ngClass]="'d-block'">
        <div class="container">
            <ngb-accordion #mRsessionAcc="ngbAccordion" activeIds="active" class="accordionCustom">
                <ngb-panel id="active">
                    <ng-template ngbPanelHeader>                        
                        <div class="d-block panelHeaderButton">
                            <div class="row">
                                <div class="col-md-6">
                                    <span class="acc-headerTitle h6 avenirBlack d-block">Personalize - <small>Choose the data you need</small></span>
                                </div>
                                <div class="col-md-6 text-right">
                                    <ng-template #tipContent>Report Generated at: <br>{{downloadDateTime}}<br>File Name: {{downloadFileName}}</ng-template>
                                    <span *ngIf="canGenerateReport && downloadReportLink" class="avenirBlack" [ngbTooltip]="tipContent" placement="bottom">Report Generated</span>
                                    <button *ngIf="canGenerateReport && downloadReportLink" class="btn-transparent btn-transparent-primary manage-tableExport-btn border-right-0" (click)="exportReport()" ngbTooltip="Export Report" placement="bottom"><img class="export-icon" src="../../assets/images/apps-icon/export.png" alt="export.png" />Export</button>
                                    <span *ngIf="!canGenerateReport && !loader" ngbTooltip="Download request in progress." placement="left"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                                </div>
                            </div>                                                        
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <form [formGroup]="generateCourseReport">
                            <div class="panelContentWrap p-3">
                                <div class="row justify-content-center">
                                    <div class="col-md-12 asslist form">
                                        <div class="form-group mb-2 mb-md-2">
                                            <div class="assessment-item">
                                                <div class="assessment-item-checkBox">
                                                    <input class="form-check-input" type="checkbox" name="attendance" formControlName="attendance" />
                                                </div>
                                                <div class="assessment-item-label">
                                                    <div class="col-12 h-100">
                                                        <div class="row align-items-center h-100">
                                                            <div class="col-xl-12 col-md-12">
                                                                <p>Attendance</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group mb-2 mb-md-2 disabled">
                                            <div class="assessment-item">
                                                <div class="assessment-item-checkBox">
                                                    <input class="form-check-input" type="checkbox" [disabled]="true">
                                                </div>
                                                <div class="assessment-item-label">
                                                    <div class="col-12 h-100">
                                                        <div class="row align-items-center h-100">
                                                            <div class="col-xl-6 col-md-12 mb-2 mb-xl-0">
                                                                <p>Notes</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group mb-2 mb-md-2">
                                            <div class="assessment-item">
                                                <div class="assessment-item-checkBox">
                                                    <input class="form-check-input" type="checkbox" formControlName="coursework">
                                                </div>
                                                <div class="assessment-item-label">
                                                    <div class="col-12 h-100">
                                                        <div class="row align-items-center h-100">
                                                            <div class="col-xl-6 col-md-12 mb-2 mb-xl-0">
                                                                <p>Coursework</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group mb-2 mb-md-2">
                                            <div class="assessment-item">
                                                <div class="assessment-item-checkBox">
                                                    <input class="form-check-input" type="checkbox" formControlName="assessment" />
                                                </div>
                                                <div class="assessment-item-label">
                                                    <div class="col-12 h-100">
                                                        <div class="row align-items-center h-100">
                                                            <div class="col-xl-6 col-md-12 mb-2 mb-xl-0">
                                                                <p>Assessments</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>

            <div class="text-center pt-4 pb-4">
                <button class="btn btn-primary mx-2" (click)="generateReport()" *ngIf="showGenerateBtn" [disabled]="!canGenerateReport">
                    Generate Report
                    <span class="spinner-border loader-sm" role="status" *ngIf="loader">
                        <span class="sr-only">Loading...</span>
                    </span>
                </button>
            </div>
        </div>
    </section>
</div>

<ng-template #generateStatusModal let-c="close" let-d="dismiss">
    <div class="new_UIstyle">
        <div class="modal-header justify-content-between align-items-center">
            <h6 class="modal-title avenirBlack primary-text">Report Status</h6>            
        </div>
        <div class="modal-body">
            <p>Your request has been added to the queue and you'll be notified via email once the data is ready.</p>
        </div>
        <div class="modal-footer">
            <div class="text-left">                
            </div>
            <div class="text-right">
                <button type="button" class="btn btn-primary" (click)="onConfirmStatus()">Okay</button>
            </div>
        </div>
    </div>
</ng-template>
