import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { HandleUploadService } from 'src/app/shared/modules/shared/services/handle-upload.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ResourceService } from 'src/app/services/resources/resource.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tr } from 'date-fns/locale';

@Component({
  selector: 'app-upload-video-audio-file',
  templateUrl: './upload-video-audio-file.component.html',
  styleUrls: ['./upload-video-audio-file.component.scss']
})
export class UploadVideoAudioFileComponent implements OnInit {

  @Input() fieldLabelName: string = '';	
  @Input() moduleName: string = '';
  @Input() fieldControlName: string = '';
  @Input() isRequiredField: boolean = false;

  @Input() maxSize: any = 100;
  @Input() validationMessage: string = 'Only mp3, wav and mp4 formats are allowed.';
  @Input() allowedFileTypes: any[] = ['audio/mpeg', 'audio/wav', 'audio/mp3', 'video/mp4'];
  @Input() isAudioVideoResource: boolean = true;

  //FOR CHILD FIELD
  @Input() isChildField: boolean = false;
  @Input() parentQusIndex: number = 0;
  @Input() subQusIndex: number = 0;
  @Input() parentFieldControlName: string = '';

  submitFormSub!: Subscription;  
  formSubmitStatus: boolean = false;
  formData!: any;

  //FOR FILE UPLOAD PROGRESS STATUS
  multiFilesData: any[] = [];
  uploadedFilesData: any[] = [];

  //FOR DELETE CONFIRM POPUP
  deleteModalRef: any = null;
  @ViewChild('deleteModal', { static: false }) deleteModal!: ElementRef;
  deleteMessage: string = '';
  deleteInfo: any = null;

  fileTypeChecks:string = null;

  fileOver = false;
  @Output() moveButtonDisabled = new EventEmitter<boolean>();
  constructor(private notificationService: NotificationService, private handleUploadService: HandleUploadService, private resourceService: ResourceService, private fb: FormBuilder, private modalService: NgbModal) { }

  ngOnInit() {

  	this.submitFormSub = this.handleUploadService.submitFormStatus.subscribe((data: any) => {  		
  		this.formData = this.handleUploadService.addForm;
  		this.formSubmitStatus = data.formSubmitStatus;
  	});
  }

  ngAfterViewInit() {
    this.formData = this.handleUploadService.addForm;
  }

  resetFileField(event) {
  if (event.target) {
      event.target.value = '';
    }
  }

  onDragOver(event) {
    event.preventDefault();
    this.fileOver = true;
  }

  onDragLeave(event) {
    event.preventDefault();
    this.fileOver = false;
  }

  onFileDropped(event) {
    this.fileOver = false;
    let files = event.files;
    this.uploadMultiResource(files, event);
  }

  onSelectFiles(event) {
    let files = event.target.files;
    this.uploadMultiResource(files, event);
  }

  uploadMultiResource(files, event) {
 this.fileTypeChecks = files[0].type;
    //FOR CHILD FIELD
    if(this.isChildField) {
      this.addMoreResource();
      if(files[0].type === 'video/mp4'){
        if (this.handleUploadService.addForm.controls[this.parentFieldControlName]['controls'][this.subQusIndex].controls[this.fieldControlName].value[0].ans_recorded_video_key !== '') {
          this.resetFileField(event);
          this.notificationService.showNotification('info', 'Please remove previous uploaded file.');
          return false;
        }
      } else{
        if (this.handleUploadService.addForm.controls[this.parentFieldControlName]['controls'][this.subQusIndex].controls[this.fieldControlName].value[0].ans_recorded_audio_key !== '') {
          this.resetFileField(event);
          this.notificationService.showNotification('info', 'Please remove previous uploaded file.');
          return false;
        }
      }
      
    }
    else {
      if (this.handleUploadService.addForm.controls[this.fieldControlName].value != '') {
        this.resetFileField(event);
        this.notificationService.showNotification('info', 'Please remove previous uploaded file.');
        return false;
      }
    }

    if (files.length > 1) {
      this.resetFileField(event);
      this.notificationService.showNotification('info', 'Please provide only one file.');
      return false;
    }

    if (!this.validateFiles(files)) {
      this.resetFileField(event);
      return false;
    }
  }

  validateFiles(files) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!this.validateFileType(file)) {
        return false;
      }
    }

    //UPLOAD FILES
    this.multiFilesData = [];
    for (let i = 0; i < files.length; i++) {
      this.uploadSingleResource(i, files[i], 'file');
    }
  }

  validateFileType(file) {
    const fileType = file.type;
    const allowedFileType = this.allowedFileTypes;
    if (allowedFileType.indexOf(fileType) == -1) {
      this.notificationService.showNotification('info', this.validationMessage);
      return false;
    }

    if(this.isAudioVideoResource) {
      if(fileType.indexOf('video/mp4') != -1) {
        this.moduleName = 'answer_recorded_video';
      } else {
        this.moduleName = 'answer_recorded_audio';
      }
    }

    return true;
  }

  validateFileSize(file) {
    let fileSize = ((file.size / 1024) / 1024);
    if (fileSize > this.maxSize) {
      const fileErr = "Maximum file size allowed for upload: " + this.maxSize + ' MB';
      this.notificationService.showNotification('info', fileErr);
      return false;
    }

    return true;
  }

  uploadSingleResource(idx, file, type) {
    if (file) {
      this.moveButtonDisabled.emit(true);
      const fileName = file.name;
      let progressBar = 0;
      let progressTime = 0;
      let startDate = new Date;

      this.notificationService.upload_resource(this.moduleName, file).subscribe(
        (response: HttpEvent<any>) => {
          switch (response.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              progressBar = Math.round(response.loaded / response.total * 100);

              //TIME CALCULATION
              let currDate = new Date;
              let timeElapsed: number = currDate.getTime() - startDate.getTime();
              let uploadSpeed = response.loaded / (timeElapsed / 1000);
              let seconds = (file.size - response.loaded) / uploadSpeed;
              progressTime = Math.round(seconds);

              this.multiFilesData[idx] = { name: fileName, progressBar: progressBar, progressTime: progressTime, uploadStatus: false };
              break;

            case HttpEventType.Response:
              this.notificationService.showNotification('success', 'File uploaded successfully');
              setTimeout(() => {
                progressBar = 0;
                progressTime = 0;
                this.multiFilesData[idx] = { name: fileName, progressBar: progressBar, progressTime: progressTime, uploadStatus: true };
                this.updateUploadedResources(this.multiFilesData[idx], response);
                this.moveButtonDisabled.emit(false);
              }, 1000);
          }
        },
        (err) => {
          progressBar = 0;
          progressTime = 0;
          this.multiFilesData[idx] = { name: fileName, progressBar: progressBar, progressTime: progressTime, uploadStatus: false };
          this.notificationService.showNotification('info', 'Please check file type and add again not able to save your file');
        });
    }
  }

  updateUploadedResources(multiFile, response) {
    const resourceData = response.body.resource_info[0];
    const resourcePath = resourceData.path;
    const resourceId = resourceData.resource_id;
    const resourceUrl = resourceData.url;
    let resourceInfo = {
      resource_title: multiFile.name,
      resource_type: 'file',
      resource_id: resourceId,
      resource_url: resourceUrl,
      path: resourcePath,
      resource_deleted: false
    };
    this.uploadedFilesData.unshift(resourceInfo);

    //TO REMOVE RESOURCES
    this.handleUploadService.allUploadedFiles.push(resourcePath);

    //SET DATA INTO FORM FIELD
    this.manageUploadedFiles();
  }

  //FOR DELETE CONFIRM POPUP
  deleteUploadedResource(uploadedFileIndex) {
    this.openDeleteModal();
    this.deleteMessage = 'Are you sure want to remove this ' + this.fieldLabelName + ' ? ';
    this.deleteInfo = {uploadedFileIndex: uploadedFileIndex};
  }

  openDeleteModal() {
    this.deleteModalRef = this.modalService.open(this.deleteModal);
  }

  closeDeleteModal() {
    this.deleteModalRef.close();
    return;
  }

  onConfirmDelete() {
    const uploadedFileIndex = this.deleteInfo.uploadedFileIndex;

    this.uploadedFilesData[uploadedFileIndex].resource_deleted = true;
    this.multiFilesData = [];
    this.manageUploadedFiles();

    this.deleteModalRef.close();
    this.deleteInfo = null;
  }

  manageUploadedFiles() {

    //FOR CHILD FIELD
    if(this.isChildField) {
      this.setChildResourcesFiles();
    }
  }

  fieldFormArr(fieldControl) {
    //FOR CHILD FIELD
    if(this.isChildField) {
      return this.handleUploadService.addForm.controls[this.parentFieldControlName]['controls'][this.subQusIndex].controls[fieldControl] as FormArray;
    }
  }

  addMoreResource() {
    this.fieldFormArr(this.fieldControlName).push(this.createResource());
  }

  createResource() {
    return this.fb.group({
      value: [''],
      speech_text: [''],
      ans_recorded_audio_key:[''],
      ans_recorded_video_key:['']
    });
  }

  //FOR CHILD FIELD
  setChildResourcesFiles() {
    this.fieldFormArr(this.fieldControlName).clear();
    if (this.uploadedFilesData.length > 0) {
      this.uploadedFilesData.forEach((e, index) => {
    
        if (!e.resource_deleted) {
          this.addMoreResource();
          let resourceIndex = this.handleUploadService.addForm.controls[this.parentFieldControlName]['controls'][this.subQusIndex].controls[this.fieldControlName]['controls'].length - 1;
        if(this.fileTypeChecks === 'video/mp4'){
          this.handleUploadService.addForm.controls[this.parentFieldControlName]['controls'][this.subQusIndex].controls[this.fieldControlName]['controls'][resourceIndex].get('ans_recorded_video_key').setValue(e.path);
        } else{
          this.handleUploadService.addForm.controls[this.parentFieldControlName]['controls'][this.subQusIndex].controls[this.fieldControlName]['controls'][resourceIndex].get('ans_recorded_audio_key').setValue(e.path);
        }
        console.log()
        }
        else {
          this.handleUploadService.allDeletedFiles.push(e.path);
        }
      });
    }
  }
  ngOnDestroy() {
    this.submitFormSub.unsubscribe();
  }

}