
<div class="new_UIstyle" appDisableRightClick>
<!-- banner section -->
    <div class="banner banner-gradient banner-course-assement">
        <div class="container">
            <div class="banner-content">
                <div class="banner-content-content">
                    <p class="position-relative pos-top-10 mb-0" *ngIf="feedBackData?.negative_marking_status"><span class="badge badge-light badge-primary avenirRoman m-0 mb-1" >Negative Marking</span></p>
                    <h2 class="white-text avenirBlack prevent-select">{{assName | capitalization}}</h2>
                </div>
                <div class="banner-content-action btn-groups">
                    <button class="btn btn-primary btn-w-120" *ngIf="role == 'student' && isTA == 'false'" (click)="go_to_list()">Cancel</button>
                    <button class="btn btn-primary btn-w-120" *ngIf="(role != 'student') || (role == 'student' && isTA == 'true') || (isTA == 'true')" (click)="go_to_list()">Back</button>
                </div>
            </div>
        </div>
    </div>

    <section class="section">
        <div class="container" [ngClass]="{submitting : loader}">
            <div class="text-center pt-2 pb-2">
                <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            
            <div class="row align-items-center">
                <div class="col-md-12" *ngIf="role == 'student' && isTA == 'false'">
                    <div class="course-assessment-list" *ngIf="feedBackData?.questions.length > 0" [ngClass]="sumaryResult ? 'd-block':'d-none'">
                        <div class="yourScoreWrap bg-grey mb-4">                            
                            <div class="yourScoreContent">
                                <div *ngIf="!reviewCompleted"><span class="mb-4 scoreGet btn-lg rounded-pill avenirBlack">Assessement under review</span></div>
                                <h4 class="avenirBlack">Your Score <span class="ms-2 scoreGet btn-w-120 btn-lg rounded-pill avenirBlack">{{studentMarkTotal}}</span></h4>
                                <p><small>Total Marks: </small><strong class="ms-2 avenirBlack">{{totalMarks}}</strong></p>
                            </div>
                            <div class="yourScorePrecent blueTheme">
                                <span class="scorePrec">{{calcPercnt}}%</span>
                            </div>   
                        </div>

                        <div class="course-assessment-reviewBlk">
                        <div class="table-responsive assessment-reviewTable mb-3">
                            <table class="table">
                                <thead>
                                    <tr> 
                                        <th>Question</th>
                                        <th>Result</th>
                                        <th class="w-250px">Marks</th>
                                        <th class="w-200px">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    <tr *ngFor="let fbQuestion of  feedBackData?.questions; let i = index;">
                                        <td><span class="number-icon">{{i + 1}}</span></td>
                                        <td >
                                            <div class="questionsStatus">
                                                <div *ngIf="fbQuestion?.is_question_correct === 'True'">
                                                 <div class="correctAns">
                                                    <span class="dark-green-text tickGreen "><i class="fas fa-check-double"></i></span>
                                                    <span class="primary-text tickRed"><i class="fas fa-times"></i></span>
                                                 </div>                                          
                                                </div>
                                                <div *ngIf="fbQuestion?.is_question_correct === 'False'">
                                                 <div class="incorrectAns">
                                                    <span class="dark-green-text tickGreen "><i class="fas fa-check-double"></i></span>
                                                    <span class="primary-text tickRed"><i class="fas fa-times"></i></span>
                                                 </div>                                          
                                                </div>                            
                                            </div>
                                            
                                        </td>
                                        <td class="w-250px">
                                            <ng-container *ngIf="fbQuestion.question_review_completed">
                                                <small>{{fbQuestion.grades.content}}</small>
                                            </ng-container>
                                            <ng-container *ngIf="!fbQuestion.question_review_completed">
                                                <small>Under Review</small>
                                            </ng-container>
                                        </td>
                                        <td  class="w-250px"><button type="button" class="btn btn-primary-border btn-sm" [id]="i" (click)="goQustn($event, i)">Go to question</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="text-center mt-3">
                            <button class="btn btn-primary  btn-w-120" (click)="reviewOneByOne()">Review</button>
                        </div>
                        </div>
                        </div>  
                    </div>
                  
                    <div class="col-md-12 m-auto">
                    <div [ngClass]="sumaryResult ? 'd-none':'d-block'">
                        <div class="stepsButtons d-flex align-items-center justify-content-between" 
                        *ngIf="feedBackData?.questions.length === (itemm + 1) && role == 'student' && isTA == 'false'">
                            <button type="button" class="btn btn-primary btn-w-120 nextButton"  (click)="goToSummary()">Go to Result</button>
                        </div>
                        <div class="course-assessment-qsn-blk" *ngFor="let aData of feedBackData?.questions; let i = index;" [ngClass]="i == itemm ? 'd-block':'d-none'">
                            <div class="row">
                            <div class="course-assessment-qsn col-12 mb-3 " [ngClass]="(role != 'student') || (role == 'student' && isTA == 'true') || (isTA == 'true')? 'col-lg-8 border-right':'col-12'">
                            <span class="badge badge-green-lt mb-2 prevent-select" *ngIf="role == 'student' && isTA == 'false'">Marks: {{aData?.max_marks}}</span>
                            <div class="tinyText ass_ques_btn_wrap">
                            <h5 class="avenirBlack prevent-select"><span class="number-icon">{{i+1}}</span></h5>
                            <div class="tinyTextContent prevent-select" [ngClass]="{'mb-1':feedBackData?.questions[itemm]?.question_recording_url && feedBackData.questions[itemm].question_recording_url.url 
                            || feedBackData?.questions[itemm]?.question_video_url && feedBackData.questions[itemm].question_video_url.url}" 
                            [innerHTML]="aData?.question_html" 
                            title="view image"></div>
                           
                            <button type="button" class="audio_video_iconButtton" 
                            *ngIf="feedBackData?.questions[itemm]?.question_recording_url && feedBackData.questions[itemm].question_recording_url.url 
                            || feedBackData?.questions[itemm]?.question_video_url && feedBackData.questions[itemm].question_video_url.url"
                                        (click)="openAudioVideoPopup()" 
                                        >Click this link to play the audio/video to answer the question.</button>
                            
                            <div class="questionsAnsw">
                                <div *ngIf="aData.question_type == 'singleanswermcq'">
                                    <div class="row gy-2">
                                        <div class="col-md-6 custom-control custom-radio mb-2"
                                            *ngFor="let option of aData?.options;let ii = index"  
                                            [ngClass]="(option.answer === false && option.is_option_student_answer === false) ? '' : (option.is_option_student_answer === true && option.answer === false) ? 'incorrectAns' : (option.answer === true && option.is_option_student_answer === true) ? 'correctAns' : ''">
                                            <input type="radio" [value]="option.is_option_student_answer" [checked]="option.is_option_student_answer"
                                                class="form-check-input custom-control-input me-2"  readonly>
                                            <label class="form-check-label custom-control-label prevent-select" >
                                                <div class="d-inline-block vTop"
                                                    [innerHTML]="option?.option"></div>

                                            </label>
                                        </div>
                                    </div>       
                                </div>
            
                                <div *ngIf="aData.question_type == 'multianswermcq'">
                                    <div class="row gy-2" >
                                            <div class="col-md-6 custom-control custom-checkbox mb-2"
                                                *ngFor="let option of aData?.options;let ii = index"  
                                                [ngClass]="(option.answer === false && option.is_option_student_answer === false) ? '' : (option.is_option_student_answer === true && option.answer === false) ? 'incorrectAns' : (option.answer === true && option.is_option_student_answer === true) ? 'correctAns' : ''">
                                                <input type="checkbox" [checked]="option.is_option_student_answer" [value]="option.is_option_student_answer"
                                                    class="form-check-input custom-control-input"  readonly>
                                                <label class="form-check-label custom-control-label prevent-select">
            
                                                    <div class="d-inline-block vTop"
                                                        [innerHTML]="option?.option"></div>
            
                                                </label>
                                            </div>
                                        </div>
                                </div>
                                <div *ngIf="aData.question_type == 'bool'">
                                    <div class="row gy-2" >
                                        <div class="col-md-6 custom-control custom-radio mb-2"
                                            *ngFor="let option of aData?.options;let ii = index" 
                                            [ngClass]="(option.answer === false && option.is_option_student_answer === false) ? '' : (option.is_option_student_answer === true && option.answer === false) ? 'incorrectAns' : (option.answer === true && option.is_option_student_answer === true) ? 'correctAns' : ''">
                                            <input type="radio" [value]="option?.is_option_student_answer" [checked]="option.is_option_student_answer"
                                                class="form-check-input custom-control-input"  readonly>
                                            <label class="form-check-label custom-control-label prevent-select" >
                                                <div class="d-inline-block vTop"
                                                    [innerHTML]="option?.option"></div>

                                            </label>
                                        </div>
                                    </div>
                                </div>
            
                                <div *ngIf="aData.question_type == 'speech'">
                                    <div class="mb-2" *ngIf="aData.ans_recorded_audio_key === '' && aData.ans_recorded_video_key === ''">
                                        <div *ngIf="aData.is_question_correct === 'True'"
                                        class="form-control form-control-textarea correctAns prevent-select" 
                                        [innerHTML]="aData?.student_answer"></div>  
                                        <div *ngIf="aData.is_question_correct === 'False'"
                                        class="form-control form-control-textarea incorrectAns prevent-select" 
                                        [innerHTML]="aData?.student_answer"></div>       
                                        <div *ngIf="aData.is_question_correct === 'Review Pending'"
                                        class="form-control form-control-textarea prevent-select" 
                                        [innerHTML]="aData?.student_answer"></div>       
                                    </div>

                                    <div class="mb-2" *ngIf="aData?.ans_recorded_audio_key !== '' 
                                    || aData?.ans_recorded_video_key !== ''">
                                        <label class="avenirBlack dark-green-text mb-1">Student Answer:</label> 
                                        <div class="mb-2">
                                            <button type="button" class="audio_video_iconButtton" 
                                            (click)="openAudioVideoStudentPopup()" 
                                            >Click this link to play the audio/video of the student answer.</button>
                                        </div>
                                    </div>

                                </div>

                                <div *ngIf="aData.question_type == 'text'">
                                    <div class="mb-2" *ngIf="aData.ans_recorded_audio_key === '' && aData.ans_recorded_video_key === ''">
                                        <div *ngIf="aData.is_question_correct === 'True'"
                                        class="form-control form-control-textarea correctAns" 
                                        [innerHTML]="aData?.student_answer"></div>  
                                        <div *ngIf="aData.is_question_correct === 'False'"
                                        class="form-control form-control-textarea incorrectAns" 
                                        [innerHTML]="aData?.student_answer"></div>       
                                        <div *ngIf="aData.is_question_correct === 'Review Pending'"
                                        class="form-control form-control-textarea" 
                                        [innerHTML]="aData?.student_answer"></div>       
                                    </div>

                                    <div class="mb-2" *ngIf="aData?.ans_recorded_audio_key !== '' 
                                    || aData?.ans_recorded_video_key !== ''">
                                        <label class="avenirBlack dark-green-text mb-1">Student Answer:</label> 
                                        <div class="mb-2">
                                            <button type="button" class="audio_video_iconButtton" 
                                            (click)="openAudioVideoStudentPopup()" 
                                            >Click this link to play the audio/video of the student answer.</button>
                                        </div>
                                    </div>

                                </div>


                                <div *ngIf="aData.question_type == 'fib'">
                                    <div class="mb-2" *ngIf="aData.ans_recorded_audio_key === '' && aData.ans_recorded_video_key === ''"> 
                                        <div *ngIf="aData.is_question_correct === 'True'"
                                        class="form-control form-control-textarea correctAns prevent-select" 
                                        [innerHTML]="aData?.student_answer"></div>  
                                        <div *ngIf="aData.is_question_correct === 'False'"
                                        class="form-control form-control-textarea incorrectAns prevent-select" 
                                        [innerHTML]="aData?.student_answer"></div>       
                                        <div *ngIf="aData.is_question_correct === 'Review Pending' "
                                        class="form-control form-control-textarea prevent-select" 
                                        [innerHTML]="aData?.student_answer"></div>  
                                    </div>

                                    <div class="mb-2" *ngIf="aData?.ans_recorded_audio_key !== '' 
                                    || aData?.ans_recorded_video_key !== ''">
                                        <label class="avenirBlack dark-green-text mb-1">Student Answer:</label> 
                                        <div class="mb2">
                                            <button type="button" class="audio_video_iconButtton" 
                                            (click)="openAudioVideoStudentPopup()" 
                                            >Click this link to play the audio/video of the student answer.</button>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
            
                            </div>

                            <div  class="questContent"  *ngIf="aData.model_answer.length > 0">	
                                <label class="avenirBlack dark-green-text">Correct Answer:</label> 
                                <div class="mb-2" >
                                    <div class="row"> 
                                        <div class="col-auto" *ngFor="let modAns of aData.model_answer">
                                            <span class="d-block mb-2 prevent-select" [innerHTML] = "modAns?.text"></span>	
                                            <span class="d-inline-block prevent-select" *ngIf="modAns.img?.length>0"><img [src]="modAns.img"	
                                                    width="100px" height="100px" /></span>	
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                           </div>
                            <div class="col-lg-4 form" *ngIf="(role != 'student') || (role == 'student' && isTA == 'true') || (isTA == 'true')">
                                <div class="mb-3">	
                                    <div class="w-auto max-mark-blk d-inline-flex align-items-center rounded">
                                        Max Marks: <span class="ml-2" [innerHTML] = "aData.max_marks"></span>	
                                    </div>
                                </div>
                                <div class="mb-3">
                                <div>	
                                    <form #addGradeRemark="ngForm" class="typo-16" (ngSubmit)="addGradeSubmit(addGradeRemark,aData)">	
                                        <div class="mb-3">
                                            <label  class="label avenirBlack mb-1">Grade / marks</label>
                                            <Input type="number" class="gradeform-control form-control form-control-lg form-control-style-2 mb-2" 		
                                            max="3" name="grade"  [(ngModel)]="aData.grades.content" ngDefaultControl
                                             placeholder="Give grade to student" [disabled]="feedBackData.current_user_remark == false" [readonly]="aData?.question_type == 'text' || aData?.question_type == 'fib' || aData?.question_type == 'speech' ? false : true" />	
                                        </div>

                                        <div class="mb-3">
                                            <label  class="label avenirBlack mb-1">Remarks</label> 
                                            <textarea type="text" class="gradeform-control form-control form-control-style-2 mb-2 form-control-textarea"
                                            cols="10" rows="6" name="remark" [(ngModel)]="aData.remarks.content" placeholder="Enter remarks here"
                                            [disabled]="feedBackData.current_user_remark == false"></textarea>	
                                        </div>    
                                        
                                        <div class="btn-groups d-flex align-items-center justify-content-end" *ngIf="feedBackData.current_user_remark == true">
                                            <button type="button" class="btn btn-sm btn-primary-border" (click)="resetAddGrade(addGradeRemark,aData)">Clear</button>
                                            <button type="submit" class="btn btn-sm btn-primary">Save</button>
                                       </div>
                                    </form>     
                                
                                </div>	
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>

                </div>
                <div class="stepsButtons d-flex align-items-center justify-content-between" [ngClass]="(role != 'student') || (role == 'student' && isTA == 'true') || (isTA == 'true') ? 'col-lg-8':'col-lg-12'"
                *ngIf="!isBlcokTrue && !sumaryResult && !goSummary && feedBackData?.questions.length > 0">
                    <button type="button" class="btn btn-primary btn-w-120 prevButton" [hidden]="(itemm + 1) == 1 && role !== 'student' || (itemm + 1) == 1 && role == 'student' && isTA == 'true'"  (click)="prevItem()">Previous</button>
                    <button type="button" class="btn btn-primary btn-w-120 nextButton" [hidden]="(itemm + 1) == feedBackData?.questions?.length" (click)="nextItem()">Next</button>
                    <!-- <button type="button" class="btn btn-primary btn-w-120 nextButton"  (click)="goToSummary()" *ngIf="(itemm + 1) == feedBackData?.questions?.length">Go to result</button> -->
                </div>
                <div class="stepsButtons d-flex align-items-center justify-content-between"
                [ngClass]="role !== 'student' ? 'col-lg-8':'col-lg-12'"
                 *ngIf="goSummary && isTA == 'false'">
                    <button type="button" class="btn btn-primary btn-w-120 nextButton"  (click)="goToSummary()">Go to Result</button>
                </div>
            </div>
        </div>
    </section> 



    <!-- audio/video modal -->
    <app-audio-video-model *ngIf="openVideoAudioPopup" [openVideoAudioPopup]="openVideoAudioPopup" 
    (openVideoAudioPopupClick)="closeAudioVideoPopup()"
    [videoCondion]="feedBackData?.questions[itemm]?.question_video_url && feedBackData.questions[itemm].question_video_url.url" 
    [audioCondion]="feedBackData?.questions[itemm]?.question_recording_url && feedBackData.questions[itemm].question_recording_url.url"
    [videoUrl]="feedBackData?.questions[itemm].question_video_url.url"
    [audioUrl]="feedBackData.questions[itemm].question_recording_url.url"></app-audio-video-model>

    <!-- audio/video modal -->
    <app-audio-video-model *ngIf="openVideoAudioStudentPopup" [openVideoAudioPopup]="openVideoAudioStudentPopup" 
    (openVideoAudioPopupClick)="closeAudioVideoStudentPopup()"
    [videoCondion]="feedBackData?.questions[itemm]?.ans_recorded_video_key !== ''" 
    [audioCondion]="feedBackData?.questions[itemm]?.ans_recorded_audio_key !== ''"
    [videoUrl]="feedBackData?.questions[itemm].ans_recorded_video_key"
    [audioUrl]="feedBackData.questions[itemm].ans_recorded_audio_key"></app-audio-video-model>
</div>
