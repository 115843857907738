import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-question-detail',
  templateUrl: './question-detail.component.html',
  styleUrls: ['./question-detail.component.scss']
})
export class QuestionDetailComponent implements OnInit {

  quesId;
  instanceId;
  resDetails:any;

  loader:boolean;

  // audio/video popup var
  openVideoAudioPopup:boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private courseService:CourseService,
    private router: Router,

  ) { }

  ngOnInit() {
    this.quesId = this.activatedRoute.snapshot.queryParamMap.get('quesId');
    this.questionDetails()

  }

  
questionDetails(){
  this.loader = true
  this.courseService.viewQuestion(this.quesId).pipe(finalize(() => this.loader=false)).subscribe(viewRes => {
      this.resDetails = viewRes.data;
      this.loader = false
    })
  
}

goBack() {
  this.router.navigate(['/resource-bank']);
}


openAudioVideoPopup() {
  this.openVideoAudioPopup = true;
}
closeAudioVideoPopup() {
  this.openVideoAudioPopup = false;
}

//FOR BUILD FIX
ansResourceImg: string = '';

}

