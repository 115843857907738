import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CourseService } from 'src/app/services/course.service';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-cw-teaching-assisatnt',
  templateUrl: './cw-teaching-assisatnt.component.html',
  styleUrls: ['./cw-teaching-assisatnt.component.scss']
})
export class CwTeachingAssisatntComponent implements OnInit {

  assignType = 'manual';
  loader = false;
  courseId = '';
  topicId = '';
  course_work_id = '';
  schedule_id = '';
  getSubscribeStudentList = [];
  courseName = '';
  getAssignList: any[];
  get_course_work_team: any[];
  activeIdNav:any = 1;

  constructor(private ns: NotificationService, private courseService: CourseService, private route: Router, private activatedRoute: ActivatedRoute,) { }

  ngOnInit() {
    this.activeIdNav = this.activatedRoute.snapshot.queryParamMap.get('tab_no');
    this.courseId = this.activatedRoute.snapshot.queryParamMap.get('course_id');
    if(this.activatedRoute.snapshot.queryParamMap.get('course') !== null){
      this.courseName = this.activatedRoute.snapshot.queryParamMap.get('course');
    } else if(this.activatedRoute.snapshot.queryParamMap.get('courseName') !== null){
      this.courseName = this.activatedRoute.snapshot.queryParamMap.get('courseName');
    } else{
      this.courseName = localStorage.getItem('courseName')
    }

    this.course_work_id = this.activatedRoute.snapshot.queryParamMap.get('course_work_id');
    this.schedule_id = this.activatedRoute.snapshot.queryParamMap.get('schedule_id');
    this.topicId = this.activatedRoute.snapshot.queryParamMap.get('topic_id');
      this.get_assign_teams();
      this.get_teams();
  }

  get_teams() {
    this.courseService.viewCourseWork(this.course_work_id, this.schedule_id, this.courseId).subscribe(getTeams => {
      this.get_course_work_team = getTeams.response.teams_info;
    },
    (err)=>{
      // this.ns.showNotification('info', err.message);
    })
  }

  get_assign_teams() {
    this.courseService.get_assign_teams(this.courseId, this.course_work_id, this.schedule_id).subscribe(getAssigess => {
      this.getAssignList = getAssigess.response;
    },
    (err)=>{
      this.ns.showNotification('info', err.message);
      console.log(err);
      
    })
  }

  get_member(item) {
    let member = [];
    item.members.filter(value => {
      member.push(value);
    });
    return member

  }

  onChangeAssign(value) {
    this.assignType = value;
    this.auto_item = this.getSubscribeStudentList
    if (this.assignType == 'auto') {
      this.generateOptions();
    } else {
    }
  }

  assists = [];
  MyDragEvent: CdkDragDrop<string[]>;
  drop(event: CdkDragDrop<string[]>) {
    this.MyDragEvent = event;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
  LIST_IDS = [];
  addId(i) {
    this.LIST_IDS.push('cdk-drop-list-' + i);
    return i + ''
  }

  submit() {
    this.loader = true
    this.courseService.save_CW_assiatnt(this.getAssignList, this.courseId, this.course_work_id, this.schedule_id)
    .pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe(getData => {
        this.ns.showNotification('success', getData.message);
      })

  }

  result = [];
  auto_item = [];
  generateOptions() {
    const chunkSize = this.getAssignList.length;  
    let groups = this.mySubscriber(this.get_course_work_team).map((e, i) => {
      return i % chunkSize === 0 ? this.mySubscriber(this.get_course_work_team).slice(i, i + chunkSize) : null;
    }).filter(e => { return e; });  
    if (this.getAssignList.length > 0 && this.mySubscriber(this.get_course_work_team).length > 0 && groups.length > 0) {
      for (let i = 0; i < groups.length; i++) {
        let cube = groups[i];
        for (let j = 0; j < cube.length; j++) {
          for (let k = 0; k < this.getAssignList.length; k++) {
            if (cube.length > 0 && !this.getAssignList[k].assignees.some(subs => subs.members[0]._id == cube[j]._id)) {
              this.getAssignList[k].assignees.push(cube[j]);
              cube.splice(j, 1);
            }

          }
        }
      }
    }

    if (groups.length == 0) {
      this.ns.showNotification('info', 'Assign the subscriber to the Teaching assistant');
      return
    }
  }

  print(v) {
    console.log(v);
  }

  go_to_url(url) {
    if(this.get_course_work_team.length > 0) { 
      this.route.navigate(['/courses/course-work-detail'], { queryParams: { courseWork: this.course_work_id, course_id: this.courseId, course: this.courseName,topic_id: this.topicId , tab_no:this.activeIdNav} });
    }
    else {
      this.route.navigate([url], { queryParams: { course_id: this.courseId, course: this.courseName, course_work_id: this.course_work_id, schedule_id: this.schedule_id, tab_no:this.activeIdNav } });
    }
  }

  findValue(result, index) {
    return result;
  }
  mySubscriber(lists) {
    let list = [];
    let myArrayFiltered = [];
    if (this.getAssignList != undefined && this.getAssignList.length > 0) {
      this.getAssignList.filter(assigns => {
        assigns.assignees.filter(assign => {
          list.push(assign);
        });
      })
      
      if(lists)
      myArrayFiltered = lists.filter(subs => !list.some(assigne => subs.name === assigne.name))
    }
    return myArrayFiltered
  }

  //FOR BUILD FIX
  assistList: any = '';
}
