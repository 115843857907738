import { Directive, HostListener, Output, EventEmitter, HostBinding } from '@angular/core';

@Directive({
  selector: '[appDragDropFiles]'
})
export class DragDropFilesDirective {

  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();	

  constructor() { }

  @HostListener('dragover', ['$event']) onDragOver(evt) {
  	this.stopDefaultBehaviour();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    this.stopDefaultBehaviour();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) public onDropSuccess(evt) {
    this.stopDefaultBehaviour();
    this.fileOver = false;
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit({files: files});
    }	  
  }

  stopDefaultBehaviour() {
  	event.preventDefault();
  	event.stopPropagation();
  }

}
