<div class="new_UIstyle">
    <div class="banner banner-gradient banner-course-assement">
        <div class="container">
             <div class="banner-content">
                 <div class="banner-content-content">
                     <h2 class="white-text avenirBlack">Attendance</h2>
                 </div>

                 <div class="banner-content-action btn-groups">
                    <button class="btn btn-primary btn-w-120" (click)="backBtn()">Back</button>
                 </div>
             </div>
        </div>
     </div>

    <section class="section attandence-section"  [ngClass]="{submitting : loader}">
        <div class="spinner-border text-warning outer_loader_over" role="status" *ngIf="loader">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="container">
            <div class="attdenceTable"  *ngIf="students?.length > 0">
                <div class="frezecolumns">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Name of Student</th>
                                <th scope="col">Username of Student</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let student of students">
                                <td>{{student.name}}</td>
                                <td>{{student.username}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="attendanceMarkBlock">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">
                                    <table class="table">
                                        <tr>
                                            <th class="text-center">{{topicTitle}}</th>
                                        </tr>
                                        <tr>
                                            <th>{{sessionTitle}}</th>
                                        </tr>
                                    </table>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let student of students; index as i">
                                <td>
                                    <table class="table">
                                        <tr>
                                            
                                            <td>
                                                <!-- <div class="attendanceArea" [ngClass]="student.availability ? 'bgGreen':'bgRed'">
                                                     <button class="attendance-btn present-btn" 
                                                     [ngClass]="student.availability  == true ? 'openBtn disabled':''"
                                                     (click)="attendanceBtn(student,true ,i)">{{student.availability  == true ? 'Present':'P'}}</button>
                                                     <button class="attendance-btn absent-btn" 
                                                     [ngClass]="student.availability == false ? 'openBtn disabled':''"
                                                      (click)="attendanceBtn(student,false, i)">{{student.availability == false ? 'Absent' : 'A'}}</button>

                                                      <button class="attendance-btn absent-btn" 
                                                      [ngClass]="student.availability == false ? 'openBtn disabled':''"
                                                       (click)="attendanceBtn(student,false, i)">L</button>
                                                </div> -->

                                                <div class="attendanceArea">
                                                    <label class="attendance-btn present-btn" ngbTooltip="Present" [ngClass]="{'selected': student.availability === true && student.is_leave === false}">
                                                        <input type="radio" name="attendance{{i}}" 
                                                               value="true" 
                                                               [(ngModel)]="student.availability"
                                                               (change)="attendanceBtn(student, true, false, i)" />
                                                        P
                                                    </label>
                                                    <label class="attendance-btn absent-btn" ngbTooltip="Absent" [ngClass]="{'selected': student.availability === false && student.is_leave === false}">
                                                        <input type="radio" name="attendance{{i}}" 
                                                               value="false" 
                                                               [(ngModel)]="student.availability"
                                                               (change)="attendanceBtn(student, false, false, i)" />
                                                        A
                                                    </label>
                                                    <label class="attendance-btn leave-btn" ngbTooltip="Leave" [ngClass]="{'selected': student.is_leave === true && student.availability === false}">
                                                        <input type="radio" name="attendance{{i}}" 
                                                               value="true" 
                                                               [(ngModel)]="student.is_leave"
                                                               (change)="attendanceBtn(student, false, true, i)" />
                                                        L
                                                    </label>
                                                </div>
                                            </td>
                                            
                                        </tr>
                                    </table>
                                </td>
                               
                            </tr>
                        </tbody> 
                    </table>
                </div>
            </div>

            <!-- if data not found -->
            <div class="alert alert-info text-center" *ngIf="students?.length == 0 && !loader">
                <p>No Data Found.</p>
            </div>
        </div>
    </section>
</div>