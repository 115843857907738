<!-- New Code -->
<div class="pageBodyWrap new_UIstyle">
    <!-- banner section -->
  <div class="banner banner-gradient banner-monitoring">
     <div class="container">
          <div class="banner-content">
              <div class="banner-content-content">
                  <h2 class="white-text avenirBlack">Teaching Assistants <br /><small>Progress Report of Teaching Assistants</small></h2>
              </div>
              <div class="banner-content-action btn-groups">
                   <button class="btn btn-primary" (click)="go_to_url()"><i class="fas fa-arrow-left mr-2"></i> Back</button>
              </div>
          </div>
     </div>
  </div>


<section class="section">
    <div class="container">
        <div class="table-responsive" [ngClass]="{submitting : loader}">
            <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                <span class="sr-only">Loading...</span>
            </div>
            <table class="table altRow table-ta-status styled-table">
                <thead>
                    <tr>
                        <th style="border-bottom: 0;">Assistant Name</th>
                        <th style="border-bottom: 0;">Organization</th>
                        <th style="border-bottom: 0;" class="w150p"># Subscribers</th>
                        <th colspan="3">
                            <table class="innerTableHeader">
                                 <tr>
                                    <th>Overall IND CW Status</th>
                                 </tr>
                                 <tr>
                                    <th>CW {{report_data?.total_sub_per_student > 0 ? '- ' + report_data.total_sub_per_student : '-'}}</th>
                                 </tr>
                            </table>
                            <!-- <div>Overall IND CW Status</div> 
                            <div>CW {{report_data?.total_sub_per_student > 0 ? '- ' + report_data.total_sub_per_student : '-'}}</div> -->
                        </th>
                        <th colspan="6">
                            <table class="innerTableHeader">
                                <tr>
                                   <th>Overall Group CW Status</th>
                                </tr>
                                <tr>
                                   <th>Group CW {{report_data?.total_group_coursework > 0 ? '- ' + report_data.total_group_coursework : '-'}}</th>
                                </tr>
                           </table>
                           </th>
                    </tr>
                    <tr>
                        <th class="subTitlehead">&nbsp;</th>
                        <th class="subTitlehead">&nbsp;</th>
                        <th class="subTitlehead">&nbsp;</th>
                        <th class="subTitlehead">Total CW To Be Marked</th>
                        <th class="subTitlehead">Marked</th>
                        <th class="subTitlehead">Unmarked</th>
                        <th class="subTitlehead">Total CW To Be Marked</th>
                        <th class="subTitlehead">Marked</th>
                        <th class="subTitlehead">Unmarked</th>
                    </tr>
                </thead>
                <tbody *ngIf="report_data">
                    <tr *ngFor="let my_report of report_data.teach_assistants">
                        <td>{{my_report.username}}</td>
                        <td>{{my_report.organisation}}</td>
                        <td>{{my_report.subscribers.length}}</td>
                        <td>{{my_report.sub_need_to_mark > 0 ? my_report.sub_need_to_mark : '-'}}</td>

                        <td>
                            {{
                                my_report.sub_need_to_mark > 0 ? my_report.marked_submissions : '-'
                            }}
                        </td>
                        <td>
                            {{
                                my_report.sub_need_to_mark > 0 ? (my_report.sub_need_to_mark - my_report.marked_submissions) : '-'
                            }}
                        </td>

                        <td>{{my_report.team_sub_need_to_mark > 0 ? my_report.team_sub_need_to_mark : '-'}}</td>

                        <td>
                            {{
                                my_report.team_sub_need_to_mark > 0 ?
                                my_report.team_marked_submissions : '-'
                            }}
                        </td>
                        <td>
                            {{
                                my_report.team_sub_need_to_mark > 0 ? (
                                my_report.team_sub_need_to_mark - my_report.team_marked_submissions) : '-'
                            }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>

</div>