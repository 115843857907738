import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-teaching-assistant-report',
  templateUrl: './teaching-assistant-report.component.html',
  styleUrls: ['./teaching-assistant-report.component.scss']
})
export class TeachingAssistantReportComponent implements OnInit {
  courseId = '';
  schedule_id = '';
  course_work_id = '';
  courseName = '';
  loader = false;
  report_data: any = [];
  activeIdNav:string = null
  constructor(private activatedRoute: ActivatedRoute, private route: Router, private course_service: CourseService) { }

  ngOnInit() {
    this.courseId = this.activatedRoute.snapshot.queryParamMap.get('course_id');
    if(this.activatedRoute.snapshot.queryParamMap.get('courseName') !== null){
      this.courseName = this.activatedRoute.snapshot.queryParamMap.get('courseName');
    } else if(this.activatedRoute.snapshot.queryParamMap.get('course') !== null){
      this.courseName = this.activatedRoute.snapshot.queryParamMap.get('course');
    } else{
      this.courseName = localStorage.getItem('courseName');
    }

    this.activeIdNav = this.activatedRoute.snapshot.queryParamMap.get('tab_no');
    this.course_work_id = this.activatedRoute.snapshot.queryParamMap.get('course_work_id');
    this.schedule_id = this.activatedRoute.snapshot.queryParamMap.get('schedule_id');

    this.get_report();
  }
  get_report() {
    this.loader = true;
    this.course_service.get_report(this.courseId).pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe(report => {
        this.report_data = report.response;
      },
        (error) => {

        })
  }

  myProgress(report) {
    if (report.subscribers.length > 0 && report['sub_need_to_mark'] > 0) {
      return ((report.marked_submissions * 100) / (report['sub_need_to_mark'])).toFixed(2)
    } else {
      return 0
    }
  }

  go_to_url() {
    let url;
    let url_obj;
    if (this.course_work_id) {
      url = 'courses/cw-teaching-assistant'
      url_obj = { course_id: this.courseId, coursename: this.courseName, course_work_id: this.course_work_id, schedule_id: this.schedule_id }
    } else {
      url = "/courses/single-course";
      url_obj = { course_id: this.courseId, course: this.courseName,tab_no:this.activeIdNav  }
    }

    this.route.navigate([url], { queryParams: url_obj });
  }
}