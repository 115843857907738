import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-course-level-report',
  templateUrl: './course-level-report.component.html',
  styleUrls: ['./course-level-report.component.scss']
})
export class CourseLevelReportComponent implements OnInit {

  generateCourseReport: FormGroup;
  showGenerateBtn: boolean = false;
  loader: boolean = false;
  courseId: string = '';
  canGenerateReport: boolean = false;
  canDownloadReport: boolean = false;
  downloadReportLink: string = '';
  downloadFileName: string = '';
  downloadDateTime: string = '';
  generateRequestStatusModal: any = null;  

  @ViewChild('generateStatusModal', { static: false }) generateStatusModal!: ElementRef;

  /**
  * To get instances of required services
  */
  constructor(private fb: FormBuilder, private courseService: CourseService, private notification: NotificationService, private activatedRoute: ActivatedRoute, private modalReview: NgbModal) { }

  /**
  * To initialize form and get query parameters
  */
  ngOnInit() {

    this.courseId = this.activatedRoute.snapshot.queryParamMap.get('course_id');
    this.initializeForm();
    this.getReportStatus();

    this.generateCourseReport.valueChanges.subscribe(change => {
      this.validateForm();
    });
  }

  /**
  * To create a course level report form
  */
  initializeForm() {
    this.generateCourseReport = this.fb.group({
      attendance: [false, []],
      coursework: [false, []],
      assessment: [false, []],
    });
  }

  /**
  * To call download report api to get generated report status
  */
  getReportStatus() {
    this.loader = true;
    this.courseService.getReportDownloadStatus(this.courseId).subscribe(response => {
      this.loader = false;
      this.canGenerateReport = response.is_generate;
      this.downloadReportLink = response.file_path;
      this.downloadFileName = response.file_name;
      this.downloadDateTime = response.updated_at;
    },
      (err) => {
        this.loader = false;
        this.notification.showNotification("error", err.message);
      });
  }

  /**
  * To validate form whether user can generate report or not
  * @return {boolean} - form validation status
  */
  validateForm() {
    let formStatus = false;
    const courseReports = this.generateCourseReport.value;
    if (courseReports.attendance || courseReports.coursework || courseReports.assessment) {
      this.showGenerateBtn = true;
      formStatus = true;
    }
    else {
      this.showGenerateBtn = false;
    }

    return formStatus;
  }

  /**
  * To call a generate report request api
  */
  generateReport() {
    const postData = this.generateCourseReport.value;
    postData['course_id'] = this.courseId;

    this.loader = true;
    this.courseService.generateCourseReport(postData).subscribe(response => {
      this.loader = false;
      this.showGenerateStatusModal();
    },
      (err) => {
        this.loader = false;
        this.notification.showNotification("error", err.message);
      });
  }

  /**
  * To open modal to show generate request status to user
  */
  showGenerateStatusModal() {
    this.generateRequestStatusModal = this.modalReview.open(this.generateStatusModal, { windowClass: 'open-model', size: 'sm', scrollable: false, centered: true, backdrop : 'static', keyboard : true });
  }

  /**
  * To close modal when user confirms generate request status
  */
  onConfirmStatus() {
    this.resetForm();    
    this.generateRequestStatusModal.close();
  }

  /**
  * To reset form and get back user to initital position
  */
  resetForm() {
    this.generateCourseReport.reset();
    this.canGenerateReport = false;
    this.downloadReportLink = '';
    this.downloadFileName = '';
    this.downloadDateTime = '';
  }

  /**
  * To download latest generated report
  */
  exportReport() {
    window.open(this.downloadReportLink, "blank")
  }

}
