<!-- Audio/Video player popup Box -->
<div class="modalCustom modalCustomLarge" *ngIf="openVideoAudioPopup">
    <div class="new_UIstyle">
        <div class="mBoxInner">
            <button type="button" class="close ml-0" aria-label="Close" (click)="closeAudioVideoPopup()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="mBoxInnerBody">
                <div class="playerWraper">
                    <video width="100%" height="500" controls autoplay disablepictureinpicture controlsList="noplaybackrate nodownload nofullscreen"  *ngIf="videoCondion">
                        <source [src]="videoUrl" type="video/mp4">
                         Your browser does not support the video tag.
                      </video>
 
                  <audio width="100%"  controls controlsList="noplaybackrate nodownload nofullscreen" *ngIf="audioCondion">
                    <source [src]="audioUrl" type="audio/mpeg">
                     Your browser does not support the audio element.
                  </audio>
                </div>
            </div>
        </div>
    </div>
</div>