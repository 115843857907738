<div class="new_UIstyle">
    <div class="pageBodyWrap pb-5">
        <!-- BANNER |START -->
        <div class="banner banner-gradient banner-course-assement">
            <div class="container">
                <div class="banner-content">
                    <div class="banner-content-content">
                        <h2 class="white-text avenirBlack">{{courseTitle}}: <br />
                            {{courseworks?.title}}
                        </h2>
                        <p class="white-text" *ngIf="courseworks?.is_group == true">Group Assignment:
                            <span class="manual_ass">
                                <strong class="ms-2 primary-text ml-5">{{courseworks?.group_type |
                                    capitalization}}</strong>
                            </span>
                        </p>
                        <p class="white-text">Coursework Start Date:
                            <span class="manual_ass">
                                <strong class="ms-2 primary-text ml-3">
                                    {{courseworks?.date | dateFormatFullmonth}}
                                </strong>
                            </span>
                        </p>
                        <p class="white-text">Start Time:
                            <span class="manual_ass">
                                <strong class="ms-2 primary-text coursework-starttime">
                                    {{courseworks?.time}}
                                </strong>
                            </span>
                        </p>
                        <p class="white-text">Status:
                            <span *ngIf="courseworks?.title" class="manual_ass">
                                <strong class="ms-2 primary-text coursework-submission-status">{{courseworks.publish ==
                                    true ? 'Marked' : 'Yet to be marked'}}</strong>
                            </span>
                        </p>
                    </div>
                    <div class="banner-content-action btn-groups">
                        <div class="spinner-border text-warning formLoader d-inline-block mr-4 inline-loader"
                            *ngIf="publishMarksLoader" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <button *ngIf="isAllowedPublishMarks" class="btn btn-primary btn-w-120" (click)="openPublishMarksModel(publishMarksModal)" [disabled]="publishMarksLoader">Publish Marks</button>
                        <button class="btn btn-primary" (click)="onGoBack('view-submissions', courseworks?.is_group)" [disabled]="publishMarksLoader">
                            <i class="fas fa-arrow-left"></i> Back</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- BANNER |END -->

        <!-- COURSEWORK DESCRIPTION |START -->
        <div class="row p-5">
            <div class="col-md-12 m-auto">
                <div class="container" [ngClass]="{submitting : loader || publishMarksLoader}">
                    <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
                        <span class="sr-only">Loading...</span>
                    </div>
                    
                    <div class="row">
                        <div class="col-12 col-lg-8 order-2 order-lg-1">
                            <p [innerHTML]="courseworks?.course_work_description"></p>
                        </div>
                        <div class="col-12 col-lg-4 order-1 order-lg-2 mb-2 mb-lg-0" *ngIf="isTeachingAssistant && courseworks.is_group == false">
                            <button class="btn btn-primary chat-btn btn-icon-text float-right"
                            (click)="openGroupCreateModel(assignSubsriber, 'Create')">Assign Subscriber</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- COURSEWORK DESCRIPTION |END -->

        <!-- My RESOURCES |START -->
        <div class="container">
            <div class="learning-course-items-wrap learning-course-resources-wrap mb-4"
                *ngIf="courseworks && courseworks?.course_work_resources?.length > 0">
                <div class="learning-course-item" *ngFor="let resource of courseworks?.course_work_resources">
                    <div class="learning-course-link">
                        <div class="learning-course-elem-left">
                            <p class="learning-course-link-title">
                                <span class="title-icon">
                                    <i class="fas fa-folder"></i></span>
                                <strong>{{resource.resource_title}}</strong>
                            </p>
                        </div>
                        <div class="learning-course-elem-right">
                            <ul class="list-icon-wrap">
                                <li>
                                    <a href="javascript:void(0);" class="learning-course-download"
                                        (click)="openResource(resource.resource_url)">
                                        <i class="fas fa-download"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- My RESOURCES |END -->

        <!-- STUDENT SUBMISSIONS |START -->
        <div class="container" [ngClass]="{submitting : loader || submissionLoader}">
            <div class="spinner-border text-warning formLoader" role="status" *ngIf="submissionLoader">
                <span class="sr-only">Loading...</span>
            </div>

            <!-- FILTER BLOCK START -->
            <div class="filter-elem-block filter-main-wrapper mb-4">
              <div class="filter-elem-item filter-elem-left">
                <div class="passion-calegory-wrap">
                  <div class="filterBarlist">
                    <button type="button" [ngClass]="{'active':submissionType === 'ontime'}" (click)="onViewAllSubmissions('ontime')">On-time Submissions</button>
                    <button type="button" [ngClass]="{'active':submissionType === 'late'}" (click)="onViewAllSubmissions('late')">Late Submissions</button>
                  </div>
                </div>
              </div>

              <!-- TOTOAL MARKED UNMARKED SUBMISSIONS BLOCK START -->
              <div class="filter-elem-item filter-elem-right">
                <div class="sort-elenm">
                    <p class="mb-0 mr-2">Marked: <strong><span class="primary-text">{{markedCount}}</span></strong></p>
                    <p class="mb-0">Unmarked: <strong><span class="primary-text">{{unmarkedCount}}</span></strong></p>
                </div>
              </div>
              <!-- TOTOAL MARKED UNMARKED SUBMISSIONS BLOCK END -->

              <!-- SORTING BLOCK START -->
              <div class="filter-elem-item filter-elem-right">
                <form>
                  <div class="sort-elenm">
                    <label class="mb-0"><small>Sort by:</small></label>
                    <select class="form-control form-select form-control-caret" (change)="onShowSortData($event.target.value)">
                      <option *ngFor="let item of sortingList" value="{{item.value}}" [selected]="item.value == sortBy">{{item.name}}</option>
                    </select>
                  </div>
                </form>
              </div>
              <!-- SORTING BLOCK END -->

            </div>
            <!-- FILTER BLOCK END -->

            <div class="row mb-5">
                <div class="col-md-6 mb-3" *ngFor="let item of filteredSubmissionsData">
                    <div class="teacher-course-list">
                        <div class="teacher-course-items teacher-course-items-vCenter mb-0">
                            <div class="col-md-8 butt_grp1">
                                <div class="teacher-course-items-detail">
                                    <h6 class="avenirBlack mb-1">
                                        <a href="javascript:void(0)">
                                            <span class="d-inline-block">
                                                {{item.profile_name | titlecase}}
                                            </span>
                                        </a>
                                    </h6>
                                    <span class="user-username" *ngIf="!courseworks.is_group">{{item.name}}</span>
                                    <div class="bt_bott">
                                        <div class="submission-subheading">Teaching Assistant:</div>
                                        <div *ngIf="item.teach_assis?.name && (item.is_reassigned == false && item.is_optional == false)">
                                            <span class="teach-assis-name">{{item.teach_assis.name}}</span>
                                            <span class="user-username ml-2">({{item.teach_assis.username}})</span>
                                        </div>

                                        <div *ngIf="item.teach_assis?.name && item.is_reassigned == true">
                                            <span class="teach-assis-name">{{item.teach_assis.name}}</span>
                                            <span class="user-username ml-2">({{item.teach_assis.username}})</span>
                                        </div>

                                        <div *ngIf="item.main_ta?.name && (item.is_reassigned == false && item.is_optional == true)">
                                            <span class="teach-assis-name">{{item.main_ta.name}}</span>
                                            <span class="user-username ml-2">({{item.main_ta.username}})</span>
                                        </div>


                                        <div *ngIf=" item.is_reassigned == false && (item.is_reassigned == false && item.is_optional == false) && (item.teach_assis | json) == '{}'">
                                            <span class="teach-assis-name">Not Assigned</span>
                                            <span class="user-username ml-2"></span>
                                        </div>



                                        <!-- <ng-container *ngIf="(item.main_ta?.name && item.is_reassigned == true) || (item.teach_assis?.name && (item.is_reassigned == false && item.is_optional == true))">
                                            <div class="submission-subheading mt-2">Marked By:</div>
                                            <div *ngIf="item.main_ta?.name && item.is_reassigned == true">
                                                <span class="teach-assis-name">{{item.main_ta.name}}</span>
                                                <span class="user-username ml-2">({{item.main_ta.username}})</span>
                                            </div>

                                            <div *ngIf="item.teach_assis?.name && (item.is_reassigned == false && item.is_optional == true)">
                                                <span class="teach-assis-name">{{item.teach_assis.name}}</span>
                                                <span class="user-username ml-2">({{item.teach_assis.username}})</span>
                                            </div>

                                            <div *ngIf="item.is_reassigned == false && (item.is_reassigned == false && item.is_optional == false) && (item.main_ta | json) == '{}'">
                                                <span class="teach-assis-name">Not Assigned</span>
                                                <span class="user-username ml-2"></span>
                                            </div>
                                        </ng-container> -->

                                          <ng-container *ngIf="item.marked_by.name">
                                            <div class="submission-subheading mt-2">Marked By:</div>
                                            <div>
                                                <span class="teach-assis-name">{{item.marked_by.name}}</span>
                                                <span class="user-username ml-2">({{item.marked_by.username}})</span>
                                            </div>
                                        </ng-container>

                                        
                                        
                                        <p *ngIf="item.timestamp" class="user-username">{{item.timestamp}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 butt_grps">
                                <div class="justify-content-end butt_grp">
                                    <button class="btn btn-primary d-block mar_courwo"
                                        (click)="onMarkCoursework(item.user_id, item.name)" type="button" [disabled]="item.submission_files === false" 
                                        *ngIf="(!item.is_reassigned == true && !item.marked_status == false) || (item.is_reassigned == false && item.marked_status == false)">
                                        Mark Coursework
                                    </button>

                                    <button class="btn btn-primary d-block mar_courwo"
                                        (click)="onMarkCoursework(item.user_id, item.name)" type="button" 
                                        *ngIf="item.is_reassigned == true && item.marked_status == true">
                                        View Coursework
                                    </button>
                                    <div *ngIf="item.submission_files === false" class="mb-2 mt-2">
                                        <span class="teach-assis-name">No submissions available</span>
                                    </div>
                                    <a href="javascript:void(0);" *ngIf="item.marked_status"
                                        class="d-block mark_super no-thumb-hover" type="text">
                                        <i class='fas fa-check-double' style='font-size:11px'></i> Marked
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" *ngIf="!loader && !submissionLoader && filteredSubmissionsData.length == 0">
                    <div class="alert alert-info text-center">
                        <p>{{submissionType === 'late' ? 'There are no late submissions.' : 'There are no on-time submissions.'}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- STUDENT SUBMISSIONS |END -->
    </div>
</div>

<!-- MODALS -->
<ng-template #publishMarksModal let-c="close" let-d="dismiss">
    <div class="new_UIstyle">
        <div class="modal-header justify-content-between align-items-center">
            <h6 class="modal-title avenirBlack primary-text">Publish Marks</h6>
            <button type="button" class="close ml-0" aria-label="Close" (click)="closePublishMarksModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Are you sure you want to publish marks ?</p>
        </div>
        <div class="modal-footer">
            <div class="text-left">
                <button type="button" class="btn btn-primary" (click)="closePublishMarksModal()">Cancel</button>
            </div>
            <div class="text-right">
                <button type="button" class="btn btn-primary" (click)="onPublishMarks()">Yes</button>
            </div>
        </div>
    </div>
</ng-template>


<!-- MODAL CREATE TEAMS |START-->
<ng-template #assignSubsriber let-modal>
    <div class="new_UIstyle " [ngClass]="{submitting : groupProcessing}">
        <div class="create_mod">
            <div class="modal-header">
                <h5 class="avenirBlack blue-lt-text d-flex d-lg-inline-block mb-0">
                    Assign Subscriber to Teaching Assistant</h5>
                <button type="button" class="close close_pop" aria-label="Close" (click)="closeGroupCreateModel()">
                    <span aria-hidden="true" class="text-white">&times;</span>
                </button>
            </div>
            <div class="modal-body modalGroupBody crea_mod">
                <div class="spinner-border text-warning formLoader" role="status" *ngIf="groupProcessing">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

            <div class="modal-body modal_body_custom">
                <form>
                    <div class="row">
                        <div class="col-6">
                            <div class="header_title border-bottom  pt-1 pb-1">
                                <h6 class="avenirBlack mb-0 grey-text">Select Subscribers</h6>
                            </div>
                            <div class="body_wrap mt-1">
                            <ng-container *ngIf="studentsList.length > 0">
                                <div class="list" *ngFor="let student of studentsList; let i = index;" [ngClass]="{'active': (student.isAdded || student.is_assign) , 'disabled': student.is_assign}">
                                    <figure class="listFig">
                                        <img [src]="defaultAvtar" *ngIf="student.profile_pic == ''" alt="Avtar" />
                                        <img [src]="student.profile_pic" *ngIf="student.profile_pic !== ''" alt="Avtar"/>
                                    </figure>
                                    <div class="listContent">
                                        <div class="listContentLeft">
                                            <p class="mb-0"><b>{{student.name}}</b></p>
                                            <p><small>{{student.organisation}}</small></p>
                                        </div>
                                        <div class="listContentRight">
                                            <span class="reassigned_tag" *ngIf="student.is_assign">Already Assigned.</span>
                                            <div class="custom-control custom-checkbox custom-grey" *ngIf="!student.is_assign">
                                                <input type="checkbox" class="custom-control-input" id="_id_{{i}}" [checked]="student.isAdded" (change)="assignStudentBtn(student, $event, i)"/>
                                                <label class="custom-control-label" for="_id_{{i}}"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                                <div class="alert alert-info" *ngIf="studentsList.length == 0 && !students_loader">
                                    <p>No Data Found.</p>
                                </div>

                                <div class="text-center" *ngIf="students_loader">
                                    <div class="spinner-border text-warning formLoader"  role="status">
                                      <span class="sr-only">Loading...</span>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-6 border-left">
                            <div class="header_title  border-bottom pt-1 pb-1">
                                <h6 class="avenirBlack mb-0 grey-text">Select Teaching Assistant</h6>
                            </div>
                            <div class="body_wrap mt-1">
                            <ng-container *ngIf="getAssignList.length > 0">
                                 <ng-container *ngFor="let TA of getAssignList; let i = index;">
                                    <div class="list"  [ngClass]="i == selectedTechindex ? 'active':''" (click)="selectedTech(TA, i)">
                                        <figure class="listFig">
                                            <img [src]="defaultAvtar" *ngIf="TA.profile_pic == ''" alt="Avtar" />
                                            <img [src]="TA.profile_pic" *ngIf="TA.profile_pic !== ''" alt="Avtar" />
                                        </figure>
                                        <div class="listContent">
                                            <div class="listContentLeft">
                                                <p class="mb-0"><b>{{TA.first_name}} {{TA.last_name}}</b></p>
                                                <p><small>{{TA.organisation}}</small></p>
                                            </div>
                                            <div class="listContentRight">
                                                <div class="custom-control custom-radio">
                                                    <input type="radio" name="TA" class="custom-control-input" id="_idt_{{i}}" [checked]="i == selectedTechindex"/>
                                                    <label class="custom-control-label" for="_idt_{{i}}"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                 </ng-container>
                            </ng-container>

                            <div class="alert alert-info" *ngIf="getAssignList.length == 0 && !ta_loader">
                                <p>No Data Found.</p>
                            </div>

                            <div class="text-center" *ngIf="ta_loader">
                                <div class="spinner-border text-warning formLoader"  role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                            </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <button class="btn btn-sm btn-primary" (click)="closeGroupCreateModel()">Cancel</button>
                <button class="btn btn-sm btn-primary" (click)="onSubmitRequest()" [disabled]="submtDataLoader">{{!submtDataLoader ? 'Submit' : 'Processing...'}}</button>
            </div>
        </div>
    </div>
</ng-template>
<!-- MODAL CREATE TEAMS |END-->


