import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

import { NotificationService } from 'src/app/services/notification.service';
import { CourseService } from 'src/app/services/course.service';
import { finalize } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseAssessmentShareService } from 'src/app/services/course-assessment-share.service';
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  courseAssessmentData: any;
  courseId: any;
  course_session_id: any;
  topic_id: any;
  user_id: any;
  publishRight: any;
  _id: any;
  schedule_id: any;

  course_category: any;
  course_category_id: any;
  sessionkey: any;
  courseName: any;
  course__Id: any;
  assessmentId: any;
  assessment_name: any;
  schedule__id: any;
  start__time: any;
  topicId: any;
  session_id: any;
  user__id: any;
  assSubData: any;
  assessment_result_id: any;
  isTA:string = 'false';
  @Output() valueChange = new EventEmitter();
  @Output() title = new EventEmitter();
  loader: boolean;
  feedBackData: any;
  role = '';
  activeIdNav:any = 1;
  @Input() view_teacher_assessment;


  // audio/video modaL
  openVideoAudioPopup:boolean = false
  openVideoAudioStudentPopup:boolean = false


  ngbNavItem:any = 1;
  constructor(private _notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private _courseAssessmentShareService: CourseAssessmentShareService,
    private courseService: CourseService) { }

  ngOnInit() {
    this.courseAssessmentData = this._courseAssessmentShareService.getCourseAssessment();
    this.courseId = this._courseAssessmentShareService.getCourseId();
    this.topic_id = this._courseAssessmentShareService.getTopicId();
    this.user_id = this._courseAssessmentShareService.getUserId();
    this.course_session_id = this._courseAssessmentShareService.getCourseSessionId();
    this.publishRight = this._courseAssessmentShareService.getpublishRight();
    this.user_id = this._courseAssessmentShareService.getUserId();
    this.course_session_id = this._courseAssessmentShareService.getCourseSessionId();
    this.publishRight = this._courseAssessmentShareService.getpublishRight();
    this.role = localStorage.getItem('role');
    this.sessionkey = this._courseAssessmentShareService.getSessionKey();
    this.course_category_id = this._courseAssessmentShareService.gercourse_category_id();
    this.course_category = this._courseAssessmentShareService.getCourse_Category();
    this.assSubData = this._courseAssessmentShareService.getCourseAssessment();



    this.courseName = this.activatedRoute.snapshot.queryParamMap.get('course');
    this.course__Id = this.activatedRoute.snapshot.queryParamMap.get('course_id');
    this.course_category = this.activatedRoute.snapshot.queryParamMap.get('course_category');
    this.course_category_id = this.activatedRoute.snapshot.queryParamMap.get('course_category_id');
    this.assessmentId = this.activatedRoute.snapshot.queryParamMap.get('assessment_id');
    this.assessment_name = this.activatedRoute.snapshot.queryParamMap.get('assessment_name');
    this.schedule__id = this.activatedRoute.snapshot.queryParamMap.get('schedule_id');
    this.start__time = this.activatedRoute.snapshot.queryParamMap.get('start_time');
    this.topicId = this.activatedRoute.snapshot.queryParamMap.get('topicId');
    this.session_id = this.activatedRoute.snapshot.queryParamMap.get('session_id');
    this.user__id = this.activatedRoute.snapshot.queryParamMap.get('user__id');
    this.assessment_result_id = this.activatedRoute.snapshot.queryParamMap.get('assessment_result_id');
    this.isTA = this.activatedRoute.snapshot.queryParamMap.get('isTA');
    this.activeIdNav = Number(this.activatedRoute.snapshot.queryParamMap.get('tab_no'));
    this.ngbNavItem = this.activatedRoute.snapshot.queryParamMap.get('ngbNavItem');
    
    if (this.session_id == (undefined || null)) {
      this.session_id = '';
    }
    if (this.topicId == (undefined || null)) {
      this.topicId = '';
    }
    if (this.user__id == (undefined || null)) { 
      this.user__id = '';
    }
    if (this.user_id == (undefined || null)) {
      this.user_id = '';
    }
    if (this.isTA == (undefined || null)) {
      this.isTA = 'false';
    }
    if (this.course_session_id == null) {
      this.course_session_id = '';
    }

    if ((this.role != 'student') || (this.role == 'student' && this.isTA == 'true') || (this.isTA == 'true')) {
      this.sumaryResult = false;
    }
    this.getFeedback();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    if(event.keyCode === 123 || event.keyCode === 42 || (event.keyCode === 42 && event.key === 'p')){ 
      event.preventDefault(); 
    } 
    if (event.ctrlKey && 'cvxspwuaz'.indexOf(event.key) !== -1) {
      event.preventDefault()
    }
  }

  is_option_student_answer;
  questionValidation;
  multiQuestionValid
  assName: any;
  totalMarks = 0;
  getMarks;
  studentMarks;
  studentgrade;
  newObj;
  calcPercnt: number;
  studentMarkTotal: number = 0;
  studentMarktype: number;
  isCurrent_user_remark:boolean;
  reviewCompleted: boolean = false;
  getFeedback() {
    this.loader = true;
    let errorMsgg = true;
    if (this.role == 'student') {
      this.courseService.feedbackAssessment(this.assessmentId, this.course__Id, this.schedule_id, this.user__id, this.topicId, this.session_id, this.assessment_result_id).pipe(
        finalize(() => {
        })).subscribe(
          (response) => {
            this.feedBackData = response;
            this.loader = false;
            let multiAns;
            this.assName = response.assessmentName;
            //SHOW FEEDBACK DATA
            this.totalMarks = this.feedBackData.total_marks;
            this.calcPercnt = this.feedBackData.user_percentage.toFixed(0);
            this.studentMarkTotal = this.feedBackData.user_score;
            this.reviewCompleted = this.feedBackData.review_completed;

            for (let i = 0; i < this.feedBackData.questions.length; i++) {
              //get student mark from grades
              this.studentgrade = this.feedBackData.questions[i].grades;
              this.studentMarks = JSON.parse(JSON.stringify(this.studentgrade))
              this.studentMarktype = +this.studentMarks.content;
              // Check if value is NaN theh show 0
              if (isNaN(this.studentMarktype)) {
                this.studentMarktype = 0;
              }
              /*this.studentMarkTotal += this.studentMarktype;*/

              // Get Total Marks of Assessment
              this.getMarks = this.feedBackData.questions[i].max_marks;
              /*this.totalMarks += this.getMarks;*/
              // Calculate percentage
              /*this.calcPercnt = (this.studentMarkTotal * 100) / this.totalMarks;
              this.calcPercnt = +this.calcPercnt.toFixed(0);*/
              //  get questions option and types
              this.questionValidation = this.feedBackData.questions[i].options;
              let questype = this.feedBackData.questions[i].question_type;

              //  if type is text or FIB then we are getting 1 array item so we need to use this check
              // if (questype == 'text' || questype == 'fib') {
              //   for (let ii = 0; ii < this.feedBackData.questions[i].options.length; ii++) {
              //     let optionss = this.feedBackData.questions[i].options[ii];
              //     this.is_option_student_answer = optionss.is_option_student_answer;
              //   }
              // }
            }

            this.title.emit(response.assessmentName);

          },
          (error) => {
            this._notificationService.showNotification("error", error.message);
            this.valueChange.emit('closeFeedbackModal');
          })
    }

    if (this.role != 'student') {
      this.courseService.feedbackAssessment(this.assessmentId, this.course__Id, this.schedule__id, this.user__id, this.topicId, this.session_id, this.assessment_result_id).pipe(
        finalize(() => {
        })).subscribe(
          (response) => {
            this.feedBackData = response;
            // console.log('resp', response)
            this.loader = false;
            let multiAns;
            this.assName = response.assessmentName;
            //SHOW FEEDBACK DATA
            this.totalMarks = this.feedBackData.total_marks;
            this.calcPercnt = this.feedBackData.user_percentage.toFixed(0);
            this.studentMarkTotal = this.feedBackData.user_score;
            this.reviewCompleted = this.feedBackData.review_completed;
            
            // console.log(response.current_user_remark)
            this.isCurrent_user_remark = response.current_user_remark;
            for (let i = 0; i < this.feedBackData.questions.length; i++) {
              //get student mark from grades
              this.studentgrade = this.feedBackData.questions[i].grades;
              this.studentMarks = JSON.parse(JSON.stringify(this.studentgrade))
              this.studentMarktype = +this.studentMarks.content;
              // Check if value is NaN theh show 0
              if (isNaN(this.studentMarktype)) {
                this.studentMarktype = 0;
              }
              /*this.studentMarkTotal += this.studentMarktype;*/

              // Get Total Marks of Assessment
              this.getMarks = this.feedBackData.questions[i].max_marks;
              /*this.totalMarks += this.getMarks;*/
              // Calculate percentage
              /*this.calcPercnt = (this.studentMarkTotal * 100) / this.totalMarks;
              this.calcPercnt = +this.calcPercnt.toFixed(0);*/
              //  get questions option and types
              this.questionValidation = this.feedBackData.questions[i].options;
              let questype = this.feedBackData.questions[i].question_type;

              //  if type is text or FIB then we are getting 1 array item so we need to use this check
              // if (questype == 'text' || questype == 'fib') {
              //   for (let ii = 0; ii < this.feedBackData.questions[i].options.length; ii++) {
              //     let optionss = this.feedBackData.questions[i].options[ii];
              //     this.is_option_student_answer = optionss.is_option_student_answer;
              //   }
              // }
            }

            this.title.emit(response.assessmentName);

          },
          (error) => {
            this._notificationService.showNotification("error", error.message);
            this.valueChange.emit('closeFeedbackModal');
          })
    }
  }


  // remarkQuestion(remark_form: NgForm, fbData) {
  //   if (!remark_form.controls.remark.value) {
  //     this._notificationService.showNotification('info', 'please add some remark');
  //     return;
  //   }
  //   this.add_feedback(remark_form, fbData);
  // }

  // add_grade(remark_form: NgForm, fbData) {
  //   if (!remark_form.controls.grade) {
  //     this._notificationService.showNotification('info', 'please add the grade');
  //     return;
  //   }
  //   if (Number(remark_form.controls.grade.value) > fbData.max_marks) {
  //     this._notificationService.showNotification('info', 'Please add less than or equal too mark than max mark');
  //     return;
  //   }
  //   this.add_feedback(remark_form, fbData);
  // }



  addGradeSubmit(remark_form: NgForm, fbData) {
    // if (!remark_form.controls.remark.value && (remark_form.controls.grade.value < 0 || !remark_form.controls.grade || !remark_form.controls.grade.value)) {
    //   this._notificationService.showNotification('info', 'Please add the grade and some remark');
    //   return;
    // }
    // if (!remark_form.controls.remark.value) {
    //   this._notificationService.showNotification('info', 'Please add some remark');
    //   return;
    // }
    if (remark_form.controls.grade.value === '' || remark_form.controls.grade.value == null) {
      this._notificationService.showNotification('info', 'Please add the grade');
      return;
    }
    // if (!remark_form.controls.grade || (remark_form.controls.grade.value == '' || remark_form.controls.grade.value == null)) {
    //   this._notificationService.showNotification('info', 'Please add the grade');
    //   return;
    // }
    if (Number(remark_form.controls.grade.value) > fbData.max_marks) {
      this._notificationService.showNotification('info', 'Please add less than or equal too mark than max mark');
      return;
    }


    this.add_feedback(remark_form, fbData);
    setTimeout(() => {
      this.getFeedback();
    }, 100);
  }

  resetAddGrade(remark_form, fbData) {
    // if(fbData.question_type == 'fib' || fbData.question_type == 'text'){
    //   remark_form.controls.grade.reset();
    // }
    remark_form.controls.remark.reset();
  }

  add_feedback(remark_form, fbData) {
    this.loader = true;
    let topic_id = this.topicId;
    this.courseService.remark_assessment(remark_form, this.schedule__id, topic_id, this.course_session_id, this.assessmentId, this.course__Id, this.user__id, fbData._id, this.assessment_result_id).pipe(finalize(() => {
      this.loader = false;
    })).subscribe(view_remark => {
      this._notificationService.showNotification('success', "Added Successfully")
    },
      (error) => {
        this._notificationService.showNotification("error", error.message);
      })
  }

  // onlyNumbers(grade_Form) {
    // let updatedValue = grade_Form.controls.grade.value.replace(/[^0-9]/g, '');
    // grade_Form.controls.grade.setValue(grade_Form.controls.grade.value);
  // }


  go_to_url() {
    let url = '/courses/single-course'
    let body;
    if (this.course_session_id) {
      body = {
        course_id: this.course__Id,
        session_id: this.course_session_id,
        course_category: this.course_category,
        course_category_id: this.course_category_id,
        topicId:this.topicId,
        sessionkey: this.sessionkey,
      }
      url = '/courses/video-with-notes'
    } else {
      body = {
        course_id: this.course__Id
      }
    }
    this.route.navigate([url], { queryParams: body })
  }

  
  go_to_list() {
    let url;
    let body;
    
    // if((this.role != 'student') || (this.role == 'student' && this.isTA == 'true') || (this.isTA == 'true')){
    //    url = '/courses/assessment-marking';

    //    body = {
    //     course_id: this.course__Id,
    //     assessment_id: this.assessmentId,
    //     assessment_name: this.assessment_name,
    //     assessment_result_id: this.assessmentId,
    //     submitted_time: this.start__time,
    //     schedule_id: this.schedule_id,
    //     session_id: this.session_id,
    //     course_category: this.course_category,
    //     course_category_id: this.course_category_id,
    //     topicId:this.topicId,
            // user__id:this.user__id,
    //     sessionkey: this.sessionkey,
    //     isTA: this.isTA
    //   }
    // } 
    // else if((this.role == 'student') || (this.role == 'student' && this.isTA == 'false') || (this.isTA == 'false')){
      url = '/courses/user-submissions';
      body = {
        course_id:this.course__Id,
        course:this.courseName,
        assessment_id: this.assessmentId, 
        assessment_name: this.assessment_name,
        assessment_result_id: this.assessmentId,
        submitted_time: this.start__time,
        schedule_id: this.schedule_id,
        session_id:this.session_id,
        course_category:this.course_category,
        course_category_id:this.course_category_id,
        topicId:this.topicId, 
        user__id:this.user__id,
        sessionkey: this.sessionkey,
        isTA: this.isTA,
        tab_no:this.activeIdNav,
        ngbNavItem: this.ngbNavItem
      }

    // }
    this.route.navigate([url], { queryParams: body })
  }



  isBlcokTrue: boolean = false;
  itemm = 0;

  nextItem() {
    this.itemm++;
  }
  prevItem() {
    if (this.itemm > 0 || this.itemm != 0) {
      this.itemm--;
    }
    else if (this.itemm === 0) {
      this.sumaryResult = true;
    }
    // console.log(this.itemm);
  }


  goSummary: boolean = false;
  goQustn(v: any, iQst) {
    this.itemm = v.target.id;
    this.isBlcokTrue = false;
    this.sumaryResult = false;
    
    // this.newArrayItem = [];

    if (this.itemm == iQst) {
      this.goSummary = true;
    }
  }
  goToSummary() {
    this.goSummary = false;
    this.isBlcokTrue = true;
    this.sumaryResult = true;
  }

  sumaryResult: boolean = true;
  reviewOneByOne() {
    this.itemm = 0;
    this.sumaryResult = false;
    this.isBlcokTrue = false;
  }


  openAudioVideoPopup() {
    this.openVideoAudioPopup = true;
  }
  closeAudioVideoPopup() {
    this.openVideoAudioPopup = false;
  }

  openAudioVideoStudentPopup() {
    this.openVideoAudioStudentPopup = true;
  }

  closeAudioVideoStudentPopup() {
    this.openVideoAudioStudentPopup = false;
  }
}
