<ng-template #alertModal let-c="close" let-d="dismiss">
    <div class="new_UIstyle">
        <div class="modal-header justify-content-between align-items-center">
            <h6 class="modal-title avenirBlack primary-text">{{alertHeading}}</h6>
            <button type="button" class="close ml-0" aria-label="Close" (click)="closeAlertModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>{{alertMessage}}</p>
        </div>
        <div class="modal-footer">
            <div class="text-left">
                <button type="button" class="btn btn-primary" (click)="closeAlertModal()">OK</button>
            </div>
        </div>
    </div>
</ng-template>