<!-- New Code -->
<div class="pageBodyWrap new_UIstyle">
  <!-- banner section -->
<div class="banner banner-gradient banner-monitoring">
   <div class="container">
        <div class="banner-content">
            <div class="banner-content-content">
                <h2 class="white-text avenirBlack">Teaching Assistants  <br /><small> Assign subscribers to teaching assistants</small></h2>
                  <!-- <p class="white-text">Group Assignment: <strong class="ms-2 primary-text">Student - Led</strong></p> -->
            </div>
            <div class="banner-content-action btn-groups">
                 <button class="btn btn-primary" (click)="go_to_url('/courses/single-course')"><i class="fas fa-arrow-left mr-2"></i> Back</button>
            </div>
        </div>
   </div>
</div>


<section class="section assign-subscriber"  [ngClass]="{submitting : loader}">
   <div class="container">
    <div class="spinner-border text-warning formLoader" role="status" *ngIf="loader">
      <span class="sr-only">Loading...</span>
    </div>
      <div class="row assign-subscriber-block border">
          <div class="col-lg-4 assignsubCol assignsubColLeft border-right">
                <div class="assignsubHeader border-bottom">
                  <h5 class="primary-text avenirBlack mb-0">Teaching Assistant</h5>
                  <p class="text-muted">Total Teachers: <b>{{getAssignList?.length}}</b></p>
                </div>
                <div class="assignsubBody">
                  <ng-container *ngIf="getAssignList != undefined && getAssignList?.length > 0">
                    <div class="assignsubItems border-bottom" *ngFor="let teachassis of getAssignList; index as i;" [ngClass]="i == selectedTechindex ? 'active':''" (click)="selectedTech(teachassis, i)">
                        <figure class="assignsubItemsFig">
                          <img [src]="teachassis?.profile_pic" alt="avatar-default.jpg" />
                        </figure>
                        <div class="assignsubItemsContent">
                          <h6 class="avenirBlack mb-0 text-truncate">{{teachassis.name}}</h6>
                          <p>Total students assigned: <b>{{teachassis?.assignees?.length}}</b></p>
                        </div>
                        <i class="fas fa-angle-right leftAngel"></i>
                      </div>
                  </ng-container>
                    <div class="alert alert-info text-center" *ngIf="!loader && getAssignList?.length == 0"> No data found.</div>
                </div>
          </div>
          <div class="col-lg-8 assignsubCol assignsubColRight">
              <div class="assignsubHeader border-bottom">
                <div class="row align-items-center">
                  <div class="col-4">
                    <h5 class="primary-text avenirBlack mb-0">Students</h5>
                    <p class="text-muted">Total Students: <b>{{getSubscribeStudentList.length}}</b></p>
                  </div>
                  <div class="col-8 btn-groups justify-content-end" [ngClass]="this.getAssignList.length == 0 ? 'disabled':''">
                    <!-- <button type="button" (click)="shuffleList()" class="btn btn-sm btn-primary btn-w-100">Shuffle</button> -->
                    <button *ngIf="resetAll" (click)="resetAllList()" type="button" class="btn btn-sm btn-primary btn-w-100">Reset All</button>
                  </div>
                </div>
              </div>
              <div class="assignsubBody col-12">
                <div class="form-row">
                  <div class="col-xl-3 col-lg-4 col-md-6 col-6 mb-3" *ngFor="let subscriber of getSubscribeStudentList; index as i;">
                    <div class="assignsubStudentBox" 
                    [ngClass]="this.getAssignList.length == 0 ? 'disabled':''" >
                      <button *ngIf="subscriber.isRemoved" class="remove-Btn" type="button" (click)="removefromTechAssis(subscriber, i)"><i class="fas fa-minus-circle"></i></button>
                      <figure>
                        <img *ngIf="!subscriber.profile_pic" [src]="defaultAvtar" alt="avatar-default.jpg" />
                        <img *ngIf="subscriber.profile_pic" [src]="subscriber.profile_pic" alt="avatar-default.jpg" />
                      </figure>
                      <h6 class="avenirBlack text-truncate mb-0">{{subscriber.name | capitalization}}</h6>
                      <p class="text-muted text-truncate" [title]="subscriber.email">{{subscriber.email}}</p>
                      
                      <label for="buttonAdd-{{i}}" class="btn " (click)="assignStudentBtn(subscriber,true, i)" [ngClass]="subscriber.isAdded ? 'btn-green disabled':'btn-green-border'">
                        <span><i class="fas" [ngClass]="subscriber.isAdded ? 'fa-check-circle':'fa-plus-circle'"></i> {{subscriber.isAdded ? 'ADDED' : 'ADD'}}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      <div class="btn-groups justify-content-center mt-4  " [ngClass]="this.getAssignList.length == 0 ? 'disabled':''">
        <!-- <button type="button" class="btn btn-sm btn-primary btn-w-100" (click)="go_to_url('/courses/teaching-assistant-report')">View Report</button> -->
        <button type="button" class="btn btn-sm btn-primary btn-w-100"  (click)="submit()">Save</button>
      </div>
   </div>
</section>

</div>





<!-- old code  -->
<!-- 
<div class="pageBody d-none"> 
  <div class="container" [ngClass]="{submitting : loader}">
    <div class="spinner-border text-warning formLoader" role="status" [ngClass]="{submitting : loader}" *ngIf="loader">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col-12 col-sm-3 pb-3 pb-sm-0">
        <label class="d-inline-block mb-0 mr-3">Assign by:</label>
        <div class="d-inline-block mr-3"> -->

          <!-- no need to uncomment code start-->
          <!-- <select class="custom-select" (change)="onChangeAssign($event)">
            <option value="manual">Manual</option>
            <option value="auto">Auto</option>
          </select> -->
          <!-- <button type="button" class="btn btn-primary" (click)="generateOptions()" [disabled]="this.mySubscriber(getSubscribeStudentList).length == 0">Shuffle</button>  -->
          <!-- no need to uncomment code end-->
        <!-- </div>
      </div>
      <div class="col-12 col-sm-6 text-center pb-3 pb-sm-0">
        <button type="button" class="btn btn-link p-0" (click)="go_to_url('/courses/teaching-assistant-report')"><i class="fas fa-eye mr-3"></i>View Report</button>
      </div>
      <div class="col-12 col-sm-3 text-right pb-3 pb-sm-0">
        <button type="button" class="btn btn-primary" (click)="submit()"><i class="fas fa-save mr-2"></i>Save</button>
      </div>      
    </div> 

    <div id="manualAssignment" *ngIf="assignType == 'manual'" cdkDropListGroup>
      <p class="text-center text-muted"><small>(Drag the subscriber(s) into assistant boxe(s) below to assign
          manually)</small></p>
      <div class="genBxHldr mb-4">
        <h2>Subscribers</h2>
        <div class="bxWrap"> -->

        <!-- no need to uncomment code start-->

          <!-- <div class="row no-gutters dndList" 
          cdkDropList 
          #subsList="cdkDropList"
          [cdkDropListData]="mySubscriber(getSubscribeStudentList)" 
          [cdkDropListConnectedTo]="[assistList]"
          (cdkDropListDropped)="drop($event)"> -->
            <!-- <div> -->
              <!-- <div class="col-12 col-lg-4 mb-2 dndBx" *ngFor="let item of mySubscriber(getSubscribeStudentList)" cdkDrag> -->
                <!-- <div class="dndPH" *cdkDragPlaceholder></div> -->
                <!-- <figure><img [src]='item?.profile_pic' onerror="this.src='assets/images/avatar-default.jpg'"  alt="profile_pic"></figure> -->
                <!-- <div class="bxD"> -->
                  <!-- <h4>{{item?.username}}</h4> -->
                  <!-- <p>{{item?.organisation}}</p> -->
                <!-- </div> -->
              <!-- </div> -->
            <!-- </div> -->
            
          <!-- </div> -->

      <!-- no need to uncomment code end-->

        <!-- </div>
      </div> 

      <div class="asstFltContainer" 
      *ngIf="getAssignList != undefined && getAssignList.length > 0">

        <div class="col-12 col-lg-4" *ngFor="let teachassis of getAssignList"
        cdkScrollable
        orientation="horizontal" >
          <div class="genBxHldr mb-4">
            <h2 ngbTooltip="Lorem Ipsum Dolor Sit" container="body"> {{teachassis.username}}</h2>
            <div class="bxWrap">
              <div class="row no-gutters dndList" 
              cdkDropList
              #assistList="cdkDropList"
              [cdkDropListData]="teachassis.assignees" 
              [cdkDropListConnectedTo]="[subsList]"
              (cdkDropListDropped)="drop($event)">
                <div class="col-12 mb-2 dndBx" *ngFor="let item of teachassis.assignees" cdkDrag> -->
                  <!-- <div class="dndPH" *cdkDragPlaceholder></div> -->
                  <!-- <figure><img [src]='item?.profile_pic'  onerror="this.src='assets/images/avatar-default.jpg'" alt="profile_pic"></figure>
                  <div class="bxD">
                    <h4>{{item?.username}}</h4>
                    <p>{{item?.organisation}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </div>

    <div id="autoAssign" *ngIf="assignType == 'auto'">
      <p class="text-center mb-0 text-muted"><small>(Auto assigned subscribers are listed below)</small></p>

      <div class="asstFltContainer autoAssgn">

        <div class="col-12 col-lg-4" *ngFor="let teachassis of getAssignList; let index_assig: index">
          <div class="genBxHldr mb-4">
            <h2 ngbTooltip="Lorem Ipsum Dolor Sit" container="body">{{teachassis.username}}</h2>
            <div class="bxWrap">
              <div class="row no-gutters dndList" cdkDropList #assistListauto="cdkDropList" [cdkDropListData]="result"
                (cdkDropListDropped)="drop($event)">
                <div class="col-12 mb-2" *ngFor="let item of  findValue(result,index_assig); let i = index;">
                  <div class="dndBx"> -->
                    
                    <!-- <figure><img [src]='item.subsc_dp' alt=""></figure> -->
                    <!-- <div class="bxD">
                      <h4>{{item?.username}}</h4>
                      <p>{{item?.organisation}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div> -->