import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-custom-alert-message',
  templateUrl: './custom-alert-message.component.html',
  styleUrls: ['./custom-alert-message.component.scss']
})
export class CustomAlertMessageComponent implements OnInit {

  //FOR DELETE CONFIRM POPUP
  alertModalRef: any = null;
  @ViewChild('alertModal', { static: false }) alertModal!: ElementRef;
  @Input('heading') alertHeading: string = '';
  @Input('message') alertMessage: string = '';
  @Input() showAlertBox: boolean = false;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('showAlertBox' in changes) {
      if (!changes['showAlertBox'].isFirstChange()) {
        console.log(changes['showAlertBox']);
        this.openAlertModal();
      }
    }
  }

  openAlertModal() {
    this.alertModalRef = this.modalService.open(this.alertModal);
  }

  closeAlertModal() {
    this.alertModalRef.close();
    return;
  }

}
