import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionService } from 'src/app/services/question.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { finalize } from 'rxjs/internal/operators/finalize';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { tr } from 'date-fns/locale';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UploadFilesService } from '../../services/upload-files.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


declare var tinymce: any;


@Component({
  selector: 'app-give-your-answer',
  templateUrl: './give-your-answer.component.html',
  styleUrls: ['./give-your-answer.component.scss']
})
export class GiveYourAnswerComponent implements OnInit {
  loader: boolean = false;
  questionId = '';
  pageInfo = '';
  routerUrl: any;
  insertBlankFlag: boolean = false;
  apiEndpoint = '';

  toolbarWithoutBlankButton = 'undo redo | formatselect | bold italic backcolor | \
  alignleft aligncenter alignright alignjustify | \
  bullist numlist outdent indent | removeformat | \
  link | preview';
  fileArr: any = [];
  giveAnswerForm: FormGroup;
  currentUsername = '';
  answerId = '';
  userId = '';
  role: any;
  courseId: string;
  session_id: string;
  topic_id
  videoStartTime: string;
  @ViewChild("fileU1", { static: false })
  fileUploadClear1: ElementRef;
  selectedPic: any = [];
  selectedVideo: any = [];
  selectedDoc: any = [];
  fileItems: any = [];
  course_session_id;
  sessionkey;
  course_category_id;
  course_category;
  progressBar: number;
  selectedFiles: FileList;
  progressInfos = [];
  message = '';

  fileInfos: Observable<any>;
  uploadedMedia: Array<any> = [];
  uploadArray = [];
  courseName:string = null;
  activeIdNav:any = 1;

  ngbNavItem:any = 1;

  constructor(private questionService: QuestionService,
    private route: ActivatedRoute,
    private router: Router,
    private ns: NotificationService,
    private fb: FormBuilder,
    private modalService:NgbModal,
    private UFS: UploadFilesService) {
    this.apiEndpoint = environment.APIEndpoint;
  }

  ngOnInit() {
    this.questionId = this.route.snapshot.queryParamMap.get('questionId');
    this.pageInfo = this.route.snapshot.queryParamMap.get('info');
    this.courseId = this.route.snapshot.queryParamMap.get('courseId');
    this.courseName = this.route.snapshot.queryParamMap.get('course');
    this.course_session_id = this.route.snapshot.queryParamMap.get('course_session_id');
    this.sessionkey = this.route.snapshot.queryParamMap.get('sessionkey');
    this.course_category_id = this.route.snapshot.queryParamMap.get('course_category_id');
    this.course_category = this.route.snapshot.queryParamMap.get('course_category');
    this.topic_id = this.route.snapshot.queryParamMap.get('topicId');
    this.activeIdNav = this.route.snapshot.queryParamMap.get('tab_no');
    this.ngbNavItem = this.route.snapshot.queryParamMap.get('ngbNavItem');


    let sp = this.router.url.split('?')
    this.routerUrl = sp[0];
    if (this.routerUrl == '/give-your-answer'){
      this.getQestionDetail();
    }else if (this.routerUrl == '/edit-your-answer'){
      this.getEditAnsDetail();
      this.getQestionDetail();
    }

    this.giveAnswerForm = this.fb.group({
      answer_text: ['',]
    })

    this.currentUsername = localStorage.getItem("currentUser");
    this.userId = localStorage.getItem('user_id');
    this.role = localStorage.getItem('role');
    this.courseId = this.route.snapshot.queryParamMap.get('courseId');
    this.session_id = this.route.snapshot.queryParamMap.get('session_id');
    this.videoStartTime = this.route.snapshot.queryParamMap.get('videoStartTime');
  }

  ngAfterViewInit() {
    this.callMceEditor('textanswer');
  }

  questionName: string;
  tags: any = [];
  user_id: any;
  getQestionDetail() {
    this.loader = true;
    this.questionId = this.route.snapshot.queryParamMap.get('questionId');
    this.pageInfo = this.route.snapshot.queryParamMap.get('info');
    this.questionService.getUserQuestionAnswer(this.questionId).subscribe(res => {
      let questionData = res.response;
      this.loader = false;
      this.questionName = questionData.question;
      this.user_id = questionData.user_id;
      this.tags = questionData.tags;
      let categorySplit = questionData.course_category_taxonomy.split('/');
      this.categoryList = categorySplit;
    })
  }
  selectedDockey = [];
  selectedPickey = [];
  selectedVideokey = [];
  categoryList = [];
  getEditAnsDetail() {
    this.loader = true;
    this.questionId = this.route.snapshot.queryParamMap.get('questionId');
    this.answerId = this.route.snapshot.queryParamMap.get('answerId');

    this.questionService.myAnswer(this.answerId).subscribe(res => {
      let questionData = res.response;
      this.loader = false;
      this.questionName = questionData.question;
      this.user_id = questionData.user_id;
      this.selectedDockey = questionData.answer_doc_key;
      this.selectedPickey = questionData.answer_image_key;
      this.selectedVideokey = questionData.answer_video_key;
      // let categorySplit = questionData.course_category_taxonomy.split('/');
      // this.categoryList = categorySplit;
      tinymce.get("textanswer").setContent(questionData.answer_html);
      this.giveAnswerForm.controls.answer_text.setValue(questionData.answer_html);

      if (this.selectedDockey.length > 0) {
        this.resourceCondition(this.selectedDockey, this.uploadedMedia)
      }
      if (this.selectedPickey.length > 0) {
        this.resourceCondition(this.selectedPickey, this.uploadedMedia)
      }
      if (this.selectedVideokey.length > 0) {
        this.resourceCondition(this.selectedVideokey, this.uploadedMedia)
      }
      this.selectFilelenghth = this.uploadedMedia.length;
    })
  }

  
  // common method of resources array
  resourceCondition(getArrayItem, setArrayItem) {
    for (let i = 0; i < getArrayItem.length; i++) {
      let filePath = getArrayItem[i];
      let fileNameSplitA = getArrayItem[i].split('/');
      let fileNameSplitB = fileNameSplitA[1].split('__');
      let extension = fileNameSplitB.pop().split('.');
      let fileNameSplitTitle = fileNameSplitB[0] + '.' + extension[1];
      setArrayItem.push({
        FileName: fileNameSplitTitle,
        FileProgessSize: 100,
        FileProgress: 100,
        path: filePath
      });
    }
  }


  callMceEditor(edId) {
    tinymce.remove();
    let that = this;
    tinymce.baseURL = 'assets';
    tinymce.init({
      selector: 'textarea#' + edId,
      height: 300,
      // base_url: '/tinymce',
      suffix: '.min',
      themes: "assets/themes/silver",
      // icons: "assets/icons/",
      // content_css: '//www.tiny.cloud/css/codepen.min.css',

      // plugins: [
      //   'advlist autolink lists link image imagetools charmap print preview anchor',
      //   'searchreplace visualblocks code fullscreen',
      //   'insertdatetime media table paste code help wordcount'
      // ],
      toolbar: that.toolbarWithoutBlankButton,
      image_title: true,
      automatic_uploads: true,
      image_description: false,
      file_picker_types: 'file image media',
      // external_plugins: { tiny_mce_wiris: "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js" }, 
      // toolbar1: "tiny_mce_wiris_formulaEditor, tiny_mce_wiris_formulaEditorChemistry",
      file_picker_callback: function (cb, value, meta) {

        let input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        // Note: In modern browsers input[type="file"] is functional without 
        // even adding it to the DOM, but that might not be the case in some older
        // or quirky browsers like IE, so you might want to add it to the DOM
        // just in case, and visually hide it. And do not forget do remove it
        // once you do not need it anymore.

        input.onchange = function () {

          // var file = $event.path[0].files[0];
          let file = input.files[0];

          let reader: any = new FileReader();

          reader.onload = function () {

            // Note: Now we need to register the blob in TinyMCEs image blob
            // registry. In the next release this part hopefully won't be
            // necessary, as we are looking to handle it internally.
            let id = 'blobid' + (new Date()).getTime();
            let blobCache = tinymce.activeEditor.editorUpload.blobCache;
            let base64 = reader.result.split(',')[1];
            let blobInfo = blobCache.create(id, file, base64);

            blobCache.add(blobInfo);

            // call the callback and populate the Title field with the file name

            cb(reader.result, { title: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      },
      images_upload_handler: function (blobInfo, success, failure, progress) {
        let xhr, formData;

        xhr = new XMLHttpRequest();

        xhr.withCredentials = false;
        xhr.open('POST', that.apiEndpoint + '/upload-resource?module_name=answer');
        xhr.setRequestHeader('session-token', localStorage.getItem('token')); // manually set header

        xhr.upload.onprogress = function (e) {
          progress(e.loaded / e.total * 100);
        };

        xhr.onerror = function () {
          failure("Image upload failed due to a XHR Transport error. Code: " + xhr.status);
        };

        xhr.onload = function () {
          let json;
          if (xhr.status != (201 || 200)) {
            failure('HTTP Error: ' + xhr.status);
            return;
          }
          json = JSON.parse(xhr.response);
          if (!json || typeof json.resource_info[0].url != 'string') {
            failure('Invalid JSON: ' + xhr.response.resource_info);
            return;
          }

          success(json.resource_info[0].url);
          let fileObj: any = {};
          fileObj._id = json.resource_info[0].url,
            fileObj.type = "file"
          that.fileArr.push(fileObj);
        };

        formData = new FormData();
        formData.append('resource', blobInfo.blob(), blobInfo.filename());


        xhr.send(formData);
      },
      setup: function (editor) {
        if (that.insertBlankFlag) {
          editor.ui.registry.addButton('customBlankButton', {
            text: 'Add Blank',
          });
        }
      }
    });
  }

  discardaskForm() {
    tinymce.get("textanswer").resetContent();
    this.giveAnswerForm.controls.answer_text.setValue('');
    this.giveAnswerForm.reset();
    this.restUploadFile();
    this.progressInfos = [];
    this.selectFilelenghth = [];
    this.uploadedMedia = [];
  }

  
  selectFilelenghth;
  moduleName;
  browseFileLen = 0;
  selectedArray;
  fileSizeLimit: number;
  fileSize: number = 0;
  mediaFileSize: number = 0;
  onFileBrowse(event: Event) {
    const target = event.target as HTMLInputElement;
    let browseFile = target.files;
    this.browseFileLen = browseFile.length;
    this.uploadArray = [];
    this.processFiles(target.files);
  }
  processFiles(files) {
    let maxLimitItem = 10;
    this.fileSizeLimit = 20000000;
    this.selectedArray = this.uploadedMedia.length;
    let moreAllowItem = maxLimitItem - this.selectedArray;
    let fileLength = files.length;

    if (fileLength < moreAllowItem || fileLength == moreAllowItem) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (this.validateFile(file.name)) {
          this.ns.showNotification("info", 'Selected file format is not supported.');
          this.browseFileLen--;
        } else {
          let hasItem = this.uploadedMedia.find(s => s.FileName === file.name);
          if (!hasItem) {
            this.selectFilelenghth = this.selectedArray + this.browseFileLen;
            var reader = new FileReader();
            reader.readAsDataURL(file); // read file as data url
            reader.onload = (event: any) => {
              // called once readAsDataURL is completed

              let fileType = file.type;
              if (fileType.includes('image')) {
                this.moduleName = 'question-image';
              } else if (fileType.includes('video')) {
                this.moduleName = 'question-video';
              } else if (fileType.includes('audio')) {
                this.moduleName = 'question-file';
              } else if (fileType.includes('text') || fileType.includes('') || fileType.includes('application')) {
                this.moduleName = 'question-file';
              }

              this.uploadedMedia.push({
                FileName: file.name,
                FSB: file.size,
                FileSize:
                  this.UFS.getFileSize(file.size) +
                  ' ' +
                  this.UFS.getFileSizeUnit(file.size),
                FileType: file.type,
                FileUrl: event.target.result,
                FileProgessSize: 0,
                FileProgress: 0,
                Id: '',
                path: '',
                abc:false
              });

              this.startProgress(file, this.uploadedMedia.length - 1, this.moduleName);
            }

          } else {
            this.ns.showNotification("info", 'File already added.');
            this.browseFileLen--;
            //this.restUploadFile();
          }
        }
      }
      if (this.uploadedMedia.length > 0) {
        for (let m = 0; m < this.uploadedMedia.length; m++) {
          this.mediaFileSize += parseInt(this.uploadedMedia[m].FSB);
        }
      } else if (this.uploadedMedia.length < 1 || this.uploadedMedia.length == undefined) {
        this.mediaFileSize = 0;
      }
      let newSize = this.mediaFileSize + this.fileSize;
    } else {
      this.ns.showNotification("info", 'Only 10 files allowed.');
      this.browseFileLen = 0;
    }
  }
  savevisible: boolean = true;
  async startProgress(file, index, module_name) {
    let filteredFile = this.uploadedMedia
      .filter((u, index) => index === index)
      .pop();

    if (filteredFile != null) { 
      let fileSize = this.UFS.getFileSize(file.size);
      let fileSizeInWords = this.UFS.getFileSizeUnit(file.size);
      if (this.UFS.isApiSetup) {
        let formData = new FormData();
        formData.append('resource', file);

        this.UFS
          .uploadMedia(formData, module_name)
          .subscribe(
            (res: any) => {
              if (res.resource_info == undefined) {
                this.savevisible = false;
                
              } else {
                this.savevisible = true;
                this.uploadArray.push(res.resource_info)
              }
              
              this.uploadedMedia[index].abc = this.savevisible;

              if (res.status === 'progress') {
                let completedPercentage = parseFloat(res.message);

                filteredFile.FileProgessSize = `${(
                  (fileSize * completedPercentage) /
                  100
                ).toFixed(2)} ${fileSizeInWords}`;
                filteredFile.FileProgress = completedPercentage;

                let pro = this.uploadedMedia.find(s => s.FileProgress != 100)

              } else if (res.status === 'completed') {
                filteredFile.Id = res.resource_id;
                filteredFile.path = res.path;

                filteredFile.FileProgessSize = fileSize + ' ' + fileSizeInWords;
                filteredFile.FileProgress = 100;
              }

              if (res.resource_info) {
                filteredFile.Id = res.resource_info[0].resource_id;
                filteredFile.path = res.resource_info[0].path;
                this.restUploadFile();
              }
            },
            (error: any) => {
              console.log(error);
            }
          );
      } else {
        for (
          var f = 0;
          f < fileSize + fileSize * 0.0001;
          f += fileSize * 0.01
        ) {
          filteredFile.FileProgessSize = f.toFixed(2) + ' ' + fileSizeInWords;
          var percentUploaded = Math.round((f / fileSize) * 100);
          filteredFile.FileProgress = percentUploaded;
          await this.fakeWaiter(Math.floor(Math.random() * 35) + 1);
        }
      }
    }

  }

  fakeWaiter(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'exe') {
      return true;
    }
    else {
      return false;
    }
  }

  questionModel = null;
openModelDeleteRes(deleteRes) {
  this.questionModel = this.modalService.open(deleteRes, { size: "sm", centered: true });
}

closeResPopup() {
  this.questionModel.close();
  // this.questionId = null;
}

  deleteResouce(item, idx: number) {  
    let sp = item.path;
    let fileTitleSplit = sp.split('/');
    let fileTitle = fileTitleSplit[1];
      this.uploadedMedia = this.uploadedMedia.filter((u, index) => index !== idx);

      this.selectFilelenghth = this.uploadedMedia.length;
      this.questionService.deleteResource(sp).subscribe(res => {
        this.ns.showNotification("success", res.message);
        this.questionModel.close();
        this.restUploadFile();
        if(this.browseFileLen > 0){
          this.browseFileLen--;
        }
        
        if(this.uploadArray.length > 0){
          this.uploadArray.length--;
        }
        
      })
      if (this.selectFilelenghth == 0 || this.selectFilelenghth < 1) {
        this.restUploadFile();
      }
      if (this.isSubmitAnswer) {
        this.selectedDoc = [];
        this.selectedPic = [];
        this.selectedVideo = [];
      }
  }



  restUploadFile() {
    this.fileUploadClear1.nativeElement.value = "";
  }

  isSubmitAnswer: boolean = false;
  saveAnswer() {
    let folderPath;
    let uploadedMediaFiles;
    let uploadedMediaFilespath;
    for (let s = 0; s < this.uploadedMedia.length; s++) {
      uploadedMediaFiles = this.uploadedMedia[s];
      uploadedMediaFilespath = uploadedMediaFiles.path.split('/');
      folderPath = uploadedMediaFilespath[0];

      if (folderPath == 'question_bank_files') {
        this.selectedDoc.push(uploadedMediaFiles.path);
      }
      if (folderPath == 'question-images') {
        this.selectedPic.push(uploadedMediaFiles.path);
      }
      if (folderPath == 'question-videos') {
        this.selectedVideo.push(uploadedMediaFiles.path);
      }
    }

    this.isSubmitAnswer = true;

    if (!this.selectedPic) {
      this.selectedPic = null;
    }
    if (!this.selectedVideo) {
      this.selectedVideo = null;
    }
    if (!this.selectedDoc) {
      this.selectedDoc = null;
    }

    if (this.giveAnswerForm.valid) {
      let description = this.ns.titleCase(tinymce.get("textanswer").getContent());
      description = this.ns.paraString(description);
      this.giveAnswerForm.controls.answer_text.setValue(description);
      if (this.giveAnswerForm.value.answer_text == '') {
        this.ns.showNotification("info", "Please add your answer in text");

        this.selectedDoc = [];
        this.selectedPic = [];
        this.selectedVideo = [];
        return;
      }
      this.loader = true;
      let body;
      if (this.routerUrl == '/give-your-answer') {
        body = {
          answer_html: this.giveAnswerForm.value.answer_text,
          question_id: this.questionId,
          speech_text: '',
          answer_image: this.selectedPic,
          answer_video: this.selectedVideo,
          answer_doc: this.selectedDoc,
        }
        this.questionService.saveAnswers(body).subscribe(saveAnswer => {
          this.loader = false;
          this.restUploadFile();
          this.selectFilelenghth = [];
          this.giveAnswerForm.reset();
          this.ns.showNotification("success", "Answer successfully save");
          this.router.navigate(['/question-details'], { queryParams: { questionId: this.questionId, info: this.pageInfo,ngbNavItem: this.ngbNavItem } });
          
          this.getQestionDetail();

          if (this.courseId && this.course_session_id) {
            this.router.navigate(['/courses/video-with-notes'], { queryParams: { course_id: this.courseId, course:this.courseName, session_id: this.course_session_id, course_category: this.course_category, course_category_id: this.course_category_id, topicId:this.topic_id,sessionkey: this.sessionkey, ngbNavItem: this.ngbNavItem, tab_no:this.activeIdNav } })
          }
        },
          (error) => {
            this.ns.showNotification("error", error.error.detail);
          })
      } else if (this.routerUrl == '/edit-your-answer') {
        body = {
          answer_id: this.answerId,
          answer_html: this.giveAnswerForm.value.answer_text,
          question_id: this.questionId,
          speech_text: '',
          answer_image: this.selectedPic,
          answer_video: this.selectedVideo,
          answer_doc: this.selectedDoc
        }
        this.questionService.edit_answer(body).subscribe(res => {
          this.loader = false;
          this.restUploadFile();
          this.selectFilelenghth = [];
          this.giveAnswerForm.reset();
          this.ns.showNotification("success", "Answer successfully updated.");
          this.router.navigate(['/question-details'], { queryParams: { questionId: this.questionId, info: this.pageInfo,ngbNavItem: this.ngbNavItem } });
          this.getQestionDetail();

          if (this.courseId  && this.course_session_id ) {
            this.router.navigate(['/courses/video-with-notes'], { queryParams: { course_id: this.courseId, course:this.courseName, session_id: this.course_session_id, course_category: this.course_category, course_category_id: this.course_category_id, topicId:this.topic_id,sessionkey: this.sessionkey, ngbNavItem: this.ngbNavItem, tab_no:this.activeIdNav } })
          }
        },(error) => {
          this.ns.showNotification("error", error.error.detail);
        })
      }


    }
  }

  getTime(time) {
    return new Date(time).toString().split(' ').slice(0, 4).join(' ');
  }


}
